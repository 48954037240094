import React from 'react'
import { StyleSheet, TouchableOpacity, Keyboard } from 'react-native'
import { AppStateStore } from '../contexts/AppStateStore'
import Icons from './icons'
import { AppColors as COLORS } from '../common/AppColors'

export const DrawerHeaderButton = () => {
  const onPressed = () => {
    Keyboard.dismiss()
    AppStateStore.eventBus.emit('show-side-menu')
  }

  return <TouchableOpacity
    onPress={() => onPressed()}
    style={styles.button}
  >
    <Icons.Hamburger height={24} width={25} color={COLORS.orange.primary} />
  </TouchableOpacity>
}

const styles = StyleSheet.create({
  button: {
    padding: 10,
  },
})
