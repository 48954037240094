import React from "react";
import { Svg, Path } from 'react-native-svg';

export default (props: any) => {
    return (
        <Svg width="50" height="50" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path fill-rule="evenodd" clip-rule="evenodd" d="M5 14.625V6.625C5 5.725 5.725 5 6.625 5H36.35C37.25 5 37.975 5.725 37.975 6.625V14.65C37.975 15.55 37.25 16.275 36.35 16.275H6.625C5.725 16.25 5 15.525 5 14.625ZM53.375 24.375H6.675C5.775 24.375 5.05 25.1 5.05 26V34.025C5.05 34.925 5.775 35.65 6.675 35.65H53.4C54.3 35.65 55.025 34.925 55.025 34.025V26C55 25.1 54.275 24.375 53.375 24.375ZM45.725 43.75H6.625C5.725 43.75 5 44.475 5 45.375V53.4C5 54.275 5.725 55 6.625 55H45.725C46.625 55 47.35 54.275 47.35 53.375V45.35C47.325 44.475 46.6 43.75 45.725 43.75Z" fill="#4ED542"/>
        </Svg>

    );
}