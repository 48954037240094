import { Pressable, StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'
import React from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import PlayAudio from './PlayAudio'
import AudioTimeline from './AudioTimeline'

interface Props {
  onPress?: () => void
  style?: StyleProp<ViewStyle>
  disabled?: boolean
}

export const AudioPreview = (props: Props) => {
  return (
    <Pressable onPress={props.onPress} style={styles.container}>
      <View style={styles.icon}>
        <PlayAudio/>
      </View>
      <View style={styles.audioRepresentation} >
        <AudioTimeline/>
        <Text style={styles.time}>
          
        </Text>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 50,
    flex: 1,
    flexDirection: 'row',
  },
  audioRepresentation: {
    flex: 4,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  icon: {
    marginRight: "2%"
  },
  time: {
    fontWeight: 'bold',
    color: '#626262',
  }
})
