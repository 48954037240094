import axios from 'axios'

export type FileData = {
  uri: string
  type: string
  name: string
} | Blob

export const uploadFile = async (url: string, fields: {[key: string]: any}, file: FileData) => {
  const formData = new FormData()
  for (const k in fields) {
    formData.append(k, fields[k])
  }
  formData.append('file', file as any)

  const response = await axios.create({
    timeout: 90000,
  })
    .post(url, formData)

  return response.data
}
