import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { ScrollView, StyleSheet, TouchableOpacity, View, Platform } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { utc } from '../../common/Util'
import { DefaultText } from '../../components/DefaultText'
import { ReceivedFormField } from '../../api/schema/models/ReceivedForm'
import { runInAction } from 'mobx'
import { SentForm } from '../../api/schema/models/SentForm'
import { BoldText } from '../../components/BoldText'
import { AppColors as COLORS } from '../../common/AppColors'
import Icons from '../../components/icons'
import { KonektiIcon } from '../../components/KonektiIcon'
import { getModalManager } from '../../contexts/ModalContext'
import { API } from '../../api/API'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
//import * as Sentry from 'sentry-expo'

export type SentFormDetailScreenParams = {
  sentForm: SentForm
}

export const SentFormDetailScreen = observer((props: StackScreenProps<RootNavigationParamList, 'SentFormDetail'>) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    fields: [] as ReceivedFormField[],
    translateSentForm: undefined as SentForm | undefined,
    translateFields: [] as ReceivedFormField[],
    isLoadingTranslation: false,
    showTranslation: false,
  }))

  useEffect(() => {
    runInAction(() => {
      state.fields = props.route.params.sentForm.fields.length ? props.route.params.sentForm.fields : props.route.params.sentForm.formFields.map(f => ({ label: f.label, value: '' }))
    })
  }, [props.route.params.sentForm])

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Form', 'Form'),headerRight: () => {
        return (
          <View style={styles.translateButtonContainer}>
            <TouchableOpacity
              onPress={translateAll}
            >
              <KonektiIcon name={'translate-icon'} size={20} color={'#ff8d2d'} />
            </TouchableOpacity>
          </View>
        );
      }
    })
  }, [props.navigation])

  const sentForm = props.route.params.sentForm

  const translateAll = async () => {
    let translate = !state.showTranslation

    runInAction(() => state.isLoadingTranslation = true)

    if (translate && !state.translateSentForm) {
      try {
        const translation = await API.getTranslation(props.route.params.sentForm, AppStateStore.userLanguage)
        runInAction(() => {
          console.log(translation)
          state.translateSentForm = translation.translation
          state.translateFields = translation.translation.fields.length ? translation.translation.fields : translation.translation.formFields.map(f => ({ label: f.label, value: '' }))
        })
        console.log(state)
      } catch (err) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(err)
        }
        else {
          //Sentry.Native.captureException(err)
        }
        console.log(err)
        getModalManager()
          .showModal({
            title: t('Error', 'Error'),
            message: t('There was an error loading the translation', 'There was an error loading the translation'),
          })
        translate = false
      }
    }

    runInAction(() => {
      state.isLoadingTranslation = false
      state.showTranslation = translate
    })
  }

  return <ScrollView
  showsVerticalScrollIndicator={false} style={styles.container}>
    <SafeAreaView edges={['bottom']}>
      <View style={styles.section}>
        <DefaultText style={styles.sectionHeaderText}>{t('Title', 'Title')}</DefaultText>
        <View style={styles.sectionFormText}>
          <DefaultText style={styles.sectionText} numberOfLines={1}>{state.showTranslation? state.translateSentForm?.formTitle: sentForm.formTitle}</DefaultText>
        </View>
      </View>
      <View style={styles.section}>
        <DefaultText style={styles.sectionHeaderText}>{t('Date', 'Date')}</DefaultText>
        <View style={styles.sectionFormText}>
          <DefaultText style={styles.sectionText}>{utc(sentForm.sentAt).format('MM/DD/YYYY')}</DefaultText>
        </View>
      </View>
      <View style={styles.section}>
        <DefaultText style={styles.sectionHeaderText}>{t('Category', 'Category')}</DefaultText>
        <View style={styles.sectionFormText}>
          <DefaultText style={styles.sectionText}>{state.showTranslation? state.translateSentForm?.formCategoryName: sentForm.formCategoryName}</DefaultText>
        </View>
      </View>
      <View style={styles.section}>
        <DefaultText style={styles.sectionHeaderText}>{t('Document', 'Document')}</DefaultText>
        <TouchableOpacity onPress={() => props.navigation.push('DocumentViewer', { url: sentForm.formUrl!, title: sentForm.formTitle! })}>
          <View style={styles.sectionFormText}>
            <DefaultText style={styles.sectionText} numberOfLines={1}>{sentForm.formFilename}</DefaultText>
            <Icons.Forward width={12} height={20} color={COLORS.orange.primary} />
          </View>
        </TouchableOpacity>
      </View>
      {
        !sentForm.signedAt
          ? sentForm.isSignatureRequired
            ? <View style={[styles.sectionFormText, { marginBottom: 10 }]}>
              <Icons.Alert width={20} height={20} color={COLORS.red.secondary} variant='outline' />
              <DefaultText bold style={{ marginLeft: 4 }}>{t('Parent signature required', 'Parent signature is required on this document')}</DefaultText>
            </View>
            :
            <View style={[styles.sectionFormText, { marginBottom: 10 }]}>
              <Icons.Alert width={20} height={20} color={COLORS.red.secondary} variant='outline' />
              <DefaultText bold style={{ marginLeft: 4 }}>{t('Form not submitted', 'This form has not been submitted')}</DefaultText>
            </View>

          : <>

            <View style={[styles.sectionFormText, { marginBottom: 10 }]}>
              <DefaultText bold>{t('The following information was entered form this form', 'The following information was entered form this form')}</DefaultText>
            </View>
            {
              state.fields.map((field, idx) => <View style={styles.section} key={idx}>
                <DefaultText style={styles.sectionHeaderText}>{state.showTranslation? state.translateFields[idx].label: field.label}</DefaultText>
                <View style={styles.sectionFormText}>
                  <DefaultText style={styles.sectionText}>{state.showTranslation? state.translateFields[idx].value: field.value}</DefaultText>
                </View>
              </View>
              )
            }
            {
              sentForm.isSignatureRequired
                ? <>
                  <View style={[styles.sectionFormText, { marginBottom: 10 }]}>
                    <DefaultText bold style={{ fontSize: 16 }}>{t('Electronic Signature', 'Electronic Signature')}</DefaultText>
                  </View>
                  <View style={{ padding: 12, flexDirection: 'row' }}>
                    <BoldText style={{ flex: 1 }}>
                      {sentForm.signature}
                    </BoldText>
                    <BoldText>
                      {utc(sentForm.signedAt!).format('MMM D, YYYY')}
                    </BoldText>
                  </View>
                </>
                : null
            }
          </>

      }
    </SafeAreaView>
  </ScrollView>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: COLORS.white,
    marginTop: 16,
  },
  section: {
    marginBottom: 20
  },
  sectionHeaderText: {
    color: COLORS.blue.primary,
    marginBottom: 2,
    fontSize: 14
  },
  sectionText: {
    fontSize: 16,
    color: COLORS.gray.neutral1,
    flex: 1,
  },
  sectionFormText: {
    minHeight: 56,
    paddingHorizontal: 16,
    flex: 1,
    backgroundColor: COLORS.gray.neutral6,
    justifyContent: 'center',
    borderRadius: 6,
    flexDirection: 'row',
    alignItems: 'center',
  },
  translateButtonContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 15
  },
})
