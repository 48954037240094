import React, { useCallback, useEffect } from 'react'
import { FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { BoldText } from '../BoldText'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { ContentActivityIndicator } from '../ContentActivityIndicator'
import { runInAction } from 'mobx'
import { ErrorMessage } from '../ErrorMessage'
import { BlockButton } from '../BlockButton'
import { ListItemSeparator } from '../ListItemSeparator'
import { SafeAreaView } from 'react-native-safe-area-context'
import { SearchTextInput } from '../SearchTextInput'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { ExpirationOption } from '../../api/schema/models/ExpirationOption'

export type ExpirationPickerScreenParams = {
  title: string
  onChoose: (option: ExpirationOption, pop: () => void) => void
}

export const ExpirationPickerScreen = observer((props: StackScreenProps<RootNavigationParamList, 'ExpirationPicker'>) => {
  const { t } = useTranslation()

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: props.route.params.title,
    })
  }, [props.navigation])

  const state = useLocalObservable(() => ({
    loading: false,
    error: undefined as undefined | string,
    searchText: '',
    options: [
      {
        "value": 0.5,
        "name": "30 minutes"
      },
      {
        "value": 1,
        "name": "1 hour"
      },
      {
        "value": 2,
        "name": "2 hours"
      },
      {
        "value": 8,
        "name": "8 hours"
      },
      {
        "value": 24,
        "name": "1 day"
      },
      {
        "value": 168,
        "name": "1 week"
      }
    ],

  }))

  const pop = () => props.navigation.pop()

  const renderItem = (item: ListRenderItemInfo<ExpirationOption>) => {
    return <TouchableOpacity onPress={() => props.route.params.onChoose(item.item, pop)}>
      <View style={styles.listItem}>
        <BoldText style={{ color: '#999' }}>{item.item.name}</BoldText>
      </View>
    </TouchableOpacity>
  }

  const loadOptions = useCallback(async () => {
    runInAction(() => {
      state.loading = true
      state.error = undefined
    })

    runInAction(() => state.loading = false)
  }, [])

  useEffect(() => {
    loadOptions().then()
  }, [loadOptions])

  return <View style={styles.container}>
    {
      state.loading
        ? <ContentActivityIndicator />
        : state.error
          ? <>
            <ErrorMessage message={state.error} />
            <BlockButton
              variant={'secondary'}
              title={t('Retry', 'Retry')}
              onPress={() => loadOptions()}
            />
          </>
          : <>

            <FlatList
              style={styles.list}
              data={state.options}
              renderItem={renderItem}
              keyExtractor={i => `${i.name}:${i.value}`}
              ItemSeparatorComponent={ListItemSeparator}
            />
          </>
    }
    <SafeAreaView edges={['bottom']}>
      <View />
    </SafeAreaView>
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  list: {
    flex: 1,
  },
  listItem: {
    padding: 16,
  },
  footer: {
    flexDirection: 'row',
  },
})
