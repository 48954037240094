import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { Form } from '../../api/schema/models/Form'
import { KeyboardAvoidingView, Platform, ScrollView, StyleSheet, TouchableOpacity, View, Dimensions } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { handleErrorResponse, utc } from '../../common/Util'
import { DefaultText } from '../../components/DefaultText'
import { ReceivedForm, ReceivedFormField } from '../../api/schema/models/ReceivedForm'
import { BlockButton } from '../../components/BlockButton'
import { getModalManager } from '../../contexts/ModalContext'
import { runInAction } from 'mobx'
import { FormTextInput } from '../../components/forms/FormTextInput'
import ErrorBag from '../../common/ErrorBag'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { FormError } from '../../components/FormError'
import { API } from '../../api/API'
import { BoldText } from '../../components/BoldText'
import { AppColors as COLORS } from '../../common/AppColors'
import { KonektiIcon } from '../../components/KonektiIcon'
import Icons from '../../components/icons'
//import * as Sentry from 'sentry-expo'

export type ReceivedFormDetailScreenParams = {
  receivedForm: ReceivedForm
}

export const windowHeight = Dimensions.get('window').height


export const ReceivedFormDetailScreen = observer((props: StackScreenProps<RootNavigationParamList, 'ReceivedFormDetail'>) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    fields: [] as ReceivedFormField[],
    translateFields: [] as ReceivedFormField[],
    formErrors: new ErrorBag(),
    translateReceivedForm: undefined as ReceivedForm | undefined,
    isLoadingTranslation: false,
    showTranslation: false,
    get errorFields(): string[] {
      return this.fields.map((f, idx) => `field_${idx}`)
    },
  }))

  useEffect(() => {
    runInAction(() => {
      state.fields = props.route.params.receivedForm.fields.length ? props.route.params.receivedForm.fields : props.route.params.receivedForm.formFields.map(f => ({ label: f.label, value: '' }))
    })
  }, [props.route.params.receivedForm])

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Form', 'Form'),
      headerRight: () => {
        return (
          <View style={styles.translateButtonContainer}>
            <TouchableOpacity
              onPress={translateAll}
            >
              <KonektiIcon name={'translate-icon'} size={20} color={'#ff8d2d'} />
            </TouchableOpacity>
          </View>
        );
      }
    })
  }, [props.navigation])

  const receivedForm = props.route.params.receivedForm

  const submit = async (receivedForm: ReceivedForm, signed: boolean) => {
    if (receivedForm.isSignatureRequired && !signed) {
      getModalManager()
        .showModal({
          //title: t('Confirm', 'Confirm'),
          message: t('Electronic signature required', 'Are you sure you want to sign this Document?'),
          buttons: [
            {
              text: t('Cancel', 'Cancel'),
              variant: 'primary',
            },
            {
              text: t('Sign Document', 'Yes'),
              variant: 'secondary',
              onPress: dismiss => {
                dismiss()
                submit(receivedForm, true).then()
              },
            },

          ]
        })
    } else {
      runInAction(() => {
        state.formErrors.clearErrors()
      })

      AppStateStore.showActivityIndicator()

      try {
        await API.submitForm(props.route.params.receivedForm.id, {
          student_id: props.route.params.receivedForm.student.id,
          e_signature_group_id: props.route.params.receivedForm.e_signature_group_id
        })

        AppStateStore.eventBus.emit(AppEvents.FormSigned)

        props.navigation.pop()
      } catch (err: any) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(err)
        }
        else {
          //Sentry.Native.captureException(err)
        }
        runInAction(() => {
          handleErrorResponse(err.response, state.formErrors, {
            unhandledErrors: {
              handledErrorFields: state.errorFields,
              unhandledErrorsKey: '_unhandled',
            }
          })
        })
      }

      AppStateStore.hideActivityIndicator()
    }
  }

  const translateAll = async () => {
    let translate = !state.showTranslation

    runInAction(() => state.isLoadingTranslation = true)

    if (translate && !state.translateReceivedForm) {
      try {
        const translation = await API.getTranslation(props.route.params.receivedForm, AppStateStore.userLanguage)
        runInAction(() => {
          console.log(translation)
          state.translateReceivedForm = translation.translation
          //state.translateFields = translation.translation.fields.length ? translation.translation.fields : translation.translation.formFields.map(f => ({ label: f.label, value: '' }))
        })
        console.log(state)
      } catch (err) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(err)
        }
        else {
          //Sentry.Native.captureException(err)
        }
        console.log(err)
        getModalManager()
          .showModal({
            title: t('Error', 'Error'),
            message: t('There was an error loading the translation', 'There was an error loading the translation'),
          })
        translate = false
      }
    }

    runInAction(() => {
      state.isLoadingTranslation = false
      state.showTranslation = translate
    })
  }

  return <KeyboardAvoidingView
    {...(Platform.OS === "ios" ? { behavior: "padding" } : {})}
    style={[styles.container, { flex: 1 }]}
    enabled
    contentContainerStyle={{ flex: 1 }}>
    <ScrollView
      showsVerticalScrollIndicator={false}>
      <SafeAreaView edges={['bottom']}>
        <View style={styles.section}>
          <DefaultText style={styles.sectionHeaderText}>{t('Title', 'Title')}</DefaultText>
          <View style={styles.sectionFormText}>
            <DefaultText style={styles.sectionText} numberOfLines={1}>{state.showTranslation ? state.translateReceivedForm?.formTitle : receivedForm.formTitle}</DefaultText>
          </View>
        </View>

        {/*  <View style={styles.section}>
        <DefaultText style={styles.sectionHeaderText}>{t('Sender', 'Sender')}</DefaultText>
        <View style={styles.sectionFormText}>
          <DefaultText style={styles.sectionText} numberOfLines={1}>{receivedForm.teacher ? `${receivedForm.teacher.displayName ?? receivedForm.teacher.firstName + ' ' + receivedForm.teacher.lastName}` : 'Teacher'}</DefaultText>
        </View>
      </View> */}

        <View style={styles.section}>
          <DefaultText style={styles.sectionHeaderText}>{t('Student', 'Student')}</DefaultText>
          <View style={styles.sectionFormText}>
            <DefaultText style={styles.sectionText} numberOfLines={1}>{state.showTranslation ? `${state.translateReceivedForm?.student.firstName} ${state.translateReceivedForm?.student.lastName}` : `${receivedForm.student.firstName} ${receivedForm.student.lastName}`}</DefaultText>
          </View>
        </View>
        <View style={styles.section}>
          <DefaultText style={styles.sectionHeaderText}>{t('Date', 'Date')}</DefaultText>
          <View style={styles.sectionFormText}>
            <DefaultText style={styles.sectionText}>{utc(receivedForm.sentAt).format('MM/DD/YYYY')}</DefaultText>
          </View>
        </View>
        {/* <View style={styles.section}>
        <DefaultText style={styles.sectionHeaderText}>{t('Category', 'Category')}</DefaultText>
        <View style={styles.sectionFormText}>
          <DefaultText style={styles.sectionText}>{state.showTranslation ? state.translateReceivedForm?.formCategoryName: receivedForm.formCategoryName}</DefaultText>
        </View>
      </View> */}

        <View style={styles.section}>
          <View style={styles.sectionFormText}>
            <DefaultText style={styles.sectionText}>{receivedForm.formMessage}</DefaultText>
          </View>
        </View>
        <View style={styles.section}>
          <DefaultText style={styles.sectionHeaderText}>{t('File', 'File')}</DefaultText>
          <TouchableOpacity onPress={() => props.navigation.push('FormViewer', { signatureId: receivedForm.id, studentId: receivedForm.student.id })}>
            <View style={styles.sectionFormText}>
              <DefaultText style={styles.sectionText} numberOfLines={1}>{receivedForm.formFilename}</DefaultText>
              <Icons.Forward width={12} height={20} color={COLORS.orange.primary} />
            </View>
          </TouchableOpacity>
        </View>
        {
          !props.route.params.receivedForm.signedAt
            ? <>
              {
                AppStateStore.userContext!.userType === 'parent'
                  ? <>
                    <FormError field={'_unhandled'} errors={state.formErrors} />
                    {
                      state.fields.length
                        ? <>
                          <View style={[styles.sectionFormText]}>
                            <DefaultText bold>{t('Review document fields', 'Please review the attached document and then complete the form below')}</DefaultText>
                          </View>
                          {
                            state.fields.map((field, idx) => <View>
                              <DefaultText style={[styles.sectionHeaderText, { marginTop: 20 }]}>{state.showTranslation ? state.translateFields[idx].label : field.label}</DefaultText>
                              <FormTextInput
                                key={idx}
                                placeholder={''}
                                value={field.value}
                                onChangeText={text => runInAction(() => state.fields[idx].value = text)}
                                errors={state.formErrors}
                                fieldName={`field_${idx}`}
                              />
                            </View>)
                          }
                        </>
                        : null
                    }
                    {
                      receivedForm.isSignatureRequired
                        ? <>
                          <View style={[{ marginBottom: 20, flex: 1, justifyContent: 'center', alignItems: 'center' }]}>
                            <DefaultText >{t('Review document signature', 'Please review the attached document before completing the electronic signature below.')}</DefaultText>
                          </View>
                          <View style={styles.footer}>
                            <BlockButton
                              variant='secondary'
                              style={styles.footerButton}
                              title={t('Sign', 'Sign')}
                              onPress={() => submit(receivedForm, false).then()}
                            />
                          </View>
                        </>
                        : <>
                          <BlockButton
                            variant='secondary'
                            style={{ marginTop: 10 }}
                            title={t('Submit Form', 'Submit Form')}
                            onPress={() => submit(receivedForm, false).then()}
                          />
                        </>
                    }
                  </>
                  : <>
                    <View style={styles.sectionFormText}>
                      <Icons.Alert width={20} height={20} color={COLORS.red.secondary} variant='outline' />
                      <DefaultText bold style={{ marginLeft: 4 }}>{t('Parent signature required', 'Parent signature is required on this document')}</DefaultText>
                    </View>
                  </>
              }
            </>
            : <>
              <View style={[styles.sectionFormText, { marginBottom: 10 }]}>
                <DefaultText bold style={{ fontSize: 16 }}>{t('You entered the following information', 'You entered the following information')}</DefaultText>
              </View>
              {
                state.fields.map((field, idx) => <View style={styles.section} key={idx}>
                  <DefaultText style={styles.sectionHeaderText}>{state.showTranslation ? state.translateFields[idx].label : field.label}</DefaultText>
                  <View style={styles.sectionFormText}>
                    <DefaultText style={styles.sectionText}>{state.showTranslation ? state.translateFields[idx].value : field.value}</DefaultText>
                  </View>
                </View>
                )
              }
              {
                receivedForm.isSignatureRequired
                  ? <>
                    <View style={[styles.sectionFormText, { marginBottom: 10 }]}>
                      <DefaultText bold style={{ fontSize: 16 }}>{t('Electronic Signature', 'Electronic Signature')}</DefaultText>
                    </View>
                    <View style={{ padding: 12, flexDirection: 'row' }}>
                      <BoldText style={{ flex: 1 }}>
                        {receivedForm.signature}
                      </BoldText>
                      <BoldText>
                        {utc(receivedForm.signedAt!).format('MMM D, YYYY')}
                      </BoldText>
                    </View>
                  </>
                  : null
              }
            </>
        }
      </SafeAreaView>
    </ScrollView >
  </KeyboardAvoidingView>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: COLORS.white,
    marginTop: 16,
  },
  section: {
    marginBottom: 20
  },
  sectionHeaderText: {
    color: COLORS.blue.primary,
    marginBottom: 2,
    fontSize: 14
  },
  sectionText: {
    fontSize: 16,
    color: COLORS.gray.neutral1,
    flex: 1,
  },
  sectionFormText: {
    minHeight: 56,
    paddingHorizontal: 16,
    paddingVertical: 12,
    flex: 1,
    backgroundColor: COLORS.gray.neutral6,
    justifyContent: 'center',
    borderRadius: 6,
    flexDirection: 'row',
    alignItems: 'center',
  },
  translateButtonContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 15
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: windowHeight * 0.19
  },
  footerButton: {
    marginRight: 1,
    width: '100%',
    maxHeight: 60,
    borderRadius: 8,
    marginTop: 20,
    backgroundColor: COLORS.orange.primary,
  },
})
