import { observer, useLocalObservable } from 'mobx-react-lite';
import { StyleSheet, TouchableOpacity, View, Platform } from 'react-native';
import React, { useEffect } from 'react';
import { Audio, AVPlaybackStatus } from 'expo-av';
import { runInAction } from 'mobx';
import { AppColors } from '../../common/AppColors';
import Icons from '../icons';
import { AudioProgressBar, AudioProgressBarVariant } from './AudioProgressBar';
//import * as Sentry from 'sentry-expo'

interface Props {
  url: string;
  progressBarVariant?: AudioProgressBarVariant;
}

interface State {
  loading: boolean;
  playing: boolean;
  playProgressMillis: number;
  durationMillis: number;
  soundInstance?: Audio.Sound;
  progressBarWidth: string;
}

export const MessageAudioPlayer = observer(({
  url,
  progressBarVariant,
}: Props) => {
  const state = useLocalObservable<State>(() => ({
    loading: false,
    playing: false,
    playProgressMillis: 0,
    durationMillis: 0,
    soundInstance: undefined,
    get progressBarWidth() {
      if (this.durationMillis === 0) {
        return `0%`
      } else {
        return `${Math.ceil(this.playProgressMillis / this.durationMillis * 100)}%`
      }
    },
  }))

  useEffect(() => {
    loadAudio().then()
    return () => {
      unloadAudio().then()
    }
  }, [
    url,
  ])

  const loadAudio = async () => {
    runInAction(() => state.loading = true)
    await unloadAudio()

    if (url) {
      try {
        const soundObject = new Audio.Sound()
        const status = await soundObject.loadAsync({ uri: url }, {
          shouldPlay: false,
        })

        soundObject.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate)

        runInAction(() => {
          state.playing = false
          state.durationMillis = status.isLoaded ? (status.durationMillis ?? 0) : 0
          state.playProgressMillis = status.isLoaded ? (status.positionMillis ?? 0) : 0
          state.soundInstance = soundObject
        })
      } catch (err) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(err)
        }
        else {
          //Sentry.Native.captureException(err)
        }
      }
    }

    runInAction(() => state.loading = false)
  }

  const onPlaybackStatusUpdate = (status: AVPlaybackStatus) => {
    if (status.isLoaded) {
      if (status.didJustFinish) {
        state.soundInstance!.stopAsync().then()
      }

      runInAction(() => {
        state.durationMillis = status.durationMillis ?? 0
        state.playProgressMillis = status.positionMillis
        state.playing = status.isPlaying
      })
    }
  }

  const unloadAudio = async () => {
    if (state.soundInstance) {
      await state.soundInstance.unloadAsync()
      runInAction(() => state.soundInstance = undefined)
    }
  }

  const playAudio = async () => {
    if (state.soundInstance) {
      if (state.playing) {
        await state.soundInstance.stopAsync()
      } else {
        await state.soundInstance.playAsync()
      }
    }
  }

  return (
    <View style={styles.container}>
      <AudioProgressBar
        playProgressMillis={state.playProgressMillis}
        durationMillis={state.durationMillis}
        variant={progressBarVariant}
      />
      <TouchableOpacity style={styles.button} onPress={playAudio}>
        {
          state.playing
            ? <Icons.AudioPauseIcon color={AppColors.orange.primary} />
            : <Icons.AudioPlayIcon color={AppColors.orange.primary} />
        }
      </TouchableOpacity>
    </View>
  );
})

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  durationText: {
    color: AppColors.white,
    fontFamily: 'Rubik_500Medium',
    fontSize: 12,
  },
  playbackStatus: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: AppColors.gray.neutral3,
    borderRadius: 8,
    marginRight: 6,
  },
  progressBar: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 0,
    backgroundColor: AppColors.blue.primary,
    borderRadius: 8,
  },
  button: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: AppColors.white,
    borderRadius: 20,
  },
})
