import { Image, ImageResizeMode, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle, ImageStyle } from 'react-native'
import React from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons'

interface Props {
  posterImageUrl: string
  onPress?: () => void
  style?: StyleProp<ViewStyle>
  resizeMode?: ImageResizeMode
  disabled?: boolean
  shadowStyle?: StyleProp<ViewStyle>
  borderStyle?: StyleProp<ImageStyle>
}

export const VideoPreview = (props: Props) => {
  return <TouchableOpacity onPress={props.onPress} style={[styles.container, props.style]} disabled={props.disabled}>
    <View style={[StyleSheet.absoluteFillObject, styles.wrapper, props.shadowStyle]}>
      <Image
        source={{ uri: props.posterImageUrl }}
        style={[StyleSheet.absoluteFillObject, props.borderStyle, {borderRadius: 10}]}
        resizeMode={props.resizeMode ?? "contain"}
      />
      <MaterialCommunityIcons style={styles.playIcon} name="play-circle"/>
    </View>
  </TouchableOpacity>
}

const styles = StyleSheet.create({
  container: {},
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10
  },
  playIcon: {
    fontSize: 40,
    color: '#fff',
    textShadowColor: '#000',
    textShadowRadius: 8,
    elevation: 100,
    zIndex: 100
  },
})
