import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { Form } from '../../api/schema/models/Form'
import { ScrollView, StyleSheet, TouchableOpacity, View, Text, Platform } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { runInAction } from 'mobx'
import { extractErrorMessage, fullName, utc } from '../../common/Util'
import { API } from '../../api/API'
import { ContentActivityIndicator } from '../../components/ContentActivityIndicator'
import { ErrorMessage } from '../../components/ErrorMessage'
import { DefaultText } from '../../components/DefaultText'
import { FormSelectInput } from '../../components/forms/FormSelectInput'
import { BlockButton } from '../../components/BlockButton'
import { getModalManager } from '../../contexts/ModalContext'
import { VisibilityOption } from '../../api/schema/models/VisibilityOption'
import { StudentOption } from '../../api/schema/models/StudentOption'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { AppColors as COLORS } from '../../common/AppColors'
import Icons from '../../components/icons'
import { SignatureGroupDto } from '../../api/schema/models/SignatureDto'
import { ModalBottomOptions, ModalConfirmation } from '../../components/modals'
//import * as Sentry from 'sentry-expo'


export type SendFormScreenParams = {
  formId: number
}

export const SendFormScreen = observer((props: StackScreenProps<RootNavigationParamList, 'SendForm'>) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false);

  const state = useLocalObservable(() => ({
    form: undefined as Form | undefined,
    error: undefined as string | undefined,
    openModal: false,
    get sendLabel() {
      return undefined
    },
    submitting: false,
    get isValid() {
      return this.recipients.groups.length > 0 || this.recipients.students.length > 0
    },
    recipients: {
      //groups: [] as VisibilityOption[],
      groups: [] as SignatureGroupDto[],
      students: [] as StudentOption[],
    },
    get isNotSent() {
      return this.recipients.groups.find(g => g.sent_at === null) !== undefined
    }
  }))

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Detail', 'Detail'),
    })
  }, [props.navigation])

  const loadForm = useCallback(async () => {
    runInAction(() => {
      state.form = undefined
      state.error = undefined
    })

    try {
      const response = await API.getForm(props.route.params.formId)
      runInAction(() => state.form = response.form)
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = extractErrorMessage(err))
    }

  }, [state, props.route.params.formId])

  const loadGroups = useCallback(async () => {
    try {
      const response = await API.getSignatureGroups(state.form!.id)
      runInAction(() => {
        state.recipients.groups = response.groups
      })

    } catch (err: any) {
      console.log(err)
    }

  }, [state, props.route.params.formId])

  useLayoutEffect(() => {
    loadForm().then(
      () => {
        loadGroups()
      }
    )
  }, [loadForm])

  const chooseRecipient = () => {
    runInAction(() => state.openModal = false)
    showVisibilityPicker()
    /*  getModalManager()
       .showModal({
         type: 'bottom-drawer',
         title: t('Send To', 'Send To'),
         message: t('Would you like to send this form to a group or choose a specific student', 'Would you like to send this form to a group or choose a specific student?'),
         buttons: [
           {
             text: t('Group', 'Group'),
             onPress: dismiss => {
               dismiss()
               showVisibilityPicker()
             }
           },
             {
              text: t('Student', 'Student'),
              variant: 'secondary',
              onPress: dismiss => {
                dismiss()
                showStudentPicker()
              }
            }, 
           { text: t('Cancel', 'Cancel'), variant: 'tertiary' },
         ]
       }) */
  }

  const addRecipientGroup = async (option: VisibilityOption, pop: () => void) => {
    try {

      await API.updateSignatureGroups(state.form!.id, option)
      loadGroups()

    } catch (err: any) {

      console.log(err)

    }
    pop()

  }

  const addRecipientStudent = (option: StudentOption, pop: () => void) => {
    runInAction(() => {
      if (!state.recipients.students.find(s => s.id === option.id)) {
        state.recipients.students.push(option)
      }
    })
    pop()
  }

  const showVisibilityPicker = () => {
    props.navigation.push('VisibilityPicker', { title: t('Send To', 'Send To'), onChoose: addRecipientGroup, form: true })
  }

  const showStudentPicker = () => {
    props.navigation.push('StudentPicker', { title: t('Send To', 'Send To'), onChoose: addRecipientStudent })
  }

  const handleSumbit = () => {
    //Keyboard.dismiss()
    setShowModal(true);
  }

  const onSubmit = async () => {
    setShowModal(false);
    AppStateStore.showActivityIndicator()

    try {
      await API.sendForm(props.route.params.formId)
      /*  await API.sendForm(props.route.params.formId, {
         recipients: state.recipients,
       }) */

      AppStateStore.eventBus.emit(AppEvents.FormSent)

      props.navigation.pop()
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      getModalManager()
        .showModal({
          title: t('Error', 'Error'),
          message: extractErrorMessage(err.response),
        })
    }

    AppStateStore.hideActivityIndicator()
  }

  const hanldeDeleteGroup = async (group: SignatureGroupDto) => {
    try {
      await API.deleteSignatureGroup(group.id)
      runInAction(() => {
        state.recipients.groups = state.recipients.groups.filter(g => g.id !== group.id)
      })
    } catch (err: any) {
      console.log(err)
    }
  }

  return state.error
    ? <ErrorMessage message={state.error} />
    : !state.form
      ? <ContentActivityIndicator />
      : <View style={{ flex: 1 }}>
        <ModalConfirmation
          visible={showModal}
          onDismiss={() => setShowModal(false)}
          onSuccess={onSubmit}
        >
          <Text numberOfLines={3} style={{ textAlign: 'center' }}>
            <Text style={{ fontSize: 18 }}>
              Are you sure you want to {'\n'} send <Text style={{
                fontWeight: 'bold',
                fontSize: 18
              }}>
                E-Signature?
              </Text>
            </Text>

          </Text>
        </ModalConfirmation>
        <ScrollView style={styles.container}>
          <SafeAreaView edges={['bottom']}>
            <View style={styles.section}>
              <DefaultText bold style={styles.sectionHeaderText}>{t('Title', 'Title')}</DefaultText>
              <View style={styles.sectionFormText}>
                <DefaultText style={styles.sectionText} numberOfLines={1}>{state.form.title}</DefaultText>
              </View>
            </View>
            <View style={styles.section}>
              <DefaultText bold style={styles.sectionHeaderText}>{t('Created', 'Created')}</DefaultText>
              <View style={styles.sectionFormText}>
                <DefaultText style={styles.sectionText}>{utc(state.form.createdAt).format('MM/DD/YYYY')}</DefaultText>
              </View>
            </View>
            <View style={styles.section}>
              <DefaultText bold style={styles.sectionHeaderText}>{t('Category', 'Category')}</DefaultText>
              <View style={styles.sectionFormText}>
                <DefaultText style={styles.sectionText}>{state.form.categoryName}</DefaultText>
              </View>
            </View>
            <View style={styles.section}>
              <View style={styles.sectionFormText}>
                <DefaultText style={styles.sectionText}>{state.form.message}</DefaultText>
              </View>
            </View>
            <View style={styles.section}>
              <DefaultText bold style={styles.sectionHeaderText}>{t('File', 'File')}</DefaultText>
              <TouchableOpacity onPress={() => props.navigation.push('DocumentViewer', { url: state.form!.formUrl!, title: state.form!.title })}>
                <View style={styles.sectionFormText}>
                  <DefaultText style={styles.sectionText} numberOfLines={1}>{state.form.formFilename}</DefaultText>
                  <Icons.Forward width={12} height={20} color={COLORS.orange.primary} />
                </View>
              </TouchableOpacity>
            </View>
            {
              state.form.fields.length
                ? <View style={styles.section}>
                  <DefaultText style={styles.sectionHeaderText}>{t('Fields', 'Fields')}</DefaultText>
                  <View style={[styles.sectionFormText, { flexDirection: 'column', alignItems: 'flex-start' }]}>
                    {
                      state.form.fields.map((f, idx) => <View key={idx} style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <DefaultText style={{ marginRight: 4 }}>{f.label}</DefaultText>
                        {
                          f.required
                            ? <Icons.Alert width={10} height={10} color={COLORS.red.secondary} variant='outline' />
                            : null
                        }
                      </View>)
                    }
                  </View>
                </View>
                : null
            }
            <FormSelectInput
              label={t('Send to', 'Send to') + '...'}
              value={state.sendLabel}
              onPress={() => showVisibilityPicker()}
              //onPress={() => runInAction(() => state.openModal = true)}
              placeholder={t('Choose', 'Choose')}
              style={{ marginBottom: 20 }}
            />
            {
              state.recipients.groups.map(group => <View key={group.id} style={styles.section}>
                <View style={styles.sectionFormText}>
                  <View style={{ flex: 1 }}>
                    <DefaultText bold style={{ flex: 1 }}>{group.visibility_name}</DefaultText>
                    <DefaultText style={{ flex: 1 }}>{`${group.recipients} Recipients`}</DefaultText>
                  </View>
                  {
                    !group.sent_at ?
                      <TouchableOpacity onPress={() => hanldeDeleteGroup(group)}>
                        <Icons.Delete width={16} height={20} variant='outline' color={COLORS.orange.primary} />
                      </TouchableOpacity>
                      : null
                  }
                </View>
              </View>)
            }
            {
              state.recipients.students.map(student => <View key={`${student.id}`} style={styles.section}>
                <View style={styles.sectionFormText}>
                  <View style={{ flex: 1 }}>
                    <DefaultText>{fullName(student)}</DefaultText>
                    {
                      student.externalId
                        ? <DefaultText style={{ color: COLORS.gray.neutral2 }}>{student.externalId}</DefaultText>
                        : null
                    }
                  </View>
                  <TouchableOpacity onPress={() => runInAction(() => state.recipients.students = state.recipients.students.filter(s => s.id !== student.id))}>
                    <Icons.Delete width={16} height={20} variant='outline' color={COLORS.orange.primary} />
                  </TouchableOpacity>
                </View>
              </View>)
            }
            {
              state.openModal && <ModalBottomOptions
                isVisible={state.openModal}
                title={t('Send To', 'Send To')}
                message={t('Would you like to send this form to a group or choose a specific student', 'Would you like to send this form to a group or choose a specific student?')}
                dismiss={() => runInAction(() => state.openModal = false)}
                buttons={[
                  { text: t('Group', 'Group'), variant: 'primary', onPress: () => chooseRecipient() },
                  {
                    text: t('Cancel', 'Cancel'),
                    variant: 'tertiary',
                    onPress: () => runInAction(() => state.openModal = false),
                  },
                ]}

              />
            }
          </SafeAreaView>
        </ScrollView>
        <View style={styles.footer}>
          <SafeAreaView edges={['bottom']} style={{ marginBottom: 16 }}>
            <BlockButton
              variant={'secondary'}
              title={t('Send E-signature', 'Send E-signature')}
              disabled={state.submitting || !state.isValid || !state.isNotSent}
              onPress={handleSumbit}
            //style={{ marginBottom: 60 }}
            />
          </SafeAreaView>
        </View>
      </View>
})




const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: COLORS.white,
    marginTop: 16,
  },
  section: {
    marginBottom: 20
  },
  sectionHeaderText: {
    color: COLORS.blue.primary,
    marginBottom: 2,
    fontSize: 14
  },
  sectionText: {
    fontSize: 16,
    color: COLORS.gray.neutral1,
    flex: 1,
  },
  sectionFormText: {
    minHeight: 56,
    paddingHorizontal: 16,
    flex: 1,
    backgroundColor: COLORS.gray.neutral6,
    justifyContent: 'center',
    borderRadius: 6,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5
  },
  button: {
    height: 60,
  },
  footer: {
    paddingHorizontal: 16,
  },
  drawerHeaderText: {
    paddingTop: 30,
    fontSize: 24,
    color: '#0082CD',
    textAlign: 'center',
    fontWeight: '500',
  },
  subText: {
    paddingTop: 15,
    paddingHorizontal: 50,
    fontSize: 16,
    color: '#303030',
    textAlign: 'center',
    fontWeight: '400',
  },
  drawerBody: {
    paddingHorizontal: 16,
    marginTop: 10
  }
})
