import { StyleSheet, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { DefaultText } from '../DefaultText'
import moment, { Moment } from 'moment-timezone'
import { ListItemSeparator } from '../ListItemSeparator'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import ErrorBag from '../../common/ErrorBag'
import { FormError } from '../FormError'
import { RootNavigationParamList } from '../../navigation/RootNav'

type Props = {
  placeholder: string
  value?: Moment
  dateOnly?: boolean
  onChange?: (date: Moment) => void
  onClear?: () => void
  disabled?: boolean
  hideSeparator?: boolean
  prefix?: string
  errors?: ErrorBag
  fieldName?: string
}

export const FormDateInput = (props: Props) => {
  const navigation = useNavigation<StackNavigationProp<RootNavigationParamList>>()

  const chooseDate = () => {
    navigation.push('DateTimePicker', {
      dateOnly: props.dateOnly,
      initialDate: props.value ?? moment(),
      allowClear: !!props.onClear,
      onChoose: (d, pop) => {
        if (d && props.onChange) {
          props.onChange(d)
        }

        if (!d && props.onClear) {
          props.onClear()
        }

        pop()
      },
    })
  }

  return <View>
    <TouchableOpacity onPress={chooseDate} disabled={props.disabled}>
      <View style={styles.container}>
        {
          props.value
            ? <>
              <DefaultText style={styles.date}>
                {props.prefix ? `${props.prefix} ` : null}
                {props.value.format('ddd, MMMM D, YYYY')}
              </DefaultText>
              {
                !props.dateOnly
                  ? <DefaultText style={styles.time}>
                    {props.prefix ? `${props.prefix} ` : null}
                    {props.value.format('h:mm A')}
                  </DefaultText>
                  : null
              }
            </>
            : <DefaultText style={styles.placeholder}>{props.placeholder}</DefaultText>
        }
      </View>
    </TouchableOpacity>
    {
      (props.errors && props.fieldName)
        ? <FormError field={props.fieldName} errors={props.errors}/>
        : null
    }
    {
      !props.hideSeparator
        ? <ListItemSeparator/>
        : null
    }
  </View>
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 50,
    paddingHorizontal: 12,
  },
  placeholder: {
    flex: 1,
    fontSize: 18,
    fontFamily: 'Rubik_400Regular',
    color: '#999',
  },
  date: {
    flex: 1,
    fontSize: 18,
    fontFamily: 'Rubik_400Regular',
    color: '#333',
  },
  time: {
    flex: 1,
    textAlign: 'right',
    fontSize: 18,
    fontFamily: 'Rubik_400Regular',
    color: '#333',
  },
})
