import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View, Platform } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { API } from '../../api/API'
import { runInAction } from 'mobx'
import { useTranslation } from 'react-i18next'
import { extractErrorMessage, MYSQL_DATE_FORMAT } from '../../common/Util'
import { ContentActivityIndicator } from '../../components/ContentActivityIndicator'
import { ErrorMessage } from '../../components/ErrorMessage'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { AttendanceSection } from '../../api/schema/models/AttendanceSection'
import moment, { Moment } from 'moment-timezone'
import { DefaultText } from '../../components/DefaultText'
import { ListItemSeparator } from '../../components/ListItemSeparator'
import { WeeklyCalendarPicker } from '../../components/attendance/WeeklyCalendarPicker'
import { Colors } from '../../common/Colors'
import { MaterialIcons } from '@expo/vector-icons'
import { useFocusEffect } from '@react-navigation/native'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { DrawerHeaderButton } from '../../components/DrawerHeaderButton'
import { BoldText } from '../../components/BoldText'
import { AppColors } from '../../common/AppColors'
//import * as Sentry from 'sentry-expo'

export const AttendanceScreen = observer((props: StackScreenProps<RootNavigationParamList, 'Attendance'>) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    attendanceSections: [] as AttendanceSection[],
    loading: false,
    error: undefined as string | undefined,
    date: moment(),
    needsRefresh: false,
  }))

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Attendance', 'Attendance'),
      headerLeft: () => <DrawerHeaderButton/>,
    })
  }, [props.navigation])

  const loadData = useCallback(async (date: Moment) => {
    runInAction(() => {
      state.error = undefined
      state.loading = true
    })

    try {
      const response = await API.getAttendanceSections(date)
      runInAction(() => state.attendanceSections = response.attendanceSections)
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => {
        state.error = extractErrorMessage(err)
      })
    }

    runInAction(() => {
      state.loading = false
    })
  }, [state])

  useEffect(() => {
    const listener = AppStateStore.eventBus.addRemovableListener(AppEvents.AttendanceInvalidated, () => runInAction(() => state.needsRefresh = true))
    return () => listener.remove()
  })

  useFocusEffect(() => {
    if (state.needsRefresh) {
      runInAction(() => state.needsRefresh = false)
      loadData(state.date).then()
    }
  })

  useLayoutEffect(() => {
    loadData(state.date).then()
  }, [loadData, state.date])

  const renderItem = (info: ListRenderItemInfo<AttendanceSection>) => {
    return <TouchableOpacity onPress={() => props.navigation.push('TakeAttendance', { sectionId: info.item.section.id, date: state.date.format(MYSQL_DATE_FORMAT) })}
              style={styles.section}>
      <View style={{ flexDirection: 'row', alignItems: 'center', padding: 12 }}>
        <BoldText style={{ flex: 1 }}>{info.item.section.name}</BoldText>
        {
          info.item.attendanceCompletedAt
            ? <BoldText style={styles.checklist}>{t('Complete','Complete').toUpperCase()}</BoldText>
            : null
        }
        <MaterialIcons
          name={'chevron-right'}
          size={30}
          color={'#FF7A33'}
        />
      </View>
    </TouchableOpacity>
  }

  return <View style={styles.container}>
    <WeeklyCalendarPicker
      disabled={state.loading}
      onDateSelected={date => runInAction(() => state.date = date.clone())}
      selectedDate={state.date}
    />
    {
      state.error
        ? <ErrorMessage message={state.error}/>
        : state.loading
          ? <ContentActivityIndicator/>
          : state.attendanceSections.length
            ? <FlatList
              data={state.attendanceSections}
              keyExtractor={s => String(s.section.id)}
              renderItem={renderItem}
              ListFooterComponent={<SafeAreaView edges={['bottom']}/>}
            />
            : <DefaultText style={styles.noAttendance}>{t('There are no attendance records to display', 'There are no attendance records to display')}</DefaultText>

    }
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColors.white
  },
  section: {
    backgroundColor:'#F6F7FA', 
    marginHorizontal:16, 
    marginVertical: 8, 
    borderRadius:6, 
    height: 56
  },
  checklist: {
    flex: 1, 
    color: '#0082CD', 
    textAlign:'right'
  },
  noAttendance: {
    textAlign: 'center',
    padding: 16,
    color: '#999',
    fontSize: 18,
  }
})
