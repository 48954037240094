import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import Icons from './icons'
import { AppColors } from '../common/AppColors'
import { Keyboard } from 'react-native'

export type AttachmentType = 'audio' | 'file' | 'photo' | 'video'

type AttachmentComponent = {
  [key in AttachmentType]: JSX.Element;
}

type Props = {
  onPress: (type: AttachmentType) => void
  attachmentList?: AttachmentType[]
}

export const AttachmentButtons = ({
  attachmentList: attachmentsList = ['audio', 'file', 'photo', 'video'],
  onPress,
}: Props) => {

  const renderAttachmentComponent = (attachmentType: AttachmentType, index: number): JSX.Element => {
    const attachmentComponent: AttachmentComponent = {
      audio: (
        <TouchableOpacity
          style={styles.attachmentButton}
          onPress={() => {
            Keyboard.dismiss();
            onPress('audio')}
          }
          key={`${attachmentType}-${index}`}
        >
          <Icons.AudioIcon
            width={30}
            height={30}
            color={AppColors.gray.neutral2}
          />
          <Text style={styles.attachmentText}>Audio</Text>
        </TouchableOpacity>
      ),
      file: (
        <TouchableOpacity
          style={styles.attachmentButton}
          onPress={() => {
            Keyboard.dismiss();
            onPress('file')
          }}
          key={`${attachmentType}-${index}`}
        >
          <Icons.FileIcon
            width={22}
            height={30}
            color={AppColors.gray.neutral2}
          />
          <Text style={styles.attachmentText}>File</Text>
        </TouchableOpacity>
      ),
      photo: (
        <TouchableOpacity
          style={styles.attachmentButton}
          onPress={() => {
            onPress('photo');
            Keyboard.dismiss();

          }}
          key={`${attachmentType}-${index}`}
        >
          <Icons.ImageIcon
            width={24}
            height={30}
            color={AppColors.gray.neutral2}
          />
          <Text style={styles.attachmentText}>Image</Text>
        </TouchableOpacity>
      ),
      video: (
        <TouchableOpacity
          style={styles.attachmentButton}
          onPress={() => {
            Keyboard.dismiss();
            onPress('video')
          }}
          key={`${attachmentType}-${index}`}
        >
          <Icons.VideoIcon
            width={26}
            height={30}
            color={AppColors.gray.neutral2}
          />
          <Text style={styles.attachmentText}>Video</Text>
        </TouchableOpacity>
      )
    }

    return attachmentComponent[attachmentType];
  }

  return (
    <View style={styles.attachmentIcons}>
      {attachmentsList.map((attachment, index) => renderAttachmentComponent(attachment, index))}
    </View>
  );
}

const styles = StyleSheet.create({
  attachmentIcons: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
  },

  attachmentButton: {
    alignItems: 'center',
    flex: 1,
  },

  attachmentIcon: {
    marginBottom: 8,
  },

  attachmentText: {
    color: AppColors.gray.neutral2,
    fontSize: 12,
    fontFamily: 'Rubik_400Regular',
  },
})
