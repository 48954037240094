import React from 'react'
import { Svg, Path } from "react-native-svg"
import { View, StyleProp, ViewStyle } from 'react-native'

type Props = {
  size: number
  color: string
  style?: StyleProp<ViewStyle>
}
export default function Search(props: Props) {
  return (
    <View style={props.style}>
      <Svg width={props.size} height={props.size} viewBox="0 0 44 44">
        <Path d="M24.3764 22.4769C26.0463 20.1337 25.8301 16.8596 23.7279 14.7574C21.3848 12.4142 17.5858 12.4142 15.2426 14.7574C12.8995 17.1005 12.8995 20.8995 15.2426 23.2426C17.3449 25.3449 20.619 25.561 22.9622 23.8911L28.6777 29.6066L30.0919 28.1924L24.3764 22.4769ZM22.3137 16.1716C23.8758 17.7337 23.8758 20.2663 22.3137 21.8284C20.7516 23.3905 18.219 23.3905 16.6569 21.8284C15.0948 20.2663 15.0948 17.7337 16.6569 16.1716C18.219 14.6095 20.7516 14.6095 22.3137 16.1716Z"
          fill={props.color} />
      </Svg>
    </View>

  )
}
