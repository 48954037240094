import React from 'react'
import { KeyboardAvoidingView, Platform, StyleProp, ViewStyle } from 'react-native'
import { useHeaderHeight } from '@react-navigation/elements'

type Props = {
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
}
export const SmartKeyboardAvoidingView = (props: Props) => {
  const headerHeight = useHeaderHeight()

  return <KeyboardAvoidingView
    {...(Platform.OS === "ios" ? { behavior: "padding" } : {})}
    style={[{ flex: 1 }, props.style]}
    contentContainerStyle={{ flex: 1 }}
    keyboardVerticalOffset={(headerHeight ?? 0) + 10}
  >
    {props.children}
  </KeyboardAvoidingView>
}
