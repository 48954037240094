import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconProps } from '.';
import { AppColors } from '../../common/AppColors';

const MessageCameraIcon = ({
  color = AppColors.gray.neutral3,
  height = 20,
  width = 20,
}: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path d="M19.1604 2.47H16.785L16.1706 0.95C15.9351 0.37 15.3618 0 14.7167 0H7.28335C6.64854 0 6.06493 0.38 5.83968 0.95L5.22535 2.47H2.84995C1.69296 2.47 0.76123 3.35 0.76123 4.44V15.02C0.76123 16.11 1.69296 17 2.84995 17H19.1604C20.3071 17 21.2388 16.11 21.2388 15.02V4.44C21.2388 3.35 20.3071 2.47 19.1604 2.47ZM19.7235 15.02C19.7235 15.29 19.4675 15.51 19.1604 15.51H2.84995C2.53254 15.51 2.27657 15.29 2.27657 15.02V4.44C2.27657 4.17 2.53254 3.95 2.84995 3.95H5.74753C6.05469 3.95 6.34138 3.76 6.45401 3.48L7.29359 1.48H14.7167L15.5563 3.48C15.6689 3.76 15.9556 3.95 16.2628 3.95H19.1604C19.4778 3.95 19.7235 4.17 19.7235 4.44V15.02ZM11 5C8.31747 5 6.1366 7.13 6.1366 9.75C6.1366 12.37 8.31747 14.5 11 14.5C13.6826 14.5 15.8635 12.37 15.8635 9.75C15.8635 7.13 13.6826 5 11 5ZM11 13.03C9.15705 13.03 7.65195 11.56 7.65195 9.76C7.65195 7.96 9.15705 6.49 11 6.49C12.8533 6.49 14.3481 7.96 14.3481 9.76C14.3481 11.56 12.8533 13.03 11 13.03Z" fill={color} />
    </Svg>
  );
}

export default MessageCameraIcon;