import React, { useEffect, useRef } from 'react'
import { ScrollView, StyleSheet, View, Text, Platform, TextInput } from 'react-native'
import { BoldText } from '../../components/BoldText'
import { Colors } from '../../common/Colors'
import { BlockButton } from '../../components/BlockButton'
import { StyledTextInput } from '../../components/StyledTextInput'
import { SmartKeyboardAvoidingView } from '../../components/SmartKeyboardAvoidingView'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { useTranslation } from 'react-i18next'
import { handleErrorResponse } from '../../common/Util'
import { FormError } from '../../components/FormError'
import ErrorBag from '../../common/ErrorBag'
import { API } from '../../api/API'
import { AppStateStore } from '../../contexts/AppStateStore'
import { getModalManager } from '../../contexts/ModalContext'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';
import { AppColors } from '../../common/AppColors'
import { MiniLogoSvg } from '../../../assets/images/mini-logo'
//import * as Sentry from 'sentry-expo'

export type VerifyForgottenScreenParams = {
  email: string,
  countdown: number
}

export const VerifyForgottenScreen = observer((props: StackScreenProps<RootNavigationParamList, 'VerifyForgotten'>) => {
  const { t } = useTranslation()
  const inputs = useRef<TextInput[]>([])

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('', ''),
      headerStyle: { backgroundColor: '#fff', borderBottomWidth: 0 },
      headerShown: true,
      headerTitle: '',
      headerBackImage: () => <Svg width="13" height="21" viewBox="0 0 13 21" fill="none">
        <Path d="M12.59 2.23001C13.13 1.72001 13.13 0.890007 12.59 0.390007C12.05 -0.119993 11.17 -0.119993 10.63 0.390007L1.4 9.09001C0.859995 9.60001 0.859995 10.43 1.4 10.93L10.63 19.63C11.17 20.14 12.05 20.14 12.59 19.63C13.13 19.12 13.13 18.29 12.59 17.79L4.34 10L12.59 2.23001Z" fill="#FF7A33" />
      </Svg>

    })
  }, [props.navigation])

  const state: any = useLocalObservable(() => ({
    form: {
      verificationCode: '',
      code1: '',
      code2: '',
      code3: '',
      code4: '',
    },
    formErrors: new ErrorBag(),
    submitting: false,
    get isValid() {
      return this.form.code1.length > 0 && this.form.code2.length > 0 && this.form.code3.length > 0 && this.form.code4.length > 0
    },
    disableCountdown: props.route.params.countdown,
    showCountdown: props.route.params.countdown > 0 ? true : false
  }))

  const onLoad = () => {
    if (state.disableCountdown != 0) {
      let counter = setInterval(() => {
        state.disableCountdown -= 1
        console.log(state.disableCountdown)
      }, 1000)

      setTimeout(() => {
        state.showCountdown = false
        clearInterval(counter)
      }, state.disableCountdown * 1000);
    }
  }

  useEffect(() => {
    onLoad()
  }, [])

  const submit = async () => {
    runInAction(() => {
      state.submitting = true
      state.formErrors.clearErrors()
    })

    try {
      const verificationCode = state.form.code1 + state.form.code2 + state.form.code3 + state.form.code4

      const response = await API.verifyForgotPassword({ verificationCode: verificationCode, email: props.route.params.email })
      props.navigation.push('ChangePassword', { authToken: response.authToken })
      //await AppStateStore.setAuthToken(response.authToken)
      //await AppStateStore.setAuthToken(props.route.params.authToken)
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => {
        handleErrorResponse(err.response, state.formErrors, {
          unhandledErrors: {
            handledErrorFields: Object.keys(state.form),
            unhandledErrorsKey: '_unhandled',
          }
        })
      })
    }

    runInAction(() => {
      state.submitting = false
    })
  }

  const resendVerificationCode = async () => {
    runInAction(() => {
      state.submitting = true
      state.formErrors.clearErrors()
    })

    try {
      const response = await API.resendForgottenCode(props.route.params.email)
      state.disableCountdown = response.countdown
      if (state.disableCountdown == 0) {
        if (!props.route.params.email.includes('@')) {
          getModalManager()
            .showModal({
              title: t('Verification Code Sent', 'Verification Code Sent'),
              message: t('SMS verification resent', 'A new verification code has been sent to your phone'),
            })
        } else {
          getModalManager()
            .showModal({
              title: t('Verification Code Sent', 'Verification Code Sent'),
              message: t('Email verification resent', 'A new verification code has been sent to your email address'),
            })
        }
      }
      else {
        state.showCountdown = true

        let counter = setInterval(() => {
          state.disableCountdown -= 1
          console.log(state.disableCountdown)
        }, 1000)

        setTimeout(() => {
          state.showCountdown = false
          clearInterval(counter)
        }, state.disableCountdown * 1000);
      }

    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => {
        handleErrorResponse(err.response, state.formErrors, {
          unhandledErrors: {
            handledErrorFields: Object.keys(state.form),
            unhandledErrorsKey: '_unhandled',
          }
        })
      })
    }

    runInAction(() => {
      state.submitting = false
    })
  }

  const focusNextInput = (index: number, value: string) => {
    runInAction(() => {
      state.form[`code${index + 1}`] = value
    })
    inputs.current[index + 1]?.focus()
  }

  return <SmartKeyboardAvoidingView style={styles.container}>
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1
      }}
      keyboardShouldPersistTaps="handled"
    >
      <View style={styles.main}>
        <MiniLogoSvg></MiniLogoSvg>
        <BoldText style={styles.headerText}>{t('Request sent successfully!', 'Request sent successfully!')}</BoldText>
        <Text style={styles.subTitleText}>{t('Forgotten verification code sent', "We've sent a 4-digit confirmation code to your email or phone number. Please enter the code in below box to verify your account.")}</Text>

        <View style={styles.formContainer}>
          <FormError field={'_unhandled'} errors={state.formErrors} displayAsList={true} containerStyle={{ marginBottom: 16 }} />
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginBottom: 20 }}>
            {['code1', 'code2', 'code3', 'code4'].map((item, index) => (
              <TextInput
                key={index}
                ref={(ref) => inputs.current[index] = ref!}
                placeholder={'-'}
                keyboardType={'default'}
                value={state.form[item]}
                onChangeText={(e) => {
                  if (e.length > 0) {
                    focusNextInput(index, e)
                  }
                }}
                editable={!state.submitting}
                style={styles.outlinedInput}
                maxLength={1}
                onKeyPress={(e) => {
                  if (e.nativeEvent.key === 'Backspace') {
                    runInAction(() => state.form[item] = '')
                    inputs.current[index - 1]?.focus()
                  }
                }}
              />

            ))}
          </View>

          <FormError field={'verificationCode'} errors={state.formErrors} />
          <BlockButton
            title={t('Confirm code', 'Confirm code')}
            variant={'secondary'}
            flex={1}
            disabled={!state.isValid || state.submitting}
            onPress={submit}
            textStyle={{ fontSize: 16 }}
            style={{ marginRight: 1, width: '100%', minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 20, backgroundColor: (!state.isValid || state.submitting) ? '#9D9FA0' : '#FF7A33' }}
          />
          {
            state.showCountdown
              ? <Text style={{ fontWeight: '500', fontSize: 16, textAlign: 'center', marginTop: 15, color: '#f00' }}>{`Resend reenabled after ${state.disableCountdown} seconds`}</Text>
              : <BlockButton
                title={t('Resend code', 'Resend code')}
                variant={'link'}
                disabled={state.submitting}
                onPress={resendVerificationCode}
                textStyle={{ fontWeight: '500', fontSize: 16 }}
              />
          }
        </View>
      </View>
    </ScrollView>
  </SmartKeyboardAvoidingView>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  main: {
    flex: 1,
    alignItems: 'center',
  },
  headerText: {
    marginTop: 24,
    paddingHorizontal: 24,
    fontSize: 24,
    color: '#0082CD',
    textAlign: 'center',
  },
  formContainer: {
    marginTop: 24,
    paddingHorizontal: 16,
    width: '100%',
  },
  outlinedInput: {
    marginTop: 16,
    minHeight: 64,
    width: 48,
    borderColor: AppColors.blue.primary,
    borderWidth: 2,
    backgroundColor: AppColors.gray.neutral6,
    textAlign: 'center',
    marginHorizontal: 15,
    borderRadius: 8,
    height: 40,
    fontSize: 18,
    color: '#333',
    paddingHorizontal: 12,
  },
  subTitleText: {
    marginTop: 24,
    paddingHorizontal: 24,
    fontSize: 16,
    color: '#626262',
    textAlign: 'center',
  },
  footer: {},
})
