import React from "react";
import { Svg, Path } from "react-native-svg";

export const AddIcon = (props: any) => {
    return (
        <Svg width={props.size} height={props.size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path d="M16.794 7.794H10.206V1.206C10.206 0.9 10.071 0.585 9.846 0.36C9.612 0.135 9.306 0 9 0C8.676 0 8.379 0.126 8.145 0.351C7.911 0.576 7.794 0.882 7.794 1.206V7.794H1.206C0.882 7.794 0.585 7.92 0.36 8.145C0.126 8.37 0 8.676 0 9C0 9.324 0.126 9.63 0.351 9.855C0.576 10.08 0.882 10.206 1.206 10.206H7.794V16.794C7.794 17.118 7.92 17.415 8.145 17.649C8.379 17.874 8.676 18 8.991 18C8.991 18 8.991 18 9 18C9.324 18 9.621 17.874 9.846 17.649C10.071 17.424 10.197 17.118 10.197 16.794V10.206H16.785C17.109 10.206 17.415 10.08 17.64 9.855C17.874 9.621 18 9.324 18 9C18 8.334 17.46 7.794 16.794 7.794Z" fill="white"/>
        </Svg>

    );
}