import React, { useCallback, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { Form } from '../../api/schema/models/Form'
import { Pressable, ScrollView, StyleSheet, TouchableOpacity, View, Platform } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { runInAction } from 'mobx'
import { extractErrorMessage, fullName, utc } from '../../common/Util'
import { API } from '../../api/API'
import { ContentActivityIndicator } from '../../components/ContentActivityIndicator'
import { ErrorMessage } from '../../components/ErrorMessage'
import { DefaultText } from '../../components/DefaultText'
import { SentFormGroup } from '../../api/schema/models/SentFormGroup'
import { SentForm } from '../../api/schema/models/SentForm'
import { AppColors, AppColors as COLORS } from '../../common/AppColors'
import Icons from '../../components/icons'
import { BoldText } from '../../components/BoldText'
//import * as Sentry from 'sentry-expo'

export type SentFormGroupDetailScreenParams = {
  FormId: number
}

export const SentFormGroupDetailScreen = observer((props: StackScreenProps<RootNavigationParamList, 'SentFormGroupDetail'>) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    data: undefined as {
      sentFormGroup: SentFormGroup
      form: Form
      sentForms: SentForm[]
    } | undefined,
    error: undefined as string | undefined,
  }))

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Sent E-Signature', 'Sent E-Signature'),
    })
  }, [props.navigation])

  const loadData = useCallback(async () => {
    runInAction(() => {
      state.data = undefined
      state.error = undefined
    })

    try {
      const response = await API.getSentFormGroup(props.route.params.FormId)
      runInAction(() => state.data = {
        sentFormGroup: response.sentFormGroup,
        form: response.form,
        sentForms: response.sentForms,
      })
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = extractErrorMessage(err))
    }

  }, [state, props.route.params.FormId])

  useLayoutEffect(() => {
    loadData().then()
  }, [loadData])

  const data = state.data

  return state.error
    ? <ErrorMessage message={state.error} />
    : !data
      ? <ContentActivityIndicator />
      : <>
        <ScrollView style={styles.container}>
          <SafeAreaView edges={['bottom']}>
            <View style={styles.section}>
              <DefaultText bold style={styles.sectionHeaderText}>{t('Title', 'Title')}</DefaultText>
              <View style={styles.sectionFormText}>
                <DefaultText style={styles.sectionText} numberOfLines={1}>{data.form.title}</DefaultText>
              </View>
            </View>
            <View style={styles.section}>
              <DefaultText bold style={styles.sectionHeaderText}>{t('Created', 'Created')}</DefaultText>
              <View style={styles.sectionFormText}>
                <DefaultText style={styles.sectionText}>{utc(data.form.createdAt).format('MM/DD/YYYY')}</DefaultText>
              </View>
            </View>
            <View style={styles.section}>
              <DefaultText bold style={styles.sectionHeaderText}>{t('Category', 'Category')}</DefaultText>
              <View style={styles.sectionFormText}>
                <DefaultText style={styles.sectionText}>{data.form.categoryName}</DefaultText>
              </View>
            </View>
            <View style={styles.section}>
              <View style={styles.sectionFormText}>
                <DefaultText style={styles.sectionText}>{data.form.message}</DefaultText>
              </View>
            </View>
            <View style={styles.section}>
              <DefaultText bold style={styles.sectionHeaderText}>{t('File', 'File')}</DefaultText>
              <TouchableOpacity onPress={() => props.navigation.push('DocumentViewer', { url: data!.form!.formUrl!, title: data.form!.title! })}>
                <View style={styles.sectionFormText}>
                  <DefaultText style={styles.sectionText} numberOfLines={1}>{data.form.formFilename}</DefaultText>
                  <Icons.Forward width={12} height={20} color={COLORS.orange.primary} />
                </View>
              </TouchableOpacity>
            </View>
            {/* {
              data.form.fields.length
                ? <View style={styles.section}>
                  <DefaultText style={styles.sectionHeaderText}>{t('Fields', 'Fields')}</DefaultText>
                  <View style={[styles.sectionFormText, { flexDirection: 'column', alignItems: 'flex-start' }]}>

                    {
                      data.form.fields.map((f, idx) => <View key={idx} style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <DefaultText style={{ marginRight: 4 }}>{f.label}</DefaultText>
                        {
                          f.required
                            ? <Icons.Alert width={10} height={10} color={COLORS.red.secondary} variant='outline' />
                            : null
                        }
                      </View>)
                    }
                  </View>

                </View>
                : null
            }
            <View style={styles.section}>
              <DefaultText style={styles.sectionHeaderText}>{t('Signature Required', 'Signature Required?')}</DefaultText>
              <View style={styles.sectionFormText}>
                <DefaultText style={styles.sectionText}>{data.form.isSignatureRequired ? t('Yes', 'Yes') : t('No', 'No')}</DefaultText>
              </View>
            </View> */}
            <DefaultText bold style={[styles.sectionHeaderText, { paddingHorizontal: 12, paddingTop: 12 }]}>{t('Sent To', 'Sent To')}</DefaultText>
            {
              data.sentForms.map(sentForm => <TouchableOpacity
                key={sentForm.student.id}
                disabled
                //onPress={() => props.navigation.push('SentFormDetail', { sentForm: sentForm })}
                style={styles.section}>

                <View key={sentForm.id} style={styles.sectionFormText}>
                  {
                    sentForm.signedAt
                      ? <Icons.Attendance width={20} height={20} color={COLORS.leaf.secondary} variant='outline' />
                      : <Icons.Alert width={20} height={20} color={COLORS.egg.secondary} variant='outline' />
                  }
                  <DefaultText style={{ flex: 1, marginLeft: 4 }}>{fullName(sentForm.student)}</DefaultText>
                  {
                    sentForm.signedAt
                      ? <BoldText style={{ color: COLORS.leaf.secondary }}>{
                        t('Signed', 'Signed') + ' ' + utc(sentForm.signedAt).format('MM/DD/YYYY')}
                      </BoldText>
                      : <BoldText style={{ color: COLORS.egg.secondary }}>
                        {t('Pending', 'Pending')}
                      </BoldText>
                  }
                </View>
              </TouchableOpacity>)
            }
          </SafeAreaView>
        </ScrollView>
      </>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: COLORS.white,
    marginTop: 16,
  },
  section: {
    marginBottom: 20
  },
  sectionHeaderText: {
    color: COLORS.blue.primary,
    marginBottom: 2,
    fontSize: 14
  },
  sectionText: {
    fontSize: 16,
    color: COLORS.gray.neutral1,
    flex: 1,
  },
  sectionFormText: {
    minHeight: 56,
    paddingHorizontal: 16,
    flex: 1,
    backgroundColor: COLORS.gray.neutral6,
    justifyContent: 'center',
    borderRadius: 6,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5
  },
  button: {
    height: 60,
  },
  footer: {
    paddingHorizontal: 16,
  }
})
