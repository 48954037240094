import { PixelRatio, StyleSheet } from "react-native";

export const headerStyles = StyleSheet.create({
    header: {
        minHeight: 120,
        justifyContent: 'space-around',
        alignItems: 'center',
        alignContent: 'center',
        backgroundColor: 'white',
        flexDirection: 'row',
    },
    userInfo: {
        flex: 3,
        marginLeft: 12,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: (PixelRatio.getFontScale()) > 2 ? 20 : 17,
        paddingBottom: 7,
        fontWeight: '600',
        fontFamily: 'Rubik_700Bold',
    },
    messages: {
        fontSize: (PixelRatio.getFontScale()) > 2 ? 20 : 14,
        fontFamily: 'Rubik_400Regular',
        color: '#626262'
    },
    notification: { flex: 1, justifyContent: 'center', flexDirection: 'row'},
    title2: {
        fontSize: 18,
        paddingBottom: 7,
        fontWeight: '500',
        fontFamily: 'Rubik_500Medium',
    },
})