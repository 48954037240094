import React from 'react'
import { Svg, Path } from "react-native-svg"

type Props = {
  width?: number
  height?: number
  color?: string
}

export default function EmptyDocument({
  width = 144,
  height = 96,
  color = '#000',
}: Props
) {
  return (
    <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}  >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M143.94 41.54C143.94 41.53 143.92 41.47 143.92 41.46C143.87 41.28 143.81 41.1 143.73 40.93C143.73 40.92 143.71 40.87 143.7 40.86L123.43 1.5C122.96 0.57 122.03 0 121 0H23C21.97 0 21.04 0.57 20.57 1.5L0.3 40.86C0.29 40.89 0.29 40.91 0.27 40.93C0.19 41.1 0.12 41.28 0.08 41.46C0.08 41.47 0.06 41.51 0.06 41.52C0.02 41.74 0 41.94 0 42.13V93.24C0 94.76 1.23 96 2.73 96H141.27C142.78 96 144 94.76 144 93.23V42.13C144 41.94 143.98 41.74 143.94 41.54ZM121.36 9.45L136.62 39C136.66 39.08 136.65 39.17 136.61 39.24C136.57 39.31 136.49 39.36 136.4 39.36H124.46L121.36 30.79V9.45V9.45ZM27.68 29.1H116.32V5.53H27.68V29.1ZM27.03 34.21H116.97L118.83 39.36H100.85C99.38 39.36 98.18 40.53 98.12 42.02C97.58 56.29 86.1 67.47 72 67.47C57.9 67.47 46.42 56.29 45.88 42.02C45.82 40.53 44.62 39.36 43.15 39.36H25.17L27.03 34.21ZM22.64 9.45V30.78L19.54 39.36H7.6C7.51 39.36 7.44 39.32 7.39 39.24C7.35 39.17 7.35 39.08 7.39 39L22.64 9.45ZM138.54 90.22C138.54 90.35 138.43 90.46 138.3 90.46H137.28H6.72H5.7C5.57 90.46 5.46 90.35 5.46 90.22V78.38V45.14C5.46 45.01 5.57 44.9 5.7 44.9H17.55H23.17H40.42C40.54 44.9 40.64 44.99 40.66 45.11C42.65 61.01 56.13 73 72 73C87.87 73 101.35 61.01 103.35 45.1C103.37 44.98 103.47 44.89 103.59 44.89H120.84H126.46H138.31C138.44 44.89 138.55 45 138.55 45.13V90.22H138.54Z"
        fill={color} />
    </Svg>
  )
}
