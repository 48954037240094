import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { WeeklyCalendar } from '../../components/calendar/WeeklyCalendar'
import { observer, useLocalObservable } from 'mobx-react-lite'
import moment, { Moment } from 'moment-timezone'
import { runInAction } from 'mobx'
import { AppStateStore } from '../../contexts/AppStateStore'
import { AgendaView } from '../../components/calendar/AgendaView'
import { DrawerHeaderButton } from '../../components/DrawerHeaderButton'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { KonektiIcon } from '../../components/KonektiIcon'
import { AppColors } from '../../common/AppColors'

export const CalendarScreen = observer((props: StackScreenProps<RootNavigationParamList, 'Calendar'>) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    selectedDate: moment(),
  }))

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: state.selectedDate ? state.selectedDate.format('MMMM YYYY') : t('Calendar', 'Calendar'),
      headerLeft: () => <DrawerHeaderButton/>,
      headerRight: () => <>
        {
          AppStateStore.userContext!.userType === 'teacher'
            ? <TouchableOpacity
              style={styles.headerButton}
              onPress={() => props.navigation.push('EditEvent')}
            >
              <KonektiIcon name={'new-event-icon'} size={20} color={'#fff'}/>
            </TouchableOpacity>
            : null
        }
      </>,
    })
  }, [props.navigation, state.selectedDate])

  const selectDate = (date: Moment) => runInAction(() => state.selectedDate = date.clone())

  return <View style={styles.container}>
    <View style={styles.header}>
      <WeeklyCalendar
        selectedDate={state.selectedDate}
        onDateSelected={selectDate}
      />
    </View>
    <View style={styles.main}>
      <AgendaView
        date={state.selectedDate}
      />
    </View>
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColors.white
  },
  header: {},
  main: {
    flex: 1,
  },
  headerButton: {
    padding: 10,
  },
})
