import React, { useEffect, useState } from "react"
import { BottomTabBarProps } from "@react-navigation/bottom-tabs"
import { useFocusEffect } from '@react-navigation/native'
import { Pressable, View, Platform, Dimensions } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { observer } from 'mobx-react-lite'
import { MainTabNames, TabIcon } from "./TabIcon"
import { AppStateStore } from '../../contexts/AppStateStore'
import { AppColors as COLORS } from '../../common/AppColors'
import { API } from "../../api/API"


export const CustomTabBar = observer((props: BottomTabBarProps) => {
    const [currentRoute, setCurrentRoute] = useState(AppStateStore.menuSelected)
    const unreadCount = AppStateStore.getUnreadMessagesCount()

    useEffect(() => {
        props.navigation.navigate(currentRoute)

        API.getUnreadConversations().then(response => {
            AppStateStore.setUnreadMessagesCount(response.unread_messages)
        })
    }, [currentRoute])

    useFocusEffect(() => {
        const listener = AppStateStore.addPushNotificationListener(async notification => {
            if (notification.request.content.data.notificationType === 'new-message') {
                API.getUnreadConversations().then(response => {
                    AppStateStore.setUnreadMessagesCount(response.unread_messages)
                })
            }

            return false
        })

        return () => listener.remove()
    })


    const onPress = (route: MainTabNames) => {
        setCurrentRoute(route)
        AppStateStore.menuSelected = route
        props.navigation.navigate(currentRoute);
    }
    const routesWithoutHome = props.state.routes.map(
        (route: any) => {
            if (AppStateStore.userContext?.userType === 'student') {
                if (route.name === 'Attendance' || route.name === 'E-Sign' || route.name === 'ReceivedFormsScreen' || route.name === 'SupportScreen') {
                    return null
                }
            } else {
                if (route.name === 'Attendance' || route.name === 'Profile' || route.name === 'ReceivedFormsScreen' || route.name === 'SupportScreen') {
                    return null
                }
            }

            return <Pressable
                key={route.key}
                onPress={() => onPress(route.name)}>
                <TabIcon
                    currentRoute={AppStateStore.menuSelected}
                    selected={AppStateStore.menuSelected == route.name}
                    tabName={(route.name === 'Conversations' ? 'Messages' : route.name) as MainTabNames}
                    messageBadge={unreadCount}
                />
            </Pressable>
        }

    )

    const tabBarHeight = 54 + useSafeAreaInsets().bottom * 0.6

    return (
        <View style={{ justifyContent: 'center', flexDirection: 'row' }}>

            <View style={{
                flex: 1,
                flexBasis: '0%',
                position: 'absolute',
                width: '100%',
                backgroundColor: 'white',
                borderTopRightRadius: 6,
                borderTopLeftRadius: 6,
                shadowOffset: {
                    width: 0,
                    height: -3
                },
                shadowColor: COLORS.orange.primary,
                shadowRadius: 10,
                shadowOpacity: Platform.OS === 'android' ? 0.4 : 0.2,
                height: tabBarHeight,
                elevation: Platform.OS === 'android' ? 20 : 0,
            }}>

            </View>

            <View style={{
                height: 62,
                width: 62,
                backgroundColor: 'white',
                position: 'absolute',
                bottom: Platform.OS === 'ios' ? Dimensions.get('screen').height / 23 : 15,
                justifyContent: 'center',
                borderRadius: 100,
                alignItems: 'center',
                shadowOffset: {
                    width: 0,
                    height: -3
                },
                shadowColor: Platform.OS === 'android' ? 'black' : COLORS.orange.primary,
                shadowRadius: 10,
                shadowOpacity: Platform.OS === 'android' ? 0.4 : 0.2,
                elevation: Platform.OS === 'android' ? 20 : 0,
            }}>

            </View>

            <View style={{
                flex: 1,
                flexBasis: '0%',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                backgroundColor: 'white',
                borderTopRightRadius: 15,
                borderTopLeftRadius: 15,
                paddingBottom: 20,
                paddingTop: 12,
                height: tabBarHeight + 10,
                elevation: 20
            }}>
                {routesWithoutHome}
            </View>


        </View>
    )
})