import React from 'react'
import { makeObservable, observable, runInAction } from 'mobx'
import { v4 as uuidv4 } from 'uuid'
import { BlockButtonVariant } from '../components/BlockButton'

type ModalButton = {
  text: string
  onPress?: (dismiss: () => void) => void
  variant?: BlockButtonVariant
}

type ModalOptions = {
  title?: React.ReactElement
  message?: React.ReactElement
  buttons?: ModalButton[]
  type?: 'popup' | 'bottom-drawer' | 'content'
  children?: React.ReactElement | React.ReactElement[]
  showClosse?: boolean
  drawerHeight?: number
}


type Modal = {
  uuid: string
  show: boolean
  options: ModalOptions
}

class ModalManager {
  private modals: Modal[] = []

  constructor() {
    makeObservable<this, 'modals'>(this, {
      modals: observable,
    })
  }

  showModal = (options: ModalOptions) => {
    runInAction(() => {
      this.modals.push({
        uuid: uuidv4(),
        show: true,
        options,
      })
    })
  }

  getModals = (): Modal[] => {
    return this.modals.slice()
  }

  dismissModal = (uuid: string) => {
    const modal = this.modals.find(a => a.uuid === uuid)

    if (modal) {
      runInAction(() => modal.show = false)
    }
  }

  removeModal = (uuid: string) => {
    runInAction(() => this.modals = this.modals.filter(a => a.uuid !== uuid))
  }
}

const modalManager = new ModalManager()

export const getModalManager = () => modalManager
