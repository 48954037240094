import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useCallback, useEffect } from 'react'
import { ActivityIndicator, KeyboardAvoidingView, Platform, Pressable, StyleSheet, TextInput, View } from 'react-native'
import { runInAction } from 'mobx'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import ErrorBag from '../../common/ErrorBag'
import { extractErrorMessage, getMimeType, handleErrorResponse } from '../../common/Util'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useTranslation } from 'react-i18next'
import { BlockButton } from '../../components/BlockButton'
import { Attachment, AttachmentBar } from '../../components/AttachmentBar'
import { FormError } from '../../components/FormError'
import { Post } from '../../api/schema/models/Post'
import { API } from '../../api/API'
import { ErrorMessage } from '../../components/ErrorMessage'
import { PostAttachment } from '../../api/schema/models/PostAttachment'
import { PostHeader } from '../../components/posts/PostHeader'
import { StackScreenProps } from '@react-navigation/stack'
import { useHeaderHeight } from '@react-navigation/elements'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { FileData } from '../../api/methods/upload-file'
import { AppColors } from '../../common/AppColors'
import Icons from '../../components/icons'
//import * as Sentry from 'sentry-expo'

export type EditPostScreenParams = {
  postId: number
}

export const EditPostScreen = observer((props: StackScreenProps<RootNavigationParamList, 'EditPost'>) => {
  const { t } = useTranslation()

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Post', 'Post'),
    })
  }, [props.navigation])

  const state = useLocalObservable(() => ({
    form: {
      message: '',
    },
    errors: new ErrorBag(),
    submitting: false,
    loading: false,
    error: undefined as string | undefined,
    post: undefined as Post | undefined,
    get isValid (): boolean {
      return this.form.message.length > 0
    },
    uploading: false,
    removeExistingAttachment: false,
    attachment: undefined as Attachment | undefined,
  }))

  const submit = async () => {
    if (!state.isValid) {
      return
    }

    AppStateStore.showActivityIndicator()
    runInAction(() => {
      state.errors.clearErrors()
      state.submitting = true
    })

    try {
      let attachment: PostAttachment | undefined = undefined

      if (state.attachment) {
        const uploadData = await API.requestUpload()

        let mimeType = 'application/octet-stream'
        let filename = 'upload'
        if (state.attachment.type === 'file') {
          mimeType = state.attachment.mimeType
          filename = state.attachment.name
        } else if (!state.attachment.url.startsWith('data:')) {
          mimeType = getMimeType(state.attachment.url) || 'application/octet-stream'
        } else if(state.attachment.type === 'video' || state.attachment.type === 'photo'){
          mimeType = state.attachment.mimeType
      }

        let file: FileData = {
          uri: state.attachment.url,
          name: filename,
          type: mimeType,
        }

        if (state.attachment.url.startsWith('data:')) {
          const response = await fetch(state.attachment.url)
          const binary = await response.blob()
          file = new Blob([binary], {
            type: 'application/pdf',
          })
        }

        await API.uploadFile(
          uploadData.url,
          uploadData.fields,
          file,
        )

        attachment = {
          type: state.attachment.type,
          key: uploadData.key,
          filename: filename,
          mimeType: mimeType,
        }
      }

      await API.updatePost(props.route.params.postId, {
        message: state.form.message,
        removeExistingAttachment: state.removeExistingAttachment,
        attachment,
      })

      AppStateStore.eventBus.emit(AppEvents.PostCreated)

      props.navigation.pop()
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      handleErrorResponse(err?.response)
    }

    AppStateStore.hideActivityIndicator()
    runInAction(() => state.submitting = false)
  }

  const loadPost = useCallback(async () => {
    runInAction(() => {
      state.loading = true
      state.error = undefined
    })

    try {
      const response = await API.getPost(props.route.params.postId)
      runInAction(() => {
        state.post = response.post
        state.form.message = response.post.message
      })
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [props.route.params.postId, state])

  useEffect(() => {
    loadPost().then()
  }, [loadPost])

  const headerHeight = useHeaderHeight()

  return <View style={styles.container}>
    {
      state.loading
        ? <ActivityIndicator/>
        : state.error
          ? <ErrorMessage message={state.error}/>
          : state.post
            ? <>
              <KeyboardAvoidingView
                {...(Platform.OS === "ios" ? { behavior: "padding" } : {})}
                style={{ flex: 1 }}
                contentContainerStyle={{ flex: 1 }}
                keyboardVerticalOffset={(headerHeight ?? 0) + 10}
              >
                <View style={styles.header}>
                  <PostHeader
                    post={state.post!}
                    hideEditButton={true}
                  />
                </View>
                <View style={styles.formContainer}>
                  <FormError field={'_unhandled'} errors={state.errors}/>
                  <TextInput
                    style={styles.input}
                    multiline={true}
                    placeholder={t('Type your message here', 'Type your message here')}
                    value={state.form.message}
                    onChange={ev => runInAction(() => state.form.message = ev.nativeEvent.text)}
                    editable={!state.submitting}
                  />
                </View>
                {
                  <AttachmentBar
                    existingAttachment={state.removeExistingAttachment ? undefined : state.post?.attachments}
                    onRemoveExistingAttachment={() => runInAction(() => state.removeExistingAttachment = true)}
                    onAttached={attachment => runInAction(() => state.attachment = attachment)}
                    attachment={state.attachment}
                  />
                }
                <View style={styles.footer}>
                  <SafeAreaView>
                    <BlockButton
                      variant={'secondary'}
                      style={styles.button}
                      title={t('Update Post', 'Update Post')}
                      disabled={state.submitting || !state.isValid}
                      onPress={submit}
                    />
                  </SafeAreaView>
                </View>
              </KeyboardAvoidingView>
            </>
            : null
    }
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  header: {
    paddingHorizontal: 24,
    paddingVertical: 12,
  },

  formContainer: {
    flex: 1,
  },

  visibilityContainer: {
    paddingLeft: 24,
  },

  input: {
    marginTop: 12,
    padding: 24,
    fontSize: 15,
    borderTopWidth: .5,
    borderBottomWidth: .5,
    borderColor: '#ccc',
    flex: 1,
    textAlignVertical: 'top'
  },

  button: {
    height: 60,
  },

  footer: {},
})
