import React from 'react'
import { StyleSheet, View } from 'react-native'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';

export const NotificationUrgent = () => {
  return <Svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <Path d="M0.5 20C0.5 31.0457 9.45431 40 20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.95431 31.5457 0 20.5 0C9.45431 0 0.5 8.95431 0.5 20Z" fill="#F10000"/>
  <Path clipRule={'evenodd'} fillRule={'evenodd'} d="M21.8 14.87V21.66C21.8 22.38 21.22 22.96 20.5 22.96C19.78 22.96 19.2 22.38 19.2 21.66V14.87C19.2 14.15 19.78 13.57 20.5 13.57C21.22 13.57 21.8 14.15 21.8 14.87ZM20.5 23.83C19.78 23.83 19.2 24.41 19.2 25.13C19.2 25.85 19.78 26.43 20.5 26.43C21.22 26.43 21.8 25.85 21.8 25.13C21.8 24.42 21.22 23.83 20.5 23.83ZM30.5 20C30.5 25.52 26.02 30 20.5 30C14.98 30 10.5 25.52 10.5 20C10.5 14.48 14.98 10 20.5 10C26.02 10 30.5 14.48 30.5 20Z" fill="white"/>
  </Svg>
  
}
