import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconProps } from '.';
import { AppColors } from '../../common/AppColors';

const MessageAudioPauseIcon = ({
  color = AppColors.gray.neutral3,
  height = 20,
  width = 20,
}: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path fillRule="evenodd" clipRule="evenodd" d="M12.19 6C11.69 6 11.29 6.4 11.29 6.9V13.1C11.29 13.6 11.69 14 12.19 14C12.69 14 13.09 13.6 13.09 13.1V6.9C13.09 6.4 12.68 6 12.19 6ZM7.9 6C7.4 6 7 6.4 7 6.9V13.1C7 13.6 7.4 14 7.9 14C8.4 14 8.8 13.6 8.8 13.1V6.9C8.8 6.4 8.4 6 7.9 6ZM10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10 0Z" fill={color} />
    </Svg>
  );
}

export default MessageAudioPauseIcon;