const version = require('./app-version.json')

const apiUrl = process.env.REACT_APP_API_URL ?? 'https://api.app.konektiapps.com/api'
const i18nBaseUrl = process.env.REACT_APP_I18N_BASE_URL ?? 'https://web.app.konektiapps.com/'
//const apiUrl = process.env.REACT_APP_API_URL ?? 'https://api.stage.konektiapps.com/api'
//const i18nBaseUrl = process.env.REACT_APP_I18N_BASE_URL ?? 'https://web.stage.konektiapps.com/'
//const apiUrl = process.env.REACT_APP_API_URL ?? 'http://192.168.1.8:3000/api'
//const i18nBaseUrl = process.env.REACT_APP_I18N_BASE_URL ?? 'http://192.168.1.8:19006/'
//temp tester
//const apiUrl = 'https://api.stage.konektiapps.com/api'
//const i18nBaseUrl = 'https://web.stage.konektiapps.com/'
const appEnv = process.env.REACT_APP_ENV_NAME ?? 'staging'

export const AppConfig = {
  APP_ENV: appEnv,
  I18N_BASE_URL: i18nBaseUrl,
  API_URL: apiUrl,
  VERSION: version.version,
  BUILD_NUMBER: version.buildNumber,
}
