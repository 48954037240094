import axios from 'axios'
import { Platform } from 'react-native'
import { AppStateStore } from '../contexts/AppStateStore'
import { AppConfig } from '../AppConfig'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'

class ApiClient {
  getInstance = (authToken?: string) => {
    let params = {
      maxContentLength: 10000,
      timeout: 60000,
    }

    const headers: any = {}

    if (authToken) {
      headers['X-AUTH-TOKEN'] = authToken
    } else if (AppStateStore.authToken) {
      headers['X-AUTH-TOKEN'] = AppStateStore.authToken
    }

    headers['X-DEVICE-TYPE'] = Platform.OS

    return axios.create({
      ...params,
      baseURL: AppConfig.API_URL,
      headers: headers,
    })
  }
}

export default new ApiClient()
