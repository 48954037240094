import { PixelRatio, StyleProp, Text, TextStyle } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Colors } from '../common/Colors'

type Props = {
  style?: StyleProp<TextStyle>
  children?: React.ReactNode
  bold?: boolean
  numberOfLines?: number
}

export const DefaultText = (props: Props) => {

  const style: StyleProp<TextStyle> = {
    fontFamily: props.bold ? 'Rubik_700Bold' : 'Rubik_400Regular',
    color: Colors.DefaultTextColor,
  }

  return <Text allowFontScaling={PixelRatio.getFontScale() > 2 ? false : true} style={[style, props.style, (PixelRatio.getFontScale() > 2) ? {fontSize: 20}: {}]} numberOfLines={props.numberOfLines}>{props.children}</Text>
}
