import React from 'react'
import { Svg, Path } from "react-native-svg"

type Props = {
  width: number
  height: number
  color: string,
  variant: 'fill' | 'outline'
}
const Alert = (props: Props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 20 20" >
      {props.variant === 'fill' ? (
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d="M11.3 4.87V11.66C11.3 12.38 10.72 12.96 10 12.96C9.28 12.96 8.7 12.38 8.7 11.66V4.87C8.7 4.15 9.28 3.57 10 3.57C10.72 3.57 11.3 4.15 11.3 4.87ZM10 13.83C9.28 13.83 8.7 14.41 8.7 15.13C8.7 15.85 9.28 16.43 10 16.43C10.72 16.43 11.3 15.85 11.3 15.13C11.3 14.42 10.72 13.83 10 13.83ZM20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10Z"
          fill={props.color} />
      ) : (
        <Path
          d="M11.3 4.87V11.66C11.3 12.38 10.72 12.96 10 12.96C9.28 12.96 8.7 12.38 8.7 11.66V4.87C8.7 4.15 9.28 3.57 10 3.57C10.72 3.57 11.3 4.15 11.3 4.87ZM10 13.83C9.28 13.83 8.7 14.41 8.7 15.13C8.7 15.85 9.28 16.43 10 16.43C10.72 16.43 11.3 15.85 11.3 15.13C11.3 14.42 10.72 13.83 10 13.83ZM20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10ZM18.33 10C18.33 5.4 14.6 1.67 10 1.67C5.4 1.67 1.67 5.4 1.67 10C1.67 14.6 5.4 18.33 10 18.33C14.6 18.33 18.33 14.6 18.33 10Z"
          fill={props.color} />
      )
      }

    </Svg>
  )
}

export default Alert

