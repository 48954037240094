import React from 'react'
import { Post } from '../../api/schema/models/Post'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native'
import { DefaultText } from '../DefaultText'
import { PostHeader } from '../posts/PostHeader'
import { WebImage } from '../WebImage'
import { VideoPreview } from '../VideoPreview'
import { AudioPreview } from './AudioPreview'
import { FilePreview } from '../FilePreview'
import SurveyPost from './SurveyPost'
import EventPost from './EventPost'
import { DefaultTextAutoLink } from '../DefaultTextAutoLink'

type Props = {
  post: Post
  onPress: (post: Post) => void
}
export const FeedPost = (props: Props) => {
  const { t } = useTranslation()

  const post = props.post

  return <TouchableOpacity onPress={() => props.onPress(post)}>
    {
      post.postType === 'survey'
        ?
        <SurveyPost post={post} />
        :
        post.postType === 'event'
          ?
          <EventPost post={post} />
          :
          <View style={styles.container}>
            <PostHeader post={props.post} />
            <View style={styles.message}>
              <DefaultTextAutoLink style={styles.messageText}>{
                post.message.trim().length > 300
                  ? `${post.message.slice(0, 250)}... (${t('read more', 'read more')})`
                  : post.message
              }</DefaultTextAutoLink>
            </View>
            {
              post.processedAt
                ? <>
                  {
                    post.attachments.imageUrl
                      ? <View style={styles.imageAttachmentContainer}>
                        <WebImage
                          uri={post.attachments.imageUrl}
                          style={{ width: '100%' }}
                          maxHeight={300}
                        />
                      </View>
                      : null
                  }
                  {
                    post.attachments.videoUuid
                      ? <View style={styles.videoAttachmentContainer}>
                        <VideoPreview
                          disabled={true}
                          style={StyleSheet.absoluteFillObject}
                          posterImageUrl={post.attachments.videoThumbnailUrl!}
                          resizeMode="cover"
                        />
                      </View>
                      : null
                  }
                  {
                    post.attachments.audioUuid
                      ? <View style={styles.audioAttachmentContainer}>
                        <AudioPreview
                          onPress={() => props.onPress(post)}
                          disabled={false}
                        />
                      </View>
                      : null
                  }
                  {
                    post.attachments.fileUuid
                      ? <View style={styles.fileAttachmentContainer}>
                        <FilePreview
                          disabled={true}
                          fileName={post.attachments.fileName!}
                          fileType={post.attachments.fileType!}
                        />
                      </View>
                      : null
                  }

                </>
                : <DefaultText style={styles.pendingAttachmentsText}>{t('This post has media attachments that are still processing', 'This post has media attachments that are still processing and should be available soon')}</DefaultText>
            }
          </View>
    }
  </TouchableOpacity>
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
    paddingVertical: 24,
    shadowColor: Platform.OS === 'ios' ? 'grey' : 'black',
    borderRadius: 24,
    marginHorizontal: 10,
    marginVertical: 10,
    shadowOpacity: .3,
    backgroundColor: 'white',
    shadowOffset: {
      width: 3,
      height: 5
    },
    shadowRadius: 7,

    elevation: 10
  },
  message: {},
  messageText: {
    marginVertical: 15,
    color: '#303030',
  },
  pendingAttachmentsText: {
    color: '#f00',
    padding: 10,
    textAlign: 'center',
  },
  imageAttachmentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  videoAttachmentContainer: {
    width: '100%',
    height: 300,

  },
  audioAttachmentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  fileAttachmentContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
})