import React from 'react'
import { Svg, Path } from "react-native-svg"

type Props = {
  width: number
  height: number
  color: string,
}
export default function Attach(props: Props) {
  return (
    <Svg
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 40 40" >
      <Path
        d="M0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20Z"
        fill={props.color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.47 15.1C13.54 15.17 13.57 15.25 13.57 15.34V16.7C13.57 16.79 13.54 16.88 13.47 16.94C13.4 17 13.32 17.04 13.23 17.04H9.38V18.8H12.97C13.06 18.8 13.14 18.83 13.21 18.9C13.28 18.97 13.31 19.05 13.31 19.14V20.5C13.31 20.59 13.28 20.68 13.21 20.74C13.14 20.81 13.06 20.84 12.97 20.84H9.38V23.62C9.38 23.71 9.35 23.8 9.28 23.86C9.21 23.97 9.13 24 9.03 24H7.35C7.25 24 7.17 23.97 7.1 23.9C7.03 23.83 7 23.75 7 23.65V15.35C7 15.25 7.03 15.17 7.1 15.1C7.17 15.03 7.25 15 7.35 15H13.23C13.32 15 13.4 15.03 13.47 15.1ZM16.96 15H15.28C15.19 15 15.1 15.03 15.03 15.1C14.96 15.17 14.93 15.25 14.93 15.34V23.65C14.93 23.74 14.96 23.83 15.03 23.89C15.1 23.97 15.19 24 15.28 24H16.96C17.05 24 17.14 23.97 17.2 23.9C17.27 23.83 17.3 23.75 17.3 23.66V15.35C17.3 15.26 17.27 15.17 17.2 15.11C17.14 15.03 17.06 15 16.96 15ZM25.41 21.94H21.49V15.34C21.49 15.25 21.46 15.16 21.39 15.1C21.32 15.03 21.24 15 21.14 15H19.46C19.37 15 19.28 15.03 19.22 15.1C19.15 15.17 19.12 15.25 19.12 15.34V23.65C19.12 23.74 19.15 23.83 19.22 23.89C19.28 23.97 19.36 24 19.46 24H25.41C25.5 24 25.59 23.97 25.65 23.9C25.72 23.83 25.75 23.75 25.75 23.66V22.29C25.75 22.2 25.72 22.11 25.65 22.05C25.59 21.98 25.5 21.94 25.41 21.94ZM33.55 22.05C33.48 21.98 33.4 21.95 33.31 21.95H29.25V20.5H32.93C33.02 20.5 33.11 20.47 33.17 20.4C33.24 20.33 33.27 20.25 33.27 20.16V18.79C33.27 18.7 33.24 18.61 33.17 18.55C33.1 18.48 33.02 18.45 32.93 18.45H29.25V17.06H33.21C33.3 17.06 33.38 17.03 33.45 16.96C33.52 16.89 33.55 16.81 33.55 16.72V15.35C33.55 15.26 33.52 15.17 33.45 15.11C33.38 15.03 33.3 15 33.2 15H27.21C27.12 15 27.03 15.03 26.97 15.1C26.9 15.17 26.87 15.25 26.87 15.34V23.65C26.87 23.74 26.9 23.83 26.97 23.89C27.03 23.97 27.12 24 27.21 24H33.3C33.39 24 33.48 23.97 33.54 23.9C33.61 23.83 33.64 23.75 33.64 23.66V22.29C33.65 22.2 33.62 22.11 33.55 22.05Z"
        fill="white"
      />

    </Svg>
  )
}