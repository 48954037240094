import React from 'react'
import { StyleSheet, View } from 'react-native'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';

export const NotificationPoll = () => {
  return <Svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <Path d="M0.5 20C0.5 31.0457 9.45431 40 20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.95431 31.5457 0 20.5 0C9.45431 0 0.5 8.95431 0.5 20Z" fill="#4ED542"/>
  <Path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 13.85V10.65C10.5 10.29 10.79 10 11.15 10H23.04C23.4 10 23.69 10.29 23.69 10.65V13.86C23.69 14.22 23.4 14.51 23.04 14.51H11.15C10.79 14.5 10.5 14.21 10.5 13.85ZM29.85 17.75H11.17C10.81 17.75 10.52 18.04 10.52 18.4V21.61C10.52 21.97 10.81 22.26 11.17 22.26H29.86C30.22 22.26 30.51 21.97 30.51 21.61V18.4C30.5 18.04 30.21 17.75 29.85 17.75ZM26.79 25.5H11.15C10.79 25.5 10.5 25.79 10.5 26.15V29.36C10.5 29.71 10.79 30 11.15 30H26.79C27.15 30 27.44 29.71 27.44 29.35V26.14C27.43 25.79 27.14 25.5 26.79 25.5Z" fill="white"/>
  </Svg>
}
