import React from "react";
import { Svg, Path } from 'react-native-svg';


export default (props: any) => {

    return (
        <Svg width="255" height="24" viewBox="0 0 255 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path d="M1.03659 9.89478C0.463008 9.89478 0 10.164 0 10.4975V13.5028C0 13.8363 0.463008 14.1055 1.03659 14.1055C1.61016 14.1055 2.07317 13.8363 2.07317 13.5028V10.4975C2.07317 10.164 1.61016 9.89478 1.03659 9.89478Z" fill="#9D9FA0"/>
            <Path d="M4.59551 9.2959C4.02194 9.2959 3.55893 9.56509 3.55893 9.89858V14.1053C3.55893 14.4388 4.02194 14.708 4.59551 14.708C5.16909 14.708 5.6321 14.4388 5.6321 14.1053V9.89858C5.6321 9.56509 5.16909 9.2959 4.59551 9.2959Z" fill="#9D9FA0"/>
            <Path d="M8.16137 8.39185C7.58779 8.39185 7.12479 8.66104 7.12479 8.99452V15.0052C7.12479 15.3387 7.58779 15.6079 8.16137 15.6079C8.73495 15.6079 9.19796 15.3387 9.19796 15.0052V8.99452C9.19796 8.66104 8.73495 8.39185 8.16137 8.39185Z" fill="#9D9FA0"/>
            <Path d="M11.7203 8.01831C11.1468 8.01831 10.6837 8.28751 10.6837 8.62099V15.383C10.6837 15.7165 11.1468 15.9857 11.7203 15.9857C12.2939 15.9857 12.7569 15.7165 12.7569 15.383V8.62099C12.7569 8.28751 12.2939 8.01831 11.7203 8.01831Z" fill="#9D9FA0"/>
            <Path d="M15.2862 5.48291C14.7126 5.48291 14.2496 5.75211 14.2496 6.08559V17.9142C14.2496 18.2476 14.7126 18.5168 15.2862 18.5168C15.8598 18.5168 16.3228 18.2476 16.3228 17.9142V6.08559C16.3228 5.75211 15.8598 5.48291 15.2862 5.48291Z" fill="#9D9FA0"/>
            <Path d="M18.8451 6.42334C18.2715 6.42334 17.8085 6.69254 17.8085 7.02602V16.9783C17.8085 17.3117 18.2715 17.5809 18.8451 17.5809C19.4187 17.5809 19.8817 17.3117 19.8817 16.9783V7.02602C19.8817 6.69254 19.4187 6.42334 18.8451 6.42334Z" fill="#9D9FA0"/>
            <Path d="M22.411 7.64062C21.8374 7.64062 21.3744 7.90982 21.3744 8.2433V15.7527C21.3744 16.0862 21.8374 16.3554 22.411 16.3554C22.9846 16.3554 23.4476 16.0862 23.4476 15.7527V8.2433C23.4476 7.91384 22.9846 7.64062 22.411 7.64062Z" fill="#9D9FA0"/>
            <Path d="M25.9699 8.58081C25.3964 8.58081 24.9333 8.85001 24.9333 9.18349V14.8165C24.9333 15.15 25.3964 15.4192 25.9699 15.4192C26.5435 15.4192 27.0065 15.15 27.0065 14.8165V9.18349C27.0065 8.85001 26.5435 8.58081 25.9699 8.58081Z" fill="#9D9FA0"/>
            <Path d="M29.5358 5.05322C28.9622 5.05322 28.4992 5.32242 28.4992 5.6559V18.3443C28.4992 18.6778 28.9622 18.947 29.5358 18.947C30.1094 18.947 30.5724 18.6778 30.5724 18.3443V5.6559C30.5724 5.32242 30.1094 5.05322 29.5358 5.05322Z" fill="#9D9FA0"/>
            <Path d="M33.0947 4.4624C32.5211 4.4624 32.0581 4.7316 32.0581 5.06508V18.9347C32.0581 19.2682 32.5211 19.5374 33.0947 19.5374C33.6683 19.5374 34.1313 19.2682 34.1313 18.9347V5.06508C34.1313 4.7316 33.6683 4.4624 33.0947 4.4624Z" fill="#9D9FA0"/>
            <Path d="M36.6606 6.08569C36.087 6.08569 35.624 6.35489 35.624 6.68837V17.3116C35.624 17.6451 36.087 17.9143 36.6606 17.9143C37.2342 17.9143 37.6972 17.6451 37.6972 17.3116V6.68837C37.6972 6.35489 37.2342 6.08569 36.6606 6.08569Z" fill="#9D9FA0"/>
            <Path d="M40.2195 6.37915C39.6459 6.37915 39.1829 6.64835 39.1829 6.98183V17.0144C39.1829 17.3479 39.6459 17.6171 40.2195 17.6171C40.7931 17.6171 41.2561 17.3479 41.2561 17.0144V6.98183C41.2561 6.65236 40.7931 6.37915 40.2195 6.37915Z" fill="#9D9FA0"/>
            <Path d="M43.7854 7.56006C43.2118 7.56006 42.7488 7.82926 42.7488 8.16274V15.8328C42.7488 16.1663 43.2118 16.4355 43.7854 16.4355C44.3589 16.4355 44.8219 16.1663 44.8219 15.8328V8.16274C44.8219 7.82926 44.3589 7.56006 43.7854 7.56006Z" fill="#9D9FA0"/>
            <Path d="M47.3443 8.44824C46.7707 8.44824 46.3077 8.71744 46.3077 9.05092V14.9532C46.3077 15.2866 46.7707 15.5558 47.3443 15.5558C47.9179 15.5558 48.3809 15.2866 48.3809 14.9532V9.05092C48.3809 8.71744 47.9179 8.44824 47.3443 8.44824Z" fill="#9D9FA0"/>
            <Path d="M50.9102 8.44824C50.3366 8.44824 49.8736 8.71744 49.8736 9.05092V14.9532C49.8736 15.2866 50.3366 15.5558 50.9102 15.5558C51.4838 15.5558 51.9468 15.2866 51.9468 14.9532V9.05092C51.9468 8.71744 51.4838 8.44824 50.9102 8.44824Z" fill="#9D9FA0"/>
            <Path d="M54.4691 8.51245C53.8955 8.51245 53.4325 8.78165 53.4325 9.11513V14.8808C53.4325 15.2142 53.8955 15.4834 54.4691 15.4834C55.0427 15.4834 55.5057 15.2142 55.5057 14.8808V9.11513C55.5057 8.78567 55.0427 8.51245 54.4691 8.51245Z" fill="#9D9FA0"/>
            <Path d="M58.0349 7.70898C57.4614 7.70898 56.9984 7.97818 56.9984 8.31166V15.6884C56.9984 16.0219 57.4614 16.2911 58.0349 16.2911C58.6085 16.2911 59.0715 16.0219 59.0715 15.6884V8.31166C59.0715 7.97818 58.6085 7.70898 58.0349 7.70898Z" fill="#9D9FA0"/>
            <Path d="M68.7187 9.92285C68.1451 9.92285 67.6821 10.192 67.6821 10.5255V13.4746C67.6821 13.8081 68.1451 14.0773 68.7187 14.0773C69.2923 14.0773 69.7553 13.8081 69.7553 13.4746V10.5255C69.7553 10.192 69.2923 9.92285 68.7187 9.92285Z" fill="#9D9FA0"/>
            <Path d="M72.2845 9.92285C71.711 9.92285 71.248 10.192 71.248 10.5255V13.4746C71.248 13.8081 71.711 14.0773 72.2845 14.0773C72.8581 14.0773 73.3211 13.8081 73.3211 13.4746V10.5255C73.3211 10.192 72.8581 9.92285 72.2845 9.92285Z" fill="#9D9FA0"/>
            <Path d="M61.5939 7.84155C61.0203 7.84155 60.5573 8.11075 60.5573 8.44423V15.5518C60.5573 15.8853 61.0203 16.1545 61.5939 16.1545C62.1675 16.1545 62.6305 15.8853 62.6305 15.5518V8.44423C62.6305 8.11477 62.1675 7.84155 61.5939 7.84155Z" fill="#9D9FA0"/>
            <Path d="M65.1598 8.94653C64.5862 8.94653 64.1232 9.21573 64.1232 9.54921V14.9131C64.1232 15.2465 64.5862 15.5157 65.1598 15.5157C65.7333 15.5157 66.1963 15.2465 66.1963 14.9131V9.54921C66.1963 9.21573 65.7333 8.94653 65.1598 8.94653Z" fill="#9D9FA0"/>
            <Path d="M75.8435 10.385C75.2699 10.385 74.8069 10.6542 74.8069 10.9877V13.0167C74.8069 13.3502 75.2699 13.6194 75.8435 13.6194C76.4171 13.6194 76.8801 13.3502 76.8801 13.0167V10.9877C76.8801 10.6542 76.4171 10.385 75.8435 10.385Z" fill="#9D9FA0"/>
            <Path d="M79.4094 10.0474C78.8358 10.0474 78.3728 10.3166 78.3728 10.65V13.3541C78.3728 13.6875 78.8358 13.9567 79.4094 13.9567C79.9829 13.9567 80.4459 13.6875 80.4459 13.3541V10.65C80.4459 10.3166 79.9829 10.0474 79.4094 10.0474Z" fill="#9D9FA0"/>
            <Path d="M82.9683 8.24341C82.3947 8.24341 81.9317 8.5126 81.9317 8.84609V15.1541C81.9317 15.4876 82.3947 15.7568 82.9683 15.7568C83.5419 15.7568 84.0049 15.4876 84.0049 15.1541V8.84609C84.0049 8.5126 83.5419 8.24341 82.9683 8.24341Z" fill="#9D9FA0"/>
            <Path d="M86.5341 6.32666C85.9606 6.32666 85.4976 6.59586 85.4976 6.92934V17.0704C85.4976 17.4039 85.9606 17.6731 86.5341 17.6731C87.1077 17.6731 87.5707 17.4039 87.5707 17.0704V6.92934C87.5707 6.59586 87.1077 6.32666 86.5341 6.32666Z" fill="#9D9FA0"/>
            <Path d="M90.0931 6.88916C89.5195 6.88916 89.0565 7.15836 89.0565 7.49184V16.5039C89.0565 16.8374 89.5195 17.1066 90.0931 17.1066C90.6667 17.1066 91.1297 16.8374 91.1297 16.5039V7.49184C91.1297 7.16237 90.6667 6.88916 90.0931 6.88916Z" fill="#9D9FA0"/>
            <Path d="M93.6589 2.44141C93.0854 2.44141 92.6223 2.7106 92.6223 3.04408V20.9597C92.6223 21.2932 93.0854 21.5624 93.6589 21.5624C94.2325 21.5624 94.6955 21.2932 94.6955 20.9597V3.04408C94.6955 2.7106 94.2325 2.44141 93.6589 2.44141Z" fill="#9D9FA0"/>
            <Path d="M97.2179 4.63525C96.6443 4.63525 96.1813 4.90445 96.1813 5.23793V18.758C96.1813 19.0915 96.6443 19.3607 97.2179 19.3607C97.7915 19.3607 98.2545 19.0915 98.2545 18.758V5.23793C98.2545 4.90847 97.7915 4.63525 97.2179 4.63525Z" fill="#9D9FA0"/>
            <Path d="M100.784 6.32666C100.21 6.32666 99.7471 6.59586 99.7471 6.92934V17.0704C99.7471 17.4039 100.21 17.6731 100.784 17.6731C101.357 17.6731 101.82 17.4039 101.82 17.0704V6.92934C101.82 6.59586 101.357 6.32666 100.784 6.32666Z" fill="#9D9FA0"/>
            <Path d="M104.343 3.4541C103.769 3.4541 103.306 3.7233 103.306 4.05678V19.9434C103.306 20.2769 103.769 20.5461 104.343 20.5461C104.916 20.5461 105.379 20.2769 105.379 19.9434V4.05678C105.379 3.7233 104.916 3.4541 104.343 3.4541Z" fill="#9D9FA0"/>
            <Path d="M107.909 5.31421C107.335 5.31421 106.872 5.58341 106.872 5.91689V18.087C106.872 18.4205 107.335 18.6897 107.909 18.6897C108.482 18.6897 108.945 18.4205 108.945 18.087V5.91689C108.945 5.58341 108.482 5.31421 107.909 5.31421Z" fill="#9D9FA0"/>
            <Path d="M111.467 5.65161C110.894 5.65161 110.431 5.92081 110.431 6.25429V17.7454C110.431 18.0788 110.894 18.348 111.467 18.348C112.041 18.348 112.504 18.0788 112.504 17.7454V6.25429C112.504 5.92081 112.041 5.65161 111.467 5.65161Z" fill="#9D9FA0"/>
            <Path d="M115.033 7.00171C114.46 7.00171 113.997 7.27091 113.997 7.60439V16.3914C113.997 16.7249 114.46 16.9941 115.033 16.9941C115.607 16.9941 116.07 16.7249 116.07 16.3914V7.60439C116.07 7.27492 115.607 7.00171 115.033 7.00171Z" fill="#9D9FA0"/>
            <Path d="M118.592 5.98926C118.019 5.98926 117.556 6.25845 117.556 6.59194V17.408C117.556 17.7415 118.019 18.0107 118.592 18.0107C119.166 18.0107 119.629 17.7415 119.629 17.408V6.59194C119.629 6.25845 119.166 5.98926 118.592 5.98926Z" fill="#9D9FA0"/>
            <Path d="M122.158 6.66431C121.585 6.66431 121.122 6.9335 121.122 7.26698V16.7331C121.122 17.0665 121.585 17.3357 122.158 17.3357C122.732 17.3357 123.195 17.0665 123.195 16.7331V7.26698C123.195 6.9335 122.732 6.66431 122.158 6.66431Z" fill="#9D9FA0"/>
            <Path d="M125.717 6.66431C125.143 6.66431 124.68 6.9335 124.68 7.26698V16.7331C124.68 17.0665 125.143 17.3357 125.717 17.3357C126.291 17.3357 126.754 17.0665 126.754 16.7331V7.26698C126.754 6.9335 126.291 6.66431 125.717 6.66431Z" fill="#9D9FA0"/>
            <Path d="M129.283 4.12915C128.709 4.12915 128.246 4.39835 128.246 4.73183V19.2644C128.246 19.5979 128.709 19.8671 129.283 19.8671C129.856 19.8671 130.319 19.5979 130.319 19.2644V4.73183C130.319 4.39835 129.856 4.12915 129.283 4.12915Z" fill="#9D9FA0"/>
            <Path d="M132.842 4.97656C132.268 4.97656 131.805 5.24576 131.805 5.57924V18.4243C131.805 18.7578 132.268 19.027 132.842 19.027C133.415 19.027 133.878 18.7578 133.878 18.4243V5.57924C133.878 5.24576 133.415 4.97656 132.842 4.97656Z" fill="#9D9FA0"/>
            <Path d="M136.408 7.1062C135.834 7.1062 135.371 7.3754 135.371 7.70888V16.291C135.371 16.6245 135.834 16.8937 136.408 16.8937C136.981 16.8937 137.444 16.6245 137.444 16.291V7.70888C137.444 7.3754 136.981 7.1062 136.408 7.1062Z" fill="#9D9FA0"/>
            <Path d="M139.967 6.32666C139.393 6.32666 138.93 6.59586 138.93 6.92934V17.0704C138.93 17.4039 139.393 17.6731 139.967 17.6731C140.54 17.6731 141.003 17.4039 141.003 17.0704V6.92934C141.003 6.59586 140.54 6.32666 139.967 6.32666Z" fill="#9D9FA0"/>
            <Path d="M143.533 7.26294C142.959 7.26294 142.496 7.53214 142.496 7.86562V16.1344C142.496 16.4679 142.959 16.737 143.533 16.737C144.106 16.737 144.569 16.4679 144.569 16.1344V7.86562C144.569 7.53214 144.106 7.26294 143.533 7.26294Z" fill="#9D9FA0"/>
            <Path d="M147.091 6.4834C146.518 6.4834 146.055 6.75259 146.055 7.08608V16.9138C146.055 17.2472 146.518 17.5164 147.091 17.5164C147.665 17.5164 148.128 17.2472 148.128 16.9138V7.08608C148.128 6.75259 147.665 6.4834 147.091 6.4834Z" fill="#9D9FA0"/>
            <Path d="M150.657 5.31421C150.084 5.31421 149.621 5.58341 149.621 5.91689V18.087C149.621 18.4205 150.084 18.6897 150.657 18.6897C151.231 18.6897 151.694 18.4205 151.694 18.087V5.91689C151.694 5.58341 151.224 5.31421 150.657 5.31421Z" fill="#9D9FA0"/>
            <Path d="M154.216 7.34326C153.643 7.34326 153.18 7.61246 153.18 7.94594V16.058C153.18 16.3915 153.643 16.6607 154.216 16.6607C154.79 16.6607 155.253 16.3915 155.253 16.058V7.94594C155.253 7.61246 154.79 7.34326 154.216 7.34326Z" fill="#9D9FA0"/>
            <Path d="M157.782 5.98926C157.209 5.98926 156.746 6.25845 156.746 6.59194V17.408C156.746 17.7415 157.209 18.0107 157.782 18.0107C158.356 18.0107 158.819 17.7415 158.819 17.408V6.59194C158.819 6.25845 158.356 5.98926 157.782 5.98926Z" fill="#9D9FA0"/>
            <Path d="M161.341 3.4541C160.767 3.4541 160.304 3.7233 160.304 4.05678V19.9434C160.304 20.2769 160.767 20.5461 161.341 20.5461C161.915 20.5461 162.378 20.2769 162.378 19.9434V4.05678C162.378 3.7233 161.915 3.4541 161.341 3.4541Z" fill="#9D9FA0"/>
            <Path d="M164.907 4.63525C164.333 4.63525 163.87 4.90445 163.87 5.23793V18.758C163.87 19.0915 164.333 19.3607 164.907 19.3607C165.48 19.3607 165.944 19.0915 165.944 18.758V5.23793C165.944 4.90847 165.48 4.63525 164.907 4.63525Z" fill="#9D9FA0"/>
            <Path d="M168.466 4.63525C167.892 4.63525 167.429 4.90445 167.429 5.23793V18.758C167.429 19.0915 167.892 19.3607 168.466 19.3607C169.039 19.3607 169.502 19.0915 169.502 18.758V5.23793C169.502 4.90847 169.039 4.63525 168.466 4.63525Z" fill="#9D9FA0"/>
            <Path d="M172.032 2.44141C171.458 2.44141 170.995 2.7106 170.995 3.04408V20.9597C170.995 21.2932 171.458 21.5624 172.032 21.5624C172.605 21.5624 173.068 21.2932 173.068 20.9597V3.04408C173.068 2.7106 172.598 2.44141 172.032 2.44141Z" fill="#9D9FA0"/>
            <Path d="M175.591 4.63525C175.017 4.63525 174.554 4.90445 174.554 5.23793V18.758C174.554 19.0915 175.017 19.3607 175.591 19.3607C176.164 19.3607 176.627 19.0915 176.627 18.758V5.23793C176.627 4.90847 176.164 4.63525 175.591 4.63525Z" fill="#9D9FA0"/>
            <Path d="M179.156 7.68091C178.583 7.68091 178.12 7.9501 178.12 8.28359V15.7206C178.12 16.0541 178.583 16.3233 179.156 16.3233C179.73 16.3233 180.193 16.0541 180.193 15.7206V8.28359C180.193 7.9501 179.723 7.68091 179.156 7.68091Z" fill="#9D9FA0"/>
            <Path d="M182.715 8.01831C182.142 8.01831 181.679 8.28751 181.679 8.62099V15.383C181.679 15.7165 182.142 15.9857 182.715 15.9857C183.289 15.9857 183.752 15.7165 183.752 15.383V8.62099C183.752 8.28751 183.289 8.01831 182.715 8.01831Z" fill="#9D9FA0"/>
            <Path d="M186.281 6.66431C185.708 6.66431 185.245 6.9335 185.245 7.26698V16.7331C185.245 17.0665 185.708 17.3357 186.281 17.3357C186.855 17.3357 187.318 17.0665 187.318 16.7331V7.26698C187.318 6.9335 186.848 6.66431 186.281 6.66431Z" fill="#9D9FA0"/>
            <Path d="M189.84 4.63525C189.267 4.63525 188.804 4.90445 188.804 5.23793V18.758C188.804 19.0915 189.267 19.3607 189.84 19.3607C190.414 19.3607 190.877 19.0915 190.877 18.758V5.23793C190.877 4.90847 190.414 4.63525 189.84 4.63525Z" fill="#9D9FA0"/>
            <Path d="M193.406 3.7915C192.833 3.7915 192.37 4.0607 192.37 4.39418V19.6058C192.37 19.9393 192.833 20.2085 193.406 20.2085C193.98 20.2085 194.443 19.9393 194.443 19.6058V4.39418C194.443 4.0607 193.973 3.7915 193.406 3.7915Z" fill="#9D9FA0"/>
            <Path d="M196.965 0.75C196.391 0.75 195.928 1.0192 195.928 1.35268V22.6473C195.928 22.9808 196.391 23.25 196.965 23.25C197.539 23.25 198.002 22.9808 198.002 22.6473V1.35268C198.002 1.0192 197.539 0.75 196.965 0.75Z" fill="#9D9FA0"/>
            <Path d="M200.531 2.44141C199.957 2.44141 199.494 2.7106 199.494 3.04408V20.9597C199.494 21.2932 199.957 21.5624 200.531 21.5624C201.104 21.5624 201.567 21.2932 201.567 20.9597V3.04408C201.567 2.7106 201.098 2.44141 200.531 2.44141Z" fill="#9D9FA0"/>
            <Path d="M204.09 4.63525C203.516 4.63525 203.053 4.90445 203.053 5.23793V18.758C203.053 19.0915 203.516 19.3607 204.09 19.3607C204.663 19.3607 205.126 19.0915 205.126 18.758V5.23793C205.126 4.90847 204.663 4.63525 204.09 4.63525Z" fill="#9D9FA0"/>
            <Path d="M207.656 6.32666C207.082 6.32666 206.619 6.59586 206.619 6.92934V17.0704C206.619 17.4039 207.082 17.6731 207.656 17.6731C208.229 17.6731 208.692 17.4039 208.692 17.0704V6.92934C208.692 6.59586 208.222 6.32666 207.656 6.32666Z" fill="#9D9FA0"/>
            <Path d="M214.78 3.4541C214.207 3.4541 213.744 3.7233 213.744 4.05678V19.9434C213.744 20.2769 214.207 20.5461 214.78 20.5461C215.354 20.5461 215.817 20.2769 215.817 19.9434V4.05678C215.817 3.7233 215.347 3.4541 214.78 3.4541Z" fill="#9D9FA0"/>
            <Path d="M218.339 5.31421C217.766 5.31421 217.303 5.58341 217.303 5.91689V18.087C217.303 18.4205 217.766 18.6897 218.339 18.6897C218.913 18.6897 219.376 18.4205 219.376 18.087V5.91689C219.376 5.58341 218.913 5.31421 218.339 5.31421Z" fill="#9D9FA0"/>
            <Path d="M221.905 5.65161C221.332 5.65161 220.869 5.92081 220.869 6.25429V17.7454C220.869 18.0788 221.332 18.348 221.905 18.348C222.479 18.348 222.942 18.0788 222.942 17.7454V6.25429C222.942 5.92081 222.472 5.65161 221.905 5.65161Z" fill="#9D9FA0"/>
            <Path d="M225.464 7.00171C224.891 7.00171 224.428 7.27091 224.428 7.60439V16.3914C224.428 16.7249 224.891 16.9941 225.464 16.9941C226.038 16.9941 226.501 16.7249 226.501 16.3914V7.60439C226.501 7.27492 226.038 7.00171 225.464 7.00171Z" fill="#9D9FA0"/>
            <Path d="M229.03 5.98926C228.457 5.98926 227.993 6.25845 227.993 6.59194V17.408C227.993 17.7415 228.457 18.0107 229.03 18.0107C229.604 18.0107 230.067 17.7415 230.067 17.408V6.59194C230.067 6.25845 229.597 5.98926 229.03 5.98926Z" fill="#9D9FA0"/>
            <Path d="M232.589 6.66431C232.015 6.66431 231.552 6.9335 231.552 7.26698V16.7331C231.552 17.0665 232.015 17.3357 232.589 17.3357C233.163 17.3357 233.626 17.0665 233.626 16.7331V7.26698C233.626 6.9335 233.163 6.66431 232.589 6.66431Z" fill="#9D9FA0"/>
            <Path d="M236.155 6.66431C235.581 6.66431 235.118 6.9335 235.118 7.26698V16.7331C235.118 17.0665 235.581 17.3357 236.155 17.3357C236.728 17.3357 237.191 17.0665 237.191 16.7331V7.26698C237.191 6.9335 236.722 6.66431 236.155 6.66431Z" fill="#9D9FA0"/>
            <Path d="M239.714 7.51221C239.14 7.51221 238.677 7.7814 238.677 8.11489V15.8894C238.677 16.2229 239.14 16.4921 239.714 16.4921C240.287 16.4921 240.75 16.2229 240.75 15.8894V8.11489C240.75 7.7814 240.287 7.51221 239.714 7.51221Z" fill="#9D9FA0"/>
            <Path d="M243.28 8.6853C242.706 8.6853 242.243 8.9545 242.243 9.28798V14.7121C242.243 15.0456 242.706 15.3148 243.28 15.3148C243.853 15.3148 244.316 15.0456 244.316 14.7121V9.28798C244.316 8.9545 243.846 8.6853 243.28 8.6853Z" fill="#9D9FA0"/>
            <Path d="M246.839 8.78174C246.265 8.78174 245.802 9.05093 245.802 9.38442V14.6197C245.802 14.9532 246.265 15.2224 246.839 15.2224C247.412 15.2224 247.875 14.9532 247.875 14.6197V9.38442C247.875 9.05093 247.412 8.78174 246.839 8.78174Z" fill="#9D9FA0"/>
            <Path d="M250.398 9.35229C249.824 9.35229 249.361 9.62149 249.361 9.95497V14.0452C249.361 14.3786 249.824 14.6478 250.398 14.6478C250.971 14.6478 251.434 14.3786 251.434 14.0452V9.95497C251.434 9.62149 250.971 9.35229 250.398 9.35229Z" fill="#9D9FA0"/>
            <Path d="M253.963 10.5415C253.39 10.5415 252.927 10.8107 252.927 11.1442V12.8558C252.927 13.1893 253.39 13.4585 253.963 13.4585C254.537 13.4585 255 13.1893 255 12.8558V11.1442C255 10.8107 254.537 10.5415 253.963 10.5415Z" fill="#9D9FA0"/>
            <Path d="M211.215 2.44141C210.641 2.44141 210.178 2.7106 210.178 3.04408V20.9597C210.178 21.2932 210.641 21.5624 211.215 21.5624C211.788 21.5624 212.251 21.2932 212.251 20.9597V3.04408C212.251 2.7106 211.788 2.44141 211.215 2.44141Z" fill="#9D9FA0"/>
        </Svg>

    );
}