import React from 'react';
import { Pressable, StyleSheet, Text, View  } from 'react-native'; 
import Modal from 'react-native-modal';

import { AppColors } from '../../../common/AppColors';

interface ModalProps {
    visible: boolean,
    onDismiss: () => void,
    onSuccess: () => void
}

export default (props: ModalProps) => {
    return (
        <Modal
            isVisible={props.visible}
            coverScreen={false}

        >

            <View style={styles.container}>
                <View style={styles.content}>
                <Text numberOfLines={3} style={{textAlign: 'center'}}>
                    <Text style={{fontSize: 18}}>
                        Are you sure you want to {'\n'} publish this <Text style={{
                        fontWeight: 'bold',
                        fontSize: 18
                    }}>
                        Urgent Alert?
                    </Text>
                    </Text>
                    
                </Text>
                <View style={styles.actions}>
                    <Pressable onPress={props.onDismiss} style={[styles.button, {backgroundColor: AppColors.orange.primary}]}>
                        <Text style={styles.buttonText}>Cancel</Text>
                    </Pressable>
                    <Pressable onPress={props.onSuccess} style={[styles.button, {backgroundColor: AppColors.blue.primary}]}>
                        <Text style={styles.buttonText}>Yes</Text>
                    </Pressable>
                </View>
                </View>
            </View>

        </Modal>
    );
}
 
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    actions: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 120,
        paddingVertical: 10,
        borderRadius: 8,
        marginHorizontal: 5
    },
    buttonText: {
        color: 'white',
        fontWeight: 'bold'
    },
    content: {
        padding: 20,
        backgroundColor: 'white',
        borderRadius: 24,
        height: 200,
        justifyContent: 'space-evenly'
    }

});