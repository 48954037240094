import { AxiosResponse } from 'axios'
import i18n from 'i18next'
import ErrorBag from './ErrorBag'
import _ from 'lodash'
import { getModalManager } from '../contexts/ModalContext'
import { AppStateStore } from '../contexts/AppStateStore'
import moment, { Moment } from 'moment-timezone'
import { Linking, Platform } from 'react-native'

export const extractErrorMessage = (response: AxiosResponse) => {
  return ((response && response.data)
    ? (response.data.error || response.data.message)
    : undefined)
    || i18n.t('Server Error', 'A server error has occurred')
}

type HandleErrorResponseOptions = {
  suppressDefaultAlert?: boolean
  unhandledErrors?: {
    unhandledErrorsKey: string
    handledErrorFields: string[]
  }
}

export const handleErrorResponse = (response: AxiosResponse, errorBag?: ErrorBag, options: HandleErrorResponseOptions = {}) => {
  if (errorBag) {
    if (response && response.status === 422) {
      if (response.data && response.data.errors) {
        for (const fieldName in response.data.errors) {
          if (response.data.errors.hasOwnProperty(fieldName)) {
            const errorMessages = response.data.errors[fieldName]

            if (options.unhandledErrors) {
              if (!_.find(options.unhandledErrors.handledErrorFields, s => s === fieldName)) {
                for (const errorMessage of errorMessages) {
                  errorBag.addError(options.unhandledErrors.unhandledErrorsKey, errorMessage)
                }
              } else {
                for (const errorMessage of errorMessages) {
                  errorBag.addError(fieldName, errorMessage)
                }
              }
            } else {
              for (const errorMessage of errorMessages) {
                errorBag.addError(fieldName, errorMessage)
              }
            }
          }
        }

        return
      }
    }
  }

  if (!options.suppressDefaultAlert) {
    getModalManager()
      .showModal({
        title: i18n.t('Error', 'Error'),
        message: extractErrorMessage(response),
      })
  }
}

export const handleLogOutButton = () => {
  getModalManager()
    .showModal({
      title: i18n.t('Log Out', 'Log Out'),
      message: i18n.t('Are you sure you want to log out of your account', 'Are you sure you want to log out of your account?'),
      buttons: [
        {
          text: i18n.t('Confirm Log Out', 'Yes, Log Out'),
          onPress: dismiss => {
            AppStateStore.logout()
            dismiss()
          },
        },
        {
          text: i18n.t('Cancel', 'Cancel'),
          variant: 'secondary',
        },
      ],
    })
}

export const MYSQL_DATE_FORMAT = 'YYYY-MM-DD'
export const MYSQL_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const utc = (str?: string) => {
  return str ? moment.tz(str, 'UTC') : moment.tz('UTC')
}

export const localTz = (d?: Moment) => {
  return (d ? d.clone() : utc()).tz('America/Phoenix')
}

export const relativeTime = (date: string | Moment) => {
  let d = (typeof date === 'string' ? utc(date) : date.clone().tz('UTC'))

  const ageMinutes = utc().diff(d, 'minutes')

  if (ageMinutes <= 1) {
    return 'Just now'
  } else if (ageMinutes < 60) {
    return `${ageMinutes}m`
  } else if (d.isSame(moment(), 'day')) {
    return `${Math.round(ageMinutes / 60)}h`
  } else if (d.isSame(moment(), 'year')) {
    return d.format('MMM D h:mma')
  } else {
    return d.format('MMM D YYYY h:mma')
  }
}

export const formatDuration = (seconds: number): string => {
  const m = Math.floor(seconds / 60)
  const s = seconds - m * 60

  return `${m}:${_.padStart(String(s), 2, '0')}`
}

export const fullName = (obj?: { firstName: string | null, lastName: string | null, displayName?: string | null }) => {
  return obj
    ? (obj.displayName ?? [obj.firstName, obj.lastName].filter(a => !!a).join(' '))
    : '---'
}

export const formatNumber = (c: number, decPlaces: number = 0, thouSeparator: string | undefined = undefined, decSeparator: string | undefined = undefined, forceDecimal: boolean = false) => {
  decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces
  decSeparator = decSeparator === undefined ? '.' : decSeparator
  thouSeparator = thouSeparator === undefined ? ',' : thouSeparator
  let n: number = c
  let sign = n < 0 ? '-' : ''
  let s = Math.abs(+n || 0)
    .toFixed(decPlaces)
  n = Number(s)
  let i = parseInt(s) + ''
  let j = (i.length > 3) ? (i.length % 3) : 0

  let str = sign + (j ? i.substr(0, j) + thouSeparator : '') + i.substr(j)
    .replace(/(\d{3})(?=\d)/g, '$1' + thouSeparator)
  if (n % 1 !== 0 || forceDecimal) {
    return str + (decPlaces ? decSeparator + Math.abs(n - Number(i))
      .toFixed(decPlaces)
      .slice(2) : '')
  } else {
    return str
  }
}

export const getMimeType = (filename: string): string | undefined => {
  const ext: string = filename.slice((Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1)
  return MIME_TYPES[ext]
}

const MIME_TYPES: { [key: string]: string } = {
  "aac": "audio/aac",
  "abw": "application/x-abiword",
  "ai": "application/postscript",
  "arc": "application/octet-stream",
  "avi": "video/x-msvideo",
  "azw": "application/vnd.amazon.ebook",
  "bin": "application/octet-stream",
  "bz": "application/x-bzip",
  "bz2": "application/x-bzip2",
  "csh": "application/x-csh",
  "css": "text/css",
  "csv": "text/csv",
  "doc": "application/msword",
  "dll": "application/octet-stream",
  "eot": "application/vnd.ms-fontobject",
  "epub": "application/epub+zip",
  "gif": "image/gif",
  "htm": "text/html",
  "html": "text/html",
  "ico": "image/x-icon",
  "ics": "text/calendar",
  "jar": "application/java-archive",
  "jpeg": "image/jpeg",
  "jpg": "image/jpeg",
  "js": "application/javascript",
  "json": "application/json",
  "mid": "audio/midi",
  "midi": "audio/midi",
  "mp2": "audio/mpeg",
  "mp3": "audio/mpeg",
  "mp4": "video/mp4",
  "mpa": "video/mpeg",
  "mpe": "video/mpeg",
  "mpeg": "video/mpeg",
  "mpkg": "application/vnd.apple.installer+xml",
  "odp": "application/vnd.oasis.opendocument.presentation",
  "ods": "application/vnd.oasis.opendocument.spreadsheet",
  "odt": "application/vnd.oasis.opendocument.text",
  "oga": "audio/ogg",
  "ogv": "video/ogg",
  "ogx": "application/ogg",
  "otf": "font/otf",
  "png": "image/png",
  "pdf": "application/pdf",
  "ppt": "application/vnd.ms-powerpoint",
  "rar": "application/x-rar-compressed",
  "rtf": "application/rtf",
  "sh": "application/x-sh",
  "svg": "image/svg+xml",
  "swf": "application/x-shockwave-flash",
  "tar": "application/x-tar",
  "tif": "image/tiff",
  "tiff": "image/tiff",
  "ts": "application/typescript",
  "ttf": "font/ttf",
  "txt": "text/plain",
  "vsd": "application/vnd.visio",
  "wav": "audio/x-wav",
  "weba": "audio/webm",
  "webm": "video/webm",
  "webp": "image/webp",
  "woff": "font/woff",
  "woff2": "font/woff2",
  "xhtml": "application/xhtml+xml",
  "xls": "application/vnd.ms-excel",
  "xlsx": "application/vnd.ms-excel",
  "xlsx_OLD": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "xml": "application/xml",
  "xul": "application/vnd.mozilla.xul+xml",
  "zip": "application/zip",
  "3gp": "video/3gpp",
  "3gp_DOES_NOT_CONTAIN_VIDEO": "audio/3gpp",
  "3gp2": "video/3gpp2",
  "3gp2_DOES_NOT_CONTAIN_VIDEO": "audio/3gpp2",
  "7z": "application/x-7z-compressed"
}

export const openExternalUrl = (url: string) => {
  if (Platform.OS === 'web') {
    const link = document.createElement('a')
    link.setAttribute('target', '_blank')
    link.setAttribute('href', url)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    link.remove()
  } else {
    Linking.openURL(url).then()
  }
}

export const getExtension = (filename: string): string | undefined => {
  const ext: string = filename.slice((Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1)
  return ext
}
