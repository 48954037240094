import { useTranslation } from 'react-i18next'
import React, { useEffect, useCallback, useState } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { StackScreenProps } from '@react-navigation/stack'
//import PdfReader from 'rn-pdf-reader-js'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { View, TouchableOpacity, StyleSheet, Share, Pressable, Text, ActivityIndicator } from 'react-native'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { Document } from '../../api/schema/models/Document'
import Icons from '../../components/icons'
import { AppColors, AppColors as COLORS } from '../../common/AppColors'
import { openExternalUrl } from '../../common/Util'
import { SuspenseScreen } from '../../../src/screens/SuspenseScreen'
import { API } from '../../api/API'
import { runInAction } from 'mobx'
import { GetDocumentResponse } from '../../api/schema/GetDocumentResponse'


export type DocumentLoadingScreenParams = {
  title: string
  documentId: number
}

export const DocumentLoadingScreen = (props: StackScreenProps<RootNavigationParamList, 'DocumentLoader'>) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    document: undefined as Document | undefined,
    isLoading: true,
    documentRetrieved: undefined as Document | undefined,
  }))
  const [loading, setLoading] = useState(true);
  const [documentUrl, setDocument] = useState(undefined as string | undefined);

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: props.route.params.title ? props.route.params.title.slice(0, 25) + (props.route.params.title.length > 25 ? '...' : '') : 'Document',
      headerRight: () => <View style={{ flexDirection: 'row' }}>
        {
          <ActivityIndicator
            size={'small'}
            color={AppColors.orange.primary}
            animating={true}
          />
        }

      </View>
    })
  }, [props.navigation, state])

  const retrieveDocument = useCallback(async () => {
    if (props.route.params.documentId) {
      let response = await API.getDocument(props.route.params.documentId)
      setLoading(false)
      if (response.document.documentUrl) {
        setDocument(response.document.documentUrl)
        props.navigation.replace('DocumentViewer', { title: props.route.params.title, url: response.document.documentUrl })
      }

    }
  }, [props.route.params.documentId, state])

  useEffect(() => {
    retrieveDocument().then().catch((err) => console.log('error', err))
  }, [retrieveDocument])



  /*   const onShare = async () => {
      const url = props.route.params.url
      await Share.share({
        message: url,
        title: 'Share Link'
      }, {
        dialogTitle: 'Share Link'
      })
    } */

  return <View style={{ width: '100%', height: '100%' }}>
    <View style={{ width: '100%', height: '100%', backgroundColor: AppColors.orange.primary, paddingTop: '100%' }}>
      {/* <SuspenseScreen/> */}
      <ActivityIndicator
        size={'large'}
        color={AppColors.white}
        animating={true}
      />
    </View>
  </View>


}

const styles = StyleSheet.create({
  headerButton: {
    padding: 10,
  },
})