import React from 'react'
import Icons from './icons'
import { AppColors as COLORS } from '../common/AppColors'

type Props = {
  type: string
}

export const DocumentIcon = (props: Props) => {
  if (props.type === 'pdf' || props.type === 'application/pdf') {
    return <Icons.Pdf height={40} width={40} color={COLORS.salmon.primary} />
  } else if (props.type === 'application/msword' || props.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    return <Icons.Doc height={40} width={40} color={COLORS.aqua.secondary} />
  } else {
    return <Icons.Attach height={40} width={40} color={COLORS.egg.secondary} />
  }
}

