import React, {useEffect} from 'react'
import { ScrollView, StyleSheet, View, Text, Platform } from 'react-native'
import { BoldText } from '../../components/BoldText'
import { Colors } from '../../common/Colors'
import { BlockButton } from '../../components/BlockButton'
import { StyledTextInput } from '../../components/StyledTextInput'
import { SmartKeyboardAvoidingView } from '../../components/SmartKeyboardAvoidingView'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { useTranslation } from 'react-i18next'
import { handleErrorResponse } from '../../common/Util'
import { FormError } from '../../components/FormError'
import ErrorBag from '../../common/ErrorBag'
import { API } from '../../api/API'
import { AppStateStore } from '../../contexts/AppStateStore'
import { getModalManager } from '../../contexts/ModalContext'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';
import { AppColors } from '../../common/AppColors'
import { MiniLogoSvg } from '../../../assets/images/mini-logo'
//import * as Sentry from 'sentry-expo'

export type VerificationScreenParams = {
  authMethod: 'phone' | 'email'
  login: string
  countdown: number
}

export const VerificationScreen = observer((props: StackScreenProps<RootNavigationParamList, 'Verification'>) => {
  const { t } = useTranslation()

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('', ''),
      headerStyle: {backgroundColor: '#fff', borderBottomWidth: 0},
      headerShown: true,
      headerTitle: '',
      headerBackImage: () => <Svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <Path d="M12.59 2.23001C13.13 1.72001 13.13 0.890007 12.59 0.390007C12.05 -0.119993 11.17 -0.119993 10.63 0.390007L1.4 9.09001C0.859995 9.60001 0.859995 10.43 1.4 10.93L10.63 19.63C11.17 20.14 12.05 20.14 12.59 19.63C13.13 19.12 13.13 18.29 12.59 17.79L4.34 10L12.59 2.23001Z" fill="#FF7A33"/>
                            </Svg>
      
    })
  }, [props.navigation])
  
  const state = useLocalObservable(() => ({
    form: {
      verificationCode: '',
    },
    formErrors: new ErrorBag(),
    submitting: false,
    get isValid () {
      return this.form.verificationCode.trim().length > 0
    },
    disableCountdown: props.route.params.countdown,
    showCountdown: props.route.params.countdown > 0 ? true : false
    
  }))

  const onLoad = () => {
    if(state.disableCountdown != 0) {
      let counter = setInterval(() => {
        state.disableCountdown -= 1
        console.log(state.disableCountdown)
      }, 1000)

      setTimeout(() => {
        state.showCountdown = false
        clearInterval(counter)
      }, state.disableCountdown * 1000);
    }  
  }

  useEffect(() => {
    onLoad()
  }, [])

  const submit = async () => {
    runInAction(() => {
      state.submitting = true
      state.formErrors.clearErrors()
    })

    try {
      const response = await API.verifyUser({ verificationCode: state.form.verificationCode, email: props.route.params.login })
      await AppStateStore.setAuthToken(response.authToken)
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => {
        handleErrorResponse(err.response, state.formErrors, {
          unhandledErrors: {
            handledErrorFields: Object.keys(state.form),
            unhandledErrorsKey: '_unhandled',
          }
        })
      })
    }

    runInAction(() => {
      state.submitting = false
    })
  }

  const resendVerificationCode = async () => {
    runInAction(() => {
      state.submitting = true
      state.formErrors.clearErrors()
    })

    try {
      const response = await API.resendVerificationCode(props.route.params.login)
      console.log(response.countdown)
      state.disableCountdown = response.countdown
      if (state.disableCountdown == 0) {
        if (props.route.params.authMethod === 'phone') {
          getModalManager()
            .showModal({
              title: t('Verification Code Sent', 'Verification Code Sent'),
              message: t('SMS verification resent', 'A new verification code has been sent to your phone'),
            })
        } else {
          getModalManager()
            .showModal({
              title: t('Verification Code Sent', 'Verification Code Sent'),
              message: t('Email verification resent', 'A new verification code has been sent to your email address'),
            })
        }
      }
      else {
        state.showCountdown = true
        
        let counter = setInterval(() => {
          state.disableCountdown -= 1
          console.log(state.disableCountdown)
        }, 1000)

        setTimeout(() => {
          state.showCountdown = false
          clearInterval(counter)
        }, state.disableCountdown * 1000);

      }
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => {
        handleErrorResponse(err.response, state.formErrors, {
          unhandledErrors: {
            handledErrorFields: Object.keys(state.form),
            unhandledErrorsKey: '_unhandled',
          }
        })
      })
    }

    runInAction(() => {
      state.submitting = false
    })
  }

  return <SmartKeyboardAvoidingView style={styles.container}>
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1
      }}
      keyboardShouldPersistTaps="handled"
    >
      <View style={styles.main}>
        <MiniLogoSvg></MiniLogoSvg>
        <BoldText style={styles.headerText}>
          {
            props.route.params.authMethod === 'phone'
              ? t('SMS verification code sent', 'We have sent you a verification code to the provided phone number. Please enter the number below.')
              : t('Email verification code sent', 'We have sent you a verification code to the provided email address. Please enter the number below.')
          }
        </BoldText>
        <View style={styles.formContainer}>
          <FormError field={'_unhandled'} errors={state.formErrors} displayAsList={true} containerStyle={{ marginBottom: 16 }}/>
          <StyledTextInput
            placeholder={t('Verification code', 'Verification code')}
            keyboardType={'number-pad'}
            value={state.form.verificationCode}
            onChangeText={t => runInAction(() => state.form.verificationCode = t)}
            editable={!state.submitting}
            maxLength={6}
            style={{ marginTop: 16, minHeight:56, borderColor: AppColors.blue.primary, borderWidth: 2, backgroundColor: AppColors.gray.neutral6 }}
          />
          <FormError field={'verificationCode'} errors={state.formErrors}/>
          
          <BlockButton
          title={t('Confirm code', 'Confirm code')}
          variant={'secondary'}
          flex={1}
          disabled={!state.isValid || state.submitting}
          onPress={submit}
          textStyle={{ fontSize: 16}}
          style={{ marginRight: 1, width: '100%', minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 20, backgroundColor:  (!state.isValid || state.submitting) ? '#9D9FA0' : '#FF7A33'}}
          />
          {
            state.showCountdown 
            ? <Text style={{fontWeight:'500', fontSize: 16, textAlign:'center', marginTop:15, color:'#f00'}}>{`Resend reenabled after ${state.disableCountdown} seconds`}</Text>
            : <BlockButton
                title={t('Resend code', 'Resend code')}
                variant={'link'}
                disabled={state.submitting}
                onPress={resendVerificationCode}
                textStyle={{fontWeight:'500', fontSize: 16}}
              />
          }
        </View>
      </View>
    </ScrollView>
  </SmartKeyboardAvoidingView>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  main: {
    flex: 1,
    alignItems: 'center',
  },
  headerText: {
    marginTop: 24,
    paddingHorizontal: 24,
    fontSize: 24,
    color: '#0082CD',
    textAlign: 'center',
  },
  formContainer: {
    marginTop: 24,
    paddingHorizontal: 24,
    width: '100%',
  },
  footer: {},
})
