import { observer } from 'mobx-react-lite'
import { getModalManager } from '../contexts/ModalContext'
import React from 'react'
import { Text, StyleSheet, View, Pressable } from 'react-native'
import Modal from 'react-native-modal'
import { BoldText } from './BoldText'
import { DefaultText } from './DefaultText'
import { BlockButton } from './BlockButton'
import { useTranslation } from 'react-i18next'
import { Colors } from '../common/Colors'
import BottomDrawer from 'react-native-bottom-drawer-view';
import { AppColors } from '../common/AppColors'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

export const ModalRenderer = observer(() => {
  const { t } = useTranslation()

  const modalManager = getModalManager()

  const modals = modalManager.getModals()

  return (

    <>
      {
        modals.length
          ? <View style={styles.container}>
            {
              modals.map(modal => {
                if (modal.options.type === 'bottom-drawer') {
                  return (
                    <Modal
                      key={modal.uuid}
                      coverScreen={false}
                      isVisible={modal.show}
                      style={{ margin: 0 }}
                      onModalHide={() => {
                        modalManager.removeModal(modal.uuid)
                      }}>
                      <BottomDrawer
                        key={modal.uuid}
                        containerHeight={modal.options.drawerHeight ?? 370}
                        borderTopRightRadius={24}
                        borderTopLeftRadius={24}
                        onCollapsed={() => modalManager.removeModal(modal.uuid)}>
                        {
                          <View style={styles.drawerContainer}>
                            <Text style={styles.drawerHeaderText}>{modal.options.title}</Text>
                            <Text style={styles.subText}>{modal.options.message}</Text>
                            {modal.options.buttons?.length
                              ? modal.options.buttons?.map((button, idx) => (
                                <BlockButton
                                  key={idx}
                                  title={button.text}
                                  variant={button.variant}
                                  onPress={() => button.onPress ? button.onPress(() => modalManager.dismissModal(modal.uuid)) : modalManager.dismissModal(modal.uuid)}
                                  style={styles.drawerButton}
                                  textStyle={styles.buttonText} />
                              ))
                              :
                              <BlockButton
                                title={t('OK', 'OK')}
                                onPress={() => modalManager.dismissModal(modal.uuid)}
                                style={styles.button}
                                textStyle={styles.buttonText}
                              />
                            }
                          </View>
                        }
                      </BottomDrawer>
                    </Modal>
                  );
                }

                if (modal.options.type === 'content') {
                  return (
                    <Modal
                      key={modal.uuid}
                      coverScreen={true}
                      isVisible={modal.show}
                      style={{ margin: 0 }}
                      onModalHide={() => {
                        modalManager.removeModal(modal.uuid)
                      }}
                    >
                      {modal.options.children}

                      {modal.options.showClosse && (
                        <Pressable style={[styles.closeModalButton]} onPress={() => modalManager.dismissModal(modal.uuid)}>
                          <MaterialCommunityIcons
                            name="close"
                            color={AppColors.white}
                            size={22} />
                        </Pressable>
                      )}
                    </Modal>
                  );
                }

                return (
                  <Modal
                    key={modal.uuid}
                    coverScreen={false}
                    isVisible={modal.show}
                    onModalHide={() => {
                      modalManager.removeModal(modal.uuid)
                    }}>
                    <View style={styles.wrapper}>
                      <View style={styles.modal}>
                        <View style={styles.content}>
                          <BoldText style={styles.title}>{modal.options.title}</BoldText>
                          <DefaultText style={styles.message}>{modal.options.message}</DefaultText>
                        </View>
                        <View style={styles.footer}>
                          {modal.options.buttons?.length
                            ? modal.options.buttons?.map((button, idx) => <BlockButton
                              key={idx}
                              title={button.text}
                              variant={button.variant}
                              onPress={() => button.onPress ? button.onPress(() => modalManager.dismissModal(modal.uuid)) : modalManager.dismissModal(modal.uuid)}
                              flex={1}
                              style={[styles.button, idx > 0 ? { marginLeft: 1 } : undefined]}
                              textStyle={styles.buttonText} />)
                            : <BlockButton
                              title={t('OK', 'OK')}
                              onPress={() => modalManager.dismissModal(modal.uuid)}
                              flex={1}
                              style={styles.button}
                              textStyle={styles.buttonText} />}
                        </View>
                      </View>
                    </View>
                  </Modal>
                );
              })
            }
          </View>
          : null
      }
    </>
  );
})

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  drawerHeaderText: {
    paddingTop: 30,
    fontSize: 24,
    color: AppColors.blue.primary,
    textAlign: 'center',
    fontWeight: '500',
    fontFamily: 'Rubik_500Medium',
  },
  subText: {
    paddingTop: 15,
    paddingHorizontal: 50,
    marginBottom: 6,
    fontSize: 16,
    color: AppColors.gray.neutral1,
    textAlign: 'center',
    fontWeight: '400',
  },
  wrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    backgroundColor: 'white',
    borderRadius: 24,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  content: {
    padding: 24,
    marginTop: 16
  },
  closeModalButton: {
    position: 'absolute',
    bottom: 15,
    right: 15,
    zIndex: 100,
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: 30,
    top: 45,
    backgroundColor: AppColors.blue.primary,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3,
    elevation: 3,
  },
  footer: {
    flexDirection: 'row',
    overflow: 'hidden',
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 45

  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    color: Colors.MutedTextColor,
    marginBottom: 12,
  },
  message: {
    fontSize: 16,
    textAlign: 'center',
  },
  button: {
    paddingHorizontal: 16,
    margin: 6,
  },
  drawerButton: {
    marginHorizontal: 16,
    marginVertical: 8,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3,
    elevation: 3,
  },
  drawerContainer: {
    flex: 1,
    padding: 0,
    backgroundColor: 'white',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    paddingBottom: 12,
  },
  buttonText: {
    fontSize: 16,
    fontFamily: 'Rubik_500Medium',
  },
})
