import { MaterialCommunityIcons } from "@expo/vector-icons";
import { StackActions, useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BackHandler, Keyboard, KeyboardAvoidingView, Platform, Pressable, StyleSheet, Text, TextInput, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { SafeAreaView, useSafeAreaInsets } from "react-native-safe-area-context";
import { API } from "../../../api/API";
import { FileData } from "../../../api/methods/upload-file";
import { PostAttachment } from "../../../api/schema/models/PostAttachment";
import { SurveyQuestion } from "../../../api/schema/models/SurveyQuestion";
import { VisibilityOption } from "../../../api/schema/models/VisibilityOption";
import ErrorBag from "../../../common/ErrorBag";
import { getMimeType, handleErrorResponse } from "../../../common/Util";
import { Attachment } from "../../../components/AttachmentBar";
import { FormError } from "../../../components/FormError";
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import { AppEvents, AppStateStore } from "../../../contexts/AppStateStore";
import ConfirmationModal from "./ConfirmationModal";
import { SearchIcon } from "./icons/Icons";
import NewSurvey from "./NewSurvey";
import NewUrgentAlert from "./NewUrgentAlert";
import NormalPost from "./NormalPost";
import { AppColors } from "../../../common/AppColors"
//import * as Sentry from 'sentry-expo'

interface NewPostProps {
  postType: string
}

interface Form {
  message: string,
  surveyTitle: string,
  surveyFinalMessage: string,
  surveyQuestions: SurveyQuestion[]
}
export default (props: any) => {
  const { t } = useTranslation();
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const [visibility, setVisibility] = useState<VisibilityOption>();
  const [form, setForm] = useState<Form>({
    message: '', surveyTitle: '', surveyFinalMessage: '', surveyQuestions: [{
      type: undefined,
      question: '',
      choices: [],
      isRequired: false,
    }]
  });
  const [sending, setSending] = useState(false);
  const [attachment, setAttachment] = useState<Attachment>();
  const [errors] = useState(new ErrorBag());
  const [expiration, setExpiration] = useState<number | undefined>()
  const [showModal, setShowModal] = useState(false);
  const regex = /\b\w+\b/g;

  BackHandler.addEventListener('hardwareBackPress', () => {
    navigation.dispatch(StackActions.popToTop());
    return true
  });

  const postType: 'post' | 'survey' | 'urgentAlert' = (props.route.params.postType) ? props.route.params.postType : 'post'
  const textButton = postType === 'post' ? 'Create Announcement / Message' : postType === 'urgentAlert' ? 'Create Urgent Alert' : 'Create Poll / Survey '

  const getButtonColor = (flatColor?: boolean) => {
    if (flatColor) {
      return postType === 'post' ? '#0082CD' : postType === 'urgentAlert' ? '#F10000' : '#4ED442'
    }
    if (isValidPost() && !sending) {
      return postType === 'post' ? '#0082CD' : postType === 'urgentAlert' ? '#F10000' : '#4ED442'
    } else {
      return postType === 'post' ? '#72BBE5' : postType === 'urgentAlert' ? '#E99C9C' : '#AFEEA9'
    }
  }

  const setVisibilityName = (option: VisibilityOption, pop: () => void) => {
    setVisibility(option);
    navigation.goBack();
  }

  const setExpirationValue = (value: number) => {
    setExpiration(value);
  }

  const setMessage = (message: string) => {
    setForm({ ...form, message });
  }

  const setTitle = (title: string) => {
    setForm({ ...form, surveyTitle: title });
  }

  const setFinalMessage = (finalMessage: string) => {
    setForm({ ...form, surveyFinalMessage: finalMessage });
  }

  const setQuestions = (questions: SurveyQuestion[]) => {
    setForm({ ...form, surveyQuestions: questions })
  }

  const navigateToVisibilityPicker = () => {
    navigation.navigate('VisibilityPicker' as never, { title: t('Choose Visibility', 'Choose Visibility'), onChoose: setVisibilityName } as never);
  }

  const isValidString = (string: string) => {
    return string.match(regex) != undefined;
  }

  const isValidPost = () => {
    if (postType == 'survey') {
      for (let question of form.surveyQuestions) {
        if (!isValidString(question.question) || !question.type || !isValidString(form.surveyTitle) || !isValidString(form.surveyFinalMessage) || !isValidString(form.message) || !visibility) {
          return false
        }
      }
    } else if (postType == 'urgentAlert') {
      if (!isValidString(form.message) || !visibility || !expiration) {
        return false
      }

    } else {
      if (!visibility || !isValidString(form.message)) {
        return false;
      }
    }


    return true
  }

  const sendAlert = () => {
    Keyboard.dismiss()
    setShowModal(true);
  }

  const showQuestionsError = (message: string) => {
    errors.addError('questions', message)
  }

  const onSubmit = async () => {
    Keyboard.dismiss()
    AppStateStore.showActivityIndicator();
    setSending(true);
    errors.clearErrors();
    try {


      let newAttachment: PostAttachment | undefined = undefined

      if (attachment) {
        const uploadData = await API.requestUpload()

        let mimeType = 'application/octet-stream'
        let filename = 'upload'
        if (attachment.type === 'file') {
          mimeType = attachment.mimeType
          filename = attachment.name
        } else if (!attachment.url.startsWith('data:')) {
          mimeType = getMimeType(attachment.url) || 'application/octet-stream'
        }

        if (Platform.OS === 'web') {
          if (attachment.type === ('photo' || 'video')) {
            mimeType = attachment.mimeType
          }
        }

        let file: FileData = {
          uri: attachment.url,
          name: filename,
          type: mimeType,
        }

        if (attachment.url.startsWith('data:')) {
          const response = await fetch(attachment.url)
          const binary = await response.blob()
          file = new Blob([binary], {
            type: 'application/pdf',
          })
        }

        await API.uploadFile(
          uploadData.url,
          uploadData.fields,
          file,
        )

        newAttachment = {
          type: attachment.type,
          key: uploadData.key,
          filename: filename,
          mimeType: mimeType,
        }
      }
      let invalidQuestion = false
      form.surveyQuestions.forEach((question, idx) => {
        if (question.type == 'multipleChoiceOne' || question.type == 'multipleChoiceMany') {
          if ((new Set(question.choices)).size !== question.choices.length) {
            showQuestionsError(`Choices repeated in question ${idx + 1}`)
            invalidQuestion = true
          }
        }
      })
      if (invalidQuestion) {
        AppStateStore.hideActivityIndicator();
        setSending(false);
        return
      }
      await API.createPost({
        postType: postType,
        surveyTitle: form.surveyTitle,
        surveyFinalMessage: form.surveyFinalMessage,
        message: form.message,
        visibility: visibility!,
        surveyQuestions: form.surveyQuestions,
        attachment: newAttachment,
        expiration: expiration ? expiration : null
      })

      AppStateStore.eventBus.emit(AppEvents.PostCreated)

      navigation.dispatch(StackActions.popToTop());
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      handleErrorResponse(err?.response, errors, {
        unhandledErrors: {
          unhandledErrorsKey: '_unhandled',
          handledErrorFields: [
            'surveyTitle',
            'surveyFinalMessage',
            'visibility',
            'message',
            'questions',
          ],
        }
      })
    }
    AppStateStore.hideActivityIndicator();
    setSending(false);
  }

  return (
    <View style={{ flex: 1 }}>

      <ConfirmationModal
        visible={showModal}
        onDismiss={() => setShowModal(false)}
        onSuccess={onSubmit}
      />

      <KeyboardAvoidingView
        {...(Platform.OS === "ios" ? { behavior: "padding" } : {})}
        style={{ flex: 1 }}
        enabled
        contentContainerStyle={{ flex: 1 }}
      >
        <ScrollView nestedScrollEnabled={true} style={[styles.container, { paddingTop: insets.top + 10 }]}
        >

          <View style={styles.header}>
            <MaterialCommunityIcons name="arrow-left" size={25} onPress={() => {
              navigation.dispatch(StackActions.popToTop)
            }} />

            <View style={{
              marginLeft: 10,
            }}>
              <Text style={styles.newPost}>New Post</Text>
            </View>
          </View>

          <Text style={{ color: getButtonColor(true), fontWeight: 'bold', fontSize: 18 }}>{textButton.split('Create')[1]}</Text>

          <Pressable style={styles.postTo} onPressIn={navigateToVisibilityPicker}>
            <TextInput
              style={{ fontSize: 15 }}
              placeholder="Post to (required)"
              placeholderTextColor={AppColors.gray.neutral3}
              editable={false}
              focusable={true}
              value={visibility?.name}
              onPressIn={navigateToVisibilityPicker}>
            </TextInput>
            <View style={styles.inputIcon}>
              <SearchIcon />
            </View>
          </Pressable>
          <FormError field={'visibility'} errors={errors} />

          <View style={{}}>
            {
              postType === 'post'
                ?
                <NormalPost
                  attachment={attachment}
                  errors={errors}
                  setAttachment={setAttachment}
                  setMessage={setMessage}
                />
                :
                postType === 'urgentAlert'
                  ?
                  <NewUrgentAlert
                    attachment={attachment}
                    errors={errors}
                    setAttachment={setAttachment}
                    setMessage={setMessage}
                    setExpiration={setExpirationValue}
                  />
                  :
                  postType === 'survey'
                    ?
                    <NewSurvey
                      errors={errors}
                      setTitle={setTitle}
                      setFinalMessage={setFinalMessage}
                      setInstructions={setMessage}
                      setQuestions={setQuestions}
                      questions={form.surveyQuestions}
                      attachment={attachment}
                      setAttachment={setAttachment}
                    />
                    : null
            }
          </View>

          <View style={{ height: 100 }}>

          </View>

        </ScrollView>

        <SafeAreaView
          edges={['bottom']}
        >
          <SubmitButton
            color={getButtonColor()}
            disabled={sending || !isValidPost()}
            text={(sending) ? 'Submitting post' : textButton}
            onSubmit={postType === 'urgentAlert' ? sendAlert : onSubmit}
          />
        </SafeAreaView>

      </KeyboardAvoidingView>
    </View>
  );
}

const styles = StyleSheet.create({
  postTo: {
    backgroundColor: '#F6F7FA',
    width: '100%',
    minHeight: 50,
    borderRadius: 6,
    justifyContent: 'center',
    padding: 10,
    marginTop: 20,
  },
  container: {
    paddingHorizontal: 20,
    flex: 1,
  },
  inputIcon: {
    position: 'absolute',
    right: 0,
    marginRight: 10
  },
  message: {
    backgroundColor: '#F6F7FA',
    width: '100%',
    height: 180,
    borderRadius: 6,
    justifyContent: 'flex-start',
    padding: 10,
    marginTop: 15
  },
  options: {
    marginTop: 15,
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderRadius: 6,
    backgroundColor: '#F6F7FA',
    width: '100%',
    paddingVertical: 15
  },
  newPost: {
    fontWeight: 'bold',
    fontSize: 18,
    justifyContent: 'space-between'
  },
  header: {
    flexDirection: 'row',
    minHeight: 30,
    marginBottom: 10,
    alignItems: "flex-start",
  },
  footer: {

  }
});

