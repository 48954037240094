import React from 'react'
import { Svg, Path } from "react-native-svg"

type Props = {
  width: number
  height: number
  color: string,
  variant: 'fill' | 'outline'
}
export default function PlaceHolder(props: Props) {
  return (
    <Svg
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 17 20" >
      {props.variant === 'fill' ? (
        <Path
          d="M8.08077 0C7.98077 0 7.88077 0 7.79077 0C3.76077 0.14 0.420773 3.28 0.0407734 7.29C-0.0392266 8.07 0.000773445 8.85 0.140773 9.6C0.140773 9.6 0.150773 9.69 0.200773 9.87C0.320773 10.42 0.510773 10.96 0.740773 11.46C1.57077 13.43 3.39077 16.46 7.34077 19.74C7.55077 19.91 7.82077 20 8.09077 20C8.36077 20 8.62077 19.91 8.83077 19.73C12.7808 16.45 14.6008 13.42 15.4308 11.46C15.6708 10.95 15.8508 10.41 15.9708 9.86C15.9908 9.76 16.0108 9.68 16.0208 9.6C16.1208 9.1 16.1708 8.58 16.1708 8.07C16.1608 3.63 12.5308 0 8.08077 0ZM8.08077 11.35C6.32077 11.35 4.90077 9.92 4.90077 8.17C4.90077 6.42 6.33077 4.99 8.08077 4.99C9.83077 4.99 11.2608 6.42 11.2608 8.17C11.2608 9.92 9.83077 11.35 8.08077 11.35Z"
          fill={props.color} />
      ) : (
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d="M8.15421 0C8.05806 0 7.9619 0 7.86575 0C3.78883 0.144231 0.423441 3.30769 0.0388253 7.35577C-0.0380978 8.14423 0.00036376 8.92308 0.144595 9.65385C0.144595 9.65385 0.15421 9.75962 0.202287 9.95192C0.327287 10.5096 0.509979 11.0481 0.740748 11.5481C1.55806 13.4808 3.3369 16.4519 7.20229 19.6538C7.47152 19.875 7.80806 20 8.16383 20C8.51959 20 8.85613 19.875 9.12536 19.6538C12.9811 16.4519 14.76 13.4904 15.5677 11.5769C15.8081 11.0577 15.9907 10.5096 16.1157 9.97115C16.1446 9.86538 16.1638 9.76923 16.1734 9.70192C16.2696 9.20192 16.3273 8.67308 16.3273 8.15385C16.3081 3.65385 12.6446 0 8.15421 0ZM14.5773 8.15385C14.5773 8.55769 14.5388 8.96154 14.4619 9.38462C14.4619 9.39423 14.4619 9.39423 14.4619 9.39423C14.4619 9.39423 14.4619 9.39423 14.4619 9.40385C14.4619 9.42308 14.4523 9.48077 14.4331 9.56731C14.3273 10.0096 14.1927 10.4327 13.9907 10.8654C13.26 12.5962 11.6542 15.2692 8.17344 18.1923C4.68306 15.2596 3.07729 12.5865 2.34652 10.8365C2.15421 10.4327 2.00998 10 1.91383 9.55769L1.88498 9.46154C1.88498 9.44231 1.87536 9.38462 1.87536 9.36538C1.73113 8.75962 1.70229 8.14423 1.75998 7.52885C2.06767 4.33654 4.72152 1.84615 7.92344 1.74038C8.00036 1.74038 8.07729 1.74038 8.15421 1.74038C9.82729 1.74038 11.4042 2.375 12.6061 3.54808C13.8754 4.75962 14.5773 6.40385 14.5773 8.15385ZM8.15421 3.83654C5.72152 3.83654 3.75036 5.81731 3.75036 8.24038C3.75036 10.6731 5.73113 12.6538 8.15421 12.6538C10.5869 12.6538 12.5581 10.6731 12.5581 8.25C12.5581 5.80769 10.5773 3.83654 8.15421 3.83654ZM10.8273 8.24038C10.8273 9.71154 9.62536 10.9135 8.15421 10.9135C6.68306 10.9135 5.48113 9.71154 5.48113 8.24038C5.48113 6.76923 6.68306 5.56731 8.15421 5.56731C9.62536 5.56731 10.8273 6.76923 10.8273 8.24038Z"
          fill={props.color} />
      )
      }

    </Svg>
  )
}
