import { computed, makeObservable, observable, runInAction } from 'mobx'
import { UserContext } from '../models/UserContext'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { EventBus } from '../common/EventBus'
import { Notification } from 'expo-notifications'

const AUTH_TOKEN_STORAGE_KEY = 'auth-token'

export enum AppEvents {
  InvalidateUserContext = 'InvalidateUserContext',
  Tick = 'Tick',
  MessageCreated = 'MessageCreated',
  PostCreated = 'PostCreated',
  PostDeleted = 'PostDeleted',
  EventCreated = 'EventCreated',
  EventDeleted = 'EventDeleted',
  DocumentCreated = 'DocumentCreated',
  FormCreated = 'FormCreated',
  FormSent = 'FormSent',
  FormReceived = 'FormReceived',
  FormSigned = 'FormSigned',
  AttendanceInvalidated = 'AttendanceInvalidated',
  SurveyAnswersSubmitted = 'SurveyAnswersSubmitted',
}

export type PushNotificationListener = (notification: Notification) => Promise<boolean>

class AppState {
  private _authToken: string | undefined = undefined
  private _userContext: UserContext | undefined = undefined
  private _pushNotificationToken: string | undefined = undefined
  public _modalActivityIndicatorCount: number = 0
  public readonly eventBus = new EventBus()
  private pushNotificationListeners: PushNotificationListener[] = []
  private unreadMessagesCount: number = 0
  menuSelected: string = "Home"
  private _allowedLinks: string[] = []

  constructor() {
    makeObservable<AppState, '_authToken' | '_userContext' | '_modalActivityIndicatorCount' | '_pushNotificationToken' | 'unreadMessagesCount' | '_allowedLinks'>(this, {
      _authToken: observable,
      _pushNotificationToken: observable,
      _userContext: observable,
      _modalActivityIndicatorCount: observable,
      userContext: computed,
      authToken: computed,
      pushNotificationToken: computed,
      userLanguage: computed,
      shouldShowModalActivityIndicator: computed,
      unreadMessagesCount: observable,
      _allowedLinks: observable,
      allowedLinks: computed,
    })
  }

  setPushNotificationToken = async (token: string | undefined) => {
    runInAction(() => this._pushNotificationToken = token)
  }

  setAuthToken = async (authToken: string) => {
    await AsyncStorage.setItem(AUTH_TOKEN_STORAGE_KEY, authToken)
    runInAction(() => this._authToken = authToken)
  }

  clearAuthToken = async () => {
    await AsyncStorage.removeItem(AUTH_TOKEN_STORAGE_KEY)
    runInAction(() => this._authToken = undefined)
  }

  loadAuthToken = async () => {
    const authToken = await AsyncStorage.getItem(AUTH_TOKEN_STORAGE_KEY)

    if (authToken) {
      runInAction(() => this._authToken = authToken)
    }
  }

  setUserContext = (userContext: UserContext) => {
    runInAction(() => this._userContext = userContext)
  }

  clearUserContext = () => {
    runInAction(() => this._userContext = undefined)
  }

  logout = async () => {
    await AsyncStorage.removeItem(AUTH_TOKEN_STORAGE_KEY)

    runInAction(() => {
      this._authToken = undefined
      this._userContext = undefined
      this.menuSelected = "Home"
    })
  }

  setAllowedLinks = (allowedLinks: string[]) => {
    runInAction(() => this._allowedLinks = allowedLinks)
  }

  get allowedLinks() {
    return this._allowedLinks
  }

  get userContext() {
    return this._userContext
  }

  get authToken() {
    return this._authToken
  }

  get pushNotificationToken() {
    return this._pushNotificationToken
  }

  get userLanguage() {
    return this.userContext?.language ?? 'en'
  }

  showActivityIndicator = () => {
    runInAction(() => this._modalActivityIndicatorCount++)
  }

  hideActivityIndicator = () => {
    runInAction(() => {
      if (this._modalActivityIndicatorCount > 0) {
        this._modalActivityIndicatorCount--
      }
    })
  }

  get shouldShowModalActivityIndicator() {
    return this._modalActivityIndicatorCount > 0
  }

  handlePushNotification = async (notification: Notification): Promise<boolean> => {
    if (!this.pushNotificationListeners.length) {
      return false
    }

    for (const listener of this.pushNotificationListeners.slice().reverse()) {
      if (await listener(notification)) {
        return true
      }
    }

    return false
  }

  addPushNotificationListener = (listener: PushNotificationListener): { remove: () => void } => {
    this.pushNotificationListeners.push(listener)

    return {
      remove: () => this.pushNotificationListeners = this.pushNotificationListeners.filter(l => l !== listener)
    }
  }

  setUnreadMessagesCount = (count: number) => {
    runInAction(() => this.unreadMessagesCount = count)
  }

  getUnreadMessagesCount = () => {
    return this.unreadMessagesCount
  }
}

export const AppStateStore = new AppState()
