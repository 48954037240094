import moment from 'moment-timezone'
import React from "react"
import { ImageBackground, StyleSheet, Text, View, Platform } from "react-native"
import { useTranslation } from 'react-i18next'
import { Post } from "../../api/schema/models/Post"
import { MYSQL_DATE_TIME_FORMAT, utc, fullName } from '../../common/Util'
import { PostHeader, visibilityDescription } from "../posts/PostHeader"
import { ProfileImage } from "../ProfileImage"
import { RelativeTime } from "../RelativeTime"
import Icons from '../../components/icons'
import { AppColors, AppColors as COLORS } from '../../common/AppColors'
import { DefaultText } from "../DefaultText"

interface EventPostProps {
    post: Post
}

export default (props: EventPostProps) => {
    const { t } = useTranslation()

    const uri = props.post.attachments.imageUrl ? props.post.attachments.imageUrl :
        props.post.attachments.videoThumbnailUrl ? props.post.attachments.videoThumbnailUrl : undefined

    const startsAt = moment(props.post.eventStartAt, MYSQL_DATE_TIME_FORMAT)
    const endsAt = moment(props.post.eventEndsAt, MYSQL_DATE_TIME_FORMAT)


    const renderSecureText = (text: string) => {
        return Platform.OS === 'web'
            ? text.slice(0, 69) + (text.length > 69 ? '...' : '')
            : text.slice(0, 15) + (text.length > 15 ? '...' : '');
    }

    const dateSpanString = () => {
        if (startsAt.isSame(endsAt, 'date')) {
            return <View style={styles.detail}>
                <Icons.Calendar width={12} height={13} variant='outline' color={COLORS.gray.neutral2} />
                <DefaultText style={{ marginLeft: 6, fontSize: 12 }} >
                    {startsAt.format('MM/DD/YYYY') + ' ' + startsAt.format('h:mm A') + ' - ' + endsAt.format('h:mm A')}
                </DefaultText>
            </View>
        } else {
            return <View>
                <View style={styles.detail}>
                    <Icons.Calendar width={12} height={13} variant='outline' color={COLORS.gray.neutral2} />
                    <DefaultText style={{ marginLeft: 6, fontSize: 12 }} >
                        {startsAt.format('MM/DD/YYYY') + ' - ' + endsAt.format('MM/DD/YYYY')}
                    </DefaultText>
                </View>
                <DefaultText style={{ flex: 1, marginTop: 2, marginLeft: 18, fontSize: 12 }} >
                    {startsAt.format('h:mm A')} -  {endsAt.format('h:mm A')}
                </DefaultText>
            </View>
        }

    }

    return (
        <View>
            {
                uri ? <ImageBackground
                    source={{ uri }}
                    resizeMode={"cover"}
                    style={[styles.container, { overflow: 'hidden' }]}
                    imageStyle={{ borderRadius: 24, }}

                >

                    <View
                        style={styles.gradient}
                    >
                    </View>
                    <View style={styles.labelWrapper}>
                        <Text style={styles.eventLabel}>Event</Text>
                    </View>

                    <View style={{ marginBottom: 20, paddingHorizontal: 20 }}
                    >

                        <Text style={styles.title}>
                            {props.post.message}
                        </Text>

                        <View style={{
                            flexDirection: 'row',
                            minHeight: 70,
                        }}>
                            <View style={{
                                alignItems: 'center',
                                justifyContent: 'space-evenly'
                            }}>
                                <Icons.Calendar color="#FFFFFF" height={23} width={23} variant={"outline"} />
                                <Icons.LocationIcon color={AppColors.white} height={23} width={23} />

                            </View>

                            <View style={{
                                justifyContent: 'space-evenly',
                                flex: 1
                            }}>
                                <Text style={[styles.eventDateText, { marginLeft: 5 }]}>
                                    {
                                        startsAt.isSame(endsAt, 'date') ?
                                            startsAt.format('MM/DD/YYYY') + ' ' + startsAt.format('h:mm A') + ' - ' + endsAt.format('h:mm A')
                                            :
                                            startsAt.format('MM/DD/YYYY') + ' ' + startsAt.format('h:mm A') + ' - ' + endsAt.format('MM/DD/YYYY') + ' ' + endsAt.format('h:mm A')
                                    }
                                </Text>
                                <Text style={[styles.eventDateText, { marginLeft: 5 }]}>
                                    {props.post.eventLocation}
                                </Text>
                            </View>

                        </View>


                        <View style={{ flexDirection: 'row' }}>
                            <ProfileImage
                                size={50} url={props.post.profilePhotoUrl ?? undefined}
                            />
                            <View style={styles.userInfo}>
                                <Text style={{
                                    fontSize: 15,
                                    color: 'white',
                                    fontWeight: '700'
                                }}>{fullName(props.post)} </Text>
                                <Text style={{
                                    color: 'white',
                                    fontWeight: '600',
                                    overflow: Platform.OS !== "web" ? 'scroll' : 'visible'
                                }}>{renderSecureText(visibilityDescription(props.post)!)} - a - <RelativeTime date={utc(props.post.createdAt)} /></Text>

                            </View>
                        </View>
                    </View>


                </ImageBackground>

                    : <View style={styles.container2}>
                        <PostHeader post={props.post} />

                        <View style={styles.message}>
                            <Icons.Calendar width={45} height={50} variant='fill' color={COLORS.orange.primary} />
                            <View style={{ zIndex: 1, marginLeft: 20 }}>
                                <Text style={styles.text}>{
                                    props.post.message.trim().length > 40
                                        ? `${props.post.message.slice(0, 37)}...`
                                        : props.post.message
                                }
                                </Text>
                                {
                                    dateSpanString()
                                }
                                {
                                    props.post.eventLocation && props.post.eventLocation?.length !== 0 ?
                                        <View style={styles.detail}>
                                            <Icons.PlaceHolder width={10} height={13} variant='outline' color={COLORS.gray.neutral2} />
                                            <DefaultText style={{ marginLeft: 6, fontSize: 12 }}>
                                                {props.post.eventLocation}
                                            </DefaultText>
                                        </View>
                                        : null
                                }
                            </View>

                        </View>
                    </View>
            }
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        shadowColor: 'grey',
        borderRadius: 24,
        elevation: 20,
        shadowOpacity: .3,
        marginHorizontal: 10,
        marginVertical: 10,
        backgroundColor: 'white',
        shadowOffset: {
            width: 3,
            height: 5
        },
        minHeight: 300,
        shadowRadius: 7
    },
    gradient: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        paddingVertical: 20,
        paddingHorizontal: 20,
        opacity: 0.35,
        zIndex: 0,
        position: 'absolute',
        borderRadius: 24,
        backgroundColor: 'black',
        height: '100%',
        width: '100%'
    },
    eventLabel: {
        fontWeight: '700',
        color: 'white',
    },
    labelWrapper: {
        backgroundColor: '#FCD034',
        borderRadius: 25,
        paddingHorizontal: 15,
        paddingVertical: 8,
        marginLeft: 20,
        marginTop: 20
    },
    title: {
        color: 'white',
        fontSize: 20,
        fontWeight: '700',
        marginBottom: 5
    },
    eventDate: {
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        marginBottom: 10,
        justifyContent: 'flex-start'
    },
    eventDateText: {
        color: 'white',
        fontSize: 13,
        fontWeight: '600',
    },
    userInfo: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        marginLeft: 6
    },
    container2: {
        paddingHorizontal: 12,
        paddingVertical: 24,
        shadowColor: Platform.OS === 'ios' ? 'grey' : 'black',
        borderRadius: 24,
        marginHorizontal: 10,
        marginVertical: 10,
        shadowOpacity: .3,
        backgroundColor: COLORS.white,
        shadowOffset: {
            width: 3,
            height: 5
        },
        shadowRadius: 7,
        elevation: 10
    },
    message: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 16
    },
    text: {
        color: COLORS.gray.neutral1,
        flex: 1,
        fontSize: 16
    },
    detail: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5
    }

});
