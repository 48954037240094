import React, { useCallback, useLayoutEffect } from 'react'
import { FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View, Platform } from 'react-native'
import { DefaultText } from '../components/DefaultText'
import { useTranslation } from 'react-i18next'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { ContentActivityIndicator } from '../components/ContentActivityIndicator'
import { runInAction } from 'mobx'
import { ErrorMessage } from '../components/ErrorMessage'
import { BlockButton } from '../components/BlockButton'
import { SafeAreaView } from 'react-native-safe-area-context'
import { SearchTextInput } from '../components/SearchTextInput'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../navigation/RootNav'
import { API } from '../api/API'
import { StudentOption } from '../api/schema/models/StudentOption'
import { fullName } from '../common/Util'
import axios, { CancelTokenSource } from 'axios'
import _ from 'lodash'
import { AppColors as COLORS } from '../common/AppColors'
import Icons from '../components/icons'
//import * as Sentry from 'sentry-expo'

export type StudentPickerScreenParams = {
  title: string
  onChoose: (option: StudentOption, pop: () => void) => void
}

const FETCH_SIZE = 100

export const StudentPickerScreen = observer((props: StackScreenProps<RootNavigationParamList, 'StudentPicker'>) => {
  const { t } = useTranslation()

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: props.route.params.title,
    })
  }, [props.navigation])

  const state = useLocalObservable(() => ({
    loading: false,
    loaded: false,
    error: undefined as undefined | string,
    searchText: '',
    options: [] as StudentOption[],
    cancelToken: undefined as CancelTokenSource | undefined,
    hasMore: false,
    loadingMore: false,
  }))

  const pop = () => props.navigation.pop()

  const renderItem = (item: ListRenderItemInfo<StudentOption>) => {
    return <TouchableOpacity onPress={() => props.route.params.onChoose(item.item, pop)}>
      <View style={styles.listItem}>
        <View style={{ flex: 1 }}>

          <DefaultText style={{ fontSize: 16 }}>{fullName(item.item)}</DefaultText>
          {
            item.item.externalId
              ? <DefaultText style={{ color: COLORS.gray.neutral2 }}>{item.item.externalId}</DefaultText>
              : null
          }
        </View>
        <Icons.Forward width={12} height={20} color={COLORS.orange.primary} />

      </View>
    </TouchableOpacity>
  }

  const loadOptions = useCallback(async (searchText: string, append = false) => {
    runInAction(() => {
      state.loading = true
      state.error = undefined
      state.loadingMore = append
    })

    if (state.cancelToken) {
      state.cancelToken.cancel()
      runInAction(() => state.cancelToken = undefined)
    }

    try {
      const cancelToken = axios.CancelToken.source()
      runInAction(() => state.cancelToken = cancelToken)

      const request: any = {
        search: state.searchText,
        offset: append ? state.options.length : 0,
        limit: FETCH_SIZE + 1,
        cancelToken: cancelToken.token,
      }

      const response = await API.getStudentOptions(request)

      runInAction(() => {
        if (append) {
          state.options = [
            ...state.options,
            ...response.students.slice(0, FETCH_SIZE),
          ]
        } else {
          state.options = response.students.slice(0, FETCH_SIZE)
        }

        state.hasMore = response.students.length > FETCH_SIZE
      })
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = t('There was an error getting the student options', 'There was an error getting the student options'))
    }

    runInAction(() => {
      state.loading = false
      state.loadingMore = false
      state.loaded = true
    })
  }, [])

  const updateSearch = useCallback(_.debounce(async () => {
    loadOptions(state.searchText).then()
  }, 250), [loadOptions])

  useLayoutEffect(() => {
    updateSearch()
  }, [state.searchText])

  const fetchMore = () => {
    if (!state.loading && state.hasMore) {
      loadOptions(state.searchText, true).then()
    }
  }

  return <View style={styles.container}>
    {
      (!state.loaded && state.loading)
        ? <ContentActivityIndicator />
        : state.error
          ? <>
            <ErrorMessage message={state.error} />
            <BlockButton
              variant={'secondary'}
              title={t('Retry', 'Retry')}
              onPress={() => loadOptions(state.searchText)}
            />
          </>
          : <>
            <View style={styles.searchContainer}>
              <SearchTextInput
                placeholder={t('Search here', 'Search here')}
                value={state.searchText}
                onChange={ev => runInAction(() => state.searchText = ev.nativeEvent.text)}
              />
            </View>
            <FlatList
              style={styles.list}
              data={state.options}
              renderItem={renderItem}
              keyExtractor={i => `${i.id}`}
              onEndReached={fetchMore}
              ListFooterComponent={<SafeAreaView edges={['bottom']} />}
            />
          </>
    }
    <SafeAreaView edges={['bottom']}>
      <View />
    </SafeAreaView>
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  list: {
    flex: 1,
    paddingTop: 14
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 12,
    height: 64,
    backgroundColor: COLORS.gray.neutral6,
    borderRadius: 13,
    marginHorizontal: 16,
    marginVertical: 10,
  },
  footer: {
    flexDirection: 'row',
  },
  searchContainer: {
    marginHorizontal: 16,
  }
})
