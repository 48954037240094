import React from 'react';
import { Pressable } from 'react-native';
import { AddIcon } from './AddIcon';
import { fabStyles } from './fabStyles';

interface FloatingActionButtonProps {
  onPress: () => void,
  bgColor: string
}

export const FloatingActionButton = (props: FloatingActionButtonProps) => {
    return (
      <Pressable style={[fabStyles.container, {backgroundColor: props.bgColor}]} onPress={props.onPress}>
        <AddIcon size={20}/>
      </Pressable>
    );
  }
