import React from 'react'
import { UrgenAlert } from '../../api/schema/models/UrgentAlert'
import { FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View } from 'react-native'
import { ListItemSeparator } from '../ListItemSeparator'
import { UrgentAlertHeader } from './UrgentAlertsHeader'
import { useTranslation } from 'react-i18next'
import { DefaultTextAutoLink } from '../DefaultTextAutoLink'
import { useWindowDimensions } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'

type Props = {
  posts: UrgenAlert[]
  onPress: (post: UrgenAlert) => void
}
export const FeedUrgentAlerts = (props: Props) => {
  const posts = props.posts
  const { t } = useTranslation()

  const feedAlerts = ({ item }: ListRenderItemInfo<UrgenAlert>) => {

    return <ScrollView style={styles.item} contentContainerStyle={{justifyContent: 'flex-start'}}>
      <TouchableOpacity onPress={() => props.onPress(item)}>
      <View>
        <UrgentAlertHeader post={item} />
        <View style={styles.message}>
          <DefaultTextAutoLink style={styles.messageText}>
            {
              item.message.trim().length > 160
                ? `${item.message.slice(0, 160)}... (${t('read more', 'read more')})`
                : item.message
            }
          </DefaultTextAutoLink>
        </View>

      </View>
    </TouchableOpacity>
    </ScrollView>
  }

  return <FlatList
    style={styles.list}
    data={posts}
    renderItem={feedAlerts}
    keyExtractor={i => String(i.id)}
    horizontal
    ItemSeparatorComponent={ListItemSeparator}

  />

}

const styles = StyleSheet.create({
  list: {
    flexGrow: 0,
    minHeight: 180
  },
  container: {
    paddingHorizontal: 12,
    paddingVertical: 24,
    backgroundColor: '#05ffb0',
    width: '80%'
  },
  message: {
    paddingBottom: 24,
  },
  messageText: {
    marginVertical: 9,
    color: '#888',
  },
  item: {
    marginTop: 2,
    marginLeft: 2,
    marginRight: 10,
    borderWidth: 2,
    padding: 10,
    borderRadius: 24,
    borderColor: '#F10000',
    width: 321,
    height: 150
  }
})