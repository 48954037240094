import React from 'react'

type Props = {
  uri: string
}

export const PdfViewer = (props: Props) => {
  return <iframe
    style={{ flex: 1 }}
    src={props.uri}
  />
}
