import React from 'react'
import { Svg, Path } from "react-native-svg"

type Props = {
  width: number
  height: number
  color: string,
  variant: 'fill' | 'outline'
}

export default function Attendance(props: Props) {
  const { ...rest } = props
  return (
    <Svg
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 20 20" >
      {props.variant === 'fill' ? (
        <Path
          d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM15.02 5.79C15.4 6.11 15.45 6.68 15.13 7.06L9.22 14.11C9.21 14.12 9.19 14.13 9.18 14.14C9.17 14.15 9.17 14.16 9.16 14.17C9.13 14.2 9.1 14.21 9.07 14.23C9.01 14.27 8.96 14.32 8.9 14.35C8.85 14.37 8.8 14.38 8.75 14.39C8.69 14.4 8.63 14.42 8.57 14.42C8.51 14.42 8.45 14.41 8.39 14.4C8.34 14.39 8.28 14.39 8.23 14.37C8.17 14.35 8.11 14.31 8.05 14.27C8.02 14.25 7.98 14.24 7.95 14.21C7.94 14.2 7.94 14.19 7.93 14.18C7.92 14.17 7.9 14.16 7.89 14.15L4.92 11.18C4.57 10.83 4.57 10.26 4.92 9.91C5.27 9.56 5.84 9.56 6.19 9.91L8.47 12.19L13.75 5.89C14.07 5.52 14.64 5.47 15.02 5.79Z"
          fill={props.color} />
      ) : (
        <Path
          d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18.33C5.4 18.33 1.67 14.6 1.67 10C1.67 5.4 5.4 1.67 10 1.67C14.6 1.67 18.33 5.4 18.33 10C18.33 14.6 14.6 18.33 10 18.33ZM15.02 5.79C15.4 6.11 15.45 6.68 15.13 7.06L9.22 14.11C9.21 14.12 9.19 14.13 9.18 14.14C9.17 14.15 9.17 14.16 9.16 14.17C9.13 14.2 9.1 14.21 9.07 14.23C9.01 14.27 8.96 14.32 8.9 14.35C8.85 14.37 8.8 14.38 8.75 14.39C8.69 14.4 8.63 14.42 8.57 14.42C8.51 14.42 8.45 14.41 8.39 14.4C8.34 14.39 8.28 14.39 8.23 14.37C8.17 14.35 8.11 14.31 8.05 14.27C8.02 14.25 7.98 14.24 7.95 14.21C7.94 14.2 7.94 14.19 7.93 14.18C7.92 14.17 7.9 14.16 7.89 14.15L4.92 11.18C4.57 10.83 4.57 10.26 4.92 9.91C5.27 9.56 5.84 9.56 6.19 9.91L8.47 12.19L13.75 5.89C14.07 5.52 14.64 5.47 15.02 5.79Z"
          fill={props.color} />
      )
      }

    </Svg>
  )
}

