import React from 'react'
import { StyleSheet, TextInput, TextInputProps, Platform } from 'react-native'
import { AppColors } from '../common/AppColors';

type Props = TextInputProps

export const StyledTextInput = (props: Props) => {
  const { style, ...rest } = props
  return <TextInput
    {...rest}
    placeholderTextColor={AppColors.gray.neutral3}
    style={[
      {
        fontFamily: 'Rubik_400Regular'
      },
      styles.input,
      style,
      Platform.OS === 'web' && {
        outlineStyle: 'none'
      },
    ]}
  />
}

const styles = StyleSheet.create({
  input: {
    //borderWidth: 1,
    borderColor: '#aaa',
    borderRadius: 8,
    height: 40,
    fontSize: 18,
    color: '#333',
    paddingHorizontal: 12,
  }
})
