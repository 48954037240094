import React from 'react'
import { Svg, Path, Circle } from "react-native-svg"

type Props = {
  width: number
  height: number
  color: string,
  variant?: 'fill' | 'outline'
}
export default function LogOut(props: Props) {
  return (
    <Svg
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 24 24" >
      <>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.74 12.5504C21.91 12.3704 22 12.1404 22 11.9104C22 11.9004 21.99 11.8904 21.99 11.8704C21.99 11.8604 22 11.8504 22 11.8404C22 11.5004 21.81 11.2204 21.53 11.0704L17.35 6.89043C17 6.54043 16.43 6.54043 16.08 6.89043C15.73 7.24043 15.73 7.81043 16.08 8.16043L18.86 10.9404H6.9C6.4 10.9404 6 11.3404 6 11.8404C6 12.3404 6.4 12.7404 6.9 12.7404H19L16.08 15.6604C15.73 16.0104 15.73 16.5804 16.08 16.9304C16.43 17.2804 17 17.2804 17.35 16.9304L21.74 12.5504Z"
          fill={props.color} />
        <Path
          d="M12.59 22H2.99C2.44 22 2 21.56 2 21.01V2.99C2 2.44 2.44 2 2.99 2H12.59C13.14 2 13.58 2.44 13.58 2.99V6.96C13.58 7.51 13.14 7.95 12.59 7.95C12.04 7.95 11.6 7.51 11.6 6.96V3.98H3.98V20.02H11.6V17.04C11.6 16.49 12.04 16.05 12.59 16.05C13.14 16.05 13.58 16.49 13.58 17.04V21.01C13.58 21.56 13.14 22 12.59 22Z"
          fill={props.color} />
      </>


    </Svg>
  )
}









