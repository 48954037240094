import { observer, useLocalObservable } from 'mobx-react-lite'
import moment, { Moment } from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { runInAction } from 'mobx'
import { BlockButton } from '../components/BlockButton'
import { SafeAreaView } from 'react-native-safe-area-context'
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../navigation/RootNav'

export type DateTimePickerScreenParams = {
  dateOnly?: boolean
  initialDate: Moment
  onChoose: (date: Moment | undefined, pop: () => void) => void
  allowClear?: boolean
}

export const DateTimePickerScreen = observer((props: StackScreenProps<RootNavigationParamList, 'DateTimePicker'>) => {
  const { t } = useTranslation()

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: props.route.params.dateOnly ? t('Choose Date', 'Choose Date') : t('Choose Date Time', 'Choose Date/Time'),
    })
  }, [props.navigation])

  const state = useLocalObservable(() => ({
    value: moment(),
  }))

  useEffect(() => {
    runInAction(() => {
      state.value = props.route.params.initialDate.clone()
    })
  }, [props.route.params.initialDate])

  const pop = () => props.navigation.pop()

  const submit = () => {
    props.route.params.onChoose(state.value.clone(), pop)
  }

  const clear = () => {
    props.route.params.onChoose(undefined, pop)
  }

  return <View style={styles.container}>
    <View style={styles.wrapper}>
      <DatePicker
        selected={state.value.toDate()}
        onChange={date => {
          runInAction(() => state.value = date ? moment(date as Date) : moment())
        }}
        showTimeSelect={!props.route.params.dateOnly}
        dateFormat={props.route.params.dateOnly ? undefined : 'Pp'}
      />
    </View>
    <SafeAreaView edges={['bottom']}>
      <View style={styles.footer}>
        {
          props.route.params.allowClear
            ? <BlockButton
              flex={1}
              title={t('Clear', 'Clear')}
              variant={'tertiary'}
              onPress={clear}
            />
            : null
        }
        <BlockButton
          flex={1}
          title={t('Choose', 'Choose')}
          variant={'secondary'}
          onPress={submit}
        />
      </View>
    </SafeAreaView>
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  wrapper: {
    flex: 1,
    marginTop: 30,
    alignItems: 'center',
  },
  footer: {
    flexDirection: 'row',
  },
})
