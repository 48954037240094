import { AppColors } from './AppColors'

export const Colors = {
  PrimaryOrange: '#ff8d2d',
  SecondaryBlue: '#149dfc',
  TertiaryGrey: '#aaa',
  DefaultTextColor: '#333',
  MutedTextColor: '#444',
  DangerTextColor: '#f00',

  CalendarBackgroundColors: {
    default: '#ccc',
    district: '#ff8d2d',
    school: '#149dfc',
    section: AppColors.egg.secondary,
    grade: '#c7c9d1',
    community: '#4dc9d1',
    private: '#FF5733'

  },
  CalendarTextColors: {
    default: '#333',
    district: '#fff',
    school: '#fff',
    section: '#333',
    grade: '#333',
    community: '#fff',
    private: '#fff'
  },
}
