import 'react-native-gesture-handler'
import 'react-native-get-random-values'
import React, { Suspense, useEffect, useState, useCallback } from 'react'
import { Platform, StyleSheet, View, Text } from 'react-native'
import { AppNavigation } from './src/AppNavigation'
import './src/i18n'
import { StatusBar } from 'expo-status-bar'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Rubik_400Regular, Rubik_700Bold, Rubik_900Black, Rubik_500Medium, Rubik_600SemiBold } from '@expo-google-fonts/rubik'
import { ModalRenderer } from './src/components/ModalRenderer'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { AppEvents, AppStateStore } from './src/contexts/AppStateStore'
import { runInAction } from 'mobx'
import { useTranslation } from 'react-i18next'
import { ModalActivityIndicator } from './src/components/ModalActivityIndicator'
import * as Notifications from 'expo-notifications'
import * as SplashScreen from 'expo-splash-screen'
import Constants from 'expo-constants'
import { API } from './src/api/API'
import { SuspenseScreen } from './src/screens/SuspenseScreen'
import { MenuProvider } from 'react-native-popup-menu'
import * as Updates from 'expo-updates'
import { UpdateEventType } from 'expo-updates'
import { UpdateNotification } from './src/components/UpdateNotification'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import * as Font from 'expo-font'
//import * as Sentry from 'sentry-expo';

/*//Sentry.init({
  dsn: 'https://bc4b09f4e85a4cdb974a95c3b97a613d@o1253167.ingest.sentry.io/4504757201928192',
  enableInExpoDevelopment: true,
  debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});*/

Notifications.setNotificationHandler({
  handleNotification: async notification => {
    if (await AppStateStore.handlePushNotification(notification)) {
      return {
        shouldShowAlert: false,
        shouldPlaySound: false,
        shouldSetBadge: false,
      }
    } else {
      return {
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
      }
    }
  },
})

const registerForPushNotificationsAsync = async () => {
  let token
  if (Constants.isDevice && Platform.OS !== 'web') {
    const { status: existingStatus } = await Notifications.getPermissionsAsync()
    let finalStatus = existingStatus
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync()
      finalStatus = status
    }
    if (finalStatus !== 'granted') {
      return
    }
    token = (await Notifications.getExpoPushTokenAsync()).data
  } else {
    console.log('Must use physical device for Push Notifications')
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    }).then()
  }

  return token
}

const UPDATE_CHECK_INTERVAL = 1000 * 60 * 30
let lastUpdateCheck: number | undefined = undefined

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();

const App = observer(() => {
  const [appIsReady, setAppIsReady] = useState(false);

  const state = useLocalObservable(() => ({
    loading: true,
    isUpdateAvailable: false,
    notification: {},
  }))

  useEffect(() => {
    registerForPushNotificationsAsync().then(token => {
      AppStateStore.setPushNotificationToken(token ?? undefined).then()
    })
    //Notifications.addNotificationReceivedListener(_handleNotification);
    Notifications.addNotificationResponseReceivedListener(_handleNotificationResponse);
  }, [])

  useEffect(() => {
    if (AppStateStore.authToken && AppStateStore.pushNotificationToken) {
      API.registerPushNotificationToken(AppStateStore.pushNotificationToken).then()
    }
  }, [AppStateStore.authToken, AppStateStore.pushNotificationToken])

  const _handleNotification = (notification: any) => {
    runInAction(() => state.notification = notification)
  };

  const _handleNotificationResponse = (response: any) => {
    console.log(response);
    API.readPushNotification(response).then()
  };

  useEffect(() => {
    (async () => {
      runInAction(() => state.loading = true)
      try {
        await AppStateStore.loadAuthToken()
        await SplashScreen.hideAsync()
      } catch (err) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(err)
        }
        else {
          //Sentry.Native.captureException(err)
        }
      }
      runInAction(() => state.loading = false)
    })()

    const tickInterval = setInterval(() => {
      AppStateStore.eventBus.emit(AppEvents.Tick)
    }, 60000)

    const listeners = [
      AppStateStore.eventBus.addRemovableListener(AppEvents.InvalidateUserContext, onInvalidateUserContext),
    ]

    const updateListener = Updates.addListener(ev => {
      if (ev.type === UpdateEventType.UPDATE_AVAILABLE) {
        runInAction(() => {
          state.isUpdateAvailable = true
        })
      }
    })

    return () => {
      clearInterval(tickInterval)
      listeners.forEach(l => l.remove())
      updateListener.remove()
    }
  }, [])

  const checkForUpdates = () => {
    if (Platform.OS === 'web') {
      return
    }

    try {
      const check = lastUpdateCheck
      if (!check || new Date().getTime() - check > UPDATE_CHECK_INTERVAL) {
        lastUpdateCheck = new Date().getTime()

          ; (async () => {
            try {
              const status = await Updates.checkForUpdateAsync()

              if (status.isAvailable) {
                await Updates.fetchUpdateAsync()
              }
            } catch (err) {
              console.log(err)
              //Sentry.Native.captureException(err)
            }
          })()
      }
    } catch (e) {
      //Sentry.Native.captureException(e)
      console.log(e)
    }
  }

  const onInvalidateUserContext = () => {
    AppStateStore.clearUserContext()
  }


  useEffect(() => {
    (async () => {
      try {
        await SplashScreen.preventAutoHideAsync()
        await Font.loadAsync({
          Rubik_400Regular,
          Rubik_500Medium,
          Rubik_600SemiBold,
          Rubik_700Bold,
          Rubik_900Black,
          'konekti-glyphs': require('./assets/konekti-glyphs.ttf'),
        })
      }
      catch (err) {
        console.log(err)
      }
      finally {
        setAppIsReady(true)
      }
    })()
  }, [])

  const onLayout = useCallback(() => {
    if (appIsReady) {
      SplashScreen.hideAsync()
    }
  }, [appIsReady])

  if (!appIsReady) {
    return null
  }

  return <GestureHandlerRootView style={{ width: '100%', height: '100%' }} onLayout={onLayout}>
    <Suspense fallback={<SuspenseScreen />}>
      <StatusBar style="light" />
      <LanguageWatcher />
      <SafeAreaProvider>
        <MenuProvider>
          <View style={styles.wrapper}>
            <View style={styles.container}>
              <AppNavigation onStateChange={() => checkForUpdates()} />
            </View>
          </View>
        </MenuProvider>
        {
          state.isUpdateAvailable
            ? <UpdateNotification />
            : null
        }
      </SafeAreaProvider>
      <ModalRenderer />
      <ModalActivityIndicator />
    </Suspense>
  </GestureHandlerRootView>
})

const LanguageWatcher = observer(() => {
  const { i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(AppStateStore.userLanguage).then()
  }, [AppStateStore.userLanguage])

  return null
})

export default App

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  container: {
    width: Platform.OS === 'web' ? '100vw' : '100%',
    maxWidth: Platform.OS === 'web' ? 900 : undefined,
    height: Platform.OS === 'web' ? '100vh' : '100%',
    marginHorizontal: 'auto',
    shadowRadius: 5,
    shadowColor: '#333',
    overflow: 'hidden',
  },
})
