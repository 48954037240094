import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconProps } from '.';
import { AppColors } from '../../common/AppColors';

const MessageSendIcon = ({
  color = AppColors.gray.neutral3,
  height = 20,
  width = 20,
}: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M19.7499 1.25017C19.5099 1.00017 19.1399 0.930173 18.8199 1.06017L0.539888 8.37017C0.219888 8.50017 0.00988814 8.80017 -0.000111863 9.14017C-0.0101119 9.48017 0.179888 9.80017 0.479888 9.94017L6.57989 12.9002L12.0499 8.67017C12.2299 8.53017 12.4599 8.76017 12.3199 8.94017L8.09989 14.4102L11.0599 20.5102C11.1999 20.8102 11.4999 21.0002 11.8599 21.0002C12.1999 20.9902 12.4999 20.7702 12.6299 20.4602L19.9399 2.18017C20.0699 1.86017 19.9899 1.50017 19.7499 1.25017Z" fill={color} />
    </Svg>
  );
}

export default MessageSendIcon;