import React, { useCallback, useEffect } from 'react'
import { FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View, ScrollView, RefreshControl, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { DrawerHeaderButton } from '../../components/DrawerHeaderButton'
import { StackNavigationProp, StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { useNavigation } from '@react-navigation/native'
import { Document } from '../../api/schema/models/Document'
import { runInAction } from 'mobx'
import { API } from '../../api/API'
import { extractErrorMessage } from '../../common/Util'
import { DocumentIcon } from '../../components/DocumentIcon'
import { DefaultText } from '../../components/DefaultText'
import { ContentActivityIndicator } from '../../components/ContentActivityIndicator'
import { ErrorMessage } from '../../components/ErrorMessage'
import { BlockButton } from '../../components/BlockButton'
import { ListRefreshControl } from '../../components/web-list-refresh-control/ListRefreshControl'
import { FloatingActionButton } from '../../components/fab/FloatingActionButton'
import Icons from '../../components/icons'
import { AppColors as COLORS } from '../../common/AppColors'
import { TextInputField } from '../../components/TextInputField'
import { ActionButton } from '../../components/buttons/ActionButton'
//import * as Sentry from 'sentry-expo'


export const DocumentsScreen = observer((props: StackScreenProps<RootNavigationParamList, 'Documents'>) => {
  const { t } = useTranslation()
  const navigation = useNavigation<StackNavigationProp<RootNavigationParamList>>()

  const state = useLocalObservable(() => ({
    loading: false,
    refreshing: false,
    error: undefined as string | undefined,
    documents: [] as Document[],
  }))

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Documents', 'Documents'),
      headerLeft: () => <DrawerHeaderButton />,

    })
  }, [props.navigation])

  const loadDocuments = useCallback(async (refresh: boolean = false) => {
    runInAction(() => {
      state.loading = true
      state.error = undefined
      state.refreshing = refresh
    })

    try {
      const response = await API.getDocuments()
      runInAction(() => state.documents = response.documents)
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => {
      state.loading = false
      state.refreshing = false
    })
  }, [state])

  useEffect(() => {
    loadDocuments().then()

    const listeners = [
      AppStateStore.eventBus.addRemovableListener(AppEvents.DocumentCreated, () => loadDocuments().then()),
    ]

    return () => {
      for (const listener of listeners) {
        listener.remove()
      }
    }
  }, [loadDocuments])


  const EmptyListMessage = () => {
    return (
      <Icons.EmptyDocument width={144} height={96} color={COLORS.gray.neutral5} />
    )
  }

  const renderListItem = (item: ListRenderItemInfo<Document>) => {
    return <TouchableOpacity
      style={styles.listItem}
      onPress={() => item.item.documentUrl ? navigation.push('DocumentViewer', { url: item.item.documentUrl, title: item.item.title }) : undefined}
    //onPress={() => item.item.documentUrl ? navigation.push('DocumentLoader', { documentId: item.item.id, title: item.item.title }) : undefined}
    >
      <DocumentIcon type={item.item.mime_type || ''} />
      <DefaultText style={styles.documentTitleText} numberOfLines={1}>{item.item.title}</DefaultText>
      <Icons.Forward width={12} height={20} color={COLORS.orange.primary} />
    </TouchableOpacity>
  }

  return <View style={styles.container}>
    {
      AppStateStore.userContext?.userType === 'teacher' ?
        <FloatingActionButton onPress={() => props.navigation.push('CreateDocument')} bgColor={COLORS.blue.primary} />
        : null
    }

    <View style={styles.content}>
      {
        (state.loading && !state.refreshing)
          ? <ContentActivityIndicator />
          : state.error
            ? <>
              <ErrorMessage message={state.error} />
              <BlockButton
                title={t('Retry', 'Retry')}
                onPress={() => loadDocuments().then()}
              />
            </>
            : state.documents.length === 0
              ?
              <>
                <ListRefreshControl
                  onPress={() => loadDocuments(true)}
                  refreshing={state.refreshing}
                />
                <ScrollView
                  contentContainerStyle={styles.emptyListContainer}
                  refreshControl={
                    <RefreshControl
                      refreshing={state.refreshing}
                      onRefresh={() => loadDocuments(true)}
                    />
                  }
                >
                  <Icons.EmptyDocument width={144} height={96} color={COLORS.gray.neutral5} />
                </ScrollView>
              </>
              :
              <>
                <TextInputField
                  placeholder="Search here"
                  rightElement={
                    <ActionButton >
                      <Icons.Search
                        color={COLORS.white}
                        size={44}
                      />
                    </ActionButton>
                  }
                />
                <ListRefreshControl
                  onPress={() => loadDocuments(true)}
                  refreshing={state.refreshing}
                />
                <FlatList
                  keyboardShouldPersistTaps="handled"
                  style={styles.list}
                  data={state.documents}
                  keyExtractor={item => String(item.id)}
                  renderItem={renderListItem}
                  onRefresh={() => loadDocuments(true)}
                  refreshing={state.refreshing}
                />
              </>
      }
    </View>
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.white,
    paddingHorizontal: 16
  },
  content: {
    flex: 1,
    paddingTop: 16,
  },
  list: {
    flex: 1,
    paddingTop: 14
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 12,
    height: 64,
    backgroundColor: COLORS.gray.neutral6,
    justifyContent: 'center',
    borderRadius: 13,
    marginVertical: 10,

  },
  documentTitleText: {
    flex: 1,
    marginHorizontal: 12,
    fontSize: 18,
  },
  emptyListContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollview: {

  }

})
