import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useCallback, useLayoutEffect, useEffect } from 'react'
import { FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View, Text, Platform } from 'react-native'
import { API } from '../../api/API'
import { runInAction } from 'mobx'
import { useTranslation } from 'react-i18next'
import { extractErrorMessage, utc } from '../../common/Util'
import { ContentActivityIndicator } from '../../components/ContentActivityIndicator'
import { ErrorMessage } from '../../components/ErrorMessage'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { DefaultText } from '../../components/DefaultText'
import { Colors } from '../../common/Colors'
import { Notification } from '../../api/schema/models/Notification'
import { SafeAreaView } from 'react-native-safe-area-context'
import { NewBackArrowSvg } from '../../../assets/images/back-arrow-svg'
import { BoldText } from '../../components/BoldText'
import { BlockButton } from '../../components/BlockButton'
import { AppColors } from '../../common/AppColors'
import { NotificationIcon } from '../../../assets/images/notification-icons'
import { OwlieShrug } from '../../../assets/images/owlie-shrug'
import { Menu, MenuOption, MenuOptions, MenuTrigger } from 'react-native-popup-menu'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { RelativeTime } from '../../components/RelativeTime'
//import * as Sentry from 'sentry-expo'

export const NotificationCenterScreen = observer((props: StackScreenProps<RootNavigationParamList, 'NotificationCenter'>) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    data: undefined as {
      notifications: Notification[] | undefined
    } | undefined,
    loading: false,
    error: undefined as string | undefined,
    completed: false,
    showPicker: false,
    showDrawer: false,
    coordY: 0,
    coordX: 0,
    selectedStudent: 0,
    listMode: false,
    search: '',
    page: 1,
    filter: false,
  }))

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: 'Notification Center',
      headerStyle: { backgroundColor: '#fff', borderBottomWidth: 0 },
      headerTitleStyle: { color: '#000', textAlign: 'center' },
      headerTitleAlign: 'center',
      headerBackImage: () => <NewBackArrowSvg></NewBackArrowSvg>,
      headerRight: () =>
        <View style={{ paddingRight: 16 }}>
          <Menu>
            <MenuTrigger>
              <MaterialCommunityIcons name={'dots-vertical'} style={styles.editButtonIcon} />
            </MenuTrigger>
            <MenuOptions customStyles={{
              optionsContainer: { width: 'auto' },
            }}>
              <MenuOption onSelect={() => markAllAsRead()} style={{ padding: 12 }}>
                <DefaultText style={{ fontSize: 16 }}>Mark all as read</DefaultText>
              </MenuOption>
            </MenuOptions>
          </Menu>
        </View>
    })
  }, [props.navigation])

  const loadData = useCallback(async () => {
    runInAction(() => {
      state.error = undefined
      state.loading = true
    })

    try {
      const response = await API.getNotifications(state.page, state.filter)
      if (response) {
        runInAction(() => {
          state.data = {
            notifications: response.notifications,
          }
        })
      }
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => {
        state.error = extractErrorMessage(err)
      })
    }

    runInAction(() => {
      state.loading = false
    })
  }, [state])

  const loadMoreData = useCallback(async () => {
    runInAction(() => {
      state.page++
    })
    try {
      const response = await API.getNotifications(state.page, state.filter)
      if (response) {
        runInAction(() => {
          state.data.notifications = state.data?.notifications?.concat(response.notifications)
        })
      }
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => {
        state.error = extractErrorMessage(err)
      })
    }
    console.log(state.page, state.filter)
  }, [state])

  const markAllAsRead = useCallback(async () => {
    await API.markAllNotifications()
    console.log('entro')
    runInAction(() => {
      let notifications = state.data?.notifications.map((n) => {
        n.read = true
        return n
      })
      state.data.notifications = notifications
      //state.data.notifications = state.data?.notifications?.concat(response.notifications)
    })

  }, [state])

  useLayoutEffect(() => {
    loadData().then()
  }, [loadData])

  useEffect(() => {
    const reloadNotifications = props.navigation.addListener('focus', () => {
      runInAction(() => {
        state.page = 1
      })
      loadData().then()
    })
    //loadProfile().then()
  }, [loadData])

  const goToNotification = async (type: string, id: number | string, notificationId: number, otherId?: string) => {
    await API.markNotification(notificationId)
    if (type === 'event') {
      props.navigation.navigate('EventDetail', { eventId: Number(id), startsAt: otherId })
    }
    else if (type === 'new-message') {
      props.navigation.navigate('Messages', { conversationId: Number(id) })
    }
    else if (type === 'post') {
      props.navigation.navigate('PostDetail', { postId: Number(id) })
    }
    else if (type === 'document') {
      props.navigation.navigate('DocumentLoader', { documentId: Number(id), title: otherId })
    }
    else if (type === 'form') {
      props.navigation.navigate('FormViewer', { signatureId: Number(id), studentId: Number(otherId) })
    }
  }

  /*const searchMember = () => {
    if(state.data) {
      if(state.search) {
        if(state.listMode) {
  
          if(state.data.parentList) {
            runInAction(() => {
              let matches = state.data.parentList.filter(p => {
                let searchName = fullName(p).toUpperCase()
                if(searchName.includes(state.search.toUpperCase()) || p.son.toUpperCase().includes(state.search.toUpperCase())) {
                  return true
                }
                return false
              })
              state.data.parents = matches[0] ? matches : undefined
            })
            
          }
  
        }
        else {
  
          if(state.data.studentList) {
            runInAction(() => {
              let matches = state.data.studentList.filter(s => {
                let searchName = fullName(s).toUpperCase()
                if(searchName.includes(state.search.toUpperCase())) {
                  return true
                }
                return false
              })
              state.data.students = matches[0] ? matches : undefined
            })
          }
  
        }
      }
      else {
        runInAction(() => {
          state.data.students = state.data?.studentList
          state.data.parents = state.data?.parentList
        })
      }
    }
  }*/

  const renderNotification = (info: ListRenderItemInfo<Notification>) => {
    return info.item ? <TouchableOpacity onPress={(event) => goToNotification(info.item.notificationType, info.item.redirectId, info.item.id, info.item?.other)} style={{ backgroundColor: info.item.read ? AppColors.white : AppColors.gray.neutral6, marginVertical: 2, minHeight: 56, justifyContent: 'center' }}>
      <View style={{ flexDirection: 'row', alignItems: 'flex-start', paddingVertical: 12, paddingHorizontal: 16 }}>
        <View style={{ marginRight: 16 }}>
          <NotificationIcon icon={info.item.notificationIcon} />
        </View>
        <View>
          <BoldText style={{ flex: 1, fontSize: 16, marginBottom: 2 }}>{info.item.notificationTitle}</BoldText>
          <DefaultText style={{ flex: 1, fontSize: 12, marginBottom: 2 }}>{`Posted by `}{<BoldText style={{ flex: 1, fontSize: 12 }}>{`${info.item.notificationAuthor} - ${info.item.notificationAuthorRole}`}</BoldText>}</DefaultText>
          <DefaultText style={{ flex: 1, fontSize: 12 }}><RelativeTime date={utc(info.item.notificationTime)} /></DefaultText>
        </View>

      </View>
    </TouchableOpacity>
      : <Text>No student found</Text>
  }


  /*const renderParent = (info: ListRenderItemInfo<SectionParent>) => {
    return info.item ? <TouchableOpacity onPress={(event) => console.log('click')} style={{backgroundColor:'#F6F7FA', marginVertical: 8, borderRadius:6, minHeight: 56, justifyContent: 'center'}}>
      <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 12, paddingHorizontal: 16 }}>
        <DefaultText style={{ flex: 1, fontSize: 16 }}>{fullName(info.item)} <Text style={{fontSize: 14, color: AppColors.gray.neutral2}}>/ {info.item.son}</Text></DefaultText>
        <MaterialIcons
          name={'chevron-right'}
          size={30}
          color={'#FF7A33'}
        />
      </View>
    </TouchableOpacity>
    :<Text>No parent found</Text>
  }*/


  return <View style={styles.container}>
    {
      state.error
        ? <ErrorMessage message={state.error} />
        : !state.data
          ? <ContentActivityIndicator />
          : <>
            {/*
              Platform.OS == 'web' ?
              <TouchableOpacity onPress={searchMember} style={{alignItems: 'flex-end'}}><SearchSvg onPress={() => null} style={{position:'relative', top:-50, right:8, marginBottom: -24}}></SearchSvg></TouchableOpacity>
              :<View style={{alignItems: 'flex-end'}}><SearchSvg onPress={searchMember} style={{position:'relative', top:-50, right:8, marginBottom: -24, elevation: 3}}></SearchSvg></View>
    */}


            <View style={{ marginBottom: 16, height: 32, flexDirection: 'row' }}>
              {/* <SegmentedControl
                appearance={'light'}
                style={{height: 56}}
                fontStyle={{color:'#9D9FA0'}}
                values={['Students', 'Parents']}
                selectedIndex={state.listMode ? 1 : 0}
                onChange={(ev) => {runInAction(() => {state.listMode = ev.nativeEvent.selectedSegmentIndex === 0 ? false : true;}); }}
                activeFontStyle={{color:'#000'}}
              /> */}
              {/* <Pressable style={[styles.filterButtons,{backgroundColor: !state.filter ? AppColors.blue.primary:AppColors.gray.neutral6}]} onPress={() => 
                  {runInAction(() => {state.filter = false; state.page = 1});
                  loadData().then()}}>
                <DefaultText style={[styles.textFilterButton,{backgroundColor: !state.filter ? AppColors.blue.primary:AppColors.gray.neutral6}]}>
                  <DefaultText style={{fontSize: 14, color: !state.filter ? AppColors.white:AppColors.blue.primary}}>{'All'}</DefaultText>
                </DefaultText>
              </Pressable> */}
              <BlockButton
                title={t('All', 'All')}
                variant={'selector'}
                onPress={() => {
                  runInAction(() => { state.filter = false; state.page = 1 });
                  loadData().then()
                }}
                textStyle={[styles.textFilterButton, { fontSize: 14, color: !state.filter ? AppColors.white : AppColors.blue.primary }]}
                style={[styles.filterButtons, { backgroundColor: !state.filter ? AppColors.blue.primary : AppColors.gray.neutral6, height: 30, width: 'auto' }]}
              />
              <BlockButton
                title={t('Unread', 'Unread')}
                variant={'selector'}
                onPress={() => {
                  runInAction(() => { state.filter = true; state.page = 1 });
                  loadData().then()
                }}
                textStyle={[styles.textFilterButton, { fontSize: 14, color: !state.filter ? AppColors.blue.primary : AppColors.gray.neutral6 }]}
                style={[styles.filterButtons, { backgroundColor: state.filter ? AppColors.blue.primary : AppColors.gray.neutral6, height: 30, width: 'auto' }]}
              />
              {/* <Pressable style={[styles.filterButtons,{backgroundColor: state.filter ? AppColors.blue.primary:AppColors.gray.neutral6}]} onPress={() => 
                  {runInAction(() => {state.filter = true; state.page = 1});
                  loadData().then()}}>
                <DefaultText style={[styles.textFilterButton,{backgroundColor: state.filter ? AppColors.blue.primary:AppColors.gray.neutral6}]}>
                  <DefaultText style={{fontSize: 14, color: !state.filter ? AppColors.blue.primary:AppColors.gray.neutral6}}>{'Unread'}</DefaultText>
                </DefaultText>
              </Pressable> */}
            </View>
            {
              state.data.notifications && state.data.notifications.length > 0 ?
                <FlatList
                  data={state.data.notifications}
                  keyExtractor={s => String(s.id)}
                  renderItem={renderNotification}
                  onEndReached={loadMoreData}
                  removeClippedSubviews={true}
                  initialNumToRender={3}
                  maxToRenderPerBatch={2}
                  updateCellsBatchingPeriod={150} // Increase time between renders
                  windowSize={5}
                  showsVerticalScrollIndicator={false}
                  ListFooterComponent={<SafeAreaView edges={['bottom']} />}
                />

                : <View style={{ display: 'flex', alignItems: 'center' }}>
                  <View style={{ marginTop: '50%', alignItems: 'center' }}>
                    <OwlieShrug></OwlieShrug>
                    <BoldText style={{ flex: 1, fontSize: 16, marginTop: 18, textAlign: 'center', color: AppColors.blue.primary }}>You have no notifications</BoldText>
                  </View>
                </View>

            }

          </>
    }
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16
  },
  section: {
    padding: 12,
  },
  sectionHeaderText: {
    color: Colors.PrimaryOrange,
    marginBottom: 4,
  },
  sectionText: {
    fontSize: 18,
    fontWeight: '500',
    color: '#0082CD'
  },
  content: {
    backgroundColor: '#fff',
  },
  drawerHeaderText: {
    paddingTop: 30,
    fontSize: 24,
    color: '#0082CD',
    textAlign: 'center',
    fontWeight: '500',
  },
  subText: {
    paddingTop: 15,
    paddingHorizontal: 50,
    fontSize: 16,
    color: '#303030',
    textAlign: 'center',
    fontWeight: '400',
  },
  editButtonIcon: {
    minWidth: 30,
    minHeight: 30,
    fontSize: 28,
    color: '#666',
  },
  filterButtons: {
    height: 30,
    borderRadius: 20,
    width: 'auto',
    marginRight: 8
  },
  textFilterButton: {
    paddingHorizontal: 20,
    /* height: 30,
    textAlignVertical: 'center',
    borderRadius: 20,
    width: 'auto',
    paddingHorizontal: 20,
    display: 'flex',
    alignItems: 'center', */
  }
})
/*[{
        notificationId: 'arcas',
        notificationType: 'event',
        id: 16,
        other: '2022-09-05 09:29:58',
        notificationIcon: 'event',
        notificationTitle: 'Art Museum Tour: Oct 28, 2022',
        notificationAuthor: 'Laura García',
        notificationAuthorRole: 'Art Teacher',
        notificationTime: '2022-09-28 01:07:24',
        read: false
      },
      {
        notificationId: 'arcas',
        notificationType: 'post',
        id: 154,
        notificationIcon: 'video',
        notificationTitle: 'Our School Cafeteria Renovated',
        notificationAuthor: 'Maria Romero',
        notificationAuthorRole: 'Principal',
        notificationTime: '2022-09-28 01:07:24',
        read: false
      },
      {
        notificationId: 'arcas',
        notificationType: 'post',
        id: 150,
        notificationIcon: 'audio',
        notificationTitle: 'Audio',
        notificationAuthor: 'Jessy Lopez',
        notificationAuthorRole: 'Superintendent',
        notificationTime: '2022-09-28 01:07:24',
        read: false
      },
      {
        notificationId: 'arcas',
        notificationType: 'post',
        id: 151,
        notificationIcon: 'post',
        notificationTitle: 'Vaccines Schedule',
        notificationAuthor: 'Clara Delacruz',
        notificationAuthorRole: 'Nurse',
        notificationTime: '2022-09-28 01:07:24',
        read: true
      },
      {
        notificationId: 'arcas',
        notificationType: 'post',
        id: 152,
        notificationIcon: 'post',
        notificationTitle: 'Career Fair',
        notificationAuthor: 'Frank Clark',
        notificationAuthorRole: 'Geography Teacher',
        notificationTime: '2022-09-28 01:07:24',
        read: false
      },
      {
        notificationId: 'arcas',
        notificationType: 'event',
        id: 153,
        notificationIcon: 'event',
        notificationTitle: 'Free Summer Meal Distribution: Jun 6, 13, 20, 27 2022',
        notificationAuthor: 'Laura García',
        notificationAuthorRole: 'Art Teacher',
        notificationTime: '2022-09-28 01:07:24',
        read: true
      },
      {
        notificationId: 'arcas',
        notificationType: 'post',
        id: 155,
        notificationIcon: 'urgent',
        notificationTitle: 'COVID-19 Update',
        notificationAuthor: 'Jessy Lopez',
        notificationAuthorRole: 'Superintendent',
        notificationTime: '2022-09-28 01:07:24',
        read: false
      },
      {
        notificationId: 'arcas',
        notificationType: 'post',
        id: 156,
        notificationIcon: 'poll',
        notificationTitle: 'Family Engagement Virtual Sessions Questionnaire',
        notificationAuthor: 'Jessy Lopez',
        notificationAuthorRole: 'Superintendent',
        notificationTime: '2022-09-28 01:07:24',
        read: true
      },
      {
        notificationId: 'arcas',
        notificationType: 'form',
        id: 157,
        notificationIcon: 'form',
        notificationTitle: 'A New Form Needs Your Signature',
        notificationAuthor: 'Frank Clark',
        notificationAuthorRole: 'Geography Teacher',
        notificationTime: '2022-09-28 01:07:24',
        read: true
      },
      {
        notificationId: 'arcas',
        notificationType: 'document',
        id: 158,
        notificationIcon: 'document',
        notificationTitle: 'We Are Wildcats - Document',
        notificationAuthor: 'Maria Romero',
        notificationAuthorRole: 'Principal',
        notificationTime: '2022-09-28 01:07:24',
        read: false
      },//
      {
        notificationId: 'arcas',
        notificationType: 'event',
        id: 159,
        notificationIcon: 'event',
        notificationTitle: 'Free Summer Meal Distribution: Jun 6, 13, 20, 27 2022',
        notificationAuthor: 'Laura García',
        notificationAuthorRole: 'Art Teacher',
        notificationTime: '2022-09-28 01:07:24',
        read: true
      },
      {
        notificationId: 'arcas',
        notificationType: 'post',
        id: 160,
        notificationIcon: 'urgent',
        notificationTitle: 'COVID-19 Update',
        notificationAuthor: 'Jessy Lopez',
        notificationAuthorRole: 'Superintendent',
        notificationTime: '2022-09-28 01:07:24',
        read: false
      },
      {
        notificationId: 'arcas',
        notificationType: 'post',
        id: 161,
        notificationIcon: 'poll',
        notificationTitle: 'Family Engagement Virtual Sessions Questionnaire',
        notificationAuthor: 'Jessy Lopez',
        notificationAuthorRole: 'Superintendent',
        notificationTime: '2022-09-28 01:07:24',
        read: true
      },
      {
        notificationId: 'arcas',
        notificationType: 'form',
        id: 162,
        notificationIcon: 'form',
        notificationTitle: 'A New Form Needs Your Signature',
        notificationAuthor: 'Frank Clark',
        notificationAuthorRole: 'Geography Teacher',
        notificationTime: '2022-09-28 01:07:24',
        read: true
      },
      {
        notificationId: 'arcas',
        notificationType: 'document',
        id: 163,
        notificationIcon: 'document',
        notificationTitle: 'We Are Wildcats - Document',
        notificationAuthor: 'Maria Romero',
        notificationAuthorRole: 'Principal',
        notificationTime: '2022-09-28 01:07:24',
        read: false
      },//
      {
        notificationId: 'arcas',
        notificationType: 'event',
        id: 159,
        notificationIcon: 'event',
        notificationTitle: 'Free Summer Meal Distribution: Jun 6, 13, 20, 27 2022',
        notificationAuthor: 'Laura García',
        notificationAuthorRole: 'Art Teacher',
        notificationTime: '2022-09-28 01:07:24',
        read: true
      },
      {
        notificationId: 'arcas',
        notificationType: 'post',
        id: 160,
        notificationIcon: 'urgent',
        notificationTitle: 'COVID-19 Update',
        notificationAuthor: 'Jessy Lopez',
        notificationAuthorRole: 'Superintendent',
        notificationTime: '2022-09-28 01:07:24',
        read: false
      },
      {
        notificationId: 'arcas',
        notificationType: 'post',
        id: 161,
        notificationIcon: 'poll',
        notificationTitle: 'Family Engagement Virtual Sessions Questionnaire',
        notificationAuthor: 'Jessy Lopez',
        notificationAuthorRole: 'Superintendent',
        notificationTime: '2022-09-28 01:07:24',
        read: true
      },
      {
        notificationId: 'arcas',
        notificationType: 'form',
        id: 162,
        notificationIcon: 'form',
        notificationTitle: 'A New Form Needs Your Signature',
        notificationAuthor: 'Frank Clark',
        notificationAuthorRole: 'Geography Teacher',
        notificationTime: '2022-09-28 01:07:24',
        read: true
      },
      {
        notificationId: 'arcas',
        notificationType: 'document',
        id: 163,
        notificationIcon: 'document',
        notificationTitle: 'We Are Wildcats - Document',
        notificationAuthor: 'Maria Romero',
        notificationAuthorRole: 'Principal',
        notificationTime: '2022-09-28 01:07:24',
        read: false
      },//
      {
        notificationId: 'arcas',
        notificationType: 'event',
        id: 159,
        notificationIcon: 'event',
        notificationTitle: 'Free Summer Meal Distribution: Jun 6, 13, 20, 27 2022',
        notificationAuthor: 'Laura García',
        notificationAuthorRole: 'Art Teacher',
        notificationTime: '2022-09-28 01:07:24',
        read: true
      },
      {
        notificationId: 'arcas',
        notificationType: 'post',
        id: 160,
        notificationIcon: 'urgent',
        notificationTitle: 'COVID-19 Update',
        notificationAuthor: 'Jessy Lopez',
        notificationAuthorRole: 'Superintendent',
        notificationTime: '2022-09-28 01:07:24',
        read: false
      },
      {
        notificationId: 'arcas',
        notificationType: 'post',
        id: 161,
        notificationIcon: 'poll',
        notificationTitle: 'Family Engagement Virtual Sessions Questionnaire',
        notificationAuthor: 'Jessy Lopez',
        notificationAuthorRole: 'Superintendent',
        notificationTime: '2022-09-28 01:07:24',
        read: true
      },
      {
        notificationId: 'arcas',
        notificationType: 'form',
        id: 162,
        notificationIcon: 'form',
        notificationTitle: 'A New Form Needs Your Signature',
        notificationAuthor: 'Frank Clark',
        notificationAuthorRole: 'Geography Teacher',
        notificationTime: '2022-09-28 01:07:24',
        read: true
      },
      {
        notificationId: 'arcas',
        notificationType: 'document',
        id: 163,
        notificationIcon: 'document',
        notificationTitle: 'We Are Wildcats - Document',
        notificationAuthor: 'Maria Romero',
        notificationAuthorRole: 'Principal',
        notificationTime: '2022-09-28 01:07:24',
        read: false
      },]*/