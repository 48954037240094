import { AttachmentButtons, AttachmentType } from './AttachmentButtons'
import { ActivityIndicator, Image, Platform, StyleSheet, TouchableOpacity, View } from 'react-native'
import { runInAction } from 'mobx'
import React, { useState } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { getModalManager } from '../contexts/ModalContext'
import { useTranslation } from 'react-i18next'
import * as ImagePicker from 'expo-image-picker'
import { MediaTypeOptions } from 'expo-image-picker'
import { Video, ResizeMode } from 'expo-av'
import { AudioPlayer } from './AudioPlayer'
import { RootNavigationParamList } from '../navigation/RootNav'
import * as DocumentPicker from 'expo-document-picker'
import { getMimeType } from '../common/Util'
import { DocumentIcon } from './DocumentIcon'
import { DefaultText } from './DefaultText'
import { AppColors } from '../common/AppColors'
import { ModalBottomOptions } from './modals'

type Props = {
  attachment?: Attachment
  onAttached: (attachment?: Attachment) => void
  existingAttachment?: ExistingAttachment
  onRemoveExistingAttachment?: () => void
  attachmentList?: AttachmentType[]
}

type ExistingAttachment = {
  imageUrl: string | null
  audioUuid: string | null
  audioUrl: string | null
  videoUrl: string | null
  videoUuid: string | null
  videoThumbnailUrl: string | null
  fileUuid: string | null
  fileUrl: string | null
  fileName: string | null
  fileType: string | null
}

export type Attachment = {
  type: 'audio'
  url: string
} | {
  type: 'file' | 'photo' | 'video'
  name: string
  mimeType: string
  url: string
}

export const AttachmentBar = observer((props: Props) => {
  const { t } = useTranslation()

  const navigation = useNavigation<StackNavigationProp<RootNavigationParamList>>()

  const state = useLocalObservable(() => ({
    uploading: false,
    hasPermission: false,
    recording: false,
    playing: false,
    loading: false,
    recordingInstance: undefined,
    recordingDurationMillis: 0,
    recordingUrl: undefined,
    maxRecordingLengthSeconds: 60,
    playProgressMillis: 0,
    soundInstance: undefined,
    openModal: false,
    modalType: undefined as 'photo' | 'video' | 'audio' | 'file' | undefined,
  }))

  const hasExistingAttachment: boolean = !!(props.existingAttachment?.audioUuid || props.existingAttachment?.videoUuid || props.existingAttachment?.imageUrl || props.existingAttachment?.fileUuid)

  const requestPermission = async () => {
    let hasPermission = false
    if (Platform.OS === 'ios') {
      const cameraRollStatus = await ImagePicker.requestMediaLibraryPermissionsAsync()
      const cameraStatus = await ImagePicker.requestCameraPermissionsAsync()
      if (
        cameraRollStatus.status !== 'granted'
        || cameraStatus.status !== 'granted'
      ) {
        getModalManager().showModal({
          title: t('Permission Required', 'Permission Required'),
          message: t('Camera permission is required', 'Camera permission is required in order to take photos'),
        })
      } else {
        hasPermission = true
      }
    } else {
      hasPermission = true
    }


    return hasPermission
  }

  const takePhoto = async (type: 'photo' | 'video') => {
    return await ImagePicker.launchCameraAsync({
      mediaTypes: type === 'photo' ? MediaTypeOptions.Images : MediaTypeOptions.Videos,
    })
  }

  const pickImage = async (type: 'photo' | 'video') => {
    return await ImagePicker.launchImageLibraryAsync({
      mediaTypes: type === 'photo' ? MediaTypeOptions.Images : MediaTypeOptions.Videos,
    })
  }

  const addAttachment = async (type: 'audio' | 'file' | 'photo' | 'video') => {
    console.log('addAttachment', type)
    if (type === 'photo' || type === 'video') {

      if (Platform.OS === 'web') {
        const pic = await DocumentPicker.getDocumentAsync({
          copyToCacheDirectory: false,
          type: type === 'photo' ? 'image/*' : 'video/*'
        })
        if (pic.type === 'success') {
          runInAction(() => {
            props.onAttached({
              type,
              mimeType: getMimeType(pic.name) || 'application/octet-stream',
              url: pic.uri,
              name: pic.name,
            })
          })
        }
      } else {

        runInAction(() => { state.openModal = true; state.modalType = type })

      }

    } else if (type === 'audio') {
      navigation.push('RecordAudio', { onSave: attachAudioRecording })

    } else if (type === 'file') {
      //pick only pdfs and docs for now
      const doc = await DocumentPicker.getDocumentAsync({
        type: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'],
        copyToCacheDirectory: false,
      })

      if (doc.type === 'success') {
        runInAction(() => {
          props.onAttached({
            type,
            mimeType: getMimeType(doc.name) || 'application/octet-stream',
            url: doc.uri,
            name: doc.name,
          })
        })
      }
    }
  }

  const attachAudioRecording = (info: { url: string, filename: string, mimeType: string }) => {
    runInAction(() => props.onAttached({ type: 'audio', url: info.url }))
  }

  const removeAttachment = () => {
    runInAction(() => props.onAttached(undefined))
  }

  const addPhotoVideoAttachment = async (source: 'camera' | 'gallery') => {
    runInAction(() => { state.openModal = false })
    const hasPermission = await requestPermission()

    if (hasPermission && state.modalType === 'photo' || state.modalType === 'video') {
      runInAction(() => state.uploading = true)

      const media = source === 'camera'
        ? await takePhoto(state.modalType)
        : await pickImage(state.modalType)

      if (!media.canceled) {
        props.onAttached({
          type: state.modalType,
          url: media.assets[0].uri,
          mimeType: '',
          name: ''
        })

      }

      runInAction(() => state.uploading = false)
    }

  }

  const renderAttachment = (attachment: Attachment) => {
    if (attachment.type === 'photo') {
      return <View style={styles.attachmentPreviewContainer}>
        <Image source={{ uri: attachment.url }} style={styles.attachmentImage} />
        <TouchableOpacity style={styles.attachmentRemoveButton} onPress={removeAttachment}>
          <MaterialCommunityIcons name="close-thick" style={styles.attachmentRemoveIcon} />
        </TouchableOpacity>
      </View>
    } else if (attachment.type === 'video') {
      return <View style={styles.attachmentPreviewContainer}>
        <Video
          source={{ uri: attachment.url }}
          rate={1.0}
          volume={1.0}
          isMuted={false}
          resizeMode={ResizeMode.COVER}
          style={styles.attachmentVideo}
          useNativeControls={false}
        />
        <View style={[StyleSheet.absoluteFillObject, styles.attachmentVideoPlayIconContainer]}>
          <MaterialCommunityIcons style={styles.playIcon} name="play-circle" />
        </View>
        <TouchableOpacity style={styles.attachmentRemoveButton} onPress={removeAttachment}>
          <MaterialCommunityIcons name="close-thick" style={styles.attachmentRemoveIcon} />
        </TouchableOpacity>
      </View>
    } else if (attachment.type === 'audio') {
      return <View style={styles.audioAttachmentContainer}>
        <View style={{ flex: 1, marginRight: 20 }}>
          <AudioPlayer url={attachment.url} />
        </View>
        <TouchableOpacity style={styles.audioAttachmentRemoveButton} onPress={removeAttachment}>
          <MaterialCommunityIcons name="close-thick" style={styles.attachmentRemoveIcon} />
        </TouchableOpacity>
      </View>
    } else if (attachment.type === 'file') {
      return <View style={styles.fileAttachmentContainer}>
        <View style={{ flex: 1, marginRight: 20 }}>
          <DocumentIcon type={attachment.mimeType} />
          <DefaultText>{attachment.name}</DefaultText>
        </View>
        <TouchableOpacity style={styles.fileAttachmentRemoveButton} onPress={removeAttachment}>
          <MaterialCommunityIcons name="close-thick" style={styles.attachmentRemoveIcon} />
        </TouchableOpacity>
      </View>
    } else {
      return null
    }
  }

  const renderExistingAttachment = (attachment: ExistingAttachment) => {
    if (attachment.imageUrl) {
      return <View style={styles.attachmentPreviewContainer}>
        <Image source={{ uri: attachment.imageUrl }} style={styles.attachmentImage} />
        <TouchableOpacity style={styles.attachmentRemoveButton} onPress={props.onRemoveExistingAttachment}>
          <MaterialCommunityIcons name="close-thick" style={styles.attachmentRemoveIcon} />
        </TouchableOpacity>
      </View>
    } else if (attachment.videoUuid) {
      return <View style={styles.attachmentPreviewContainer}>
        <Image
          source={{ uri: attachment.videoThumbnailUrl! }}
          resizeMode={ResizeMode.COVER}
          style={styles.attachmentVideo}
        />
        <View style={[StyleSheet.absoluteFillObject, styles.attachmentVideoPlayIconContainer]}>
          <MaterialCommunityIcons style={styles.playIcon} name="play-circle" />
        </View>
        <TouchableOpacity style={styles.attachmentRemoveButton} onPress={props.onRemoveExistingAttachment}>
          <MaterialCommunityIcons name="close-thick" style={styles.attachmentRemoveIcon} />
        </TouchableOpacity>
      </View>
    } else if (attachment.audioUuid) {
      return <View style={styles.audioAttachmentContainer}>
        <View style={{ flex: 1, marginRight: 20 }}>
          <AudioPlayer url={attachment.audioUrl!} />
        </View>
        <TouchableOpacity style={styles.audioAttachmentRemoveButton} onPress={props.onRemoveExistingAttachment}>
          <MaterialCommunityIcons name="close-thick" style={styles.attachmentRemoveIcon} />
        </TouchableOpacity>
      </View>
    } else if (attachment.fileUuid) {
      return <View style={styles.fileAttachmentContainer}>
        <View style={{ flex: 1, marginRight: 20 }}>
          <DocumentIcon type={attachment.fileType!} />
          <DefaultText>{attachment.fileName!}</DefaultText>
        </View>
        <TouchableOpacity style={styles.fileAttachmentRemoveButton} onPress={props.onRemoveExistingAttachment}>
          <MaterialCommunityIcons name="close-thick" style={styles.attachmentRemoveIcon} />
        </TouchableOpacity>
      </View>
    } else {
      return null
    }
  }

  return (
    <View style={styles.container}>
      {
        state.uploading
          ? <ActivityIndicator />
          : props.attachment
            ? renderAttachment(props.attachment)
            : hasExistingAttachment && props.existingAttachment
              ? renderExistingAttachment(props.existingAttachment)
              : <AttachmentButtons onPress={addAttachment} attachmentList={props.attachmentList} />
      }
      {
        state.openModal && <ModalBottomOptions
          isVisible={state.openModal}
          title={t(`Choose ${state.modalType}`, `Choose ${state.modalType}`)}
          message={t(`You can choose a ${state.modalType} from your ${state.modalType} library or take a ${state.modalType} with your camera`, `You can choose a ${state.modalType} from your ${state.modalType} library or take a ${state.modalType} with your camera.`)}
          dismiss={() => runInAction(() => state.openModal = false)}
          buttons={[
            { text: t('Camera'), onPress: () => addPhotoVideoAttachment('camera'), variant: 'primary' },
            { text: t('Gallery'), onPress: () => addPhotoVideoAttachment('gallery'), variant: 'secondary' },
            {
              text: t('Cancel', 'Cancel'),
              variant: 'tertiary',
              onPress: () => runInAction(() => state.openModal = false),
            },
          ]}

        />
      }
    </View>
  );

})

const styles = StyleSheet.create({
  container: {
    backgroundColor: AppColors.gray.neutral6,
    borderRadius: 6,
    marginVertical: 10,
  },
  attachmentPreviewContainer: {
    width: 80,
    height: 80,
    margin: 10,
  },

  attachmentImage: {
    width: 80,
    height: 80,
    resizeMode: 'cover',
    borderRadius: 8,
  },

  attachmentVideo: {
    width: 80,
    height: 80,
    borderRadius: 8,
  },

  attachmentVideoPlayIconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  playIcon: {
    fontSize: 30,
    color: '#fff',
    textShadowColor: '#000',
    textShadowRadius: 4,
    position: 'absolute',
    bottom: -10,
    left: -10,
  },

  attachmentRemoveButton: {
    position: 'absolute',
    top: -10,
    right: -10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f00',
    borderRadius: 15,
    width: 30,
    height: 30,
  },

  attachmentRemoveIcon: {
    fontSize: 18,
    color: '#fff',
  },

  audioAttachmentContainer: {
    margin: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },

  audioAttachmentRemoveButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f00',
    borderRadius: 15,
    width: 30,
    height: 30,
  },

  fileAttachmentContainer: {
    margin: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },

  fileAttachmentRemoveButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f00',
    borderRadius: 15,
    width: 30,
    height: 30,
  },
})
