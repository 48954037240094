import React from 'react'
import { StyleSheet, View, StyleProp, ViewStyle} from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler';
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';

type Props = {
  style?: StyleProp<ViewStyle>
  onPress: () => void
}

export const SearchSvg = (props: Props) => {
  return <Svg width="44" height="44" viewBox="0 0 44 44" onPress={() => props.onPress()} fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
  <Rect width="44" height="44" rx="8" fill="#FF7A33"/>
  <Path fill-rule="evenodd" clip-rule="evenodd" d="M24.3764 22.4769C26.0464 20.1337 25.8302 16.8596 23.728 14.7574C21.3848 12.4142 17.5859 12.4142 15.2427 14.7574C12.8996 17.1005 12.8996 20.8995 15.2427 23.2426C17.3449 25.3449 20.619 25.561 22.9622 23.8911L28.6777 29.6066L30.092 28.1924L24.3764 22.4769ZM22.3138 16.1716C23.8759 17.7337 23.8759 20.2663 22.3138 21.8284C20.7517 23.3905 18.219 23.3905 16.6569 21.8284C15.0948 20.2663 15.0948 17.7337 16.6569 16.1716C18.219 14.6095 20.7517 14.6095 22.3138 16.1716Z" fill="white"/>
  </Svg>
  
}
