import React from 'react'
import { Svg, Rect } from "react-native-svg"

type Props = {
  width: number
  height: number
  color: string,
  variant?: 'fill' | 'outline'
}
const Hamburger = (props: Props) => {
  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 24 25" fill="none" >
      <Rect x="3" y="6.5" width="18" height="2" rx="1" fill={props.color} />
      <Rect x="3" y="11.5" width="18" height="2" rx="1" fill={props.color} />
      <Rect x="3" y="16.5" width="18" height="2" rx="1" fill={props.color} />
    </Svg>
  )
}

export default Hamburger


