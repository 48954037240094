import React from 'react'
import { StyleSheet, View } from 'react-native'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';

export const NotificationAudio = () => {
  return <Svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <Path d="M0.5 20C0.5 31.0457 9.45431 40 20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.95431 31.5457 0 20.5 0C9.45431 0 0.5 8.95431 0.5 20Z" fill="#8D5EF2"/>
  <Path fill-rule="evenodd" clip-rule="evenodd" d="M20.13 10C22.54 10 24.5 11.96 24.5 14.37V19.53C24.5 19.55 24.49 19.57 24.49 19.59C24.43 21.95 22.5 23.85 20.13 23.85C17.72 23.85 15.76 21.89 15.76 19.48V14.38C15.76 11.96 17.72 10 20.13 10Z" fill="white"/>
  <Path fill-rule="evenodd" clip-rule="evenodd" d="M26.85 18.5701C27.35 18.5701 27.76 18.9801 27.76 19.4801C27.76 23.3601 24.88 26.6001 21.04 27.0601V28.1801H23.42C23.92 28.1801 24.33 28.5901 24.33 29.0901C24.33 29.5901 23.93 30.0001 23.43 30.0001H16.91C16.41 30.0001 16 29.5901 16 29.0901C16 28.5901 16.41 28.1801 16.91 28.1801H19.21V27.0601C15.38 26.6001 12.5 23.3601 12.5 19.4801C12.5 18.9801 12.91 18.5701 13.41 18.5701C13.91 18.5701 14.32 18.9801 14.32 19.4801C14.32 22.6801 16.92 25.2901 20.12 25.2901C23.32 25.2901 25.93 22.6901 25.93 19.4801C25.94 18.9701 26.34 18.5701 26.85 18.5701Z" fill="white"/>
  </Svg>
  
  
}
