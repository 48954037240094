import React from 'react'
import { Svg, Path } from "react-native-svg"

type Props = {
  width: number
  height: number
  color: string,
  variant: 'fill' | 'outline'
}
export default function Files(props: Props) {
  return (
    <Svg
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 20 20" >
      {props.variant === 'fill' ? (
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d="M18.65 3.49H15.06V1.35C15.06 0.6 14.45 0 13.71 0H1.35C0.61 0 0 0.61 0 1.35V15.15C0 15.9 0.61 16.5 1.35 16.5H5.93V18.64C5.93 19.39 6.54 19.99 7.28 19.99H14.74C15.35 19.99 16.18 19.66 16.62 19.24L19.19 16.82C19.65 16.4 20 15.58 20 14.96V4.85C20 4.1 19.39 3.49 18.65 3.49ZM15.79 8.4C16.12 8.4 16.39 8.67 16.39 9C16.39 9.33 16.12 9.6 15.79 9.6H10.33C10 9.6 9.73 9.33 9.73 9C9.73 8.67 10 8.4 10.33 8.4H15.79ZM5.93 4.85V14.96H1.55V1.55H13.52V3.49H7.28C6.54 3.49 5.93 4.1 5.93 4.85ZM13.18 15.6H10.34C10.01 15.6 9.74 15.33 9.74 15C9.74 14.67 10.01 14.4 10.34 14.4H13.18C13.51 14.4 13.78 14.67 13.78 15C13.78 15.33 13.51 15.6 13.18 15.6ZM10.34 12.6C10.01 12.6 9.74 12.33 9.74 12C9.74 11.67 10.01 11.4 10.34 11.4H15.8C16.13 11.4 16.4 11.67 16.4 12C16.4 12.33 16.13 12.6 15.8 12.6H10.34ZM16.09 17.62V16.45C16.09 16.41 16.11 16.38 16.13 16.35C16.15 16.33 16.19 16.3 16.24 16.3H17.48L16.09 17.62Z"
          fill={props.color} />
      ) : (
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d="M9.74 9C9.74 8.67 10.01 8.4 10.34 8.4H15.8C16.13 8.4 16.4 8.67 16.4 9C16.4 9.33 16.13 9.6 15.8 9.6H10.34C10.01 9.6 9.74 9.33 9.74 9ZM10.34 12.6H15.8C16.13 12.6 16.4 12.33 16.4 12C16.4 11.67 16.13 11.4 15.8 11.4H10.34C10.01 11.4 9.74 11.67 9.74 12C9.74 12.33 10.01 12.6 10.34 12.6ZM10.34 15.6H13.18C13.51 15.6 13.78 15.33 13.78 15C13.78 14.67 13.51 14.4 13.18 14.4H10.34C10.01 14.4 9.74 14.67 9.74 15C9.74 15.33 10.01 15.6 10.34 15.6ZM20 4.85V14.96C20 15.58 19.65 16.4 19.19 16.83L16.62 19.25C16.18 19.67 15.35 20 14.74 20H7.28C6.54 20 5.93 19.39 5.93 18.65V16.51H1.35C0.61 16.51 0 15.9 0 15.15V1.35C0 0.61 0.61 0 1.35 0H13.72C14.46 0 15.07 0.61 15.07 1.35V3.49H18.66C19.39 3.49 20 4.1 20 4.85ZM7.28 3.49H13.51V1.55H1.55V14.95H5.93V4.85C5.93 4.1 6.54 3.49 7.28 3.49ZM17.48 16.31H16.24C16.19 16.31 16.16 16.33 16.13 16.36C16.1 16.39 16.09 16.42 16.09 16.46V17.63L17.48 16.31ZM18.45 5.05H7.48V18.45H14.54V16.46C14.54 15.52 15.3 14.76 16.23 14.76H18.45V5.05Z"
          fill={props.color} />
      )
      }

    </Svg>
  )
}





