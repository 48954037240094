import { StyleSheet, Text, View } from "react-native"
import React from 'react'

type Props = {
  message: string
}

export const ErrorMessage = (props: Props) => <View style={styles.errorContainer}>
  <Text style={styles.errorText}>{props.message}</Text>
</View>

const styles = StyleSheet.create({
  errorContainer: {
    backgroundColor: '#fcc',
    padding: 10,
    borderRadius: 10,
    margin: 10,
  },
  errorText: {
    fontSize: 12,
    color: '#333',
  },
})
