import React from 'react';
import { ListRenderItemInfo, StyleSheet, Text, View } from 'react-native';
import _ from 'lodash';
import { MaterialIcons } from '@expo/vector-icons';
import { Contact } from '../../api/schema/models/Contact';
import { fullName } from '../../common/Util';
import { ProfileImage } from '../ProfileImage';
import { AppColors } from '../../common/AppColors';

interface Props {
  item: Contact;
}

const roleColors = {
  'teacher': AppColors.purple.secondary,
  'student': AppColors.orange.primary,
  'parent': AppColors.aqua.secondary,
  'principal': AppColors.blue.primary,
};

export const Recipient = ({ item }: Props) => {
  return (
    <View style={styles.recipientSearchItem}>
      <ProfileImage size={40} url={item.profilePhotoUrl} />
      <View style={styles.recipientSearchNameContainer}>
        <Text style={styles.recipientSearchName}>{fullName(item)}</Text>
        {
          item.externalId
            ? <Text style={styles.recipientSearchExternalId}>{item.externalId}</Text>
            : null
        }
      </View>
      <View style={styles.recipientSearchRoleContainer}>

        <Text
          style={[
            styles.recipientSearchRole,
            {
              color: roleColors[item.role]
            }
          ]}
        >
          {_.upperCase(item.role)}
        </Text>
        <MaterialIcons
          name={'chevron-right'}
          size={28}
          color="#626262"
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  recipientSearchItem: {
    paddingHorizontal: 16,
    paddingVertical: 14,
    flexDirection: 'row',
    alignItems: 'center',
  },
  recipientSearchNameContainer: {
    flex: 1,
    paddingLeft: 10,
  },
  recipientSearchName: {
    fontSize: 16,
    fontFamily: 'Rubik_400Regular',
    color: AppColors.gray.neutral1,
    marginBottom: 6,
  },
  recipientSearchExternalId: {
    fontSize: 12,
    color: AppColors.gray.neutral2,
    fontFamily: 'Rubik_400Regular',
  },
  recipientSearchRoleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  recipientSearchRole: {
    color: AppColors.gray.neutral1,
    fontSize: 14,
    textTransform: 'uppercase',
    fontFamily: 'Rubik_500Medium',
  },
});
