import { NativeSyntheticEvent, StyleProp, StyleSheet, TextInput, TextInputChangeEventData, TextStyle, View, ViewStyle, Platform } from 'react-native'
import React from 'react'
import { FontAwesome } from '@expo/vector-icons'
import { AppColors as COLORS } from '../common/AppColors'
import Icons from '../components/icons'

type Props = {
  containerStyle?: StyleProp<ViewStyle>
  inputStyle?: StyleProp<TextStyle>
  placeholder?: string
  value: string
  onChange: (e: NativeSyntheticEvent<TextInputChangeEventData>) => void
}

export const SearchTextInput = (props: Props) => {
  const inputRef = React.createRef<TextInput>()

  return <View
    style={[styles.container, props.containerStyle]}>
    <Icons.Search size={44} color={COLORS.gray.neutral2} style={{ marginLeft: 4 }} />
    <TextInput
      ref={inputRef}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      style={[styles.input, props.inputStyle, Platform.OS === 'web' && { outlineStyle: 'none' }]}
      placeholderTextColor={COLORS.gray.neutral1}
    />

  </View>
}
const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    minHeight: 56,
    backgroundColor: COLORS.gray.neutral6,
    borderRadius: 6,
    flexDirection: 'row',
    alignItems: 'center',
  },

  input: {
    fontSize: 15,
    flex: 1,
  },

  icon: {
    paddingHorizontal: 12,
    fontSize: 20,
    color: '#aaa',
  }
})
