import { Animated, Dimensions, ScrollView, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View, Platform } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { DefaultText } from './DefaultText'
import { AppStateStore } from '../contexts/AppStateStore'
import { CommonActions, NavigationContainerRef } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { AppColors as COLORS } from '../common/AppColors'
import Icons from './icons'
import { API } from '../api/API'

type Props = {
  navigationRef: React.RefObject<NavigationContainerRef<any>>
}

export const AppSideMenu = observer((props: Props) => {
  const [visible, setVisible] = useState(() => false)
  const slideAnim = useRef(new Animated.Value(-Dimensions.get('window').width))
  const fadeAnim = useRef(new Animated.Value(0))

  const { t } = useTranslation()

  const slideIn = useCallback((slide: Animated.Value, fade: Animated.Value) => {
    setVisible(true)

    Animated.parallel([
      Animated.timing(slide, {
        toValue: 0,
        duration: 250,
        useNativeDriver: true,
      }),
      Animated.timing(fade, {
        toValue: 1,
        duration: 250,
        useNativeDriver: true,
      }),
    ]).start()

  }, [setVisible])

  const slideOut = useCallback((slide: Animated.Value, fade: Animated.Value) => {
    Animated.parallel([
      Animated.timing(slide, {
        toValue: -Dimensions.get('window').width,
        duration: 250,
        useNativeDriver: true,
      }),
      Animated.timing(fade, {
        toValue: 0,
        duration: 250,
        useNativeDriver: true,
      }),
    ]).start(() => setVisible(false))
  }, [setVisible])

  useEffect(() => {
    const listeners = [
      AppStateStore.eventBus.addRemovableListener('show-side-menu', () => {
        slideIn(slideAnim.current, fadeAnim.current)
      }),
      AppStateStore.eventBus.addRemovableListener('hide-side-menu', () => {
        slideOut(slideAnim.current, fadeAnim.current)
      }),
    ]

    return () => {
      for (const listener of listeners) {
        listener.remove()
      }
    }
  }, [slideIn, slideOut, slideAnim, fadeAnim])


  return visible
    ? <Animated.View style={[styles.container, { opacity: fadeAnim.current }]}>
      <TouchableWithoutFeedback onPress={() => slideOut(slideAnim.current, fadeAnim.current)}>
        <View style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }} />
      </TouchableWithoutFeedback>
      <Animated.View style={[styles.menu, { transform: [{ translateX: slideAnim.current }] }]}>
        <View style={styles.menuHeader}>
          <SafeAreaView edges={['top']}>
            <TouchableOpacity onPress={() => slideOut(slideAnim.current, fadeAnim.current)}>
              <DefaultText bold style={{ fontSize: 16 }}>Menu</DefaultText>
            </TouchableOpacity>
          </SafeAreaView>
        </View>
        <SafeAreaView edges={['bottom']} style={{ flex: 1 }}>
          <ScrollView style={{ flex: 1 }}>
            <MenuItem
              title={t('Home', 'Home')}
              icon={<Icons.Home
                width={21}
                height={20}
                color={COLORS.orange.primary}
                variant={AppStateStore.menuSelected === 'Home' ? 'fill' : 'outline'}
              />}
              screenName={'Home'}
              navigationRef={props.navigationRef}
              selected={AppStateStore.menuSelected}
            />
            <MenuItem
              title={t('Messages', 'Messages')}
              icon={<Icons.Message
                width={20}
                height={20}
                color={COLORS.orange.primary}
                variant={AppStateStore.menuSelected === 'Conversations' ? 'fill' : 'outline'}
              />}
              screenName={'Conversations'}
              navigationRef={props.navigationRef}
              selected={AppStateStore.menuSelected}
            />
            <MenuItem
              title={t('Calendar', 'Calendar')}
              icon={<Icons.Calendar
                width={18}
                height={20}
                color={COLORS.orange.primary}
                variant={AppStateStore.menuSelected === 'Calendar' ? 'fill' : 'outline'}
              />}
              screenName={'Calendar'}
              navigationRef={props.navigationRef}
              selected={AppStateStore.menuSelected}
            />
            {
              AppStateStore.userContext?.userType === 'teacher'
                ? <MenuItem
                  title={t('Attendance', 'Attendance')}
                  icon={<Icons.Attendance
                    width={20}
                    height={20}
                    color={COLORS.orange.primary}
                    variant={AppStateStore.menuSelected === 'Attendance' ? 'fill' : 'outline'}
                  />}
                  screenName={'Attendance'}
                  navigationRef={props.navigationRef}
                  selected={AppStateStore.menuSelected}
                />
                : null
            }
            <MenuItem
              title={t('Documents', 'Documents')}
              icon={<Icons.Files
                width={20}
                height={20}
                color={COLORS.orange.primary}
                variant={AppStateStore.menuSelected === 'Documents' ? 'fill' : 'outline'}
              />}
              screenName={'Documents'}
              navigationRef={props.navigationRef}
              selected={AppStateStore.menuSelected}
            />
            {
              AppStateStore.userContext?.userType !== 'student' &&
              <MenuItem
                title={t('E-Sign', 'E-Sign')}
                icon={<Icons.Forms
                  width={20}
                  height={20}
                  color={COLORS.orange.primary}
                  variant={AppStateStore.menuSelected === 'ReceivedFormsScreen' || AppStateStore.menuSelected === 'E-Sign' ? 'fill' : 'outline'}
                />}
                screenName={AppStateStore.userContext?.userType === 'teacher' ? 'E-Sign' : 'ReceivedFormsScreen'}
                navigationRef={props.navigationRef}
                selected={AppStateStore.menuSelected}
              />
            }
            <MenuItem
              title={t('Profile', 'Profile')}
              icon={<Icons.Profile
                width={24}
                height={24}
                color={COLORS.orange.primary}
                variant={AppStateStore.menuSelected === 'Profile' ? 'fill' : 'outline'}
              />}
              screenName={'Profile'}
              navigationRef={props.navigationRef}
              selected={AppStateStore.menuSelected}
            />
            <MenuItem
              title={t('Support', 'Support')}
              icon={<Icons.Settings
                width={24}
                height={24}
                color={COLORS.orange.primary}
                variant={AppStateStore.menuSelected === 'SupportScreen' ? 'fill' : 'outline'}
              />}
              screenName={'SupportScreen'}
              navigationRef={props.navigationRef}
              selected={AppStateStore.menuSelected}
            />
          </ScrollView>
        </SafeAreaView>
        <View style={styles.menuFooter}>
          <SafeAreaView edges={['bottom']}>
            <MenuItem
              title={t('Log Out', 'Log Out')}
              icon={<Icons.LogOut width={24} height={24} color={COLORS.orange.primary} />}
              screenName={'logout'}
              navigationRef={props.navigationRef}
            />
          </SafeAreaView>
        </View>
      </Animated.View>
    </Animated.View>
    : null
})

type MenuItemProps = {
  icon: React.ReactElement
  title: string
  screenName: string
  navigationRef: React.RefObject<NavigationContainerRef<any>>,
  selected?: string
}

const MenuItem = (props: MenuItemProps) => {
  const navigateTo = (screenName: string) => {
    if (screenName === 'logout') {
      if (AppStateStore.pushNotificationToken)
        API.deletePushNotificationToken(AppStateStore.pushNotificationToken).then()
      AppStateStore.eventBus.emit('hide-side-menu')
      AppStateStore.logout()
    } else {
      AppStateStore.eventBus.emit('hide-side-menu')
      //props.navigationRef.current?.navigate(screenName)
      props.navigationRef.current?.dispatch(CommonActions.reset({
        index: 0,
        routes: [
          { name: screenName },
        ],
      }))
    }

    AppStateStore.menuSelected = screenName
  }

  return <TouchableOpacity onPress={() => navigateTo(props.screenName)}>
    <View style={[styles.menuItem]}>
      <View style={styles.menuItemIcon}>{props.icon}</View>
      <DefaultText style={[styles.menuItemText]} bold={props.selected === props.screenName}>{props.title}</DefaultText>
    </View>
  </TouchableOpacity >
}

const screenHeight = Dimensions.get('screen').height;
const windowHeight = Dimensions.get('window').height;
const navbarHeight = (screenHeight - windowHeight) / 2

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: Dimensions.get('window').width,
    height: Platform.OS === 'android' ? windowHeight + navbarHeight : windowHeight,
    // width: Dimensions.get('window').width,
    //height: Dimensions.get('window').height,
    backgroundColor: 'rgba(0,0,0,.5)'
  },
  menu: {
    backgroundColor: COLORS.white,
    position: 'absolute',
    height: '100%',
    top: 0,
    borderTopRightRadius: 24,
    borderBottomRightRadius: 24,
  },
  menuHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
    paddingBottom: 21,
    marginTop: 58,
  },
  closeIcon: {
    padding: 10,
    fontSize: 24,
    color: '#fff',
  },
  menuItem: {
    paddingVertical: 16,
    paddingLeft: 34,
    paddingRight: 61,
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuItemIcon: {
    paddingRight: 10,
  },
  menuItemText: {
    fontSize: 16,
    color: '#333',
  },
  menuFooter: {
    marginBottom: 114
  }
})
