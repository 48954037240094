import React from 'react'
import { StyleSheet, View } from 'react-native'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';

export const NewBackArrowSvg = () => {
  return <Svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <Path d="M12.59 2.23001C13.13 1.72001 13.13 0.890007 12.59 0.390007C12.05 -0.119993 11.17 -0.119993 10.63 0.390007L1.4 9.09001C0.859995 9.60001 0.859995 10.43 1.4 10.93L10.63 19.63C11.17 20.14 12.05 20.14 12.59 19.63C13.13 19.12 13.13 18.29 12.59 17.79L4.34 10L12.59 2.23001Z" fill="#FF7A33"/>
  </Svg>
  
}
