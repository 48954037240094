import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconProps } from '.';
import { AppColors } from '../../common/AppColors';

const MessageAudioIcon = ({
  color = AppColors.gray.neutral3,
  height = 20,
  width = 20,
  type = 'evenodd'
}: IconProps) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 24" fill="none">
      <Path fillRule={type} clipRule={type} d="M12.6212 2C15.0887 2 17.0955 3.96 17.0955 6.37V11.53C17.0955 11.55 17.0853 11.57 17.0853 11.59C17.0239 13.95 15.0478 15.85 12.6212 15.85C10.1536 15.85 8.14681 13.89 8.14681 11.48V6.38C8.14681 3.96 10.1536 2 12.6212 2ZM19.5016 10.57C20.0136 10.57 20.4334 10.98 20.4334 11.48C20.4334 15.36 17.4846 18.6 13.5529 19.06V20.18H15.9897C16.5017 20.18 16.9215 20.59 16.9215 21.09C16.9215 21.59 16.5119 22 16 22H9.32427C8.81233 22 8.39254 21.59 8.39254 21.09C8.39254 20.59 8.81233 20.18 9.32427 20.18H11.6792V19.06C7.75774 18.6 4.80896 15.36 4.80896 11.48C4.80896 10.98 5.22875 10.57 5.74069 10.57C6.25263 10.57 6.67242 10.98 6.67242 11.48C6.67242 14.68 9.33451 17.29 12.6109 17.29C15.8873 17.29 18.5597 14.69 18.5597 11.48C18.5699 10.97 18.9795 10.57 19.5016 10.57ZM10.0103 6.37C10.0103 4.97 11.1775 3.82 12.6212 3.82C14.0648 3.82 15.2321 4.96 15.2321 6.37V11.47C15.2321 12.88 14.0648 14.02 12.6212 14.02C11.1775 14.02 10.0103 12.88 10.0103 11.47V6.37Z" fill={color} />
    </Svg>
  );
}

export default MessageAudioIcon;