import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { SurveyQuestion } from "../../../api/schema/models/SurveyQuestion";
import ErrorBag from "../../../common/ErrorBag";
import { Attachment, AttachmentBar } from "../../../components/AttachmentBar";
import { FormTextInput } from "../../../components/forms/FormTextInput";
import SurveyQuestionsForm from "../../../components/SurveyQuestionsForm";

interface SurveyProps {
    attachment: Attachment | undefined,
    setAttachment: (attachment: Attachment | undefined) => void
    errors: ErrorBag,
    setTitle: (title: string) => void,
    setFinalMessage: (finalMessage: string) => void,
    setInstructions: (message: string) => void,
    setQuestions: (questions: SurveyQuestion[]) => void,
    questions: SurveyQuestion[]
}

export default (props: SurveyProps) => {

    const { t } = useTranslation();
    const [title, setLocalTitle] = useState<string>('');
    const [finalMessage, setLocalFinalMessage] = useState<string>('');
    const [instructions, setLocalInstructions] = useState<string>('');

    useEffect(() => {
        props.setTitle(title);
    }, [title])

    useEffect(() => {
        props.setInstructions(instructions);
    }, [instructions]);

    useEffect(() => {
        props.setFinalMessage(finalMessage);
    }, [finalMessage]);

    return (
        <View>

            <FormTextInput
                placeholder={t('Title', 'Title (required)')}
                value={title}
                onChangeText={value => setLocalTitle(value)}
                autoGrow={true}
                errors={props.errors}
                style={styles.input}
                fieldName={'Title'}
                hideSeparator={true}
            />
            <FormTextInput
                placeholder={t('Instructions', 'Instructions (required)')}
                value={instructions}
                onChangeText={value => setLocalInstructions(value)}
                autoGrow={true}
                errors={props.errors}
                fieldName={'Title'}
                style={styles.input}
                hideSeparator={true}
            />
            <FormTextInput
                placeholder={t('Final Message', 'Final Message (required)')}
                value={finalMessage}
                onChangeText={value => setLocalFinalMessage(value)}
                autoGrow={true}
                errors={props.errors}
                fieldName={'Title'}
                style={styles.input}
                hideSeparator={true}
            />
            <SurveyQuestionsForm
                formErrors={props.errors}
                onUpdated={questions => props.setQuestions(questions)}
                questions={props.questions}
            />

            <AttachmentBar
                onAttached={attachment => props.setAttachment(attachment)}
                attachment={props.attachment}
            />


        </View>
    );
}

const styles = StyleSheet.create({
    input: {
        fontSize: 15,
        borderRadius: 6,
        marginTop: 10,
    },
})