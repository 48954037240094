import { observer, useLocalObservable } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { RootNavigationParamList } from '../navigation/RootNav'
import { Form } from '../api/schema/models/Form'
import React, { useCallback, useEffect } from 'react'
import { runInAction } from 'mobx'
import { API } from '../api/API'
import { extractErrorMessage } from '../common/Util'
import { AppEvents, AppStateStore } from '../contexts/AppStateStore'
import {
  FlatList,
  ListRenderItemInfo,
  StyleSheet,
  TouchableOpacity,
  View,
  ScrollView,
  RefreshControl,
  Platform
} from 'react-native'
import { DocumentIcon } from './DocumentIcon'
import { DefaultText } from './DefaultText'
import { ContentActivityIndicator } from './ContentActivityIndicator'
import { ErrorMessage } from './ErrorMessage'
import { BlockButton } from './BlockButton'
import { ListRefreshControl } from './web-list-refresh-control/ListRefreshControl'
import Icons from './icons'
import { AppColors as COLORS } from '../common/AppColors'
//import * as Sentry from 'sentry-expo';


export const FormList = observer(() => {
  const { t } = useTranslation()
  const navigation = useNavigation<StackNavigationProp<RootNavigationParamList>>()

  const state = useLocalObservable(() => ({
    loading: false,
    refreshing: false,
    error: undefined as string | undefined,
    forms: [] as Form[],
  }))

  const loadForms = useCallback(async (refresh: boolean = false) => {
    runInAction(() => {
      state.loading = true
      state.error = undefined
      state.refreshing = refresh
    })

    try {
      const response = await API.getForms()
      runInAction(() => state.forms = response.forms)
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => {
      state.loading = false
      state.refreshing = false
    })
  }, [state])

  useEffect(() => {
    loadForms().then()

    const listeners = [
      AppStateStore.eventBus.addRemovableListener(AppEvents.FormCreated, () => loadForms().then()),
    ]

    return () => {
      for (const listener of listeners) {
        listener.remove()
      }
    }
  }, [loadForms])

  const renderListItem = (item: ListRenderItemInfo<Form>) => {
    return <TouchableOpacity
      style={styles.listItem}
      onPress={() => navigation.push('SendForm', { formId: item.item.id })}
    >
      <DocumentIcon type={item.item.mime_type || ''} />
      <DefaultText style={styles.formTitleText} numberOfLines={1}>{item.item.title}</DefaultText>
      <Icons.Forward width={12} height={20} color={COLORS.orange.primary} />
    </TouchableOpacity>
  }

  return <View style={styles.container}>
    <View style={styles.content}>
      {
        (state.loading && !state.refreshing)
          ? <ContentActivityIndicator />
          : state.error
            ? <>
              <ErrorMessage message={state.error} />
              <BlockButton
                title={t('Retry', 'Retry')}
                onPress={() => loadForms().then()}
              />
            </>
            : state.forms.length === 0
              ?
              <>
                <ListRefreshControl
                  onPress={() => loadForms(true)}
                  refreshing={state.refreshing}
                />
                <ScrollView
                  contentContainerStyle={styles.emptyListContainer}
                  refreshControl={<RefreshControl refreshing={state.refreshing} onRefresh={() => loadForms(true)} />}
                >
                  <Icons.EmptyDocument width={144} height={96} color={COLORS.gray.neutral5} />
                </ScrollView>
              </>
              :
              <>
                <ListRefreshControl
                  onPress={() => loadForms(true)}
                  refreshing={state.refreshing}
                />
                <FlatList
                  keyboardShouldPersistTaps="handled"
                  style={styles.list}
                  data={state.forms}
                  keyExtractor={item => String(item.id)}
                  renderItem={renderListItem}
                  onRefresh={() => loadForms(true)}
                  refreshing={state.refreshing}
                />
              </>
      }
    </View>
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  list: {
    flex: 1,
    paddingTop: 14
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 12,
    height: 64,
    backgroundColor: COLORS.gray.neutral6,
    justifyContent: 'center',
    borderRadius: 13,
    //marginVertical: 10,
    marginBottom: 20,
  },
  formTitleText: {
    flex: 1,
    marginHorizontal: 12,
    fontSize: 18,
  },
  emptyListContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
