import { StyleSheet, TouchableOpacity, View, Modal, Platform, Dimensions, Text} from 'react-native'
import React from 'react'
import { DefaultText } from './DefaultText'
import { createIconSetFromFontello, MaterialIcons } from '@expo/vector-icons'
import { Colors } from '../common/Colors'
import _ from 'lodash'

type Props = {
  height?: number
  backgrounColor?: string
  overlayColor?: string
  radius?: number
  onPress?: () => void
}

export const ModalPicker = (props) => {
  const SCREEN_WIDTH = Dimensions.get('screen').width
  const SCREEN_HEIGHT = Dimensions.get('screen').height
  const POPUP_HEIGHT = 30 * props.options.length
  const isWeb = Platform.OS == 'web'
  
  const defaultProps = {
    height: props.height ? props.height: 100,
    width: props.width ? props.width: 100,
    backgrounColor: props.backgrounColor ? props.backgrounColor: '#fff',
    overlayColor: props.overlayColor ? props.overlayColor: 'rgba(32,32,32,0.2)',
    radius: props.radius ? props.radius: 10,
    onPress: props.onPress ? props.onPress: () => {},
    swipeLength: props.swipeLength ? props.swipeLength : 75,
    //topY: props.yCoord ? props.yCoord : ((Dimensions.get('screen').height)/2),
    topY: props.yCoord ? (props.yCoord + POPUP_HEIGHT + (isWeb ? 150:50)) > SCREEN_HEIGHT ? (SCREEN_HEIGHT - POPUP_HEIGHT - (isWeb ? 150:75)) : props.yCoord : (SCREEN_HEIGHT/2),
    leftX: props.xCoord ? (props.xCoord + props.width) > SCREEN_WIDTH ? (SCREEN_WIDTH - props.width - 25) : props.xCoord : (SCREEN_WIDTH/2),
    options: props.options ? props.options : [] 
  }

  let initPoint = 0
  let finalPoint = 0
  const outsideTouch = ()  => {
    props.outsideTouch(true)
  }
  const webCenterMargins = (Dimensions.get('screen').width - 900)/2
  const middleY = (Dimensions.get('screen').height)/2
  const initTouch = (event)  => {
    initPoint = event.nativeEvent.pageY
  }

  const lastTouch = (event)  => {
    finalPoint = event.nativeEvent.pageY
    if((finalPoint - initPoint) > defaultProps.swipeLength)
    {
      outsideTouch()
    }
  }

  const onSelect = (optionId)  => {
    props.onSelect(optionId)
  }

  return <Modal transparent={true} onRequestClose={() => outsideTouch()}>
  <TouchableOpacity style={{position:'absolute', height:'100%', width:isWeb ? 900: '100%', backgroundColor: defaultProps.overlayColor, marginHorizontal:isWeb ? webCenterMargins: 0}} 
          onPress={() => outsideTouch()}
          onPressIn={(event) => initTouch(event)}
          onPressOut={(event) => lastTouch(event)}
          activeOpacity={1}>
          <TouchableOpacity style={{
            position:'absolute', top: defaultProps.topY - 50, left: isWeb ? '60%':defaultProps.leftX, width:defaultProps.width, elevation:10, zIndex:10, 
            backgroundColor:defaultProps.backgrounColor,
            borderRadius: defaultProps.radius}}
            activeOpacity={1}
            onPressIn={(event) => initTouch(event)}
            onPressOut={(event) => lastTouch(event)}>
              {
                defaultProps.options.map((o) => <TouchableOpacity 
                style={{width:'100%', justifyContent:'center', height:30, marginVertical:5, paddingHorizontal:16}}
                onPress={() => onSelect(o.value)} key={o.value}>
                  <Text style={{fontSize:16}}>{o.text}</Text>
                </TouchableOpacity>
                )
              }
          </TouchableOpacity>
        </TouchableOpacity>
  </Modal>
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 50,
  },
  label: {
    flex: 1,
    fontSize: 16,
    fontFamily: 'Rubik_400Regular',
  },
  value: {
    fontSize: 12,
    color: '#0082CD',
    fontFamily: 'Rubik_700Bold',
  },
  valueWrapper: {
    alignItems: 'flex-end',
  },
  accessory: {},
})
