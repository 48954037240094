import React from 'react'
import { StyleProp, StyleSheet, TextStyle } from 'react-native'
import { DefaultText } from './DefaultText'
import { AppConfig } from '../AppConfig'

type Props = {
  style?: StyleProp<TextStyle>
  prefix?: string
}

export const AppVersion = (props: Props) => {
  return <DefaultText
    style={[styles.text, props.style]}>
    {`${props.prefix ? `${props.prefix} ` : ''}${AppConfig.VERSION}-b${AppConfig.BUILD_NUMBER}${(AppConfig.APP_ENV && AppConfig.APP_ENV !== 'prod') ? ` [${AppConfig.APP_ENV}]` : ''}`}
  </DefaultText>
}

const styles = StyleSheet.create({
  text: {
    color: '#aaa',
    fontSize: 12,
  },
})
