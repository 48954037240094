import React, { useEffect } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { Audio, AVPlaybackStatus } from 'expo-av'
import { formatDuration } from '../../common/Util'
import { BlockButton } from '../../components/BlockButton'
import { useTranslation } from 'react-i18next'
import { RecordingStatus } from 'expo-av/build/Audio/Recording'
import { getModalManager } from '../../contexts/ModalContext'
import { BoldText } from '../../components/BoldText'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
//import * as Sentry from 'sentry-expo'

export type RecordAudioScreenParams = {
  onSave: (info: { url: string, filename: string, mimeType: string }) => void
}

type State = {
  hasPermission: boolean
  recording: boolean
  playing: boolean
  loading: boolean
  recordingInstance?: Audio.Recording
  recordingDurationMillis: number
  recordingUrl?: string
  maxRecordingLengthSeconds: number
  playProgressMillis: number
  soundInstance?: Audio.Sound
}

export const RecordAudioScreen = observer((props: StackScreenProps<RootNavigationParamList, 'RecordAudio'>) => {
  const { t } = useTranslation()

  const state = useLocalObservable<State>(() => ({
    hasPermission: false,
    recording: false,
    playing: false,
    loading: false,
    recordingInstance: undefined,
    recordingDurationMillis: 0,
    recordingUrl: undefined,
    maxRecordingLengthSeconds: 60,
    playProgressMillis: 0,
    soundInstance: undefined,
  }))

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: 'Record Audio',
    })
  }, [props.navigation])

  useEffect(() => {
    checkPermission().then()
  }, [state])

  const checkPermission = async () => {
    if (Platform.OS === 'web') {
      runInAction(() => state.hasPermission = true)
    } else {
      try {
        const hasPermission = await Audio.getPermissionsAsync()

        if (hasPermission.granted) {
          runInAction(() => state.hasPermission = true)
        }
      } catch (err) {
        //Sentry.Native.captureException(err)
        console.log(err)
      }
    }
  }

  const requestPermission = async () => {
    const permission = await Audio.requestPermissionsAsync()

    if (permission.granted) {
      runInAction(() => state.hasPermission = true)
    } else if (!permission.canAskAgain) {
      getModalManager()
        .showModal({
          title: t('Error', 'Error'),
          message: t('Audio recording permission blocked', 'Audio recording permission has been disabled. Please enabled it in your phone settings.'),
        })
    }
  }

  const renderPermission = () => <View style={styles.container}>
    <Text style={styles.instructions}>You must give permission for the application to record audio.</Text>
    <BlockButton
      variant="primary"
      title={t('Request Permission', 'Request Permission')}
      onPress={requestPermission}
    />
  </View>

  const renderRecorder = () => {
    if (Platform.OS === 'web') {
      return <BoldText style={styles.notSupportedText}>{t('Audio recording not supported', 'Audio recording is not supported on the web. Please log in with your mobile device.')}</BoldText>
    }

    const recordProgress = Math.min(1, state.recordingDurationMillis / 1000 / state.maxRecordingLengthSeconds)
    const recordLimit = state.maxRecordingLengthSeconds - state.recordingDurationMillis / 1000 <= 5
    const playProgress = state.recordingDurationMillis ? Math.min(1, state.playProgressMillis / state.recordingDurationMillis) : 0

    return <View style={styles.container}>
      <Text style={styles.instructions}>You can record up to {state.maxRecordingLengthSeconds} seconds of audio</Text>
      {
        state.recording
          ? <>
            <View style={styles.progressBarContainer}>
              <View style={[styles.progressBar, { width: `${recordProgress * 100}%` }, recordLimit ? { backgroundColor: '#f00' } : undefined]} />
              <Text style={styles.progressBarText}>{formatDuration(Math.floor(state.recordingDurationMillis / 1000))} / {formatDuration(state.maxRecordingLengthSeconds)}</Text>
            </View>
            <BlockButton
              variant="primary"
              title={t('Stop Recording', 'Stop Recording')}
              style={styles.button}
              onPress={stopRecording}
              disabled={state.loading}
            />
          </>
          : state.recordingUrl
            ? <>
              <View style={styles.progressBarContainer}>
                <View style={[styles.progressBar, { width: `${playProgress * 100}%` }]} />
                <Text style={styles.progressBarText}>{formatDuration(Math.floor(state.playProgressMillis / 1000))} / {formatDuration(Math.floor(state.recordingDurationMillis / 1000))}</Text>
              </View>
              {
                state.playing
                  ? <>
                    <BlockButton
                      variant="secondary"
                      title={t('Stop Playing', 'Stop Playing')}
                      style={styles.button}
                      onPress={stopPlaying}
                      disabled={state.loading}
                    />
                  </>
                  : <>
                    <BlockButton
                      variant="secondary"
                      title={t('Play Recording', 'Play Recording')}
                      style={styles.button}
                      onPress={playRecording}
                      disabled={state.loading}
                    />
                    <BlockButton
                      variant="danger"
                      title={t('Delete Recording', 'Delete Recording')}
                      style={styles.button}
                      onPress={deleteRecording}
                      disabled={state.loading}
                    />
                    <BlockButton
                      variant="primary"
                      title={t('Save Recording', 'Save Recording')}
                      style={{ height: 60 }}
                      onPress={saveRecording}
                      disabled={state.loading}
                    />
                  </>
              }
            </>
            : <>
              <BlockButton
                variant="primary"
                title={t('Start Recording', 'Start Recording')}
                style={styles.button}
                onPress={startRecording}
                disabled={state.loading}
              />
            </>
      }
    </View>
  }
  const startRecording = async () => {
    runInAction(() => state.loading = true)

    const recording = new Audio.Recording()
    try {
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      })
      await recording.prepareToRecordAsync({
        android: {
          extension: ".mp3",
          outputFormat: Audio.RECORDING_OPTION_ANDROID_OUTPUT_FORMAT_DEFAULT,
          audioEncoder: Audio.RECORDING_OPTION_ANDROID_AUDIO_ENCODER_DEFAULT,
          sampleRate: 44100,
          numberOfChannels: 2,
          bitRate: 128000,
        },
        ios: {
          extension: ".wav",
          audioQuality: Audio.RECORDING_OPTION_IOS_AUDIO_QUALITY_HIGH,
          sampleRate: 44100,
          numberOfChannels: 1,
          bitRate: 128000,
          linearPCMBitDepth: 16,
          linearPCMIsBigEndian: false,
          linearPCMIsFloat: false
        },
        web: {
          mimeType: "audio/wav",
          bitsPerSecond: 128000
        }
      })
      recording.setOnRecordingStatusUpdate(onRecordingStatusUpdate)
      await recording.startAsync()
      runInAction(() => {
        state.recording = true
        state.recordingDurationMillis = 0
        state.recordingInstance = recording
      })
    } catch (error) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(error)
      }
      else {
        //Sentry.Native.captureException(error)
      }
      getModalManager()
        .showModal({
          title: t('Error', 'Error'),
          message: t('There was an error starting the recording', 'There was an error starting the recording'),
        })
    }

    runInAction(() => state.loading = false)
  }

  const stopPlaying = async () => {
    if (state.soundInstance) {
      await state.soundInstance.stopAsync()
      runInAction(() => {
        state.soundInstance = undefined
        state.playing = false
      })
    }
  }

  const stopRecording = async () => {
    if (!state.recording || state.loading) {
      return
    }

    runInAction(() => state.loading = true)

    const status = await state.recordingInstance!.stopAndUnloadAsync()

    runInAction(() => {
      state.recording = false
      state.recordingDurationMillis = status.durationMillis
      state.recordingUrl = state.recordingInstance!.getURI()!
      state.loading = false
      state.playProgressMillis = 0
    })
  }

  const deleteRecording = () => {
    getModalManager()
      .showModal({
        title: t('Delete Recording', 'Delete Recording'),
        message: t('Are you sure you want to delete this recording', 'Are you sure you want to delete this recording?'),
        buttons: [
          {
            text: t('Yes Delete', 'Yes, Delete'),
            onPress: dismiss => {
              dismiss()
              runInAction(() => {
                state.recordingUrl = undefined
                state.playProgressMillis = 0
              })

            },
          },
          {
            text: t('No', 'No'),
          },
        ]
      })
  }

  const saveRecording = async () => {
    const filename = state.recordingUrl!.split('/').pop()!

    props.route.params.onSave({
      url: state.recordingUrl!,
      filename: filename,
      mimeType: 'application/octet-stream'
    })
    props.navigation.pop()
  }

  const playRecording = async () => {
    const soundObject = new Audio.Sound()

    try {
      runInAction(() => state.playing = true)

      await soundObject.loadAsync({ uri: state.recordingUrl! });
      soundObject.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate)
      await soundObject.playAsync()

      runInAction(() => {
        state.soundInstance = soundObject
        state.playProgressMillis = 0
      })
    } catch (error) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(error)
      }
      else {
        //Sentry.Native.captureException(error)
      }
      console.log(error)
    }
  }

  const onRecordingStatusUpdate = (status: RecordingStatus) => {
    if (state.recording) {
      runInAction(() => state.recordingDurationMillis = status.durationMillis)

      if (status.durationMillis / 1000 >= state.maxRecordingLengthSeconds) {
        stopRecording().then()
      }
    }
  }

  const onPlaybackStatusUpdate = (status: AVPlaybackStatus) => {
    runInAction(() => {
      if (status.isLoaded) {
        state.playProgressMillis = status.positionMillis
        if (status.didJustFinish) {
          stopPlaying().then()
        }
      }
    })
  }

  return !state.hasPermission
    ? renderPermission()
    : renderRecorder()
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  instructions: {
    padding: 20,
    fontSize: 16,
    textAlign: 'center',
  },
  progressBarContainer: {
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#666',
  },
  progressBar: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: '#090',
  },
  progressBarText: {
    fontSize: 15,
    color: '#fff',
  },
  button: {
    marginTop: 20,
  },
  notSupportedText: {
    padding: 24,
    fontSize: 30,
    textAlign: 'center',
  },
})
