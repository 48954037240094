import { useTranslation } from 'react-i18next'
import React from 'react'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'

export type DocumentViewerScreenParams = {
  url: string
}

export const DocumentViewerScreen = (props: StackScreenProps<RootNavigationParamList, 'DocumentViewer'>) => {
  const { t } = useTranslation()

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Document', 'Document'),
    })
  }, [props.navigation])

  return <iframe
    style={{ flex: 1 }}
    src={props.route.params.url}
  />
}
