import { registerUser } from './methods/register-user'
import { forgotPassword } from './methods/forgot-password'
import { verifyForgotPassword } from './methods/verify-forgot-password'
import { verifyUser } from './methods/verify-user'
import { changePassword } from './methods/change-password'
import { getUserContext } from './methods/get-user-context'
import { resendVerificationCode } from './methods/resend-verification-code'
import { resendForgottenCode } from './methods/resend-forgotten-code'
import { authenticateUser } from './methods/authenticate-user'
import { getSchoolDistricts } from './methods/get-school-districts'
import { linkStudent } from './methods/link-student'
import { addStudent } from './methods/add-student'
import { removeStudent } from './methods/remove-student'
import { deleteAccount } from './methods/delete-account'
import { getProfile } from './methods/get-profile'
import { setLanguage } from './methods/set-language'
import { updateProfile } from './methods/update-profile'
import { uploadProfilePhoto } from './methods/upload-profile-photo'
import { requestUpload } from './methods/request-upload'
import { uploadFile } from './methods/upload-file'
import { getDocuments } from './methods/get-documents'
import { getDocument } from './methods/get-document'
import { getVisibilityOptions } from './methods/get-visibility-options'
import { getFormVisibilityOptions } from './methods/get-form-visibility-options'
import { createDocument } from './methods/create-document'
import { getContacts } from './methods/get-contacts'
import { createConversation } from './methods/create-conversation'
import { getConversations } from './methods/get-conversations'
import { getTranslatedMessage } from './methods/get-translated-message'
import { getConversation } from './methods/get-conversation'
import { createMessage } from './methods/create-message'
import { getAudioTranscript } from './methods/get-audio-transcript'
import { getVideo } from './methods/get-video'
import { getVideoTranscript } from './methods/get-video-transcript'
import { getAudio } from './methods/get-audio'
import { registerPushNotificationToken } from './methods/register-push-notification-token'
import { readPushNotification } from './methods/read-push-notification'
import { deletePushNotificationToken } from './methods/delete-push-notification-token'
import { submitSupportRequest } from './methods/submit-support-request'
import { getSections } from './methods/get-sections'
import { createPost } from './methods/create-post'
import { getPosts } from './methods/get-posts'
import { getPost } from './methods/get-post'
import { getTranslatedPost } from './methods/get-translated-post'
import { getTranslation } from './methods/get-translation'
import { updatePost } from './methods/update-post'
import { deletePost } from './methods/delete-post'
import { createEvent } from './methods/create-event'
import { getEvents } from './methods/get-events'
import { getEvent } from './methods/get-event'
import { deleteEvent } from './methods/delete-event'
import { updateEvent } from './methods/update-event'
import { getDocumentCategories } from './methods/get-document-categories'
import { getDocumentAccessLevels } from './methods/get-document-access-levels'
import { getFormCategories } from './methods/get-form-categories'
import { createForm } from './methods/create-form'
import { getForm } from './methods/get-form'
import { getForms } from './methods/get-forms'
import { getStudentOptions } from './methods/get-student-options'
import { sendForm } from './methods/send-form'
import { getSentFormGroups } from './methods/get-sent-form-groups'
import { getSentFormGroup } from './methods/get-sent-form-group'
import { getReceivedForms } from './methods/get-received-forms'
import { getReceivedForm } from './methods/get-received-form'
import { submitForm } from './methods/submit-form'
import { getAttendanceSections } from './methods/get-attendance-sections'
import { getSectionAttendees } from './methods/get-section-attendees'
import { markAttendanceComplete } from './methods/mark-attendance-complete'
import { setAttendanceStatus } from './methods/set-attendance-status'
import { submitSurveyAnswers } from './methods/submit-survey-answers'
import { getSurveyResults } from './methods/get-survey-results'
import { getUrgentAlerts } from './methods/get-urgent-alerts'
import { getSectionMembers } from './methods/get-section-members'
import { getNotifications } from './methods/get-notifications'
import { getUnreadCount } from './methods/get-unread-count'
import { markNotification } from './methods/mark-notification'
import { markSearchNotification } from './methods/mark-search-notification'
import { markAllNotifications } from './methods/mark-all-notifications'
import { getUnreadConversations } from './methods/get-unread-conversation'
import { markConversation } from './methods/mark-conversation'
import { getEventTypes } from './methods/get-event-types'
import { getEventContacts } from './methods/get-event-contacts'
import { getAllowedLinks } from './methods/get-allowed-links'
import { updateSignatureGroups } from './methods/update-signature-groups'
import { getSignatureGroups } from './methods/get-signature-groups'
import { deleteSignatureGroup } from './methods/delete-signature-group'

export const API = {
  getUserContext,
  registerPushNotificationToken,
  deletePushNotificationToken,
  readPushNotification,
  markSearchNotification,
  registerUser,
  forgotPassword,
  verifyForgotPassword,
  changePassword,
  verifyUser,
  resendVerificationCode,
  resendForgottenCode,
  authenticateUser,
  getSchoolDistricts,
  linkStudent,
  addStudent,
  removeStudent,
  deleteAccount,
  getProfile,
  setLanguage,
  updateProfile,
  uploadProfilePhoto,
  requestUpload,
  uploadFile,
  getDocumentCategories,
  getDocumentAccessLevels,
  getDocuments,
  getDocument,
  getVisibilityOptions,
  getFormVisibilityOptions,
  getStudentOptions,
  createDocument,
  getFormCategories,
  createForm,
  getForms,
  getForm,
  sendForm,
  getSentFormGroups,
  getSentFormGroup,
  getReceivedForms,
  getReceivedForm,
  submitForm,
  getContacts,
  createConversation,
  getConversations,
  getTranslatedMessage,
  getConversation,
  getUnreadConversations,
  createMessage,
  getAudio,
  getAudioTranscript,
  getVideo,
  getVideoTranscript,
  submitSupportRequest,
  getSections,
  createPost,
  getPosts,
  getPost,
  getTranslatedPost,
  getTranslation,
  updatePost,
  deletePost,
  createEvent,
  getEvents,
  getEvent,
  deleteEvent,
  updateEvent,
  getAttendanceSections,
  getSectionAttendees,
  markAttendanceComplete,
  setAttendanceStatus,
  submitSurveyAnswers,
  getSurveyResults,
  getUrgentAlerts,
  getSectionMembers,
  getNotifications,
  getUnreadCount,
  markNotification,
  markAllNotifications,
  markConversation,
  getEventTypes,
  getEventContacts,
  getAllowedLinks,
  updateSignatureGroups,
  getSignatureGroups,
  deleteSignatureGroup
}
