import React, { useState } from 'react'
import { View, StyleSheet, Platform, ActivityIndicator } from 'react-native'
import WebView from 'react-native-webview'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../navigation/RootNav'

export type WebViewScreenParams = {
  url: string
}

export const WebViewScreen = (props: StackScreenProps<RootNavigationParamList, 'WebView'>) => {
  const { url } = props.route.params
  const [title, setTitle] = useState('');

  props.navigation.setOptions({
    title: title ? title.slice(0, 25) + (title.length > 25 ? '...' : '') : 'Loading...',
    headerTitleAlign: 'center',

  })

  function LoadingIndicatorView() {
    return <ActivityIndicator style={styles.spinner} size="large" />
  }

  const handleInjectedJavaScript = `
  const title = document.querySelector('title').innerText;
  title;
`

  const handleWebViewNavigationStateChange = (navState: any) => {
    if (navState.title) {
      setTitle(navState.title);
    }
  }

  return (
    Platform.OS === 'web' ?
      <iframe
        src={url}
        height={'100%'}
        width={'100%'}
      />
      :
      <View style={styles.container}>
        <WebView
          source={{ uri: url }}
          style={{ flex: 1 }}
          originWhitelist={['*']}
          javaScriptEnabled={true}
          domStorageEnabled={true}
          startInLoadingState={true}
          scalesPageToFit={true}
          renderLoading={LoadingIndicatorView}
          injectedJavaScript={handleInjectedJavaScript}
          onNavigationStateChange={handleWebViewNavigationStateChange}
        />
      </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  spinner: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0
  },
})
