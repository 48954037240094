import React from 'react'
import { StyleSheet, View } from 'react-native'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';

export const NotificationDocument = () => {
  return <Svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <Path d="M0.5 20C0.5 31.0457 9.45431 40 20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.95431 31.5457 0 20.5 0C9.45431 0 0.5 8.95431 0.5 20Z" fill="#FF69B4"/>
  <Path d="M29.15 13.49H25.56V11.35C25.56 10.6 24.95 10 24.21 10H11.85C11.11 10 10.5 10.61 10.5 11.35V25.15C10.5 25.9 11.11 26.5 11.85 26.5H16.43V28.64C16.43 29.39 17.04 29.99 17.78 29.99H25.24C25.85 29.99 26.68 29.66 27.12 29.24L29.69 26.82C30.15 26.4 30.5 25.58 30.5 24.96V14.85C30.5 14.1 29.89 13.49 29.15 13.49ZM26.29 18.4C26.62 18.4 26.89 18.67 26.89 19C26.89 19.33 26.62 19.6 26.29 19.6H20.83C20.5 19.6 20.23 19.33 20.23 19C20.23 18.67 20.5 18.4 20.83 18.4H26.29ZM16.43 14.85V24.96H12.05V11.55H24.02V13.49H17.78C17.04 13.49 16.43 14.1 16.43 14.85ZM23.68 25.6H20.84C20.51 25.6 20.24 25.33 20.24 25C20.24 24.67 20.51 24.4 20.84 24.4H23.68C24.01 24.4 24.28 24.67 24.28 25C24.28 25.33 24.01 25.6 23.68 25.6ZM20.84 22.6C20.51 22.6 20.24 22.33 20.24 22C20.24 21.67 20.51 21.4 20.84 21.4H26.3C26.63 21.4 26.9 21.67 26.9 22C26.9 22.33 26.63 22.6 26.3 22.6H20.84ZM26.59 27.62V26.45C26.59 26.41 26.61 26.38 26.63 26.35C26.65 26.33 26.69 26.3 26.74 26.3H27.98L26.59 27.62Z" fill="white"/>
  </Svg>
  
  
}
