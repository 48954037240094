import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { AppColors } from '../../common/AppColors';
import { formatDuration } from '../../common/Util';

export type AudioProgressBarVariant = 'primary' | 'secondary';

interface Props {
  playProgressMillis: number;
  durationMillis?: number;
  variant?: AudioProgressBarVariant,
}

export const AudioProgressBar = ({
  playProgressMillis,
  durationMillis = 60,
  variant = 'primary',
}: Props) => {

  const progressBarWidth = () => `${Math.ceil(playProgressMillis / durationMillis * 100)}%`;

  const playbackStatus = { ...styles.playbackStatus };
  const progressBar = { ...styles.progressBar };

  if (variant === 'secondary') {
    playbackStatus.backgroundColor = AppColors.gray.neutral3;
    progressBar.backgroundColor = AppColors.orange.primary;
  }

  return (
    <View style={styles.container}>
      <View style={playbackStatus}>
        <View style={[progressBar, { width: progressBarWidth() }]} />
        <Text style={styles.durationText}>
          {
            durationMillis
              ? `${formatDuration(Math.ceil(playProgressMillis / 1000))} / ${formatDuration(Math.ceil(durationMillis / 1000))}`
              : formatDuration(Math.ceil(playProgressMillis / 1000))
          }
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    height: 20,
    flex: 1,
    marginLeft: 6,
  },
  durationText: {
    color: AppColors.white,
    fontFamily: 'Rubik_500Medium',
    fontSize: 12,
  },
  playbackStatus: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: AppColors.gray.neutral3,
    borderRadius: 8,
    marginRight: 3,
  },
  progressBar: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 0,
    backgroundColor: AppColors.blue.primary,
    borderRadius: 8,
  },
  button: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: AppColors.white,
    borderRadius: 20,
  },
})