import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { AppStateStore } from '../../contexts/AppStateStore'
import { BoldText } from '../../components/BoldText'
import { BlockButton } from '../../components/BlockButton'
import { Colors } from '../../common/Colors'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'

export const InactiveTeacherScreen = (props: StackScreenProps<RootNavigationParamList, 'InactiveTeacher'>) => {
  const { t } = useTranslation()

  const retry = async () => {
    await AppStateStore.clearUserContext()
  }

  const logOut = async () => {
    await AppStateStore.logout()
  }

  return <View style={styles.container}>
    <View style={styles.main}>
      <BoldText style={styles.headerText}>
        {t('Inactive teacher account', 'Your account is not currently active.')}
      </BoldText>
    </View>
    <SafeAreaView edges={['bottom']}>
      <View style={styles.footer}>
        <BlockButton
          title={t('Retry', 'Retry')}
          variant={'secondary'}
          onPress={retry}
          flex={1}
        />
        <BlockButton
          title={t('Log Out', 'Log Out')}
          variant={'tertiary'}
          onPress={logOut}
          flex={1}
        />
      </View>
    </SafeAreaView>
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  main: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    marginTop: 24,
    fontSize: 24,
    paddingHorizontal: 24,
    color: Colors.MutedTextColor,
    textAlign: 'center',
  },
  footer: {
    flexDirection: 'row',
  },
})
