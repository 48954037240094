import React from 'react'
import { FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View } from 'react-native'
import { DefaultText } from '../components/DefaultText'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../navigation/RootNav'
import { AppColors as COLORS } from '../common/AppColors'

export type PickerScreenParams = {
  title: string
  options: PickerOption[]
  onChoose: (option: PickerOption, pop: () => void) => void
}

export type PickerOption = {
  value: string | number
  text: string
}

export const PickerScreen = (props: StackScreenProps<RootNavigationParamList, 'Picker'>) => {
  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: props.route.params.title,
      headerStyle: { backgroundColor: '#fff', borderBottomWidth: 0 },
      headerTitleStyle: { color: COLORS.blue.primary, fontSize: 18, fontFamily: 'Rubik_700Bold' }
    })
  }, [props.navigation])

  const pop = () => props.navigation.pop()

  const renderItem = (item: ListRenderItemInfo<PickerOption>) => {
    return <TouchableOpacity onPress={() => props.route.params.onChoose(item.item, pop)}>
      <View style={styles.listItem}>
        <DefaultText>{item.item.text}</DefaultText>
      </View>
    </TouchableOpacity>
  }

  return <View style={styles.container}>
    <FlatList
      style={styles.list}
      data={props.route.params.options}
      renderItem={renderItem}
      keyExtractor={i => i.value}
    />
    <SafeAreaView edges={['bottom']}>
      <View />
    </SafeAreaView>
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  list: {
    flex: 1,
  },
  listItem: {
    padding: 16,
  },
  footer: {
    flexDirection: 'row',
  },
})
