import { StyleSheet, View, Text, Dimensions } from 'react-native'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { BlockButton } from '../../components/BlockButton'
import { BoldText } from '../../components/BoldText'
import { useTranslation } from 'react-i18next'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import OllieWaving from '../../../assets/images/ollie-waving'
import { AppColors as COLORS } from '../../common/AppColors'
import { AppStateStore } from '../../contexts/AppStateStore'

export const windowHeight = Dimensions.get('window').height

export const WelcomeScreen = observer((props: StackScreenProps<RootNavigationParamList, 'Welcome'>) => {
  const { t } = useTranslation()

  const userType = AppStateStore.userContext?.userType === null || AppStateStore.userContext?.userType === undefined || AppStateStore.userContext?.userType === 'teacher' ? 'educator' : AppStateStore.userContext?.userType

  const register = () => {
    props.navigation.push('CompleteProfile')
  }


  return (
    <View style={styles.container}>
      <View style={styles.logoContainer}>
        <OllieWaving></OllieWaving>
        <BoldText style={styles.text}>{t(`Welcome ${userType}`, `Welcome, ${userType}!`)}</BoldText>
        <Text style={styles.subText}>{t('Lets get started', "Let's get started!")}</Text>
      </View>
      <View style={styles.footer}>
        <BlockButton
          title={t('Continue', 'Continue')}
          variant={'secondary'}
          flex={1}
          onPress={register}
          textStyle={{ fontSize: 16 }}
          style={{ marginRight: 1, width: '90%', maxHeight: 60, borderRadius: 8, marginTop: 20 }}
        />
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.orange.primary,
  },
  logoContainer: {
    width: '70%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '100%',
    resizeMode: 'contain',
  },
  footer: {
    position: 'absolute',
    bottom: 50,
    width: '100%',
    height: 76,
    alignItems: 'center',
  },
  text: {
    color: '#FFFFFF',
    fontSize: 24,
    textAlign: 'center',
    width: 500,
    marginTop: 20
  },
  subText: {
    color: '#FFFFFF',
    fontWeight: '600',
    textAlign: 'center',
    width: 600,
    fontSize: 16,
    marginTop: 6
  },
})
