import React from 'react'
import { KeyboardAvoidingView, Platform, StyleSheet, TextInput, View, Text, Dimensions } from 'react-native'
import { runInAction } from 'mobx'
import { FormError } from '../components/FormError'
import { observer, useLocalObservable } from 'mobx-react-lite'
import ErrorBag from '../common/ErrorBag'
import { handleErrorResponse } from '../common/Util'
import { useTranslation } from 'react-i18next'
import { BlockButton } from '../components/BlockButton'
import { API } from '../api/API'
import { BoldText } from '../components/BoldText'
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context'
import { useHeaderHeight } from '@react-navigation/elements'
import { StackScreenProps } from '@react-navigation/stack'
import { DrawerHeaderButton } from '../components/DrawerHeaderButton'
import { RootNavigationParamList } from '../navigation/RootNav'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';
import { AppColors } from '../common/AppColors'
import { StyledTextInput } from '../components/StyledTextInput'
import { BottomDrawer } from '../components/BottomDrawer'
import { FlyingOwlieSvg } from '../../assets/images/flying-owlie'
import { MiniLogoSvg } from '../../assets/images/mini-logo'
import { ScrollView } from 'react-native-gesture-handler'
import { AppVersion } from '../components/AppVersion'
//import * as Sentry from 'sentry-expo'

export const windowHeight = Dimensions.get('window').height


export const SupportScreen = observer((props: StackScreenProps<RootNavigationParamList, 'Support' | 'SupportScreen'>) => {
  const { t } = useTranslation()
  const insets = useSafeAreaInsets();

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      headerLeft: () => <DrawerHeaderButton />,
      title: t('Support', 'Support'),
    })
  }, [props.navigation])

  const headerHeight = useHeaderHeight()

  const state = useLocalObservable(() => ({
    form: {
      subject: '',
      message: '',
    },
    errors: new ErrorBag(),
    submitting: false,
    showDrawer: false,

    get isValid(): boolean {
      return this.form.subject.length > 0
        && this.form.message.length > 0
    },
  }))

  const submit = async () => {
    try {
      runInAction(() => {
        state.submitting = true
        state.errors.clearErrors()
      })

      await API.submitSupportRequest({
        subject: state.form.subject,
        message: state.form.message,
      })
      runInAction(() => {
        state.showDrawer = true
        state.form.subject = ''
        state.form.message = ''
      })

    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      handleErrorResponse(err.response, state.errors, {
        unhandledErrors: {
          unhandledErrorsKey: '_unhandled',
          handledErrorFields: Object.keys(state.form),
        },
      })
    }

    runInAction(() => {
      state.submitting = false
    })
  }

  return <KeyboardAvoidingView
    {...(Platform.OS === "ios" ? { behavior: "padding" } : {})}
    style={{ flex: 1 }}
    contentContainerStyle={{ flex: 1, paddingTop: 16 }}
    keyboardVerticalOffset={(headerHeight ?? 0) + 10}
  >
    <ScrollView nestedScrollEnabled={true} style={[{
      flex: 1,
      paddingTop: insets.top - 60, backgroundColor: AppColors.white
    }]}>
      <View style={{ alignItems: 'center', paddingTop: 16 }}>
        <MiniLogoSvg></MiniLogoSvg>
      </View>

      <BoldText style={styles.headerText}>{t('How Can We Help', 'How Can We Help?')}</BoldText>
      <View style={styles.inputContainer}>
        <FormError field={'_unhandled'} errors={state.errors} displayAsList={true} />
        <View style={{ marginBottom: 10 }}>
          <StyledTextInput
            style={StyleSheet.flatten([Platform.select({ web: { outlineStyle: 'none', borderWidth: 0 } }), { marginTop: 16, minHeight: 56, borderColor: '#f00', borderWidth: 0, backgroundColor: AppColors.gray.neutral6 }])}
            placeholder={t('Subject', 'Subject')}
            value={state.form.subject}
            onChange={ev => runInAction(() => state.form.subject = ev.nativeEvent.text)}
            editable={!state.submitting}
          />
          <FormError field="subject" errors={state.errors} />
        </View>
        <View style={{ marginTop: 16 }}>
          <TextInput
            style={[styles.input, styles.textAreaInput, Platform.select({ web: { outlineStyle: 'none' } })]}
            multiline={true}
            placeholder={t('Type your message here', 'Type your message here')}
            value={state.form.message}
            onChange={ev => runInAction(() => state.form.message = ev.nativeEvent.text)}
            editable={!state.submitting}
          />
          <FormError field="message" errors={state.errors} />
        </View>
        <BlockButton
          title={t('Send', 'Send')}
          variant={'secondary'}
          flex={1}
          disabled={!state.isValid || state.submitting}
          onPress={submit}
          textStyle={{ fontSize: 16 }}
          style={{ marginRight: 1, width: '100%', minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 20, backgroundColor: (!state.isValid || state.submitting) ? AppColors.blue.disabled : AppColors.blue.primary }}
        />
        <View style={styles.appVersion}>
          <AppVersion prefix={'Version'} />
        </View>
      </View>
      {
        state.showDrawer
          ? <BottomDrawer height={370} radius={30} backgrounColor={'#fff'} outsideTouch={() => runInAction(() => { state.showDrawer = false })}>
            <View style={{ alignItems: 'center', paddingTop: 80, paddingHorizontal: 0 }}>
              <FlyingOwlieSvg></FlyingOwlieSvg>
              <BoldText style={styles.drawerHeader}>{t('Thank You', 'Thank You')}</BoldText>
              <Text style={[styles.drawerText, { marginHorizontal: 125 }]}>{t('Well get back', "We'll get back to you as soon as we can.")}</Text>
            </View>
          </BottomDrawer>
          : null
      }
    </ScrollView>
  </KeyboardAvoidingView>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'scroll',
    backgroundColor: AppColors.white,
    minHeight: 2000
  },

  headerText: {
    paddingTop: 30,
    fontSize: 30,
    color: AppColors.blue.primary,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  drawerHeader: {
    paddingTop: 16,
    fontSize: 24,
    color: AppColors.blue.primary,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  drawerText: {
    paddingTop: 16,
    fontSize: 16,
    color: AppColors.gray.neutral1,
    textAlign: 'center',
    fontWeight: 'bold',
  },

  inputContainer: {
    padding: 20,
    flex: 1,
  },

  input: {
    borderRadius: 8,
    padding: 16,
    fontSize: 15,
    marginBottom: 10,
  },

  textAreaInput: {
    height: 150,
    borderWidth: 0,
    backgroundColor: AppColors.gray.neutral6,
    textAlignVertical: 'top'
  },
  appVersion: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    padding: 4,
    marginTop: windowHeight * 0.1
  },
  footer: {},
})
