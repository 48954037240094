import { observer, useLocalObservable } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect } from 'react'
import { ScrollView, StyleSheet, View, Platform, Dimensions } from 'react-native'
import { SmartKeyboardAvoidingView } from '../../components/SmartKeyboardAvoidingView'
import { BoldText } from '../../components/BoldText'
import { FormError } from '../../components/FormError'
import { runInAction } from 'mobx'
import { StyledTextInput } from '../../components/StyledTextInput'
import { BlockButton } from '../../components/BlockButton'
import ErrorBag from '../../common/ErrorBag'
import { SelectInput } from '../../components/SelectInput'
import { extractErrorMessage, handleErrorResponse } from '../../common/Util'
import { API } from '../../api/API'
import { DefaultText } from '../../components/DefaultText'
import { Language } from '../../api/schema/GetProfileResponse'
import { PickerOption } from '../PickerScreen'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { getModalManager } from '../../contexts/ModalContext'
import { LogOutHeaderButton } from '../../components/LogOutHeaderButton'
import { ProfilePhotoInput } from '../../components/ProfilePhotoInput'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { OwlieWavingSvg } from '../../../assets/images/owlie-waving'
import { AppColors as COLORS } from '../../common/AppColors'
//import * as Sentry from 'sentry-expo'

export const windowHeight = Dimensions.get('window').height

export const CompleteProfileScreen = observer((props: StackScreenProps<RootNavigationParamList, 'CompleteProfile'>) => {
  const { t } = useTranslation()


  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Profile', 'Profile'),
      headerStyle: { backgroundColor: '#fff', borderBottomWidth: 0 },
      headerTitleStyle: { color: COLORS.gray.neutral1, fontWeight: 'bold' },
      headerRight: () => <LogOutHeaderButton />,
    })
  }, [props.navigation])

  const state = useLocalObservable(() => ({
    loading: false,
    error: undefined as string | undefined,
    submitting: false,
    hasDisplayName: false,
    canEditName: false,
    supportedLanguages: [] as Language[],
    profilePhotoUrl: undefined as string | undefined,
    form: {
      firstName: '',
      lastName: '',
      language: '',
      displayName: '',
    },
    formErrors: new ErrorBag(),
    get languageNativeName() {
      if (this.form.language) {
        const lang = this.supportedLanguages.find(l => l.code === this.form.language)

        if (lang) {
          return lang.nativeName
        } else {
          return this.form.language
        }
      } else {
        return this.form.language
      }
    }
  }))

  const loadProfile = useCallback(async () => {
    runInAction(() => state.loading = true)

    try {
      const profile = await API.getProfile()

      runInAction(() => {
        state.canEditName = profile.canEditName
        state.hasDisplayName = profile.hasDisplayName
        state.form.firstName = profile.firstName ?? ''
        state.form.lastName = profile.lastName ?? ''
        state.form.language = profile.language ?? ''
        state.form.displayName = profile.displayName ?? ''
        state.supportedLanguages = profile.supportedLanguages
        state.profilePhotoUrl = profile.profilePhotoUrl ?? undefined
      })
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [])

  const submit = async () => {
    runInAction(() => {
      state.submitting = true
      state.formErrors.clearErrors()
    })

    try {
      await API.updateProfile(state.form)
      AppStateStore.eventBus.emit(AppEvents.InvalidateUserContext)
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => {
        handleErrorResponse(err.response, state.formErrors, {
          unhandledErrors: {
            handledErrorFields: Object.keys(state.form),
            unhandledErrorsKey: '_unhandled',
          }
        })
      })
    }

    runInAction(() => {
      state.submitting = false
    })
  }

  const updateLanguage = async (language: string) => {
    runInAction(() => {
      state.form.language = language
      state.submitting = true
    })

    /* runInAction(() => {
      if (AppStateStore.userContext) {
        AppStateStore.userContext.language = language
      }
    }) */

    try {
      //await API.setLanguage({ language })
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      getModalManager()
        .showModal({
          title: t('Error', 'Error'),
          message: extractErrorMessage(err.response),
        })
    }

    runInAction(() => {
      state.submitting = false
    })
  }

  const showLanguagePicker = () => {
    props.navigation.push('Picker', {
      title: t('Choose your Language', 'Choose your Language'),
      options: state.supportedLanguages.map(l => ({
        value: l.code,
        text: l.nativeName,
      })),
      onChoose: (lang: PickerOption, pop) => {
        typeof lang.value === 'string' && updateLanguage(lang.value).then()
        pop()
      },
    })
  }

  useEffect(() => {
    loadProfile().then()
  }, [loadProfile])

  return <SmartKeyboardAvoidingView
    style={[styles.container, { backgroundColor: state.loading ? '#FF7A33' : '#fff', alignContent: state.loading ? 'center' : 'center' }]}

  >
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1
      }}
      keyboardShouldPersistTaps="handled"
    >
      {
        state.loading
          ? <OwlieWavingSvg firstTime={true}></OwlieWavingSvg>
          : <>
            <View style={styles.main}>
              <BoldText style={styles.headerText}>{t('Complete Your Profile', 'Complete Your Profile')}</BoldText>
              <View style={styles.formContainer}>
                <FormError field={'_unhandled'} errors={state.formErrors} displayAsList={true} containerStyle={{ marginBottom: 16 }} />
                <View style={{ alignItems: 'center' }}>
                  <ProfilePhotoInput
                    profilePhotoUrl={state.profilePhotoUrl}
                    onProfilePhotoUpdated={url => runInAction(() => state.profilePhotoUrl = url)}
                  />
                </View>
                {
                  state.canEditName
                    ? <>
                      <View style={styles.formInput}>
                        <StyledTextInput
                          style={{ marginTop: 16 }}
                          placeholder={t('First Name', 'First Name')}
                          value={state.form.firstName}
                          onChangeText={t => runInAction(() => state.form.firstName = t)}
                          editable={!state.submitting}
                        />
                        <FormError field={'firstName'} errors={state.formErrors} />
                      </View>
                      <View style={styles.formInput}>
                        <StyledTextInput
                          style={{ marginTop: 16 }}
                          placeholder={t('Last Name', 'Last Name')}
                          value={state.form.lastName}
                          onChangeText={t => runInAction(() => state.form.lastName = t)}
                          editable={!state.submitting}
                        />
                        <FormError field={'lastName'} errors={state.formErrors} />
                      </View>
                    </>
                    : <>
                      <View style={styles.formInput}>
                        <DefaultText style={{ fontSize: 12 }}>{t('First Name', 'First Name')}</DefaultText>
                        <DefaultText bold={true}>{state.form.firstName}</DefaultText>
                      </View>
                      <View style={styles.formInput}>
                        <DefaultText style={{ fontSize: 12 }}>{t('Last Name', 'Last Name')}</DefaultText>
                        <DefaultText bold={true}>{state.form.lastName}</DefaultText>
                      </View>
                    </>
                }
                {
                  state.hasDisplayName
                    ?
                    <>
                      <View style={[styles.formInput, { backgroundColor: '#F6F7FA', height: 56, borderRadius: 6, justifyContent: 'center' }]}>
                        <StyledTextInput
                          //style={{ borderColor: '#f00 !important', borderWidth: 0 }}
                          placeholder={t('Display Name', 'Display Name')}
                          value={state.form.displayName}
                          onChangeText={t => runInAction(() => state.form.displayName = t)}
                          editable={!state.submitting}
                        />
                      </View>
                      <FormError field={'displayName'} errors={state.formErrors} containerStyle={{ marginTop: -20, marginBottom: 15 }} />
                    </>
                    : null
                }
                <View style={[styles.formInput, { backgroundColor: '#F6F7FA', height: 56, borderRadius: 6, paddingHorizontal: 16, justifyContent: 'center' }]}>
                  <SelectInput
                    label={t('Language', 'Language')}
                    value={state.languageNativeName}
                    onPress={showLanguagePicker}
                    disabled={state.submitting}
                  />
                </View>
                <FormError field={'language'} errors={state.formErrors} containerStyle={{ marginTop: -20, marginBottom: 15 }} />
              </View>
              <View style={styles.footer}>
                <BlockButton
                  title={t('Complete profile', 'Complete profile')}
                  variant={'secondary'}
                  flex={1}
                  disabled={state.submitting}
                  onPress={submit}
                  textStyle={{ fontSize: 16 }}
                  style={{ marginRight: 1, width: '90%', maxHeight: 60, borderRadius: 8, marginTop: 20 }}
                />
              </View>
            </View>
          </>
      }
    </ScrollView>
  </SmartKeyboardAvoidingView>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  main: {
    flex: 1,
    alignItems: 'center',
  },
  headerText: {
    marginTop: 24,
    paddingHorizontal: 24,
    fontSize: 18,
    color: COLORS.blue.primary,
    textAlign: 'center',
  },
  formContainer: {
    marginTop: 24,
    paddingHorizontal: 24,
    width: '100%',
  },
  formInput: {
    marginBottom: 16,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    marginTop: windowHeight * 0.31
    /*  position: 'absolute',
     bottom: 40,
     width: '100%',
     alignItems: 'center', */
  },
})
