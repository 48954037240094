import React from 'react'
import { ScrollView, StyleSheet, View, Text, Pressable, Platform, Dimensions } from 'react-native'
import { BoldText } from '../../components/BoldText'
import { Sizes } from '../../common/Sizes'
import { BlockButton } from '../../components/BlockButton'
import { StyledTextInput } from '../../components/StyledTextInput'
import { DefaultText } from '../../components/DefaultText'
import { SmartKeyboardAvoidingView } from '../../components/SmartKeyboardAvoidingView'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { useTranslation } from 'react-i18next'
import { API } from '../../api/API'
import { handleErrorResponse } from '../../common/Util'
import { FormError } from '../../components/FormError'
import ErrorBag from '../../common/ErrorBag'
import { ValidationError } from '../../exceptions/ValidationError'
import { getModalManager } from '../../contexts/ModalContext'
import { AppStateStore } from '../../contexts/AppStateStore'
import { AuthenticateUserRequest } from '../../api/schema/AuthenticateUserRequest'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import Svg, { Path } from 'react-native-svg';
import { AppColors } from '../../common/AppColors'
import { MiniLogoSvg } from '../../../assets/images/mini-logo'
import { AppColors as COLORS } from '../../common/AppColors'
//import * as Sentry from 'sentry-expo'

export const windowHeight = Dimensions.get('window').height

export const LoginScreen = observer((props: StackScreenProps<RootNavigationParamList, 'Login'>) => {
  const { t } = useTranslation()

  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  const phoneRegex = /^\d{10}$/

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      headerShown: false,
    })
  }, [props.navigation])

  const state = useLocalObservable(() => ({
    authMethod: '',
    form: {
      phoneNumber: '',
      emailAddress: '',
      emailOrPhone: '',
      password: '',
    },
    formErrors: new ErrorBag(),
    submitting: false,
    get isValid() {
      return (this.form.emailOrPhone.trim().length && this.form.password.length)
    },
    hidePassword: true
  }))

  const _changeIcon = () => {
    state.hidePassword = !state.hidePassword
  }
  const showRegister = () => {
    //props.navigation.push('Register')
  }
  const showForgot = () => {
    props.navigation.push('ForgotIdentification')
  }

  const validateEmailOrPhone = (input: string) => {

    if (emailRegex.test(input)) {

      runInAction(() => {
        state.authMethod = 'email'
        state.form.emailAddress = input
        state.form.emailOrPhone = input
      })

    } else if (phoneRegex.test(input)) {

      const formatPhoneNumber = formatPhone(input)

      runInAction(() => {
        state.authMethod = 'phone'
        state.form.phoneNumber = formatPhoneNumber
        state.form.emailOrPhone = getNumber(input)
      })

    } else {
      //remove spaces and parens
      const getinput = getNumber(input)

      runInAction(() => {
        state.authMethod = ''
        state.form.emailOrPhone = getinput
      })
    }

  }

  const submit = async () => {
    runInAction(() => {
      state.submitting = true
      state.formErrors.clearErrors()
    })

    try {
      let request: AuthenticateUserRequest

      if (!emailRegex.test(state.form.emailOrPhone) && !phoneRegex.test(state.form.emailOrPhone)) {
        runInAction(() => {
          state.formErrors.addError('emailAddress', t('Invalid email or phone number', 'Invalid email or phone number'))
          state.submitting = false
        })
        return
      }


      if (state.authMethod === 'phone') {
        request = { authMethod: 'phone', phoneNumber: state.form.emailOrPhone, password: state.form.password }
        console.log('request: ', request)
      } else if (state.authMethod === 'email') {
        request = { authMethod: 'email', emailAddress: state.form.emailOrPhone, password: state.form.password }
      } else {
        throw new ValidationError(t('Invalid auth type', 'Invalid auth type'))
      }

      const response = await API.authenticateUser(request)

      await AppStateStore.setAuthToken(response.authToken)
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      if (err.name === 'ValidationError') {
        getModalManager()
          .showModal({
            title: t('Error', 'Error'),
            message: err.message,
          })
      } else {
        runInAction(() => {
          handleErrorResponse(err.response, state.formErrors, {
            unhandledErrors: {
              handledErrorFields: Object.keys(state.form),
              unhandledErrorsKey: '_unhandled',
            }
          })
        })
      }
    }

    runInAction(() => {
      state.submitting = false
    })
  }

  const showForgotPassword = () => {
    // TODO: navigate to forgot password
  }

  return <SmartKeyboardAvoidingView style={styles.container}>
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1
      }}
      keyboardShouldPersistTaps="handled"
    >
      <View style={styles.main}>
        <MiniLogoSvg></MiniLogoSvg>
        <BoldText style={styles.headerText}>{t('Please enter your email or phone number', 'Please enter your email or phone number')}</BoldText>
        <View style={styles.formContainer}>
          <FormError field={'_unhandled'} errors={state.formErrors} displayAsList={true} containerStyle={{ marginBottom: 16 }} />
          <DefaultText style={styles.formNote}>{t('US phone numbers only', '*US phone numbers only')}</DefaultText>
          <StyledTextInput
            placeholder={t('Email or phone number', 'Email or phone number')}
            keyboardType={'email-address'}
            autoCapitalize={'none'}
            value={state.authMethod === 'phone' ? state.form.phoneNumber : state.form.emailOrPhone}
            maxLength={state.authMethod === 'phone' ? 14 : 100}
            onChangeText={t => { validateEmailOrPhone(t) }}
            editable={!state.submitting}
            style={{ minHeight: 56, borderColor: AppColors.blue.primary, borderWidth: 2, backgroundColor: AppColors.gray.neutral6, marginTop: 20 }}
          />
          <FormError field={'emailAddress'} errors={state.formErrors} />
          <FormError field={'phoneNumber'} errors={state.formErrors} />

          <StyledTextInput
            style={{ marginTop: 16, minHeight: 56, borderColor: AppColors.blue.primary, borderWidth: 2, backgroundColor: AppColors.gray.neutral6 }}
            placeholder={t('Password', 'Password')}
            secureTextEntry={state.hidePassword}
            value={state.form.password}
            onChangeText={t => runInAction(() => state.form.password = t)}
            editable={!state.submitting}
          >
          </StyledTextInput>
          {
            state.hidePassword
              ? <Pressable onPress={_changeIcon} style={styles.showPass}>
                <Svg width="20" height="19" viewBox="0 0 20 19" fill="none" onPress={_changeIcon}>
                  <Path d="M1.02538 1.32995L3.83756 4.14213C1.28934 6.09137 0.203046 8.74112 0.13198 8.9137L0 9.24873L0.13198 9.5736C0.243655 9.84772 2.8934 16.4061 10 16.4061C11.9391 16.4061 13.5431 15.9188 14.8629 15.198L18.5279 18.8629L19.868 17.533L2.36548 0L1.02538 1.32995ZM10 14.6294C4.8731 14.6294 2.50761 10.4772 1.92893 9.25888C2.28426 8.52792 3.28934 6.75127 5.11675 5.43147L7.32995 7.65482C7.1066 8.07107 6.97462 8.53807 6.97462 9.04568C6.97462 10.7107 8.32487 12.0711 10 12.0711C10.5076 12.0711 10.9746 11.9391 11.3909 11.7259L13.5228 13.8579C12.5279 14.3147 11.3604 14.6294 10 14.6294ZM20 9.24873L19.868 9.5736C19.8173 9.70558 19.2183 11.1675 17.8985 12.7005L16.6294 11.4315C17.3807 10.5381 17.8477 9.68528 18.0609 9.24873C17.4721 8.0203 15.0964 3.84772 9.98985 3.84772C9.68528 3.84772 9.38071 3.86802 9.09645 3.89848L7.56345 2.34518C8.31472 2.16244 9.11675 2.06091 10 2.06091C17.0863 2.06091 19.7563 8.63959 19.868 8.9137L20 9.24873Z" fill="#FF7A33" />
                </Svg>
              </Pressable>
              :
              <Pressable onPress={_changeIcon} style={styles.showPass}>
                <Svg width="20" height="15" viewBox="0 0 20 15" fill="none" onPress={_changeIcon} >
                  <Path d="M19.868 6.85279C19.7563 6.57868 17.0863 0 10 0C2.91371 0 0.233503 6.57868 0.13198 6.85279L0 7.17766L0.13198 7.51269C0.243655 7.7868 2.8934 14.3452 10 14.3452C17.1066 14.3452 19.7563 7.79695 19.868 7.51269L20 7.18782L19.868 6.85279ZM10 12.5584C4.8731 12.5584 2.50761 8.40609 1.92893 7.18782C2.51777 5.95939 4.8934 1.77665 10 1.77665C15.1066 1.77665 17.4822 5.94924 18.0711 7.17766C17.4822 8.38579 15.0863 12.5584 10 12.5584ZM12.3553 5.1066C12.7614 5.62437 13.0254 6.26396 13.0254 6.97462C13.0254 8.63959 11.6751 10 10 10C8.32487 10 6.97462 8.64975 6.97462 6.98477C6.97462 5.3198 8.32487 3.95939 10 3.95939C10.3553 3.95939 10.6904 4.03046 11.0051 4.14213C10.6802 4.29442 10.4467 4.61929 10.4467 5.00508C10.4467 5.54315 10.8832 5.96954 11.4112 5.96954C11.9086 5.9797 12.3046 5.59391 12.3553 5.1066Z" fill="#FF7A33" />
                </Svg>
              </Pressable>
          }
          <FormError field={'password'} errors={state.formErrors} containerStyle={{ position: 'relative', top: -25 }} />


          <BlockButton
            title={t('Log in', 'Log in')}
            variant={'secondary'}
            flex={1}
            disabled={!state.isValid || state.submitting}
            onPress={submit}
            textStyle={{ fontSize: 16 }}
            style={{ marginRight: 1, width: '100%', minHeight: 60, maxHeight: 60, borderRadius: 8, backgroundColor: (!state.isValid || state.submitting) ? '#9D9FA0' : '#FF7A33' }}
          />
          <View style={{ alignItems: 'center', marginBottom: 10 }}>
            <Text style={styles.forgotText} onPress={showForgot}>{t('Forgot Password', 'Forgot Password?')}</Text>
          </View>

        </View>

        {/* <View style={styles.footer}>
          <Text style={styles.loginText}>{t('Dont have an account', 'Don\'t have an account ')}
            <Text style={{ color: AppColors.blue.primary, fontFamily: 'Rubik_500Medium' }} onPress={showRegister}>{t('Request invitation', 'Request invitation')}</Text>
          </Text>
        </View> */}
      </View>
    </ScrollView>
  </SmartKeyboardAvoidingView>
})

//formatting phone number (xxx) xxx xxxx
const formatPhone = (input: string) => {
  const getinput = input.replace(/\s/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '')
  const getNumber = getinput.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 $3')
  return getNumber
}

//get phone number without formatting
const getNumber = (number: string) => {
  return number.replace(/\s/g, '').replace(/\(/g, '').replace(/\)/g, '')
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.white,
  },
  main: {
    flex: 1,
    alignItems: 'center',
    marginTop: windowHeight / 10,
  },
  headerText: {
    marginTop: 24,
    paddingHorizontal: 54,
    fontSize: 24,
    color: COLORS.blue.primary,
    textAlign: 'center',
  },
  formContainer: {
    marginTop: 24,
    paddingHorizontal: 16,
    width: '100%',
  },
  formNote: {
    textAlign: 'center',
    color: '#626262',
    marginTop: 4,
  },
  loginText: {
    color: AppColors.gray.neutral2,
    fontWeight: '400',
    fontSize: 16,
    marginBottom: 30,
    fontFamily: 'Rubik_400Regular'
  },
  forgotText: {
    color: AppColors.blue.primary,
    fontWeight: '500',
    fontFamily: 'Rubik_500Medium',
    fontSize: 16,
    marginTop: 25
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    marginTop: windowHeight * 0.2,
  },
  showPass: {
    position: 'relative',
    top: '-11.8%',
    left: '90%',
    height: '7%',
    width: '7%',
    justifyContent: 'center',
    alignItems: 'center'
  }
})
