import React from 'react'
import { Svg, Path } from "react-native-svg"

type Props = {
  width: number
  height: number
  color: string,
  variant: 'fill' | 'outline'
}

const Delete = (props: Props) => {
  return (
    <Svg width={props.width} height={props.height} fill="none" viewBox="0 0 16 20" >
      {props.variant === 'fill' ? (
        <Path
          d="M5.97 9.73C5.58 9.73 5.27 10.08 5.27 10.51V15.96C5.27 16.39 5.58 16.74 5.97 16.74C6.36 16.74 6.67 16.39 6.67 15.96V10.51C6.67 10.08 6.36 9.73 5.97 9.73ZM10.03 9.73C9.64 9.73 9.33 10.08 9.33 10.51V15.96C9.33 16.39 9.64 16.74 10.03 16.74C10.42 16.74 10.73 16.39 10.73 15.96V10.51C10.73 10.08 10.42 9.73 10.03 9.73ZM9.19 0C10.31 0 11.22 0.93 11.22 2.07V2.43H14.26C15.21 2.43 15.99 3.22 15.99 4.2V7.31C15.99 7.78 15.61 8.16 15.15 8.16H15.05L14.55 18.02C14.5 19.12 13.61 19.99 12.52 19.99H3.47C2.39 19.99 1.49 19.12 1.44 18.02L0.94 8.17H0.84C0.38 8.17 0 7.79 0 7.32V4.21C0 3.23 0.78 2.44 1.73 2.44H4.77V2.07C4.78 0.93 5.69 0 6.81 0H9.19ZM6.81 1.71H9.2C9.4 1.71 9.56 1.87 9.56 2.08V2.44H6.45V2.07C6.45 1.87 6.61 1.71 6.81 1.71Z"
          fill={props.color} />
      ) : (
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d="M5.97 9.73C5.58 9.73 5.27 10.08 5.27 10.51V15.96C5.27 16.39 5.58 16.74 5.97 16.74C6.36 16.74 6.67 16.39 6.67 15.96V10.51C6.67 10.08 6.36 9.73 5.97 9.73ZM10.03 9.73C9.64 9.73 9.33 10.08 9.33 10.51V15.96C9.33 16.39 9.64 16.74 10.03 16.74C10.42 16.74 10.73 16.39 10.73 15.96V10.51C10.73 10.08 10.42 9.73 10.03 9.73ZM9.19 0C10.31 0 11.22 0.93 11.22 2.07V2.43H14.26C15.21 2.43 15.99 3.22 15.99 4.2V7.31C15.99 7.78 15.61 8.16 15.15 8.16H15.05L14.55 18.02C14.5 19.12 13.61 19.99 12.52 19.99H3.47C2.39 19.99 1.49 19.12 1.44 18.02L0.94 8.17H0.84C0.38 8.17 0 7.79 0 7.32V4.21C0 3.23 0.78 2.44 1.73 2.44H4.77V2.07C4.78 0.93 5.69 0 6.81 0H9.19ZM13.39 8.17L12.89 17.94C12.88 18.14 12.72 18.29 12.53 18.29H3.47C3.28 18.29 3.12 18.14 3.11 17.94L2.61 8.17H13.39ZM6.81 1.71H9.2C9.4 1.71 9.56 1.87 9.56 2.08V2.44H6.45V2.07C6.45 1.87 6.61 1.71 6.81 1.71ZM1.67 4.21C1.67 4.18 1.7 4.15 1.73 4.15H14.27C14.3 4.15 14.33 4.18 14.33 4.21V6.47H1.67V4.21Z"
          fill={props.color} />
      )
      }
    </Svg>

  )
}

export default Delete


