import { StyleProp, TextProps, TextStyle, TouchableOpacity, ViewStyle } from 'react-native'
import React, { FC } from 'react'
import { BoldText } from './BoldText'
import { DefaultText } from './DefaultText'
import { AppColors as COLORS } from '../common/AppColors'

export type BlockButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'danger' | 'link' | 'selector'

type Props = {
  title: string
  variant?: BlockButtonVariant
  disabled?: boolean
  flex?: number
  onPress?: () => void
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
}

export const BlockButton = (props: Props) => {
  const buttonStyle: StyleProp<ViewStyle> = {
    backgroundColor: COLORS.orange.primary,
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: 8,
  }

  if (props.flex) {
    buttonStyle.flex = props.flex
  }

  const textStyle: StyleProp<TextStyle> = {
    color: COLORS.white,
    fontSize: 16,
  }

  let Component: FC<TextProps> = BoldText

  if (props.variant === 'tertiary') {
    buttonStyle.backgroundColor = COLORS.gray.neutral1
  } else if (props.variant === 'secondary') {
    buttonStyle.backgroundColor = COLORS.blue.primary
  } else if (props.variant === 'danger') {
    buttonStyle.backgroundColor = COLORS.red.secondary
  } else if (props.variant === 'link') {
    buttonStyle.backgroundColor = undefined
    textStyle.color = COLORS.blue.primary
    textStyle.fontSize = 16
    Component = DefaultText
  } else if (props.variant === 'selector') {
    buttonStyle.backgroundColor = undefined
    textStyle.fontSize = 14
    Component = DefaultText
  }

  if (props.disabled) {
    if (props.variant === 'secondary') {
      buttonStyle.backgroundColor = COLORS.blue.disabled
    } else if (props.variant !== 'link') {
      buttonStyle.backgroundColor = COLORS.gray.neutral3
    }
  }

  return <TouchableOpacity disabled={props.disabled} style={[buttonStyle, props.style]} onPress={props.onPress}>
    <Component style={[textStyle, props.textStyle]}>{props.title}</Component>
  </TouchableOpacity>
}
