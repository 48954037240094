import { StyleSheet, Switch, View, ViewStyle } from 'react-native'
import React from 'react'
import { DefaultText } from '../DefaultText'
import { ListItemSeparator } from '../ListItemSeparator'
import { FormError } from '../FormError'
import ErrorBag from '../../common/ErrorBag'
import { AppColors, AppColors as COLORS } from '../../common/AppColors'

type Props = {
  label: string
  value: boolean
  disabled?: boolean
  onChange?: (value: boolean) => void
  hideSeparator?: boolean
  errors?: ErrorBag
  fieldName?: string,
  style?: ViewStyle
}

export const FormSwitchInput = (props: Props) => {
  return <View>
    <View style={[styles.container, props.style]}>
      <DefaultText style={styles.label}>{props.label}</DefaultText>
      <Switch
        value={props.value}
        disabled={props.disabled}
        onValueChange={props.onChange}
        trackColor={{
          true: AppColors.blue.primary,
          false: AppColors.gray.neutral4
        }}
        thumbColor={'white'}
      />
    </View>
    {
      (props.errors && props.fieldName)
        ? <FormError field={props.fieldName} errors={props.errors} />
        : null
    }
    {
      !props.hideSeparator
        ? <ListItemSeparator />
        : null
    }
  </View>
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 50,
    paddingHorizontal: 32,
  },
  label: {
    flex: 1,
    fontSize: 16,
    fontFamily: 'Rubik_400Regular',
    color: COLORS.gray.neutral3,
  },
  labelhighlight: {
    color: COLORS.gray.neutral1,
  }
})
