import React, { useEffect, useState } from 'react'
import { StyleSheet, Text, TextInput, View, Dimensions, Platform } from 'react-native'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { StyledTextInput } from '../../components/StyledTextInput'
import { LogOutHeaderButton } from '../../components/LogOutHeaderButton'
import { runInAction } from 'mobx'
import { API } from '../../api/API';
import { handleErrorResponse } from '../../common/Util'
import ErrorBag from '../../common/ErrorBag'
import { FormError } from '../../components/FormError'
import { AppStateStore } from '../../contexts/AppStateStore'
import { BarCodeScannedCallback, BarCodeScanner } from 'expo-barcode-scanner'
import { BlockButton } from '../../components/BlockButton'
import { BottomDrawer } from '../../components/BottomDrawer'
import { useTranslation } from 'react-i18next'
import { getModalManager } from '../../contexts/ModalContext'
import { District } from '../../api/schema/GetSchoolDistrictsResponse'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';
import { AppColors } from '../../common/AppColors'
import { MiniLogoSvg } from '../../../assets/images/mini-logo'
//import * as Sentry from 'sentry-expo'

export type AddStudentQrCodeScreenParams = {
  district: District
}

export const AddStudentQrCodeScreen = observer((props: StackScreenProps<RootNavigationParamList, 'AddStudentQrCode'>) => {
  const { t } = useTranslation();

  const [canScan, setCanScan] = useState(true);

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: 'Add Student',
      headerStyle: { backgroundColor: '#fff', borderBottomWidth: 0 },
      headerTitleStyle: { color: '#FF7A33' }
    })
  }, [props.navigation])

  type State = {
    hasPermission?: boolean
    scanned: boolean
    showTextInput: boolean
    code: string
    isValid: boolean
    submitting: boolean
    errors: ErrorBag
    showDrawer: boolean
    studentName: string
    studentLastName: string
  }
  const state = useLocalObservable<State>(() => ({
    hasPermission: undefined,
    scanned: false,
    showTextInput: false,
    code: '',
    submitting: false,
    errors: new ErrorBag(),
    get isValid() {
      return this.showTextInput ? this.code.length > 0 : this.scanned
    },
    showDrawer: false,
    studentName: '',
    studentLastName: ''
  }))

  useEffect(() => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync()
      runInAction(() => state.hasPermission = status === 'granted')
    })();
  }, []);

  const handleBarCodeScanned: BarCodeScannedCallback = ({ type, data }) => {
    runInAction(() => state.scanned = true)
    runInAction(() => state.code = data);
    linkStudent(data, false).then();
  }

  const linkStudent = async (code: string, confirm: boolean) => {
    runInAction(() => state.submitting = true)

    try {

      if(code.length == 0) {
        getModalManager()
        .showModal({
          title: t('Error', 'Error'),
          message: t('Cannot read the code, please try again.', 'Cannot read the code, please try again'),
          buttons: [
            {
              text: 'Retry',
              variant: 'primary',
              onPress: (dismiss) => {
                runInAction(() => state.scanned = false);
                dismiss()
              }
            }
          ]
        })
        return;
      }
      state.errors.clearErrors()
      const response = await API.addStudent({ code: code, confirm: confirm, role: 'parent', districtId: props.route.params.district.id })
      //const response = await API.linkStudent({ code: code, confirm: confirm, role: 'parent', districtId: props.route.params.district.id })
      if (response.linked) {
        // successfully linked, clear the user context so that it is reloaded
        await AppStateStore.clearUserContext()
      } else if (!confirm) {
        runInAction(() => {
          state.studentName = response.student.firstName
          state.studentLastName = response.student.lastName
          state.showDrawer = true
        })
      } else {
        // did not link even though we requested it (this should not happen)
        runInAction(() => {
          state.showDrawer = false
        })
        getModalManager()
          .showModal({
            title: t('Error', 'Error'),
            message: t('There was a problem linking with the student', 'There was a problem linking with the student'),
          })
        runInAction(() => state.scanned = false)
      }
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      console.log(err);
      
      getModalManager()
          .showModal({
            title: t('Invalid QR code', 'Invalid QR code'),
            message: t(err.response.data.errors.code.join(), err.response.data.errors.code.join()),
            buttons: [
              {
                text: 'Retry',
                variant: 'primary',
                onPress: (dismiss) => {
                  runInAction(() => state.scanned = false);
                  dismiss()
                }
              }
            ]
          })
      handleErrorResponse(err.response, state.errors, {
        unhandledErrors: {
          unhandledErrorsKey: '_unhandled',
          handledErrorFields: ['code'],
        },
      })
    }

    runInAction(() => state.submitting = false)
  }

  const submit = (code: string) => {
    linkStudent(code, false).then()
  }

  const displayDrawer = (name: string, lastName: string) => {
    return state.showDrawer ?
      <BottomDrawer containerHeight={370}>
        {
          <View>
            <Text style={styles.headerText}>{t('Confirm link student', 'Are you sure you want to connect with the student {{name}}?', { name: `${name} ${lastName}` })}</Text>
          </View>
        }
      </BottomDrawer>
      :
      <View style={{ display: 'none' }}>
      </View>

  }

  return <View style={styles.container}>
    <View style={{ flex: 1, alignItems: 'center' }}>
      <MiniLogoSvg></MiniLogoSvg>
      {
        state.showTextInput
          ? <View style={{ width: '95%' }}>
            <FormError field={'_unhandled'} errors={state.errors} displayAsList={true} />
            <Text style={styles.headerText}>{t('Enter the code provided by your school', 'Enter the code provided by your school')}</Text>
            <StyledTextInput
              style={{ marginTop: 16, minHeight: 56, borderColor: AppColors.blue.primary, borderWidth: 2, backgroundColor: AppColors.gray.neutral6 }}
              placeholder={t('Enter code', 'Enter code')}
              value={state.code}
              onChange={ev => runInAction(() => state.code = ev.nativeEvent.text)}
              editable={!state.submitting || !state.showDrawer}
            >
            </StyledTextInput>
            <FormError field="code" errors={state.errors} />
            <BlockButton
              title={t('Continue', 'Continue')}
              variant={'primary'}
              disabled={!state.isValid || state.submitting || state.showDrawer}
              onPress={() => submit(state.code)}
              textStyle={{ fontSize: 16 }}
              style={{ width: 500, maxWidth: '100%', minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 20, backgroundColor: (!state.isValid || state.submitting || state.showDrawer) ? '#9D9FA0' : '#FF7A33' }}
            />
            {/* <BlockButton
            title={t('Continue', 'Continue')}
            onPress={() => submit(state.code)}
            disabled={!state.isValid || state.submitting}
            style={styles.button}
          /> */}
          </View>
          : <>
            <Text style={styles.headerText}>{t('Scan your QR code provided by your school', 'Scan your QR code provided by your school')}</Text>
            <View style={styles.scannerContainer}>
              {
                state.hasPermission
                  ? <BarCodeScanner
                    onBarCodeScanned={(state.scanned) ? undefined : handleBarCodeScanned}
                    style={StyleSheet.absoluteFillObject}
                    type={'back'}
                    />
                  : state.hasPermission === undefined
                    ? <Text style={styles.permissionText}>{t('Requesting camera permission', 'Requesting camera permission')}</Text>
                    : <Text style={styles.permissionText}>{t('No access to camera', 'No access to camera')}</Text>
              }
            </View>
          </>
      }
      <View style={styles.textCodeButtonContainer}>
        <BlockButton
          variant="link"
          textStyle={{ fontSize: 18, fontWeight: '500', color: '#0082CD' }}
          title={state.showTextInput ? t('Or scan QR code', 'Or scan QR code') : t('Or enter text code', 'Or enter text code')}
          onPress={() => runInAction(() => state.showTextInput = !state.showTextInput)}
          disabled={state.submitting || state.showDrawer}
        />
      </View>
    </View>
    {
      state.showDrawer ?
        <BottomDrawer height={370} radius={30} backgrounColor={'#fff'}
          outsideTouch={() => runInAction(() => state.showDrawer = false)}>
          <Text style={styles.drawerHeaderText}>{t('Confirm', 'Confirm')}</Text>
          <Text style={styles.subText}>{t('Confirm link student', 'Are you sure you want to connect with the student {{name}}?', { name: `${state.studentName} ${state.studentLastName}` })}</Text>
          <BlockButton
            title={t('Yes continue', 'Yes, continue')}
            variant={'primary'}
            onPress={() => {
              runInAction(() => state.showDrawer = false)
              linkStudent(state.code, true)
            }}
            textStyle={{ fontSize: 16 }}
            style={{ marginHorizontal: 25, minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 20, backgroundColor: '#FF7A33' }}
          />
          <BlockButton
            title={t('Cancel', 'Cancel')}
            variant={'secondary'}
            onPress={() => {
              runInAction(() => state.showDrawer = false)
              runInAction(() => state.scanned = false);
            }}
            textStyle={{ fontSize: 16 }}
            style={{ marginHorizontal: 25, minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 20, backgroundColor: '#0082CD' }}
          />
        </BottomDrawer>
        :
        null
    }

  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingHorizontal: 10
  },

  scannerContainer: {
    width: 300,
    height: 300,
    backgroundColor: '#000',
    marginVertical: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },

  headerText: {
    paddingTop: 30,
    fontSize: 24,
    color: '#0082CD',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  drawerHeaderText: {
    paddingTop: 30,
    fontSize: 24,
    color: '#0082CD',
    textAlign: 'center',
    fontWeight: '500',
  },
  subText: {
    paddingTop: 15,
    paddingHorizontal: 50,
    fontSize: 16,
    color: '#303030',
    textAlign: 'center',
    fontWeight: '400',
  },

  permissionText: {
    color: '#fff',
    padding: 10,
  },

  button: {
    marginTop: 20,
  },

  textCodeButtonContainer: {
    flex: 4,
    marginTop: 20,
    fontWeight: '500',
    fontSize: 18
  },

  input: {
    borderWidth: 1,
    borderColor: '#aaa',
    borderRadius: 8,
    padding: 12,
    fontSize: 15,
    marginTop: 20,
  },
})