import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useCallback, useEffect } from 'react'
import { StyleSheet, TouchableOpacity, View, Platform } from 'react-native'
import { DefaultText } from '../DefaultText'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { Colors } from '../../common/Colors'
import { runInAction } from 'mobx'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Event } from '../../api/schema/models/Event'
import { API } from '../../api/API'
import { MYSQL_DATE_TIME_FORMAT } from '../../common/Util'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { AppColors } from '../../common/AppColors'
//import * as Sentry from 'sentry-expo'

type Props = {
  onDateSelected: (date: Moment) => void
  selectedDate: Moment
}

export const WeeklyCalendar = observer((props: Props) => {
  const state = useLocalObservable(() => ({
    date: props.selectedDate.clone().startOf('week'),
    loadedEventsKey: undefined as string | undefined,
    events: [] as Event[],
  }))

  const dayLetters: string[] = [
    'S',
    'M',
    'T',
    'W',
    'T',
    'F',
    'S',
  ]

  const loadEvents = useCallback(async (date: Moment) => {
    const startDate = date.clone().startOf('week')
    const endDate = date.clone().endOf('week')

    const key = `${startDate.unix()}:${endDate.unix()}`

    if (state.loadedEventsKey !== key) {
      runInAction(() => state.events = [])
      try {
        const response = await API.getEvents(startDate, endDate)

        runInAction(() => {
          state.events = response.events
          state.loadedEventsKey = key
        })
      } catch (err) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(err)
        }
        else {
          //Sentry.Native.captureException(err)
        }
        console.log(err)
      }
    }
  }, [])

  useEffect(() => {
    runInAction(() => state.date = props.selectedDate.clone().startOf('week'))
  }, [props.selectedDate])

  useEffect(() => {
    loadEvents(state.date).then()
  }, [loadEvents, state.date])

  useEffect(() => {
    const listeners = [
      AppStateStore.eventBus.addRemovableListener(AppEvents.EventCreated, () => {
        loadEvents(state.date).then()
      }),
      AppStateStore.eventBus.addRemovableListener(AppEvents.EventDeleted, () => {
        loadEvents(state.date).then()
      }),
    ]

    return () => {
      listeners.forEach(l => l.remove())
    }
  }, [])

  const renderDay = (day: number) => {
    const isWeekend = (day === 0 || day === 6)

    const thisDate = state.date.clone().add(day, 'days')
    const dayNumber = thisDate.date()

    const isSelectedDate = props.selectedDate.isSame(thisDate, 'date')

    const hasEvents: boolean = !!state.events.find(e => moment(e.startsAt, MYSQL_DATE_TIME_FORMAT).isSameOrBefore(thisDate.clone().endOf('day')) && moment(e.endsAt, MYSQL_DATE_TIME_FORMAT).isSameOrAfter(thisDate.clone().startOf('day')))

    return <TouchableOpacity onPress={() => selectDate(thisDate)} key={String(day)} style={styles.dayContainer}>
      <DefaultText style={[styles.dayLetterText, isWeekend ? styles.dayLetterWeekendText : undefined]}>{dayLetters[day]}</DefaultText>
      <View style={styles.dayNumberContainer}>
        {
          isSelectedDate
            ? <View style={styles.selectedDateIndicator}/>
            : null
        }
        <DefaultText style={[styles.dayNumberText, isWeekend ? styles.dayNumberWeekendText : undefined, isSelectedDate ? styles.dayNumberHighlightedText : undefined]}>{dayNumber}</DefaultText>
      </View>
      <View style={[styles.hasEventsIndicator, hasEvents ? styles.hasEventsIndicatorActive : undefined]}/>
    </TouchableOpacity>
  }

  const renderWeek = () => {
    return <View style={styles.weekContainer}>
      {
        _.range(0, 7).map(day => renderDay(day))
      }
    </View>
  }

  const selectDate = (date: Moment) => {
    props.onDateSelected(date.clone())
  }

  const prevWeek = () => runInAction(() => {
    selectDate(props.selectedDate.clone().subtract(1, 'week'))
  })

  const nextWeek = () => runInAction(() => {
    selectDate(props.selectedDate.clone().add(1, 'week'))
  })

  return <View style={{overflow:'hidden', paddingBottom:30}}>
    <View style={styles.wrapper}>
    <View style={styles.content}>
      <View style={styles.header}>
        <View style={styles.weekNavContainer}>
          <TouchableOpacity style={styles.weekNav} onPress={prevWeek}>
            <MaterialCommunityIcons
              style={styles.weekNavIcon}
              name={'chevron-left'}
              color={'#FF7A33'}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.headerWrapper}>
          {renderWeek()}
          <View style={styles.selectedDateContainer}>
            <DefaultText style={styles.selectedDateText}>{props.selectedDate.format('ddd, MMMM D, YYYY')}</DefaultText>
          </View>
        </View>
        <View style={styles.weekNavContainer}>
          <TouchableOpacity style={styles.weekNav} onPress={nextWeek}>
            <MaterialCommunityIcons
              style={styles.weekNavIcon}
              name={'chevron-right'}
              color={'#FF7A33'}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  </View>
  </View>
  
})

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: '#fff', 
    borderBottomLeftRadius: 20, 
    borderBottomRightRadius: 20,
    elevation: 20, 
    shadowRadius: 20, 
    shadowColor: '#F55600', 
    shadowOffset: {width: 0, height: 0}, 
    shadowOpacity: 0.2
  },
  content: {},
  header: {
    borderBottomWidth: 0,
    borderBottomColor: '#999',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerWrapper: {
    flex: 1,
  },
  weekNavContainer: {},
  weekNav: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingBottom:15
  },
  weekNavIcon: {
    fontSize: 30,
  },
  weekContainer: {
    flexDirection: 'row',
    paddingTop: 12,
  },
  dayContainer: {
    flex: 1,
    alignItems: 'center',
  },
  dayLetterText: {
    color: '#333',
    fontSize: 14,
  },
  dayLetterWeekendText: {
    color: '#999',
  },
  dayNumberContainer: {
    height: 36,
    width: 36,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 5
  },
  dayNumberText: {
    color: '#333',
    fontSize: 16,
    textAlign: 'center',
  },
  dayNumberWeekendText: {
    color: '#999',
  },
  dayNumberHighlightedText: {
    color: '#fff',
  },
  selectedDateIndicator: {
    position: 'absolute',
    width: 36,
    height: 36,
    left: 0,
    top: 0,
    borderRadius: 7,
    backgroundColor: AppColors.blue.primary,
    zIndex: -1,
  },
  selectedDateContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 4,
    marginBottom: 8,
  },
  selectedDateText: {
    fontSize: 14,
    color: '#666',
  },
  hasEventsIndicator: {
    width: 6,
    height: 6,
    borderRadius: 3,
    marginTop: 4,
  },
  hasEventsIndicatorActive: {
    backgroundColor: '#000',
  },
})
