import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { DefaultText } from './DefaultText'
import * as Updates from 'expo-updates'

export const UpdateNotification = () => {
  return <TouchableOpacity onPress={() => {
    Updates.reloadAsync().then()
  }}>
    <View style={{ padding: 20, backgroundColor: '#eee' }}>
      <DefaultText style={{ textAlign: 'center' }}>A new version of Konekti is available! Tap here to install the latest update.</DefaultText>
    </View>
  </TouchableOpacity>
}
