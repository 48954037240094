import React from 'react'
import { ActivityIndicator, Image, StyleSheet, View } from 'react-native'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';
import { OwlieColor } from '../../assets/images/owlie-color';
import Owl from '../../assets/svg/konekti-splash.svg'
import { GestureHandlerRootView } from 'react-native-gesture-handler';

export const SuspenseScreen = () => {
  return <View style={styles.container}>
    <OwlieColor></OwlieColor>
    <ActivityIndicator
      size={'large'}
      color={'#fff'}
      animating={true}
    />
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FF7A33'
  },
  image: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  logo: {
    width: 200,
    height: 200,
    maxWidth: '50%',
    maxHeight: '50%',
    resizeMode: 'contain',
    marginBottom: 20,
  },
})
