import AudioIcon from './AudioIcon'
import AudioPauseIcon from './AudioPauseIcon'
import AudioPlayIcon from './AudioPlayIcon'
import CameraIcon from './CameraIcon'
import FileIcon from './FileIcon'
import ImageIcon from './ImageIcon'
import SendIcon from './SendIcon'
import VideoIcon from './VideoIcon'
import TrashIcon from "./TrashIcon"
import Forward from "./ForwardIcon"
import Search from "./SearchIcon"
import Download from "./DownloadIcon"
import Delete from "./DeleteIcon"
import EmptyDocument from "./EmptyDocumentIcon"
import EmptyMessages from './EmptyMessagesIcon'
import Back from "./BackIcon"
import CheckBoxIcon from "./CheckBoxIcon"
import CandyBox from "./CandyBoxIcon"
import Attendance from "./AttendanceIcon"
import Alert from "./AlertIcon"
import Hamburger from "./HamburgerIcon"
import Home from "./HomeIcon"
import Message from "./MessageIcon"
import Calendar from "./CalendarIcon"
import Files from "./FilesIcon"
import Forms from "./FormsIcon"
import Profile from "./ProfileIcon"
import Settings from "./SettingsIcon"
import LogOut from "./LogOutIcon"
import Translate from "./Translate"
import Pdf from './PdfIcon'
import Doc from './DocIcon'
import Attach from './AttachIcon'
import PlaceHolder from './PlaceHolderIcon'
import { LocationIcon } from './LocationIcon'
import Info from './InfoIcon'
import Edit from './Edit'

export interface IconProps {
  color?: string
  height?: number
  width?: number
  type?: 'nonzero' | 'evenodd'
}

const Icons = {
  AudioIcon,
  AudioPauseIcon,
  AudioPlayIcon,
  CameraIcon,
  FileIcon,
  ImageIcon,
  SendIcon,
  TrashIcon,
  VideoIcon,
  Forward,
  Search,
  Download,
  Delete,
  EmptyDocument,
  EmptyMessages,
  Back,
  CheckBoxIcon,
  CandyBox,
  Attendance,
  Alert,
  Hamburger,
  Home,
  Message,
  Calendar,
  Files,
  Forms,
  Profile,
  Settings,
  LogOut,
  Translate,
  Pdf,
  Doc,
  Attach,
  PlaceHolder,
  LocationIcon,
  Info,
  Edit
}

export default Icons