import React from "react";
import { StyleSheet, TextInput, View, Platform } from "react-native";
import ErrorBag from "../../../common/ErrorBag";
import { Attachment, AttachmentBar } from "../../../components/AttachmentBar";
import { FormError } from "../../../components/FormError";

export interface NormalPostAdition {
    attachment: Attachment | undefined,
    errors: ErrorBag,
    setMessage: (message: string) => void,
    setAttachment: (attachment: Attachment | undefined) => void
}

export default (props: NormalPostAdition) => {
    return (
        <>
            <View style={styles.message}>
                <TextInput
                    multiline={true}
                    style={[styles.messageInput, Platform.OS === 'web' && { outlineStyle: 'none' }]}
                    onChangeText={(text) => props.setMessage(text)}
                    placeholder="Message (required)">

                </TextInput>
                <FormError field={'message'} errors={props.errors} />
            </View>

            <AttachmentBar
                onAttached={attachment => props.setAttachment(attachment)}
                attachment={props.attachment}
            />


        </>
    );
}

const styles = StyleSheet.create({
    message: {
        backgroundColor: '#F6F7FA',
        width: '100%',
        height: 180,
        borderRadius: 6,
        justifyContent: 'flex-start',
        padding: 10,
        marginTop: 15
    },
    messageInput: {
        fontSize: 15,
        height: '100%',
        textAlignVertical: 'top'
    }
})