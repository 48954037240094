import React, { useCallback, useEffect } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { Platform, Pressable, ScrollView, StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import * as DocumentPicker from 'expo-document-picker'
import { SmartKeyboardAvoidingView } from '../../components/SmartKeyboardAvoidingView'
import { BlockButton } from '../../components/BlockButton'
import { FormError } from '../../components/FormError'
import ErrorBag from '../../common/ErrorBag'
import { runInAction } from 'mobx'
import { VisibilityOption } from '../../api/schema/models/VisibilityOption'
import { BoldText } from '../../components/BoldText'
import { DefaultText } from '../../components/DefaultText'
import { API } from '../../api/API'
import { handleErrorResponse } from '../../common/Util'
import { SafeAreaView } from 'react-native-safe-area-context'
import { FormSelectInput } from '../../components/forms/FormSelectInput'
import { PickerOption } from '../PickerScreen'
import { DocumentCategory } from '../../api/schema/models/DocumentCategory'
import { FormTextInput } from '../../components/forms/FormTextInput'
import { StackScreenProps } from '@react-navigation/stack'
import { FileData } from '../../api/methods/upload-file'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import BackHeaderButton from '../../components/BackHeaderButton'
import { AppColors, AppColors as COLORS } from '../../common/AppColors'
import Icons from '../../components/icons'
//import * as Sentry from 'sentry-expo'

export const CreateDocumentScreen = observer((props: StackScreenProps<RootNavigationParamList, 'CreateDocument'>) => {
  const { t } = useTranslation()

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Create Document', 'Create Document'),
    })
  }, [props.navigation])

  type SelectedDocument = {
    uri: string
    name: string
  }

  const getVisibiltyText = (text: string) => {
    
      return Platform.OS === 'web'
        ? text.slice(0, 69) + (text.length > 69 ? '...' : '')
        : text.slice(0, 15) + (text.length > 15 ? '...' : '')

  }

  const state = useLocalObservable(() => ({
    submitting: false,
    documentCategories: [] as DocumentCategory[],
    accessLevels: [] as string[],
    selectedCategory: undefined as DocumentCategory | undefined,
    selectedDocument: undefined as SelectedDocument | undefined,
    visibility: undefined as VisibilityOption | undefined,
    form: {
      title: '',
      access: undefined as string | undefined,
    },

    get visibilityLabel() {
      if (this.visibility?.type === 'district') {
        return <>
          <BoldText style={{ color: COLORS.gray.neutral2, marginRight: 12 }}>{t('District', 'District')}</BoldText>
          <DefaultText style={{ marginRight: 12 }}>{getVisibiltyText(this.visibility.name)}</DefaultText>
        </>
      } else if (this.visibility?.type === 'school') {
        return <>
          <BoldText style={{ color: COLORS.gray.neutral2, marginRight: 12 }}>{t('School', 'School')}</BoldText>
          <DefaultText style={{ marginRight: 12 }}>{getVisibiltyText(this.visibility.name)}</DefaultText>
        </>
      } else if (this.visibility?.type === 'section') {
        return <>
          <BoldText style={{ color: COLORS.gray.neutral2, marginRight: 12 }}>{t('Class', 'Class')}</BoldText>
          <DefaultText style={{ marginRight: 12 }}>{this.visibility.name}</DefaultText>
          <DefaultText style={{ color: COLORS.gray.neutral2, marginRight: 12 }}> {getVisibiltyText(this.visibility.schoolName)}</DefaultText>
        </>
      } else {
        return undefined
      }
    },

    get isValid() {
      return state.selectedDocument && state.form.title.length > 0 && state.visibility && state.selectedCategory && state.form.access
    },

    formErrors: new ErrorBag(),
  }))

  const chooseDocument = async () => {
    const doc = await DocumentPicker.getDocumentAsync({
      type: 'application/pdf',
      copyToCacheDirectory: false,
    })

    if (doc.type === 'success') {
      runInAction(() => {
        state.selectedDocument = {
          uri: doc.uri,
          name: doc.name,
        }

        if (!state.form.title) {
          state.form.title = doc.name
        }
      })
    }
  }

  const setVisibility = (option: VisibilityOption, pop: () => void) => {
    runInAction(() => state.visibility = option)
    pop()
  }

  const chooseVisibility = async () => {
    props.navigation.push('VisibilityPicker', { title: t('Choose Visibility', 'Choose Visibility'), onChoose: setVisibility })
  }

  const submit = async () => {
    if (state.selectedDocument) {
      runInAction(() => {
        state.submitting = true
        state.formErrors.clearErrors()
      })

      try {
        const uploadData = await API.requestUpload()

        let file: FileData = {
          uri: state.selectedDocument.uri,
          name: state.selectedDocument.name,
          type: 'application/pdf',
        }

        if (state.selectedDocument.uri.startsWith('data:')) {
          const response = await fetch(state.selectedDocument.uri)
          const binary = await response.blob()
          file = new Blob([binary], {
            type: 'application/pdf',
          })
        }

        await API.uploadFile(
          uploadData.url,
          uploadData.fields,
          file,
        )

        await API.createDocument({
          title: state.form.title,
          access: state.form.access! as any,
          categoryId: state.selectedCategory!.id,
          visibility: state.visibility!,
          file: {
            key: uploadData.key,
            filename: state.selectedDocument.name,
            mimeType: file.type,
          }
        })

        AppStateStore.eventBus.emit(AppEvents.DocumentCreated)

        props.navigation.pop()
      } catch (err) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(err)
        }
        else {
          //Sentry.Native.captureException(err)
        }
        runInAction(() => {
          handleErrorResponse(err.response, state.formErrors, {
            unhandledErrors: {
              handledErrorFields: ['title', 'file', 'visibility'],
              unhandledErrorsKey: '_unhandled',
            }
          })
        })
      }

      runInAction(() => state.submitting = false)
    }
  }

  const showCategoryPicker = () => {
    props.navigation.push('Picker', {
      title: t('Choose Category', 'Choose Category'),
      options: state.documentCategories.map(c => ({
        value: String(c.id),
        text: c.name,
      })),
      onChoose: (category: PickerOption, pop) => {
        runInAction(() => state.selectedCategory = state.documentCategories.find(c => String(c.id) === category.value))
        pop()
      },
    })
  }

  const showAccessPicker = () => {
    props.navigation.push('Picker', {
      title: t('Choose Access', 'Choose Access'),
      options: state.accessLevels.map(l => ({
        value: l,
        text: l,
      })),
      onChoose: (category: PickerOption, pop) => {
        runInAction(() => state.form.access = category.value)
        pop()
      },
    })
  }

  const loadDocumentCategories = useCallback(async () => {
    try {
      const categories = (await API.getDocumentCategories()).categories
      const accessLevels = (await API.getDocumentAccessLevels()).accessLevels

      runInAction(() => {
        state.documentCategories = categories
        state.accessLevels = accessLevels
      })
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      console.log(err)
    }
  }, [state])

  useEffect(() => {
    loadDocumentCategories().then()
  }, [loadDocumentCategories])

  return <SmartKeyboardAvoidingView style={styles.container}>
    <View style={styles.main}>
      <View style={styles.formContainer}>

        <ScrollView
          contentContainerStyle={{
            flexGrow: 1
          }}
          keyboardShouldPersistTaps="handled"
        >
          <FormError field={'_unhandled'} errors={state.formErrors} displayAsList={true} containerStyle={{ marginBottom: 16 }} />
          <FormSelectInput
            label={t('Visibility', 'Visibility')}
            value={state.visibilityLabel}
            onPress={chooseVisibility}
          />
          <FormSelectInput
            label={t('Access', 'Access')}
            value={state.form.access}
            onPress={showAccessPicker}
          />
          <FormSelectInput
            label={t('Category', 'Category')}
            value={state.selectedCategory?.name}
            onPress={showCategoryPicker}
            style={{ marginBottom: 8 }}
          />
          <FormTextInput
            placeholder={t('Title', 'Title')}
            value={state.form.title}
            onChangeText={t => runInAction(() => state.form.title = t)}
            editable={!state.submitting}
            style={{ marginBottom: 8 }}
          />
          <FormSelectInput
            label={t('Document', 'Document')}
            value={state.selectedDocument ? state.selectedDocument.name : undefined}
            onPress={chooseDocument}
          />

        </ScrollView>
      </View>
    </View>
    <View style={styles.footer}>
      <BlockButton
        title={t('Save', 'Save')}
        variant={'secondary'}
        disabled={!state.isValid || state.submitting}
        onPress={submit}
        style={{ marginBottom: 60 }}
      />
    </View>
  </SmartKeyboardAvoidingView>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  main: {
    flex: 1,
    paddingHorizontal: 16,

  },
  formInput: {},
  footer: {
    paddingHorizontal: 16,

  },
  formContainer: {},
})