import React from 'react'
import ErrorBag from '../common/ErrorBag'
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'
import { observer } from 'mobx-react-lite'
import { AppColors } from '../common/AppColors'

type Props = {
  field: string
  errors: ErrorBag
  containerStyle?: StyleProp<ViewStyle>
  displayAsList?: boolean
}

export const FormError = observer(({ field, errors, containerStyle, displayAsList }: Props) => {
  if (errors.hasErrors(field)) {
    return <View style={containerStyle}>
      {
        displayAsList
          ? errors.getErrors(field).map((error, idx) => <Text key={idx} style={styles.error}>{error}</Text>)
          : <Text style={styles.error}>{errors.getErrors(field).join(', ')}</Text>
      }
    </View>
  } else {
    return null
  }
})

const styles = StyleSheet.create({
  error: {
    color: AppColors.red.secondary,
    textAlign: 'center',
    marginTop: 16,
    fontFamily: 'Rubik_500Medium',
    fontSize: 14,
    fontWeight: "500"
  }
})
