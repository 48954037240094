import React, { useCallback, useEffect } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { ScrollView, StyleSheet, View, Platform, KeyboardAvoidingView, TextInput } from 'react-native'
import { useTranslation } from 'react-i18next'
import * as DocumentPicker from 'expo-document-picker'
import { BlockButton } from '../../components/BlockButton'
import { FormError } from '../../components/FormError'
import ErrorBag from '../../common/ErrorBag'
import { runInAction } from 'mobx'
import { API } from '../../api/API'
import { handleErrorResponse } from '../../common/Util'
import { FormSelectInput } from '../../components/forms/FormSelectInput'
import { PickerOption } from '../PickerScreen'
import { FormTextInput } from '../../components/forms/FormTextInput'
import { StackScreenProps } from '@react-navigation/stack'
import { FileData } from '../../api/methods/upload-file'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { FormCategory } from '../../api/schema/models/FormCategory'
import { AppColors as COLORS } from '../../common/AppColors'
//import * as Sentry from 'sentry-expo'
import { SafeAreaView } from 'react-native-safe-area-context'

export const CreateFormScreen = observer((props: StackScreenProps<RootNavigationParamList, 'CreateForm'>) => {
  const { t } = useTranslation()

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('New E-signature', 'New E-signature'),
    })
  }, [props.navigation])

  type SelectedForm = {
    uri: string
    name: string
  }

  const state = useLocalObservable(() => ({
    submitting: false,
    formCategories: [] as FormCategory[],
    selectedCategory: undefined as FormCategory | undefined,
    selectedForm: undefined as SelectedForm | undefined,
    form: {
      title: '',
      isSignatureRequired: false,
      message: '',
    },

    get isValid(): boolean {
      return !!state.selectedForm && state.form.title.length > 0 && !!state.selectedCategory
    },

    formErrors: new ErrorBag(),
  }))

  const chooseForm = async () => {
    const doc = await DocumentPicker.getDocumentAsync({
      type: 'application/pdf',
      copyToCacheDirectory: false,
    })

    if (doc.type === 'success') {
      runInAction(() => {
        state.selectedForm = {
          uri: doc.uri,
          name: doc.name,
        }

        if (!state.form.title) {
          state.form.title = doc.name
        }
      })
    }
  }

  const submit = async () => {
    if (state.selectedForm) {
      runInAction(() => {
        state.submitting = true
        state.formErrors.clearErrors()
      })

      try {
        const uploadData = await API.requestUpload()

        let file: FileData = {
          uri: state.selectedForm.uri,
          name: state.selectedForm.name,
          type: 'application/pdf',
        }

        if (state.selectedForm.uri.startsWith('data:')) {
          const response = await fetch(state.selectedForm.uri)
          const binary = await response.blob()
          file = new Blob([binary], {
            type: 'application/pdf',
          })
        }

        await API.uploadFile(
          uploadData.url,
          uploadData.fields,
          file,
        )

        await API.createForm({
          title: state.form.title,
          categoryId: state.selectedCategory!.id,
          message: state.form.message,
          file: {
            key: uploadData.key,
            filename: state.selectedForm.name,
            mimeType: file.type,
          }
        })

        AppStateStore.eventBus.emit(AppEvents.FormCreated)

        props.navigation.pop()
      } catch (err: any) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(err)
        }
        else {
          //Sentry.Native.captureException(err)
        }
        runInAction(() => {
          handleErrorResponse(err.response, state.formErrors, {
            unhandledErrors: {
              handledErrorFields: ['categoryId', 'title', 'file', 'isSignatureRequired', 'fields'],
              unhandledErrorsKey: '_unhandled',
            }
          })
        })
      }

      runInAction(() => state.submitting = false)
    }
  }

  const showCategoryPicker = () => {
    props.navigation.push('Picker', {
      title: t('Choose Category', 'Choose Category'),
      options: state.formCategories.map(c => ({
        value: String(c.id),
        text: c.name,
      })),
      onChoose: (category: PickerOption, pop) => {
        runInAction(() => state.selectedCategory = state.formCategories.find(c => String(c.id) === category.value))
        pop()
      },
    })
  }

  const loadFormCategories = useCallback(async () => {
    try {
      const categories = (await API.getFormCategories()).categories

      runInAction(() => {
        state.formCategories = categories
      })
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      console.log(err)
    }
  }, [state])

  useEffect(() => {
    loadFormCategories().then()
  }, [loadFormCategories])


  return <View style={{ flex: 1 }}>
    <KeyboardAvoidingView
      {...(Platform.OS === "ios" ? { behavior: "padding" } : {})}
      style={{ flex: 1 }}
      enabled
      contentContainerStyle={{ flex: 1 }}
    >
      {/*  <ScrollView
        contentContainerStyle={{
          flexGrow: 1
        }}
        keyboardShouldPersistTaps="handled"
        style={styles.container}> */}
      <ScrollView
        nestedScrollEnabled={true}
        //keyboardShouldPersistTaps="handled"
        style={[styles.container]}>

        <FormError field={'_unhandled'} errors={state.formErrors} displayAsList={true} containerStyle={{ marginBottom: 16 }} />
        <FormTextInput
          placeholder={t('Title', 'Title')}
          value={state.form.title}
          onChangeText={t => runInAction(() => state.form.title = t)}
          editable={!state.submitting}
          fieldName={'title'}
          errors={state.formErrors}
          style={{ marginBottom: 8 }}
        />
        <FormSelectInput
          label={t('Category', 'Category')}
          value={state.selectedCategory?.name}
          onPress={showCategoryPicker}
          fieldName={'categoryId'}
          errors={state.formErrors}
          style={{ marginBottom: 8 }}
        />
        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 8, height: 150 }}>
          <TextInput
            multiline={true}
            style={styles.messageInput}
            placeholder={t('Message', 'Message')}
            value={state.form.message}
            onChangeText={t => runInAction(() => state.form.message = t)}
            editable={!state.submitting}
          />
          <FormError field={'message'} errors={state.formErrors} />
        </View>

        {/*   <FormTextInput
          placeholder={t('Message', 'Message')}
          value={state.form.title}
          onChangeText={t => runInAction(() => state.form.title = t)}
          editable={!state.submitting}
          fieldName={'message'}
          errors={state.formErrors}
          style={{ marginBottom: 8 }}
          multiline={true}
          numberOfLines={10}
        /> */}
        <FormSelectInput
          label={t('File', 'File')}
          value={state.selectedForm ? state.selectedForm.name : undefined}
          onPress={chooseForm}
          fieldName={'file'}
          errors={state.formErrors}
        />
        <FormError
          field={'fields'}
          errors={state.formErrors}
        />
      </ScrollView>

      <View style={styles.footer}>
        <SafeAreaView edges={['bottom']} style={{ marginBottom: 16 }}>
          <BlockButton
            title={t('Save', 'Save')}
            variant={'secondary'}
            disabled={!state.isValid || state.submitting}
            onPress={submit}
          />
        </SafeAreaView>

      </View>

    </KeyboardAvoidingView>
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: COLORS.white,
    marginTop: 16,
  },
  footer: {
    paddingHorizontal: 16,
  },
  messageInput: {
    height: '100%',
    textAlignVertical: 'top',

    minHeight: 56,
    alignItems: 'center',
    fontSize: 18,
    color: COLORS.gray.neutral1,
    paddingHorizontal: 10,
    flex: 1,
    backgroundColor: COLORS.gray.neutral6,
    justifyContent: 'center',
    borderRadius: 6,
    paddingVertical: 12,
  }
})
