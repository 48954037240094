import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { ActivityIndicator, ScrollView, StyleSheet, Text, TouchableOpacity, Platform, View } from 'react-native'
import { API } from '../../api/API'
import { AVPlaybackStatus, Video as VideoPlayer, ResizeMode } from 'expo-av'
import { runInAction } from 'mobx'
import { AppStateStore } from '../../contexts/AppStateStore'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Video } from '../../api/schema/models/Video'
import { useTranslation } from 'react-i18next'
import { extractErrorMessage } from '../../common/Util'
import { ContentActivityIndicator } from '../../components/ContentActivityIndicator'
import { ErrorMessage } from '../../components/ErrorMessage'
import { getModalManager } from '../../contexts/ModalContext'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { KonektiIcon } from '../../components/KonektiIcon'
//import * as Sentry from 'sentry-expo'

export type VideoPlayerScreenParams = {
  uuid: string
}

export const VideoPlayerScreen = observer((props: StackScreenProps<RootNavigationParamList, 'VideoPlayer'>) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    video: undefined as Video | undefined,
    error: undefined as string | undefined,
    showTranslation: false,
    translatedTranscript: undefined as string | undefined,
    isLoadingTranscript: false,
  }))

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Video', 'Video'),
    })
  }, [props.navigation])

  useEffect(() => {
    loadVideo().then()
  }, [])

  const loadVideo = async () => {
    try {
      runInAction(() => state.error = undefined)

      const video = await API.getVideo(props.route.params.uuid)

      runInAction(() => state.video = video)
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = extractErrorMessage(err.response))
    }
  }

  const videoPlayerRef = React.createRef<VideoPlayer>()

  const onPlaybackStatusUpdate = (status: AVPlaybackStatus) => {
    if (status.isLoaded) {
      if (status.didJustFinish) {
        videoPlayerRef.current?.setPositionAsync(0)
      }
    }
  }

  const translateTranscript = async () => {
    let translate = !state.showTranslation

    runInAction(() => state.isLoadingTranscript = true)

    if (translate && !state.translatedTranscript) {
      if (!state.translatedTranscript) {
        try {
          const translation = await API.getVideoTranscript(props.route.params.uuid, AppStateStore.userLanguage)
          runInAction(() => state.translatedTranscript = translation)
        } catch (err) {
          if (Platform.OS === 'web') {
            //Sentry.Browser.captureException(err)
          }
          else {
            //Sentry.Native.captureException(err)
          }
          getModalManager()
            .showModal({
              title: t('Error', 'Error'),
              message: t('There was an error loading the translation', 'There was an error loading the translation'),
            })
          translate = false
        }
      }
    }

    runInAction(() => {
      state.isLoadingTranscript = false
      state.showTranslation = translate
    })
  }

  return <View style={styles.container}>
    {
      state.video
        ? <>
          <View style={styles.videoContainer}>
            <VideoPlayer
              posterSource={state.video.thumbnailUrl ? { uri: state.video.thumbnailUrl } : undefined}
              ref={videoPlayerRef}
              onPlaybackStatusUpdate={onPlaybackStatusUpdate}
              style={StyleSheet.absoluteFillObject}
              source={{ uri: state.video.videoUrl }}
              rate={1.0}
              volume={1.0}
              isMuted={false}
              resizeMode={ResizeMode.CONTAIN}
              useNativeControls={true}
            />
          </View>
          <View style={styles.transcriptContainer}>
            <SafeAreaView style={{ flex: 1 }} edges={['bottom']}>
              {
                state.video.transcript
                  ? <TouchableOpacity
                    style={styles.translateIconContainer}
                    onPress={translateTranscript}
                  >
                    <KonektiIcon name={'translate-icon'} size={20} color={'#ff8d2d'} />
                  </TouchableOpacity>
                  : null
              }
              {
                state.isLoadingTranscript
                  ? <ActivityIndicator />
                  :
                  <ScrollView style={{ flex: 1 }} keyboardShouldPersistTaps="handled">
                    <Text style={styles.transcriptText}>{state.showTranslation ? state.translatedTranscript : state.video.transcript}</Text>
                  </ScrollView>
              }
            </SafeAreaView>
          </View>
        </>
        : state.error
          ? <ErrorMessage message={state.error} />
          : <ContentActivityIndicator />
    }
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  videoContainer: {
    flex: 1,
    backgroundColor: '#000',
  },
  transcriptContainer: {
    height: 250,
  },
  transcriptText: {
    paddingHorizontal: 20,
    fontSize: 16,
  },
  translateIconContainer: {
    alignSelf: 'flex-end',
    marginBottom: 10,
    padding: 8,
  },
})
