import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { DrawerHeaderButton } from '../../components/DrawerHeaderButton'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { runInAction } from 'mobx'
import { ContentActivityIndicator } from '../../components/ContentActivityIndicator'
import { extractErrorMessage, utc } from '../../common/Util'
import { BlockButton } from '../../components/BlockButton'
import {
  FlatList,
  ListRenderItemInfo,
  StyleSheet,
  TouchableOpacity,
  View,
  ScrollView,
  RefreshControl,
  Platform
} from 'react-native'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { DefaultText } from '../../components/DefaultText'
import { API } from '../../api/API'
import { ErrorMessage } from '../../components/ErrorMessage'
import { ListRefreshControl } from '../../components/web-list-refresh-control/ListRefreshControl'
import { ReceivedForm } from '../../api/schema/models/ReceivedForm'
import { AppColors as COLORS } from '../../common/AppColors'
import Icons from '../../components/icons'
import { DocumentIcon } from '../../components/DocumentIcon'
import { TextInputField } from '../../components/TextInputField'
import { ActionButton } from '../../components/buttons/ActionButton'
//import * as Sentry from 'sentry-expo'

export const ReceivedFormsScreen = observer((props: StackScreenProps<RootNavigationParamList, 'ReceivedForms' | 'ReceivedFormsScreen' | 'E-Sign'>) => {
  const { t } = useTranslation()

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Forms', 'Forms'),
      headerLeft: () => <DrawerHeaderButton />,
    })
  }, [props.navigation])

  const state = useLocalObservable(() => ({
    loading: false,
    refreshing: false,
    error: undefined as string | undefined,
    receivedForms: [] as ReceivedForm[],
  }))

  const loadForms = useCallback(async (refresh: boolean = false) => {
    runInAction(() => {
      state.loading = true
      state.error = undefined
      state.refreshing = refresh
    })

    try {
      const response = await API.getReceivedForms()
      runInAction(() => state.receivedForms = response.receivedForms)
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => {
      state.loading = false
      state.refreshing = false
    })
  }, [state])

  useEffect(() => {
    loadForms().then()

    const listeners = [
      AppStateStore.eventBus.addRemovableListener(AppEvents.FormReceived, () => loadForms().then()),
      AppStateStore.eventBus.addRemovableListener(AppEvents.FormSigned, () => loadForms().then()),
    ]

    return () => {
      for (const listener of listeners) {
        listener.remove()
      }
    }
  }, [loadForms])

  const renderListItem = (item: ListRenderItemInfo<ReceivedForm>) => {
    return <TouchableOpacity
      style={[styles.listItem,
      {
        backgroundColor: item.item.signedAt ? COLORS.blue.primary6 : COLORS.gray.neutral7
      }]}
      onPress={() => {
        props.navigation.push('FormViewer', { signatureId: item.item.id, studentId: item.item.student.id })
        //props.navigation.push('ReceivedFormDetail', { receivedForm: item.item })
      }}
    >
      <View style={styles.listHeader}>
        <DefaultText style={styles.headerText} numberOfLines={2}>
          {t('For', 'For')} <DefaultText bold style={{ color: COLORS.orange.primary }}>{item.item?.student.firstName} {item.item?.student.lastName}</DefaultText>
        </DefaultText>

        {
          item.item.signedAt ?
            <Icons.Attendance width={20} height={20} color={COLORS.blue.primary} variant='fill' /> :
            <Icons.Edit width={20} height={20} color={COLORS.orange.primary} />
        }
      </View>

      <View style={styles.line} />

      <DefaultText style={{ fontSize: 18 }} numberOfLines={2}>{item.item.formTitle}</DefaultText>

      <View style={styles.line} />

      <DefaultText numberOfLines={2}>
        <DefaultText style={styles.subText}>
          Sent: <DefaultText style={styles.subText}>{utc(item.item?.sentAt).format('MMM DD, YYYY')}</DefaultText> by <DefaultText style={{ color: COLORS.orange.primary }}>{item.item?.teacher?.displayName}</DefaultText>
        </DefaultText>
      </DefaultText>

    </TouchableOpacity>
  }

  return <View style={styles.container}>
    <View style={styles.content}>
      {
        (state.loading && !state.refreshing)
          ? <ContentActivityIndicator />
          : state.error
            ? <>
              <ErrorMessage message={state.error} />
              <BlockButton
                title={t('Retry', 'Retry')}
                onPress={() => loadForms().then()}
              />
            </>
            : state.receivedForms.length === 0
              ?
              <>
                <ListRefreshControl
                  onPress={() => loadForms(true)}
                  refreshing={state.refreshing}
                />
                <ScrollView
                  contentContainerStyle={styles.emptyListContainer}
                  refreshControl={
                    <RefreshControl
                      refreshing={state.refreshing}
                      onRefresh={() => loadForms(true)}
                    />
                  }
                >
                  <Icons.EmptyDocument width={144} height={96} color={COLORS.gray.neutral5} />
                </ScrollView>
              </>
              :
              <>
                <TextInputField
                  placeholder="Search here"
                  rightElement={
                    <ActionButton >
                      <Icons.Search
                        color={COLORS.white}
                        size={44}
                      />
                    </ActionButton>
                  }
                />
                <ListRefreshControl
                  onPress={() => loadForms(true)}
                  refreshing={state.refreshing}
                />

                <FlatList
                  keyboardShouldPersistTaps="handled"
                  style={styles.list}
                  data={state.receivedForms}
                  keyExtractor={item => `${item.id} + ${item.student.id}`}
                  renderItem={renderListItem}
                  onRefresh={() => loadForms(true)}
                  refreshing={state.refreshing}
                />

              </>
      }
    </View>
  </View >
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.white,
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  content: {
    flex: 1,
  },
  list: {
    flex: 1,
    paddingTop: 14
  },
  listItem: {
    //flexDirection: 'row',
    //alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 12,
    //height: 64,
    //backgroundColor: COLORS.gray.neutral6,
    justifyContent: 'center',
    borderRadius: 13,
    //marginVertical: 10,
    marginBottom: 20,
  },
  listHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  emptyListContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerText: {
    fontSize: 16,
    color: COLORS.gray.neutral2,
  },
  subText: {
    fontSize: 14,
    color: COLORS.gray.neutral2,
  },
  line: {
    height: 1,
    backgroundColor: COLORS.gray.neutral4,
    marginVertical: 10,
  },
})
