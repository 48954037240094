export const AppColors = {
  aqua: {
    secondary: '#4DC9D1',
  },
  black: {
    primary: '#000',
    text: '#303030',
  },
  blue: {
    primary: '#0082CD',
    disabled: '#80C1E6',
    primary6: '#0082CD0F',
  },
  egg: {
    secondary: '#FCD034',
  },
  gray: {
    neutral1: '#303030',
    neutral2: '#626262',
    neutral3: '#9D9FA0',
    neutral4: '#C7C9D9',
    neutral5: '#EEEEEE',
    neutral6: '#F6F7FA',
    neutral7: '#F6F7FA66',
  },
  leaf: {
    secondary: '#4ED442',
  },
  orange: {
    primary: '#FF7A33',
  },
  purple: {
    secondary: '#8D5EF2',
  },
  red: {
    secondary: '#F10000',
  },
  salmon: {
    primary: '#FF5733',
  },
  white: '#FFF',
}
