import React from "react";
import { View, Text, StyleSheet, Platform } from "react-native";
import { Post } from "../../api/schema/models/Post";
import SurveyIcon from "./SurveyIcon";
import { useTranslation } from 'react-i18next'
import { PostHeader } from '../posts/PostHeader'
import { AppColors as COLORS } from '../../common/AppColors'

interface SurveyProps {
    post: Post
}

export default ({ post }: SurveyProps) => {
    const { t } = useTranslation()

    return (
        <View style={styles.container}>
            <PostHeader post={post} />
            <View style={styles.message}>
                <SurveyIcon />
                <Text style={styles.text}>{
                    post.surveyTitle ? 
                        post.surveyTitle.trim().length > 300
                            ? `${post.surveyTitle.slice(0, 250)}... (${t('read more', 'read more')})`
                            : post.surveyTitle
                        : post.message.trim().length > 300
                            ? `${post.message.slice(0, 250)}... (${t('read more', 'read more')})`
                            : post.surveyTitle
                }</Text>

            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 12,
        paddingVertical: 24,
        shadowColor: Platform.OS === 'ios' ? 'grey' : 'black',
        borderRadius: 24,
        marginHorizontal: 10,
        marginVertical: 10,
        shadowOpacity: .3,
        backgroundColor: COLORS.white,
        shadowOffset: {
            width: 3,
            height: 5
        },
        shadowRadius: 7,

        elevation: 10
    },
    message: {
        flexDirection: 'row',
        marginTop: 14
    },
    text: {
        color: COLORS.gray.neutral1,
        marginLeft: 16,
        flex: 1,
    }
});