import React from "react";
import Modal from "react-native-modal";
import { View, StyleSheet, Dimensions, Text } from "react-native";
import { Colors } from '../../common/Colors'
import { BlockButton, BlockButtonVariant } from '../BlockButton'
import { AppColors } from '../../common/AppColors'
import { useTranslation } from 'react-i18next'

type ModalButton = {
  text: string
  onPress?: (dismiss: () => void) => void
  variant?: BlockButtonVariant
}

type Props = {
  isVisible: boolean;
  dismiss: () => void;
  title?: React.ReactElement
  message?: React.ReactElement
  buttons?: ModalButton[]
  children?: React.ReactElement | React.ReactElement[]
  showClosse?: boolean
  drawerHeight?: number
};

const window = Dimensions.get('window');

const BottomOptionsModal = ({ isVisible, title, message, dismiss, buttons }: Props) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <Modal
        isVisible={isVisible}
        coverScreen={true}
        onBackdropPress={dismiss}
        style={{
          justifyContent: 'flex-end',
          margin: 0,
          height: window.height * 0.5,
        }}
        animationIn="slideInUp"
        animationOut="slideOutDown"
        propagateSwipe={true}
        statusBarTranslucent={true}
        useNativeDriver={true}
      >
        <View style={styles.wrapper}>
          <Text style={styles.drawerHeaderText}>{title}</Text>
          <Text style={styles.subText}>{message}</Text>
          <View style={styles.footer}>
            {buttons?.length
              ? buttons?.map((button, idx) => <BlockButton
                key={idx}
                title={button.text}
                variant={button.variant}
                onPress={() => button.onPress ? button.onPress(dismiss) : dismiss()}
                //flex={1}
                style={[styles.button, idx > 0 ? { marginLeft: 1 } : undefined]}
                textStyle={styles.buttonText} />)
              : <BlockButton
                title={t('OK', 'OK')}
                onPress={() => dismiss()}
                //flex={1}
                style={styles.button}
                textStyle={styles.buttonText} />}
          </View>
        </View>
      </Modal>
    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  drawerHeaderText: {
    paddingTop: 30,
    fontSize: 24,
    color: AppColors.blue.primary,
    textAlign: 'center',
    fontWeight: '500',
    fontFamily: 'Rubik_500Medium',
  },
  subText: {
    paddingTop: 15,
    paddingHorizontal: 50,
    marginBottom: 6,
    fontSize: 16,
    color: AppColors.gray.neutral1,
    textAlign: 'center',
    fontWeight: '400',
  },
  wrapper: {
    backgroundColor: 'white',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    flexDirection: 'column',
    //paddingBottom: 12,
  },
  footer: {
    flexDirection: 'column',
    overflow: 'hidden',
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 45

  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    color: Colors.MutedTextColor,
    marginBottom: 12,
  },
  message: {
    fontSize: 16,
    textAlign: 'center',
  },
  button: {
    paddingHorizontal: 16,
    margin: 6,
  },
  buttonText: {
    fontSize: 16,
    fontFamily: 'Rubik_500Medium',
  },
})

export default BottomOptionsModal;