import React from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { ActivityIndicator, Platform, StyleSheet, TouchableOpacity, View, Text } from 'react-native'
import * as ImagePicker from 'expo-image-picker'
import { ImagePickerResult, MediaTypeOptions } from 'expo-image-picker'
import { useTranslation } from 'react-i18next'
import { getModalManager } from '../contexts/ModalContext'
import { runInAction } from 'mobx'
import { API } from '../api/API'
import { ProfileImage } from './ProfileImage'
import { FileData } from '../api/methods/upload-file'
import { extractErrorMessage, getMimeType } from '../common/Util'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { BottomDrawer } from '../../src/components/BottomDrawer'
import { BlockButton } from '../../src/components/BlockButton'
import { AppColors as COLORS } from '../common/AppColors'
//import * as Sentry from 'sentry-expo';

type Props = {
  profilePhotoUrl?: string
  onProfilePhotoUpdated?: (url: string) => void
}

export const ProfilePhotoInput = observer((props: Props) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    uploading: false,
    hasCameraPermission: false,
    showDrawer: false
  }))

  const requestPermission = async () => {
    if (Platform.OS === 'ios') {
      const cameraRollStatus = await ImagePicker.requestMediaLibraryPermissionsAsync()
      const cameraStatus = await ImagePicker.requestCameraPermissionsAsync()
      if (
        cameraRollStatus.status !== 'granted'
        || cameraStatus.status !== 'granted'
      ) {
        getModalManager().showModal({
          title: t('Permission Required', 'Permission Required'),
          message: t('Camera permission is required', 'Camera permission is required in order to take photos'),
        })
      } else {
        runInAction(() => state.hasCameraPermission = true)
      }
    } else {
      runInAction(() => state.hasCameraPermission = true)
    }

    if (state.hasCameraPermission) {
      editProfilePhoto().then()
    }
  }

  const openCamera = async () => {
    return await ImagePicker.launchCameraAsync({
      mediaTypes: MediaTypeOptions.Images,
    })
  }

  const takePhoto = async () => {
    try {
      console.log('abre camara')
      let permiso = await ImagePicker.requestCameraPermissionsAsync()
      console.log(permiso)
      //let media = await openCamera()
      //console.log(media)
      let result = await ImagePicker.launchCameraAsync({
        mediaTypes: MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
      })
      console.log(result)

      await handleImagePicked(result)
    }
    catch (error) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(error)
      }
      else {
        //Sentry.Native.captureException(error)
      }
      console.log(error)
    }
  }

  const pickImage = async () => {
    try {
      console.log('abre galeria')
      let permiso = await ImagePicker.requestMediaLibraryPermissionsAsync()
      console.log(permiso)
      //let media = await openCamera()
      //console.log(media)
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
      })
      console.log(result)

      await handleImagePicked(result)
    }
    catch (error) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(error)
      }
      else {
        //Sentry.Native.captureException(error)
      }
      console.log(error)
    }
  }

  const handleImagePicked = async (pickerResult: ImagePickerResult) => {
    if (pickerResult.cancelled) {
      return
    } else {
      try {
        runInAction(() => state.uploading = true)

        let file: FileData = {
          uri: pickerResult.uri,
          name: 'file',
          type: 'image/jpeg',
        }

        if (pickerResult.uri.startsWith('data:')) {
          const binary = atob(pickerResult.uri.split(',')[1])
          const array = []
          for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i))
          }
          file = new Blob([new Uint8Array(array)], {
            type: 'image/jpeg',
          })
        } else {
          file.type = getMimeType(pickerResult.uri) || 'application/octet-stream'
        }

        const uploadData = await API.requestUpload()

        await API.uploadFile(
          uploadData.url,
          uploadData.fields,
          file,
        )

        const response = await API.uploadProfilePhoto({
          key: uploadData.key,
          filename: 'image',
          mimeType: file.type,
        })

        props.onProfilePhotoUpdated && props.onProfilePhotoUpdated(response.url)
      } catch (e: any) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(e)
        }
        else {
          //Sentry.Native.captureException(e)
        }
        getModalManager()
          .showModal({
            title: t('Error', 'Error'),
            message: extractErrorMessage(e.response),
          })
      }

      runInAction(() => state.uploading = false)
    }
  }

  const editProfilePhoto = async () => {
    if (state.uploading) {
      return
    }

    if (Platform.OS === 'web') {
      pickImage().then()
    } else {
      runInAction(() => state.showDrawer = true)
      /*getModalManager()
        .showModal({
          title: t('Choose Photo', 'Choose Photo'),
          message: t('You can choose a profile photo from your photo library or take a photo with your camera', 'You can choose a profile photo from your photo library or take a photo with your camera.'),
          buttons: [
            {
              text: t('Use Camera', 'Use Camera'),
              onPress: dismiss => {
                dismiss()
                takePhoto().then()
              },
            },
            {
              text: t('Photo Library', 'Photo Library'),
              variant: 'secondary',
              onPress: dismiss => {
                dismiss()
                pickImage().then()
              },
            },
          ]
        })*/

    }
  }

  return <View>
    <TouchableOpacity
      onPress={requestPermission}
      disabled={state.uploading}
    >
      <View style={styles.profileImageContainer}>
        <ProfileImage size={100} url={props.profilePhotoUrl ?? undefined} />
        <View style={styles.editPhotoIcon}>
          <MaterialCommunityIcons name={'camera-outline'} color={COLORS.white} size={20} />
        </View>
        {
          state.uploading
            ? <View style={styles.uploadIndicatorContainer}>
              <View style={styles.uploadIndicatorBackground}>
                <ActivityIndicator color="#fff" />
              </View>
            </View>
            : null
        }
      </View>
    </TouchableOpacity>
    {
      state.showDrawer
        ? <BottomDrawer height={370} radius={30} backgrounColor={'#fff'} outsideTouch={() => runInAction(() => state.showDrawer = false)}>
          <Text style={styles.drawerHeaderText}>{t('Choose Photo', 'Choose Photo')}</Text>
          <Text style={styles.subText}>{t('You can choose a profile photo from your photo library or take a photo with your camera', 'You can choose a profile photo from your photo library or take a photo with your camera.')}</Text>
          <BlockButton
            title={t('Use Camera', 'Use Camera')}
            variant={'primary'}
            onPress={async () => {
              runInAction(() => state.showDrawer = false)
              await takePhoto()
            }}
            textStyle={{ fontSize: 16 }}
            style={{ marginHorizontal: 25, minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 20, backgroundColor: '#FF7A33' }}
          />
          <BlockButton
            title={t('Photo Library', 'Photo Library')}
            variant={'secondary'}
            onPress={async () => {
              runInAction(() => state.showDrawer = false)
              await pickImage()
            }}
            textStyle={{ fontSize: 16 }}
            style={{ marginHorizontal: 25, minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 20, backgroundColor: '#0082CD' }}
          />
        </BottomDrawer>
        : null
    }
  </View>
})


const styles = StyleSheet.create({
  profileImageContainer: {
    width: 100,
    height: 100,
  },
  editPhotoIcon: {
    position: 'absolute',
    right: 4,
    bottom: 4,
    overflow: 'hidden',
    width: 30,
    height: 30,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.blue.primary,
  },
  uploadIndicatorContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadIndicatorBackground: {
    padding: 10,
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: 6,
  },
  drawerHeaderText: {
    paddingTop: 30,
    fontSize: 24,
    color: COLORS.blue.primary,
    textAlign: 'center',
    fontWeight: '500',
  },
  subText: {
    paddingTop: 15,
    paddingHorizontal: 50,
    fontSize: 16,
    color: '#303030',
    textAlign: 'center',
    fontWeight: '400',
  },
})
