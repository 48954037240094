import { observer, useLocalObservable } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { RootNavigationParamList } from '../navigation/RootNav'
import React, { useCallback, useEffect } from 'react'
import { runInAction } from 'mobx'
import { API } from '../api/API'
import { extractErrorMessage, formatNumber, utc } from '../common/Util'
import { AppEvents, AppStateStore } from '../contexts/AppStateStore'
import { FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View, ScrollView, RefreshControl, Platform } from 'react-native'
import { DefaultText } from './DefaultText'
import { ContentActivityIndicator } from './ContentActivityIndicator'
import { ErrorMessage } from './ErrorMessage'
import { BlockButton } from './BlockButton'
import { ListRefreshControl } from './web-list-refresh-control/ListRefreshControl'
import { SentFormGroup } from '../api/schema/models/SentFormGroup'
import Icons from './icons'
import { AppColors as COLORS } from '../common/AppColors'
//import * as Sentry from 'sentry-expo'

export const SentFormList = observer(() => {
  const { t } = useTranslation()
  const navigation = useNavigation<StackNavigationProp<RootNavigationParamList>>()

  const state = useLocalObservable(() => ({
    loading: false,
    refreshing: false,
    error: undefined as string | undefined,
    sentFormGroups: [] as SentFormGroup[],
  }))

  const loadForms = useCallback(async (refresh: boolean = false) => {
    runInAction(() => {
      state.loading = true
      state.error = undefined
      state.refreshing = refresh
    })

    try {
      const response = await API.getSentFormGroups()
      runInAction(() => state.sentFormGroups = response.sentFormGroups)
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => {
      state.loading = false
      state.refreshing = false
    })
  }, [state])

  useEffect(() => {
    loadForms().then()

    const listeners = [
      AppStateStore.eventBus.addRemovableListener(AppEvents.FormSent, () => loadForms().then()),
    ]

    return () => {
      for (const listener of listeners) {
        listener.remove()
      }
    }
  }, [loadForms])

  const renderListItem = (item: ListRenderItemInfo<SentFormGroup>) => {
    return <TouchableOpacity
      style={styles.listItem}
      onPress={() => {
        navigation.push('SentFormGroupDetail', { FormId: item.item.id })
      }}
    >
      <View style={{ flex: 1 }}>
        <DefaultText bold numberOfLines={1}>{item.item.formTitle}</DefaultText>
        <DefaultText>{utc(item.item.sentAt).format('MMMM D, YYYY')}</DefaultText>
        {
          item.item.isSignatureRequired
            ? <DefaultText>{t('Signature and recipient count', '{{numberOfSignatures}} / {{ numberOfRecipients}} signatures', { numberOfSignatures: formatNumber(item.item.numberOfSignatures), numberOfRecipients: formatNumber(item.item.numberOfRecipients) })}</DefaultText>
            : <DefaultText>{t('Submission and recipient count', '{{numberOfSignatures}} / {{ numberOfRecipients}} submissions', { numberOfSignatures: formatNumber(item.item.numberOfSignatures), numberOfRecipients: formatNumber(item.item.numberOfRecipients) })}</DefaultText>
        }
      </View>
      <Icons.Forward width={12} height={20} color={COLORS.orange.primary} />
    </TouchableOpacity>
  }

  return <View style={styles.container}>
    <View style={styles.content}>
      {
        (state.loading && !state.refreshing)
          ? <ContentActivityIndicator />
          : state.error
            ? <>
              <ErrorMessage message={state.error} />
              <BlockButton
                title={t('Retry', 'Retry')}
                onPress={() => loadForms().then()}
              />
            </>
            : state.sentFormGroups.length === 0
              ?
              <>
                <ListRefreshControl
                  onPress={() => loadForms(true)}
                  refreshing={state.refreshing}
                />
                <ScrollView
                  contentContainerStyle={styles.emptyListContainer}
                  refreshControl={<RefreshControl refreshing={state.refreshing} onRefresh={() => loadForms(true)} />}
                >
                  <Icons.EmptyDocument width={144} height={96} color={COLORS.gray.neutral5} />
                </ScrollView>
              </>
              :
              <>
                <ListRefreshControl
                  onPress={() => loadForms(true)}
                  refreshing={state.refreshing}
                />
                <FlatList
                  keyboardShouldPersistTaps="handled"
                  style={styles.list}
                  data={state.sentFormGroups}
                  keyExtractor={item => String(item.id)}
                  renderItem={renderListItem}
                  onRefresh={() => loadForms(true)}
                  refreshing={state.refreshing}
                />
              </>
      }
    </View>
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  list: {
    flex: 1,
    paddingTop: 14
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 12,
    height: 64,
    backgroundColor: COLORS.gray.neutral6,
    justifyContent: 'center',
    borderRadius: 13,
    //marginVertical: 10,
    marginBottom: 20,
  },
  emptyListContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
