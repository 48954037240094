import React, { useEffect } from 'react'
import { ActivityIndicator, Image, ImageSourcePropType, LayoutChangeEvent, StyleProp, StyleSheet, View, ViewStyle, ImageStyle } from 'react-native'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { runInAction, toJS } from 'mobx'
import { DefaultText } from './DefaultText'
import { useTranslation } from 'react-i18next'

type Props = {
  uri: string
  maxWidth?: number
  maxHeight?: number
  style?: StyleProp<ViewStyle>
  imageStyle?: StyleProp<ImageStyle>
  shadowStyle?: StyleProp<ViewStyle>
}

export const WebImage = observer((props: Props) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    loading: false,
    width: 0,
    height: 0,
    containerWidth: undefined as number | undefined,
    containerHeight: undefined as number | undefined,
    error: undefined as any,
    source: undefined as ImageSourcePropType | undefined,
    get scale () {
      if (this.width === 0 || this.height === 0) {
        return 1
      }

      const maxWidth = props.maxWidth ?? this.containerWidth
      const maxHeight = props.maxHeight ?? this.containerHeight

      const sw = maxWidth ? maxWidth / this.width : undefined
      const sh = maxHeight ? maxHeight / this.height : undefined

      if (sw && (!sh || sw < sh)) {
        return sw
      }

      if (sh && (!sw || sh < sw)) {
        return sh
      }

      return undefined
    },
    get scaledWidth () {
      return this.scale ? this.width * this.scale : '100%'
    },
    get scaledHeight () {
      return this.scale ? this.height * this.scale : '100%'
    },
  }), [
    props.maxWidth,
    props.maxHeight,
  ])

  const loadImage = async (uri: string) => {
    runInAction(() => {
      state.loading = true
      state.error = undefined
    })

    Image.getSize(uri,
      (width, height) => {
        runInAction(() => {
          state.width = width
          state.height = height
          state.source = { uri }
          state.loading = false
        })
      },
      error => {
        runInAction(() => {
          state.loading = false
          state.error = error
        })
      }
    )
  }

  useEffect(() => {
    loadImage(props.uri).then()
  }, [props.uri])

  const onContainerLayout = (ev: LayoutChangeEvent) => {
    runInAction(() => {
      state.containerWidth = ev.nativeEvent.layout.width
      state.containerHeight = ev.nativeEvent.layout.height
    })
  }

  return <View style={[styles.container, props.style]} onLayout={onContainerLayout}>
    {
      state.loading
        ? <ActivityIndicator/>
        : state.error
        ? <DefaultText>{t('Error loading image', 'Error loading image')}</DefaultText>
        : state.source
          ? <View style={[props.imageStyle, props.shadowStyle]}>
              <Image
                style={[styles.image, { width: state.scaledWidth, height: state.scaledHeight }, props.imageStyle]}
                source={toJS(state.source)}
              />
            </View>
          : null
    }
  </View>
})

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    resizeMode: 'contain',
    borderRadius: 8
  },
})
