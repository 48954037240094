import React from "react";
import { Svg, Path } from "react-native-svg";

export const RedFlag = (props: any) => {
    return (
        <Svg width="25" height="25" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
         <Path d="M17.46 1.58491C17.45 1.57491 17.44 1.57491 17.44 1.56491C16.23 0.914912 15.03 0.604912 13.64 0.604912C12.05 0.604912 10.48 1.01491 8.97 1.41491L8.46 1.55491C7.08 1.92491 5.77 2.27491 4.5 2.27491C3.67 2.27491 2.91 2.12491 2.2 1.83491V1.38491C2.2 0.774912 1.71 0.284912 1.1 0.284912C0.49 0.284912 0 0.774912 0 1.38491V19.1849C0 19.7949 0.49 20.2849 1.1 20.2849C1.71 20.2849 2.2 19.7949 2.2 19.1849V13.7049C2.92 13.9149 3.66 14.0149 4.46 14.0149C5.92 14.0149 7.36 13.6649 8.75 13.3249L9.64 13.1049C11.02 12.7349 12.32 12.3949 13.59 12.3949C14.63 12.3949 15.49 12.6249 16.32 13.0749C16.63 13.2849 17.03 13.3049 17.39 13.1249C17.76 12.9249 17.99 12.5549 17.99 12.1549V2.55491C17.99 2.15491 17.8 1.81491 17.46 1.58491Z" fill="#F10000"/>
        </Svg>

    );
}