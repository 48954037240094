import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { DefaultText } from '../DefaultText'
import _ from 'lodash'
import { Moment } from 'moment-timezone'
import { Colors } from '../../common/Colors'
import { runInAction } from 'mobx'
import { MaterialCommunityIcons } from '@expo/vector-icons'

type Props = {
  onDateSelected: (date: Moment) => void
  selectedDate: Moment
  disabled?: boolean
}

export const WeeklyCalendarPicker = observer((props: Props) => {
  const state = useLocalObservable(() => ({
    date: props.selectedDate.clone().startOf('week'),
  }))

  const dayLetters: string[] = [
    'S',
    'M',
    'T',
    'W',
    'T',
    'F',
    'S',
  ]

  useEffect(() => {
    runInAction(() => state.date = props.selectedDate.clone().startOf('week'))
  }, [props.selectedDate])

  const renderDay = (day: number) => {
    const isWeekend = (day === 0 || day === 6)

    const thisDate = state.date.clone().add(day, 'days')
    const dayNumber = thisDate.date()

    const isSelectedDate = props.selectedDate.isSame(thisDate, 'date')

    return <TouchableOpacity disabled={props.disabled} onPress={() => selectDate(thisDate)} key={String(day)} style={styles.dayContainer}>
      <DefaultText style={[styles.dayLetterText, isWeekend ? styles.dayLetterWeekendText : undefined]}>{dayLetters[day]}</DefaultText>
      <View style={styles.dayNumberContainer}>
        {
          isSelectedDate
            ? <View style={styles.selectedDateIndicator}/>
            : null
        }
        <DefaultText style={[styles.dayNumberText, isWeekend ? styles.dayNumberWeekendText : undefined, isSelectedDate ? styles.dayNumberHighlightedText : undefined]}>{dayNumber}</DefaultText>
      </View>
    </TouchableOpacity>
  }

  const renderWeek = () => {
    return <View style={styles.weekContainer}>
      {
        _.range(0, 7).map(day => renderDay(day))
      }
    </View>
  }

  const selectDate = (date: Moment) => {
    props.onDateSelected(date.clone())
  }

  const prevWeek = () => runInAction(() => {
    selectDate(props.selectedDate.clone().subtract(1, 'week'))
  })

  const nextWeek = () => runInAction(() => {
    selectDate(props.selectedDate.clone().add(1, 'week'))
  })

  return <View style={{overflow:'hidden', paddingBottom:30}}>
  <View style={styles.wrapper}>
    <View style={styles.content}>
      <View style={styles.header}>
        <View style={styles.weekNavContainer}>
          <TouchableOpacity style={styles.weekNav} onPress={prevWeek} disabled={props.disabled}>
            <MaterialCommunityIcons
              style={styles.weekNavIcon}
              name={'chevron-left'}
              color={'#FF7A33'}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.headerWrapper}>
          {renderWeek()}
          <View style={styles.selectedDateContainer}>
            <DefaultText style={styles.selectedDateText}>{props.selectedDate.format('ddd, MMMM D, YYYY')}</DefaultText>
          </View>
        </View>
        <View style={styles.weekNavContainer}>
          <TouchableOpacity style={styles.weekNav} onPress={nextWeek} disabled={props.disabled}>
            <MaterialCommunityIcons
              style={styles.weekNavIcon}
              name={'chevron-right'}
              color={'#FF7A33'}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  </View>
  </View>
})

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: '#fff', 
    borderBottomLeftRadius: 20, 
    borderBottomRightRadius: 20,
    elevation: 20, 
    shadowRadius: 20, 
    shadowColor: '#F55600', 
    shadowOffset: {width: 0, height: 0}, 
    shadowOpacity: 0.2
  },
  content: {},
  header: {
    borderBottomWidth: 0,
    borderBottomColor: '#999',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerWrapper: {
    flex: 1,
  },
  weekNavContainer: {},
  weekNav: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingBottom:15
  },
  weekNavIcon: {
    fontSize: 30,
  },
  weekContainer: {
    flexDirection: 'row',
    paddingTop: 12,
  },
  dayContainer: {
    flex: 1,
    alignItems: 'center',
  },
  dayLetterText: {
    color: '#333',
    fontSize: 14,
  },
  dayLetterWeekendText: {
    color: '#999',
  },
  dayNumberContainer: {
    height: 36,
    width: 36,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dayNumberText: {
    color: '#333',
    fontSize: 16,
    textAlign: 'center',
  },
  dayNumberWeekendText: {
    color: '#999',
  },
  dayNumberHighlightedText: {
    color: '#fff',
  },
  selectedDateIndicator: {
    position: 'absolute',
    width: 36,
    height: 36,
    left: 0,
    top: 0,
    borderRadius: 18,
    backgroundColor: Colors.PrimaryOrange,
    zIndex: -1,
  },
  selectedDateContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 4,
    marginBottom: 8,
  },
  selectedDateText: {
    fontSize: 14,
    color: '#666',
    paddingTop:16
  },
})
