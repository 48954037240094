import { observer, useLocalObservable } from 'mobx-react-lite'
import { StyleSheet, Text, TouchableOpacity, View, Platform } from 'react-native'
import React, { useEffect } from 'react'
import { Audio, AVPlaybackStatus } from 'expo-av'
import { runInAction } from 'mobx'
import { formatDuration } from '../common/Util'
import { MaterialCommunityIcons } from '@expo/vector-icons'
//import * as Sentry from 'sentry-expo';

type Props = {
  url: string
}

type State = {
  loading: boolean
  playing: boolean
  playProgressMillis: number
  durationMillis: number
  soundInstance?: Audio.Sound
  progressBarWidth: string
}

export const AudioPlayer = observer((props: Props) => {
  const state = useLocalObservable<State>(() => ({
    loading: false,
    playing: false,
    playProgressMillis: 0,
    durationMillis: 0,
    soundInstance: undefined,
    get progressBarWidth() {
      if (this.durationMillis === 0) {
        return `0%`
      } else {
        return `${Math.ceil(this.playProgressMillis / this.durationMillis * 100)}%`
      }
    },
  }))

  useEffect(() => {
    loadAudio().then()
    return () => {
      unloadAudio().then()
    }
  }, [
    props.url,
  ])

  const loadAudio = async () => {
    runInAction(() => state.loading = true)
    await unloadAudio()

    if (props.url) {
      try {
        const soundObject = new Audio.Sound()
        const status = await soundObject.loadAsync({ uri: props.url }, {
          shouldPlay: false,
        })

        soundObject.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate)

        runInAction(() => {
          state.playing = false
          state.durationMillis = status.isLoaded ? (status.durationMillis ?? 0) : 0
          state.playProgressMillis = status.isLoaded ? (status.positionMillis ?? 0) : 0
          state.soundInstance = soundObject
        })
      } catch (err) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(err)
        }
        else {
          //Sentry.Native.captureException(err)
        }
      }
    }

    runInAction(() => state.loading = false)
  }

  const onPlaybackStatusUpdate = (status: AVPlaybackStatus) => {
    if (status.isLoaded) {
      if (status.didJustFinish) {
        state.soundInstance!.stopAsync().then()
      }

      runInAction(() => {
        state.durationMillis = status.durationMillis ?? 0
        state.playProgressMillis = status.positionMillis
        state.playing = status.isPlaying
      })
    }
  }

  const unloadAudio = async () => {
    if (state.soundInstance) {
      await state.soundInstance.unloadAsync()
      runInAction(() => state.soundInstance = undefined)
    }
  }

  const playAudio = async () => {
    if (state.soundInstance) {
      if (state.playing) {
        await state.soundInstance.stopAsync()
      } else {
        await state.soundInstance.playAsync()
      }
    }
  }

  return <View style={styles.container}>
    <View style={styles.playbackStatus}>
      <View style={[styles.progressBar, { width: state.progressBarWidth }]} />
      <Text>
        {
          state.durationMillis
            ? `${formatDuration(Math.ceil(state.playProgressMillis / 1000))} / ${formatDuration(Math.ceil(state.durationMillis / 1000))}`
            : formatDuration(Math.ceil(state.playProgressMillis / 1000))
        }
      </Text>
    </View>
    <TouchableOpacity style={styles.button} onPress={playAudio}>
      <MaterialCommunityIcons name={state.playing ? 'stop' : 'play'} style={styles.icon} />
    </TouchableOpacity>
  </View>
})

const styles = StyleSheet.create({
  container: {
    borderWidth: .5,
    borderRadius: 8,
    borderColor: '#ddd',
    overflow: 'hidden',
    flexDirection: 'row',
    width: '80%'
  },
  playbackStatus: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ddd',
    borderRightWidth: .5,
    borderColor: '#ccc',
  },
  progressBar: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 0,
    backgroundColor: '#ccc',
  },
  button: {
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eee',
  },
  icon: {
    fontSize: 40,
    color: '#ff8d2d',
  },
})
