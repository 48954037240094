import { StyleSheet } from 'react-native';

export const fabStyles = StyleSheet.create({
    container: {
        height: 48,
        width: 48,
        position: 'absolute',
        bottom: 30,
        right: 24,
        zIndex: 100,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3,
        elevation: 3,
    }
})
