import React, { useEffect, useState } from "react";
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { headerStyles } from "./headerStyles";
import { PixelRatio, Pressable, Text, View } from "react-native";
import { useHeaderHeight } from "@react-navigation/elements";
import { DrawerHeaderButton } from "../DrawerHeaderButton";
import { ProfileImage } from "../ProfileImage";
import { AppStateStore } from "../../contexts/AppStateStore";
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { API } from "../../api/API";
import { NotificationIcon } from "./NotificationIcon";
import { NewEditSvg } from '../../../assets/images/edit-svg'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { Avatar, Badge, Icon, withBadge } from 'react-native-elements'
import * as Notifications from 'expo-notifications'
import moment from 'moment-timezone'
import { TouchableOpacity } from "react-native-gesture-handler";
import { runInAction } from "mobx";

export interface HeaderProps {
    routeName: string,
    badgeCount: number
}

export const Header = ({ routeName, badgeCount }: HeaderProps) => {
    const navigation = useNavigation<StackNavigationProp<RootNavigationParamList>>()
    
    const insets = useSafeAreaInsets();
    const [fullName, setFullname] = useState("");
    const [welcomeMessage, setWelcomeMessage] = useState("");
    const [role, setRole] = useState("");
    const [updateBadge, setUpdate] = useState(0);

    useEffect(() => {
        API.getProfile().then(response => {
            setFullname(`${response.firstName} ${response.lastName}`);
        });
        if(AppStateStore.userContext?.userType == 'teacher') {
            let tempSplit = AppStateStore.userContext.teacher.role?.split('_')
            let tempName = '';
            tempSplit?.forEach((segment, index) => {
                tempName += `${segment.substring(0,1).toUpperCase()}${segment.substring(1,100)}${index == tempSplit?.length ? '': ' '}`
            })
            setRole(tempName)
        }
        else if(AppStateStore.userContext?.userType){
            setRole(AppStateStore.userContext?.userType.substring(0,1).toUpperCase() + AppStateStore.userContext?.userType.substring(1, AppStateStore.userContext?.userType.length))
        }
        /*API.getUserContext().then(response => {
            if(response.userType){
                //setRole(response.userType.substring(0,1).toUpperCase() + response.userType.substring(1, response.userType.length))
            }
        })*/
        setInterval(() => {
            setUpdate(Math.random())
        }, 500)
    }, []);

   useEffect(() => {
    const now = new Date();
    const hour = now.getHours();
    
    if(hour >= 6 && hour < 12){
      setWelcomeMessage(`Good morning`)
    } else if(hour >= 12 && hour < 18){
      setWelcomeMessage(`Good afternoon`)
    } else if (hour >= 18 && hour < 22){
      setWelcomeMessage(`Good evening`)
    } else {
      setWelcomeMessage(`Good night`)
    }

   }, []);

   const testNotification = async () => {
    let now = moment()
    const id = await Notifications.scheduleNotificationAsync({
      content: {
        title: "local notification",
        body: "body notification",
        sound: 'default',
        data: {
          /*event
          notificationType: 'event',
          id: 16,
          other: '2022-09-05 09:29:58'*/
          /* chat
          notificationType: 'new-message',
          id: 15*/
          // post
          notificationType: 'post',
          id: 154
          /*notificationType: 'document',
          id: 'https://konekti-files-production.s3.us-west-2.amazonaws.com/documents/3afe505a-8d38-4684-a9cb-2c6413ed9f1b.pdf?AWSAccessKeyId=ASIAZEOR4KBH3YVHRYM6&Expires=1664326457&Signature=U85n49Ojg4cmohypIhI1eoPPqcc%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEGkaCXVzLXdlc3QtMiJGMEQCIE9xoEInuwcEQJOtQoZkPcSMX9OqR6TYJ3YFxKtpU%2B%2FkAiACcbKD28LTbyyKbL16BVYBRVULxbYHlXlJ979VH0CVRCqCAwghEAEaDDYyODA0MjI1NjQ2MyIMw8aV%2BJWLroLgaDniKt8CaduMY%2BKaRbGuUDOFmF%2BoP5flSoIdXbShgL7%2BdTtwOJZotEmB%2BvhqWiER9g8EJxbNPRABvwKCWxpUrV8alQxhhbvjBBu%2BDp556VUuU8%2FVuYgLededm1T7tENA94fuaACAArz38BdTzRmhRKsQXXrdh%2FB6VuARmZQI0okk%2FTvO3gjAiZV6UDLeSiY5JiBRHO9B%2Bc6m2WemaRDWYfNCOoUJd9NCsMA3V9we%2FSUj6RRHGkHuL0nGxl8N0laZjeRQaa%2F%2FfrOOumxeBEiR5aWoD4rQz32HdNrp1FauljHwGVCQ6D0U13QA%2Bv7wXc5IPkxoCgFD2kvynTsLlYuH0dXyeTIU6jsRYHTPmgLvQMppGiT5SNWLVtdD4R8853KpEbyezR1drdzH2j%2FuXBFpF8fD%2BxItz6JWBxMfc1cpK2MQ5p%2Fs25DbZuQeqwT%2Fpx%2F%2BHHYJJeMDBZqUBBPYFbI7SjwIMQsQMKymzpkGOp8B3A%2FXlQ4FuQxSjXNPRTc0V%2BViV2N%2BfohBvtpmMblefEoDOF6ejqFd3dbKjcS658Lb3zgDTe%2BhVm68%2FVkrowc10q12rr5mE2daT0LRtWHxPhtadW%2Fd7bN197w4MGkOr6y2b8SSd2YFHyj9mU%2FN5LXn9klilURSDa9YdyLzJQleJQ9De%2B%2F2cfgaL2zdvrrwD6V0ygTOvhPflNqlNTPItCqR',
          other: 'Test'*/
          /* form
          notificationType: 'form',
          id: `{
            "sentAt": "2022-09-28 00:46:53",
            "formTitle": "Test Form",
            "isSignatureRequired": true,
            "formFields": [
                {
                    "label": "Nombre",
                    "required": true
                }
            ],
            "formUrl": "https://konekti-files-production.s3.us-west-2.amazonaws.com/documents/d32ac228-3416-4f11-8eaa-b86018f066f6.pdf?AWSAccessKeyId=ASIAZEOR4KBH3YVHRYM6&Expires=1664326920&Signature=eclqlTcnVBtWhKlPU3YQj2KtdGU%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEGkaCXVzLXdlc3QtMiJGMEQCIE9xoEInuwcEQJOtQoZkPcSMX9OqR6TYJ3YFxKtpU%2B%2FkAiACcbKD28LTbyyKbL16BVYBRVULxbYHlXlJ979VH0CVRCqCAwghEAEaDDYyODA0MjI1NjQ2MyIMw8aV%2BJWLroLgaDniKt8CaduMY%2BKaRbGuUDOFmF%2BoP5flSoIdXbShgL7%2BdTtwOJZotEmB%2BvhqWiER9g8EJxbNPRABvwKCWxpUrV8alQxhhbvjBBu%2BDp556VUuU8%2FVuYgLededm1T7tENA94fuaACAArz38BdTzRmhRKsQXXrdh%2FB6VuARmZQI0okk%2FTvO3gjAiZV6UDLeSiY5JiBRHO9B%2Bc6m2WemaRDWYfNCOoUJd9NCsMA3V9we%2FSUj6RRHGkHuL0nGxl8N0laZjeRQaa%2F%2FfrOOumxeBEiR5aWoD4rQz32HdNrp1FauljHwGVCQ6D0U13QA%2Bv7wXc5IPkxoCgFD2kvynTsLlYuH0dXyeTIU6jsRYHTPmgLvQMppGiT5SNWLVtdD4R8853KpEbyezR1drdzH2j%2FuXBFpF8fD%2BxItz6JWBxMfc1cpK2MQ5p%2Fs25DbZuQeqwT%2Fpx%2F%2BHHYJJeMDBZqUBBPYFbI7SjwIMQsQMKymzpkGOp8B3A%2FXlQ4FuQxSjXNPRTc0V%2BViV2N%2BfohBvtpmMblefEoDOF6ejqFd3dbKjcS658Lb3zgDTe%2BhVm68%2FVkrowc10q12rr5mE2daT0LRtWHxPhtadW%2Fd7bN197w4MGkOr6y2b8SSd2YFHyj9mU%2FN5LXn9klilURSDa9YdyLzJQleJQ9De%2B%2F2cfgaL2zdvrrwD6V0ygTOvhPflNqlNTPItCqR",
            "formFilename": "certificado (35).pdf",
            "formCategoryName": "Permission",
            "id": 37,
            "signedAt": null,
            "signature": null,
            "fields": [],
            "parent": null,
            "student": {
                "id": 12,
                "firstName": "Jessie",
                "lastName": "Whyte"
            }
        }`,*/
        }
      },
      trigger: { seconds: 1 },
    })
  }

    return (

        routeName === 'Profile' ?
            <View style={[headerStyles.header, { paddingTop: insets.top, justifyContent: 'space-between' }]}>
                <DrawerHeaderButton />
                <Text allowFontScaling={(PixelRatio.getFontScale()) <= 2} style={headerStyles.title2}>
                    {routeName}
                </Text>
                <NewEditSvg onPress={() => navigation.push('EditProfile')} />
            </View>
            :
            <View style={[headerStyles.header, { paddingTop: insets.top }]}>
                <DrawerHeaderButton />
                <View style={{ width: 5 }} />
                <ProfileImage
                    size={50} url={AppStateStore.userContext?.profilePhotoUrl ?? undefined}
                />
                <Pressable style={headerStyles.userInfo} /*onPress={() => testNotification()}*/>

                    <Text allowFontScaling={(PixelRatio.getFontScale()) <= 2} style={headerStyles.title}>
                        {welcomeMessage}, {fullName}
                    </Text>
                    <Text allowFontScaling={(PixelRatio.getFontScale()) <= 2} style={headerStyles.messages}>
                        {role}
                    </Text>

                </Pressable>
                <Pressable style={headerStyles.notification} onPress={() => navigation.push('NotificationCenter')}>
                    <NotificationIcon badgeNumber={badgeCount}/>
                </Pressable>

            </View>
    );
}