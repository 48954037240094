import React from "react";
import { Svg, Path } from 'react-native-svg';

export default (props: any) => {
    return (
        <Svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path  fill-rule="evenodd" clip-rule="evenodd" d="M19.6579 14.6459C19.1777 14.886 18.8475 15.4262 18.8475 15.9664V34.0936C18.8475 34.6639 19.1477 35.1741 19.6579 35.4442C19.8679 35.5342 20.108 35.5942 20.3481 35.5942C20.6483 35.5942 20.9784 35.5042 21.2185 35.2941L34.7239 26.2305C35.114 25.9304 35.3541 25.4802 35.3541 25.03C35.3541 24.5498 35.114 24.0996 34.7239 23.8295L21.2185 14.7659C20.7683 14.4358 20.1681 14.3758 19.6579 14.6459ZM25 50C11.1945 50 0 38.8055 0 25C0 11.1945 11.1945 0 25 0C38.8055 0 50 11.1945 50 25C50 38.8055 38.8055 50 25 50Z" fill="#FF7A33"/>
        </Svg>

    );
}