import React from 'react'
import { StyleSheet, View } from 'react-native'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';

export const NotificationForm = () => {
  return <Svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <Path d="M0.5 20C0.5 31.0457 9.45431 40 20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.95431 31.5457 0 20.5 0C9.45431 0 0.5 8.95431 0.5 20Z" fill="#0082CD"/>
  <Path d="M29.89 19.4C29.11 18.61 27.73 18.61 26.94 19.4L26.57 19.77V11.35C26.57 10.6 25.96 10 25.22 10H11.85C11.11 10 10.5 10.61 10.5 11.35V27.15C10.5 27.9 11.11 28.5 11.85 28.5H19.6L19.33 29.24C19.26 29.44 19.31 29.66 19.45 29.82C19.56 29.94 19.7 30 19.86 30C19.92 30 19.97 29.99 20.02 29.98L23.13 29.03C23.21 29 23.29 28.96 23.36 28.89L29.89 22.36C30.28 21.96 30.5 21.44 30.5 20.88C30.5 20.32 30.28 19.8 29.89 19.4ZM14.9 16.91H22.36C22.69 16.91 22.96 17.18 22.96 17.51C22.96 17.84 22.69 18.11 22.36 18.11H14.9C14.57 18.11 14.3 17.84 14.3 17.51C14.3 17.18 14.58 16.91 14.9 16.91ZM18.75 24.11H14.9C14.57 24.11 14.3 23.84 14.3 23.51C14.3 23.18 14.57 22.91 14.9 22.91H18.74C19.07 22.91 19.34 23.18 19.34 23.51C19.34 23.84 19.08 24.11 18.75 24.11ZM14.9 21.11C14.57 21.11 14.3 20.84 14.3 20.51C14.3 20.18 14.57 19.91 14.9 19.91H21.36C21.69 19.91 21.96 20.18 21.96 20.51C21.96 20.84 21.69 21.11 21.36 21.11H14.9ZM22.48 28.04L21.67 28.29C21.6 28.14 21.5 28.01 21.39 27.9C21.3 27.82 21.21 27.74 21.1 27.67L21.5 26.57H21.79V26.94C21.79 27.25 22.04 27.5 22.35 27.5H22.59L22.48 28.04ZM27.12 23.53L23.84 26.81C23.82 26.73 23.78 26.65 23.73 26.58C23.62 26.45 23.46 26.37 23.29 26.37H22.91V26C22.91 25.73 22.71 25.5 22.45 25.45L25.71 22.21C25.72 22.2 25.74 22.19 25.75 22.18C26.11 21.82 26.75 21.81 27.11 22.18C27.29 22.36 27.39 22.6 27.39 22.86C27.4 23.11 27.3 23.35 27.12 23.53ZM29.09 21.56L28.42 22.23C28.32 21.91 28.14 21.62 27.91 21.38C27.67 21.14 27.38 20.97 27.06 20.87L27.72 20.21C27.73 20.2 27.73 20.2 27.74 20.19C28.1 19.84 28.73 19.85 29.08 20.21C29.26 20.39 29.36 20.63 29.36 20.89C29.38 21.14 29.28 21.38 29.09 21.56Z" fill="white"/>
  </Svg>
  
  
}
