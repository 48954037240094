import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconProps } from '.';
import { AppColors } from '../../common/AppColors';

const MessageAudioPlayIcon = ({
  color = AppColors.gray.neutral3,
  height = 20,
  width = 20,
}: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path fillRule="evenodd" clipRule="evenodd" d="M7.86315 5.85834C7.67107 5.95438 7.53902 6.17047 7.53902 6.38655V13.6375C7.53902 13.8655 7.65906 14.0696 7.86315 14.1777C7.94718 14.2137 8.04322 14.2377 8.13926 14.2377C8.2593 14.2377 8.39136 14.2017 8.4874 14.1176L13.8896 10.4922C14.0456 10.3721 14.1417 10.1921 14.1417 10.012C14.1417 9.81993 14.0456 9.63986 13.8896 9.53181L8.4874 5.90636C8.30732 5.77431 8.06723 5.7503 7.86315 5.85834ZM10 20C4.47779 20 0 15.5222 0 10C0 4.47779 4.47779 0 10 0C15.5222 0 20 4.47779 20 10C20 15.5222 15.5222 20 10 20Z" fill={color} />
    </Svg>
  );
}

export default MessageAudioPlayIcon;