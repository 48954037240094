import React from "react";
import { Svg, Path } from 'react-native-svg';

interface IconProps {
    color: string,
    width: number,
    height: number
}

export default (props: IconProps) => {
    return (
        <Svg viewBox="0 0 16 21" fill="none" {...props}>
            <Path fillRule="evenodd" clip-rule="evenodd" d="M5.97 9.834C5.58 9.834 5.27 10.184 5.27 10.614V16.064C5.27 16.494 5.58 16.844 5.97 16.844C6.36 16.844 6.67 16.494 6.67 16.064V10.614C6.67 10.184 6.36 9.834 5.97 9.834ZM10.03 9.834C9.64 9.834 9.33 10.184 9.33 10.614V16.064C9.33 16.494 9.64 16.844 10.03 16.844C10.42 16.844 10.73 16.494 10.73 16.064V10.614C10.73 10.184 10.42 9.834 10.03 9.834ZM9.19 0.104004C10.31 0.104004 11.22 1.034 11.22 2.174V2.534H14.26C15.21 2.534 15.99 3.324 15.99 4.304V7.414C15.99 7.884 15.61 8.264 15.15 8.264H15.05L14.55 18.124C14.5 19.224 13.61 20.094 12.52 20.094H3.47C2.39 20.094 1.49 19.224 1.44 18.124L0.94 8.274H0.84C0.38 8.274 0 7.894 0 7.424V4.314C0 3.334 0.78 2.544 1.73 2.544H4.77V2.174C4.78 1.034 5.69 0.104004 6.81 0.104004H9.19ZM13.39 8.274L12.89 18.044C12.88 18.244 12.72 18.394 12.53 18.394H3.47C3.28 18.394 3.12 18.244 3.11 18.044L2.61 8.274H13.39ZM6.81 1.814H9.2C9.4 1.814 9.56 1.974 9.56 2.184V2.544H6.45V2.174C6.45 1.974 6.61 1.814 6.81 1.814ZM1.67 4.314C1.67 4.284 1.7 4.254 1.73 4.254H14.27C14.3 4.254 14.33 4.284 14.33 4.314V6.574H1.67V4.314Z" fill="#626262"/>
        </Svg>

    );
}
