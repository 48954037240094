import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useCallback, useLayoutEffect } from 'react'
import { FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View, Text, Platform } from 'react-native'
import { API } from '../../api/API'
import { runInAction } from 'mobx'
import { useTranslation } from 'react-i18next'
import { extractErrorMessage, fullName, MYSQL_DATE_FORMAT } from '../../common/Util'
import { ContentActivityIndicator } from '../../components/ContentActivityIndicator'
import { ErrorMessage } from '../../components/ErrorMessage'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { AttendanceSection } from '../../api/schema/models/AttendanceSection'
import moment from 'moment-timezone'
import { DefaultText } from '../../components/DefaultText'
import { Colors } from '../../common/Colors'
import { MaterialIcons } from '@expo/vector-icons'
import { SectionAttendee } from '../../api/schema/models/SectionAttendee'
import { FormSwitchInput } from '../../components/forms/FormSwitchInput'
import { ListItemSeparator } from '../../components/ListItemSeparator'
import { SafeAreaView } from 'react-native-safe-area-context'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { getModalManager } from '../../contexts/ModalContext'
import { NewBackArrowSvg} from '../../../assets/images/back-arrow-svg'
import { BoldText } from '../../components/BoldText'
import { ModalPicker } from '../../components/ModalPicker'
import { BottomDrawer } from '../../components/BottomDrawer'
import { BlockButton } from '../../components/BlockButton'
//import * as Sentry from 'sentry-expo'

export type TakeAttendanceScreenParams = {
  sectionId: number
  date: string
}

export const TakeAttendanceScreen = observer((props: StackScreenProps<RootNavigationParamList, 'TakeAttendance'>) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    data: undefined as {
      attendanceSection: AttendanceSection,
      attendees: SectionAttendee[],
      statuses: {value: string, text: string}[],
    } | undefined,
    loading: false,
    error: undefined as string | undefined,
    completed: false,
    showPicker: false,
    showDrawer: false,
    coordY: 0,
    coordX: 0,
    selectedStudent: 0
  }))

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Take Attendance', 'Take Attendance'),
      headerStyle: {backgroundColor: '#fff', borderBottomWidth: 0},
      headerTitleStyle: {color: '#000'},
      headerBackImage: () => <NewBackArrowSvg></NewBackArrowSvg>
    })
  }, [props.navigation])

  const loadData = useCallback(async () => {
    runInAction(() => {
      state.error = undefined
      state.loading = true
    })

    try {
      const response = await API.getSectionAttendees(props.route.params.sectionId, moment(props.route.params.date, MYSQL_DATE_FORMAT))
      runInAction(() => {
        state.data = {
          attendanceSection: response.attendanceSection,
          attendees: response.attendees,
          statuses: response.attendanceStatuses,
        }

        state.completed = !!response.attendanceSection.attendanceCompletedAt
      })
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => {
        state.error = extractErrorMessage(err)
      })
    }

    runInAction(() => {
      state.loading = false
    })
  }, [state, props.route.params.date, props.route.params.sectionId])

  useLayoutEffect(() => {
    loadData().then()
  }, [loadData])

  const renderItem = (info: ListRenderItemInfo<SectionAttendee>) => {
    return <TouchableOpacity onPress={(event) => showStatusPicker(info.item.id, event.nativeEvent.pageY, event.nativeEvent.pageX)} style={{backgroundColor:'#F6F7FA', marginHorizontal:16, marginVertical: 8, borderRadius:6}}>
      <View style={{ flexDirection: 'row', alignItems: 'center', padding: 12 }}>
        <DefaultText style={{ flex: 1 }}>{fullName(info.item)}</DefaultText>
        <BoldText style={{ fontSize:12, 
          color: info.item.status == 'Present' ? '#4ED542': info.item.status == 'Late' ? '#4DC9D1' : 
          info.item.status == 'Absent Excused' ? '#8D5EF2' : info.item.status == 'Absent Unexcused' ? '#F10000' : '#000'}}>{info.item.status}</BoldText>
        <MaterialIcons
          name={'keyboard-arrow-down'}
          size={30}
          color={'#FF7A33'}
        />
      </View>
    </TouchableOpacity>
  }

  const markAttendanceComplete = async (complete: boolean) => {
    AppStateStore.showActivityIndicator()

    try {
      await API.markAttendanceComplete({
        sectionId: props.route.params.sectionId,
        date: props.route.params.date,
        complete,
      })

      AppStateStore.eventBus.emit(AppEvents.AttendanceInvalidated)

      await loadData()
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      getModalManager()
        .showModal({
          title: t('Error', 'Error'),
          message: extractErrorMessage(err),
        })
    }

    AppStateStore.hideActivityIndicator()
  }

  const setAttendanceStatus = async (studentId: number, status: string | null) => {
    AppStateStore.showActivityIndicator()

    try {
      await API.setAttendanceStatus({
        sectionId: props.route.params.sectionId,
        date: props.route.params.date,
        studentId,
        status,
      })

      AppStateStore.eventBus.emit(AppEvents.AttendanceInvalidated)

      await loadData()
      if(state.completed) {
        markAttendanceComplete(false)
        runInAction(() => state.completed = false)
      }
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      getModalManager()
        .showModal({
          title: t('Error', 'Error'),
          message: extractErrorMessage(err),
        })
    }

    AppStateStore.hideActivityIndicator()
  }

  const showStatusPicker = (studentId: number, yCoord: number, xCoord: number) => {
    runInAction(() => {state.showPicker = true; state.coordY = yCoord; state.coordX = xCoord; state.selectedStudent = studentId })
  }

  const onSelectAttendance = (optionValue: string) => {
    runInAction(() => {state.showPicker = false; })
    setAttendanceStatus(state.selectedStudent, optionValue).then()
  }

  return <View style={styles.container}>
    {
      state.error
        ? <ErrorMessage message={state.error}/>
        : !state.data
          ? <ContentActivityIndicator/>
          : <>
            <View style={styles.section}>
              <BoldText style={styles.sectionText}>{state.data.attendanceSection.section.name}</BoldText>
            </View>
            <FormSwitchInput
              label={t('Attendance is completed', 'Attendance is completed')}
              value={state.completed}
              onChange={value => runInAction(() => {
                state.completed = value
                state.showDrawer = true
                //markAttendanceComplete(value).then()
              })}
            />
            <FlatList
              data={state.data.attendees}
              keyExtractor={s => String(s.id)}
              renderItem={renderItem}
              ListFooterComponent={<SafeAreaView edges={['bottom']}/>}
            />
          </>
    }
    {
      state.showPicker
      ? <ModalPicker outsideTouch={() => runInAction(() => state.showPicker = false)} 
        yCoord={state.coordY} xCoord={400} radius={5} width={200}
        options={state.data?.statuses ?? []}
        onSelect={(value) => onSelectAttendance(value)}></ModalPicker>
      : null
    }

{
      state.showDrawer
      ? <BottomDrawer  height={370} radius={30} backgrounColor={'#fff'} outsideTouch={() => runInAction(() => {state.completed = !state.completed; state.showDrawer = false})}>
      <Text style={styles.drawerHeaderText}>{t('Confirm', 'Confirm')}</Text>
      {
        state.completed
        ?<Text style={styles.subText}>{t('Confirm Attendance Complete', 'Are you sure you want to mark attendance as completed?')}</Text>
        :<Text style={styles.subText}>{t('Confirm Attendance not Complete', 'Are you sure you want to mark attendance as not completed?')}</Text>
      }
      <BlockButton
        title={t('Yes Continue', 'Yes, Continue')}
        variant={'primary'}
        onPress={() => {
          runInAction(() => {state.showDrawer = false})
          markAttendanceComplete(state.completed).then()}}
        textStyle={{ fontSize: 16}}
        style={{ marginHorizontal: 25, minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 20, backgroundColor: '#FF7A33' }}
      />
      <BlockButton
        title={t('Cancel', 'Cancel')}
        variant={'secondary'}
        onPress={() => {
          runInAction(() => {state.completed = !state.completed; state.showDrawer = false})
          }}
        textStyle={{ fontSize: 16}}
        style={{ marginHorizontal: 25, minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 20, backgroundColor: '#0082CD'}}
      />
    </BottomDrawer>
    : null
    }
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  section: {
    padding: 12,
  },
  sectionHeaderText: {
    color: Colors.PrimaryOrange,
    marginBottom: 4,
  },
  sectionText: {
    fontSize: 18,
    fontWeight: '500',
    color: '#0082CD'
  },
  content: {
    backgroundColor: '#fff',
  },
  drawerHeaderText: {
    paddingTop: 30,
    fontSize: 24,
    color: '#0082CD',
    textAlign: 'center',
    fontWeight: '500',
  },
  subText: {
    paddingTop: 15,
    paddingHorizontal: 50,
    fontSize: 16,
    color: '#303030',
    textAlign: 'center',
    fontWeight: '400',
  },
})
