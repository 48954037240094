import { MaterialCommunityIcons } from "@expo/vector-icons"
import { StackActions, useNavigation } from "@react-navigation/native"
import React from "react"
import { Pressable, View, Text, StyleSheet, Platform, ScrollView } from "react-native"
import { Colors } from "../../../common/Colors"
import { ArrowForward } from "./icons/Icons"


export const PostOptionList = () => {

    const navigation = useNavigation();

    return (
        <ScrollView style={styles.optionList} contentContainerStyle={{justifyContent: 'space-evenly',}}>
                <PostOptionItem 
                    optionTitle={'Announcement / Message'} 
                    onClick={() => {
                        navigation.dispatch(StackActions.replace('CreatePost' as never, { postType: 'post' } as never))
                    }}
                    optionInfo={'Share something with your community'}
                    optionColor={'#0082CD'}
                />

                <PostOptionItem 
                    optionTitle={'Event'} 
                    optionInfo={'Add an event to the calendar'}
                    optionColor={'#FF7A33'}
                    onClick={() => {
                        navigation.dispatch(StackActions.replace('EditEvent' as never))
                    }}
                />

                <PostOptionItem 
                    optionTitle={'Poll / Survey'} 
                    optionInfo={'Survey your community '}
                    optionColor={'#4ED442'}
                    onClick={() => {
                        navigation.dispatch(StackActions.replace('CreatePost' as never, { postType: 'survey' } as never))

                    }}
                />

                <PostOptionItem 
                    optionTitle={'Urgent Alert'} 
                    onClick={() => {
                        navigation.dispatch(StackActions.replace('CreatePost' as never, { postType: 'urgentAlert' } as never))
                    }}
                    optionInfo={'Inmediately alert your community'}
                    optionColor={'#F10000'}
                />
                
                <View style={{height: 100}}>

                </View>
            </ScrollView>

    );
}

const PostOptionItem = ({optionTitle, optionInfo, onClick}: PostOptionProps) => {
    return <Pressable style={styles.optionItem} onPress={() => onClick()}>
        <View>
            <Text style={styles.optionTitle}>{optionTitle}</Text>
            <Text style={styles.optionSubtitle}>{optionInfo}</Text>
        </View>
        <ArrowForward/>
    </Pressable>
}

interface PostOptionProps {
    optionTitle: string,
    optionInfo: string,
    onClick: () => void,
    optionColor: string,
}

const styles = StyleSheet.create({
    
    optionList: {
        flex: 10,
        flexDirection: 'column',
    },
    closeModal: {    
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'absolute'
    },
    optionItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: "#F6F7FA",
        paddingVertical: 20,
        paddingHorizontal: 10,
        alignItems: 'center',
        borderRadius: 13,
        marginVertical: 13

    },
    optionTitle: {
        fontSize: 18,
        fontWeight: '700'
    },
    optionSubtitle: {
        fontWeight: '500',
        fontSize: 15
    },

  })
  

