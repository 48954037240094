import React from "react"
import Modal from "react-native-modal"
import { View, StyleSheet, Dimensions, Pressable } from "react-native"
import { BlockButtonVariant } from '../BlockButton'
import { AppColors } from '../../common/AppColors'
import { useTranslation } from 'react-i18next'
import { MaterialCommunityIcons } from '@expo/vector-icons'

type ModalButton = {
  text: string
  onPress?: (dismiss: () => void) => void
  variant?: BlockButtonVariant
}

type Props = {
  isVisible: boolean
  dismiss: () => void
  title?: React.ReactElement
  message?: React.ReactElement
  buttons?: ModalButton[]
  children?: React.ReactElement | React.ReactElement[]
  showClosse?: boolean
  drawerHeight?: number
}

const window = Dimensions.get('window')

const ContentModal = ({ isVisible, dismiss, children, showClosse }: Props) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <Modal
        isVisible={isVisible}
        coverScreen={true}
        onBackdropPress={dismiss}
        style={{ margin: 0, }}
        animationIn="slideInUp"
        animationOut="slideOutDown"
        propagateSwipe={true}
        //statusBarTranslucent={true}
        useNativeDriver={true}
        /*  customBackdrop={
           <View style={{ flex: 1, backgroundColor: 'red' }} />
         } */
        backdropOpacity={0.5}
      //useNativeDriverForBackdrop={true}
      >
        <View style={{ flex: 1, marginVertical: 50, marginHorizontal: 20 }}>
          {children}
        </View>

        {showClosse && (
          <Pressable
            style={[styles.closeModalButton]}
            onPress={() => dismiss()}
            hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
          >
            <MaterialCommunityIcons
              name="close"
              color={AppColors.white}
              size={22} />
          </Pressable>
        )}
      </Modal>
    </View>
  )
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeModalButton: {
    position: 'absolute',
    bottom: 15,
    right: 15,
    zIndex: 100,
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: 30,
    top: 35,
    backgroundColor: AppColors.blue.primary,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3,
    elevation: 3,
  },
})

export default ContentModal