import React from 'react'
import { Svg, Path, Circle } from "react-native-svg"

type Props = {
  width: number
  height: number
  color: string
}

const CandyBox = (props: Props) => {
  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 29 11" fill="none" >
      <Circle cx="2.5" cy="2.20703" r="2" fill={props.color} />
      <Circle cx="10.5" cy="2.20703" r="2" fill={props.color} />
      <Circle cx="18.5" cy="2.20703" r="2" fill={props.color} />
      <Circle cx="26.5" cy="2.20703" r="2" fill={props.color} />
      <Circle cx="2.5" cy="8.20703" r="2" fill={props.color} />
      <Circle cx="10.5" cy="8.20703" r="2" fill={props.color} />
      <Circle cx="18.5" cy="8.20703" r="2" fill={props.color} />
      <Circle cx="26.5" cy="8.20703" r="2" fill={props.color} />
    </Svg>
  )
}

export default CandyBox