import React, { useCallback, useEffect } from 'react'
import { ScrollView, StyleSheet, View, Text, TouchableOpacity, Pressable, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { HeaderTextButton } from '../../components/HeaderTextButton'
import { BlockButton } from '../../components/BlockButton'
import { AppStateStore } from '../../contexts/AppStateStore'
import { ProfilePhotoInput } from '../../components/ProfilePhotoInput'
import { runInAction } from 'mobx'
import { API } from '../../api/API'
import { extractErrorMessage, fullName } from '../../common/Util'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { GetProfileResponse } from '../../api/schema/GetProfileResponse'
import { ContentActivityIndicator } from '../../components/ContentActivityIndicator'
import { ErrorMessage } from '../../components/ErrorMessage'
import { BoldText } from '../../components/BoldText'
import { DefaultText } from '../../components/DefaultText'
import { ListItemSeparator } from '../../components/ListItemSeparator'
import { ProfileSection } from '../../api/schema/models/ProfileSection'
import { ProfileContact } from '../../api/schema/models/ProfileContact'
import { AppVersion } from '../../components/AppVersion'
import { DrawerHeaderButton } from '../../components/DrawerHeaderButton'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { NewEditSvg } from '../../../assets/images/edit-svg'
import { ProfileImage } from '../../components/ProfileImage'
import { AddSvg } from '../../../assets/images/add-svg'
import { RemoveSvg } from '../../../assets/images/remove-svg'
import { CloseSvg } from '../../../assets/images/close-svg'
import { BottomDrawer } from '../../components/BottomDrawer'
import { getModalManager } from '../../contexts/ModalContext'
import { AppColors } from '../../common/AppColors'
import { OwlieNotes } from '../../../assets/images/owlie-notes'
import * as Notifications from 'expo-notifications'
import moment from 'moment-timezone'
import { MaterialIcons } from '@expo/vector-icons'

//import * as Sentry from 'sentry-expo'

export const ProfileScreen = observer((props: StackScreenProps<RootNavigationParamList, 'Profile'>) => {
  const { t } = useTranslation()

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Profile', 'Profile'),
      headerLeft: () => <DrawerHeaderButton />,
      headerRight: () => <NewEditSvg onPress={() => props.navigation.push('EditProfile')} />,
    })
  }, [props.navigation])



  const state = useLocalObservable(() => ({
    loading: false,
    profile: undefined as GetProfileResponse | undefined,
    error: undefined as string | undefined,
    profilePhotoUrl: undefined as string | undefined,
    confirmContact: undefined as ProfileContact | undefined,
    showDrawer: false,
    deleteDrawer: false,
    deleteSuccessDrawer: false,
    confirmIndex: undefined as number | undefined
  }))

  const loadProfile = useCallback(async () => {
    runInAction(() => state.loading = true)

    try {
      const profile = await API.getProfile()

      runInAction(() => {
        state.profile = profile
        state.profilePhotoUrl = profile.profilePhotoUrl ?? undefined
      })
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [])

  const removeStudent = (async () => {
    const response = await API.removeStudent({ studentId: state.confirmContact.id })
    console.log(state.profile?.students)
    runInAction(() => {
      state.profile.students = state.profile.students.slice(0, state.confirmIndex).concat(state.profile.students.slice(state.confirmIndex + 1, 1000))
      state.showDrawer = false
    })
  })

  const confirmRemove = ((student: ProfileContact, index: number) => {
    if (state.profile?.students.length > 1) {
      runInAction(() => {
        state.confirmContact = student
        state.showDrawer = true
        state.confirmIndex = index
      })
    }
    else {
      getModalManager()
        .showModal({
          title: t('Error', 'Error'),
          message: t('You cant remove all your students', 'You cannot remove all your students'),
        })
    }
  })

  const deleteAccount = (async () => {
    runInAction(() => {
      state.deleteDrawer = false
    })
    try {
      const response = await API.deleteAccount()
      if (response?.success) {
        runInAction(() => {
          state.deleteSuccessDrawer = true
        })
      }
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      getModalManager()
        .showModal({
          title: t('Error', 'Error'),
          message: extractErrorMessage(err.response),
        })
    }
  })

  const testNotification = async () => {
    let now = moment()
    console.log(now.hours())
    const id = await Notifications.scheduleNotificationAsync({
      content: {
        title: "local notification",
        body: "body notification",
        sound: 'default',
        data: {
          notificationType: 'post',
          id: 154
        }
      },
      trigger: { seconds: 1 },
    })
    console.log(id)
  }

  const renderSectionList = (sections: ProfileSection[]) => {
    return sections.length
      ? <View style={styles.listContainer}>
        <BoldText style={styles.listHeader}>{t('My Classes', 'My Classes')}</BoldText>
        {
          sections.map((section, idx) => <View key={section.id}>
            <TouchableOpacity onPress={(event) => {
              if (AppStateStore.userContext?.userType === 'teacher') {
                props.navigation.push('SectionMembers', { sectionId: section.id, sectionName: section.name })
              }
            }}>
              <View style={styles.listItem}>
                <DefaultText style={styles.listItemText}>{section.name}</DefaultText>
                {AppStateStore.userContext?.userType === 'teacher' ? <MaterialIcons
                  name={'chevron-right'}
                  size={30}
                  color={'#FF7A33'}
                /> : null}
              </View>

            </TouchableOpacity>
          </View>)
        }
      </View>

      : null
  }


  const renderContactList = (title: string, contacts: ProfileContact[], isParent: boolean) => {
    return contacts.length
      ? <View style={styles.listContainer}>
        {/* onPress={() => {console.log('click'); addStudent()}} */}
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {/* <BoldText style={styles.listHeader}>{title}</BoldText> */}
          <TouchableOpacity /*onPress={() => {testNotification()}}*/>
            <BoldText style={styles.listHeader}>{title}</BoldText>
          </TouchableOpacity>
          {
            /* isParent ?
              <TouchableOpacity onPress={() => { console.log('click'); props.navigation.push('SearchSchoolDistrict') }} style={{ marginLeft: 'auto', marginRight: 18 }}>
                <AddSvg></AddSvg>
              </TouchableOpacity>
              : null */
          }

        </View>
        {/* <BoldText style={styles.listHeader}>{title}</BoldText> */}
        {
          contacts.map((contact, idx) => <View key={contact.id} style={{ marginVertical: 8, borderRadius: 6, minHeight: 56, justifyContent: 'center' }}>
            <View style={styles.listItem}>
              <DefaultText style={styles.listItemText}>
                {fullName(contact)}
              </DefaultText>
              {/* {isParent ? <Pressable onPress={() => { confirmRemove(contact, idx) }} style={{ marginLeft: 'auto', marginRight: 18 }}><RemoveSvg></RemoveSvg></Pressable> : null} */}
            </View>
          </View>)
        }
      </View>
      : null
  }

  useEffect(() => {
    const reloadProfile = props.navigation.addListener('focus', () => {
      loadProfile().then()
    })
    //loadProfile().then()
  }, [loadProfile])

  return <View style={styles.container}>
    <View style={styles.content}>
      {
        state.loading
          ? <ContentActivityIndicator />
          : state.error
            ? <ErrorMessage message={t(state.error)} />
            : state.profile
              ? <>
                <View style={styles.header}>
                  <View style={styles.profilePhoto}>
                    <ProfileImage size={100} url={AppStateStore.userContext?.profilePhotoUrl} />
                  </View>
                  <View style={{ flex: 1 }}>
                    <BoldText style={{ fontSize: 16, marginBottom: 8, flexDirection: 'row' }}>{fullName(state.profile)}</BoldText>
                    {
                      state.profile.school
                        ? <DefaultText style={{ fontSize: 12, marginBottom: 8 }}>{state.profile.school.name}</DefaultText>
                        : null
                    }
                    <DefaultText style={{ fontSize: 12 }}>{state.profile.emailAddress ?? state.profile.phoneNumber}</DefaultText>
                  </View>
                </View>
                <ScrollView style={{ flex: 1 }}>
                  {renderSectionList(state.profile.sections)}
                  {renderContactList(t('My Parents', 'My Parents'), state.profile.parents, false)}
                  {renderContactList(t('My Students', 'My Students'), state.profile.students, true)}
                </ScrollView>
              </>
              : null
      }
    </View>
    <View style={styles.footer}>
      <BlockButton
        title={t('Log Out', 'Log Out')}
        variant={'secondary'}
        onPress={() => {
          if (AppStateStore.pushNotificationToken)
            API.deletePushNotificationToken(AppStateStore.pushNotificationToken).then()
          AppStateStore.logout()
        }}
        textStyle={{ fontSize: 16 }}
        style={{ width: '95%', minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 20, backgroundColor: '#FF7A33' }}
      />

      <Pressable
        onPress={() => runInAction(() => state.deleteDrawer = true)}
        style={({ pressed }) => [
          {
            marginVertical: 30,
            opacity: pressed ? 0.5 : 1,
          },
        ]}>
        <DefaultText style={{ fontSize: 16, color: AppColors.gray.neutral2, textAlign: 'center' }}>{t('Delete Account', 'Delete My Account')}</DefaultText>
      </Pressable>

    </View>
    {
      state.showDrawer ?
        <BottomDrawer height={370} radius={30} backgrounColor={'#fff'}
          outsideTouch={() => runInAction(() => state.showDrawer = false)}>
          <Text style={styles.drawerHeaderText}>{t('Confirm', 'Confirm')}</Text>
          <Text style={styles.subText}>{t('Confirm unlink student', 'Are you sure you want to remove the student {{name}}?', { name: `${state.confirmContact?.firstName} ${state.confirmContact?.lastName}` })}</Text>
          <BlockButton
            title={t('Yes continue', 'Yes, continue')}
            variant={'primary'}
            onPress={() => removeStudent()}
            textStyle={{ fontSize: 16 }}
            style={{ marginHorizontal: 25, minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 20, backgroundColor: '#FF7A33' }}
          />
          <BlockButton
            title={t('Cancel', 'Cancel')}
            variant={'secondary'}
            onPress={() => runInAction(() => state.showDrawer = false)}
            textStyle={{ fontSize: 16 }}
            style={{ marginHorizontal: 25, minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 20, backgroundColor: '#0082CD' }}
          />
        </BottomDrawer>
        :
        null
    }
    {
      state.deleteDrawer ?
        <BottomDrawer height={'65%'} radius={30} backgrounColor={'#fff'}
          outsideTouch={() => runInAction(() => state.deleteDrawer = false)}>
          <Text style={[styles.drawerHeaderText, { marginTop: 40 }]}>{t('Delete Account', 'Delete My Account')}</Text>
          <Text style={[styles.subText, Platform.select({ web: { paddingHorizontal: '30%' } })]}>{t('Delete paragraph one', "Once you delete your EduKonekti account, you will no longer receive any communication from your school or school district, like messages from your teacher, school events, important news, etc.")}</Text>
          <Text style={[styles.subText, Platform.select({ web: { paddingHorizontal: '30%' } })]}>{t('Delete paragraph two', "If you're sure you want to delete your account, we'll send a request for deletion to the school district and we'll email you when your account has been completely deleted.")}</Text>
          <BlockButton
            title={t('Yes continue', 'Yes, continue')}
            variant={'primary'}
            onPress={() => deleteAccount()}
            textStyle={{ fontSize: 16 }}
            style={{ marginHorizontal: 25, minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 'auto', backgroundColor: '#FF7A33' }}
          />
          <BlockButton
            title={t('Cancel', 'Cancel')}
            variant={'secondary'}
            onPress={() => runInAction(() => state.deleteDrawer = false)}
            textStyle={{ fontSize: 16 }}
            style={{ marginHorizontal: 25, minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 20, marginBottom: 64, backgroundColor: '#0082CD' }}
          />
        </BottomDrawer>
        :
        state.deleteSuccessDrawer ?
          <BottomDrawer height={'102%'} radius={0} backgrounColor={'#fff'} overlayColor={'#fff'}
            outsideTouch={() => {
              runInAction(() => state.deleteSuccessDrawer = false)
              AppStateStore.logout()
            }}>
            <View style={{ alignItems: 'center', marginTop: 80 }}>
              <OwlieNotes></OwlieNotes>
            </View>
            <Text style={[styles.drawerHeaderText, { marginTop: 20, paddingTop: 0 }]}>{t('Request submitted', 'Request Submitted')}</Text>
            <Text style={styles.subText}>{t('Remove Successful', "Your request to delete the account has been received and will be processed.")}</Text>
            <BlockButton
              title={t('Close', 'Close')}
              variant={'primary'}
              onPress={() => {
                runInAction(() => state.deleteSuccessDrawer = false)
                AppStateStore.logout()
              }}
              textStyle={{ fontSize: 16 }}
              style={{ marginHorizontal: 25, minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 'auto', marginBottom: 40, backgroundColor: '#FF7A33' }}
            />
          </BottomDrawer>
          :
          null
    }
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  content: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  profilePhoto: {
    padding: 16,
  },
  listContainer: {
    paddingTop: 16,
    paddingHorizontal: 16
  },
  listHeader: {
    marginBottom: 8,
    marginLeft: 16,
    fontSize: 18,
    color: '#0082CD'
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
    backgroundColor: '#F6F7FA',
    borderRadius: 6,
    minHeight: 56,
    justifyContent: 'center',
    marginVertical: 8,
  },
  listItemText: {
    flex: 1,
    fontSize: 16,
    fontWeight: 'bold'
  },
  footer: {
    alignItems: 'center'
  },
  appVersion: {
    alignItems: 'flex-end',
    padding: 4,
  },
  drawerHeaderText: {
    paddingTop: 30,
    fontSize: 24,
    color: '#0082CD',
    textAlign: 'center',
    fontWeight: '500',
  },
  subText: {
    paddingTop: 15,
    paddingHorizontal: 50,
    fontSize: 16,
    color: '#303030',
    textAlign: 'center',
    fontWeight: '400',
  },
})
