import React from 'react'
import { ScrollView, StyleSheet, View, Text, Platform } from 'react-native'
import { BoldText } from '../../components/BoldText'
import { Colors } from '../../common/Colors'
import { Sizes } from '../../common/Sizes'
import { BlockButton } from '../../components/BlockButton'
import { StyledTextInput } from '../../components/StyledTextInput'
import { DefaultText } from '../../components/DefaultText'
import { SmartKeyboardAvoidingView } from '../../components/SmartKeyboardAvoidingView'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { useTranslation } from 'react-i18next'
import { API } from '../../api/API'
import { handleErrorResponse } from '../../common/Util'
import { FormError } from '../../components/FormError'
import ErrorBag from '../../common/ErrorBag'
import { ValidationError } from '../../exceptions/ValidationError'
import SegmentedControl from '@react-native-segmented-control/segmented-control'
import { getModalManager } from '../../contexts/ModalContext'
import { AppStateStore } from '../../contexts/AppStateStore'
import { ForgottenPasswordRequest } from '../../api/schema/ForgottenPasswordRequest'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';
import { AppColors } from '../../common/AppColors'
import { MiniLogoSvg } from '../../../assets/images/mini-logo'
//import * as Sentry from 'sentry-expo'

export const ForgotIdentification = observer((props: StackScreenProps<RootNavigationParamList, 'ForgotIdentification'>) => {
  const { t } = useTranslation()

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('', ''),
      headerStyle: { backgroundColor: '#fff', borderBottomWidth: 0 },
      headerShown: true,
      headerTitle: '',
      headerBackImage: () => <Svg width="13" height="21" viewBox="0 0 13 21" fill="none" >
        <Path d="M12.59 2.23001C13.13 1.72001 13.13 0.890007 12.59 0.390007C12.05 -0.119993 11.17 -0.119993 10.63 0.390007L1.4 9.09001C0.859995 9.60001 0.859995 10.43 1.4 10.93L10.63 19.63C11.17 20.14 12.05 20.14 12.59 19.63C13.13 19.12 13.13 18.29 12.59 17.79L4.34 10L12.59 2.23001Z" fill="#FF7A33" />
      </Svg>

    })
  }, [props.navigation])

  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  const phoneRegex = /^\d{10}$/

  const state = useLocalObservable(() => ({
    form: {
      phoneNumber: '',
      emailAddress: '',
      emailOrPhone: '',
    },
    authMethod: '',
    formErrors: new ErrorBag(),
    submitting: false,
    get isValid() {
      return (this.form.emailOrPhone.trim().length)
      //return (this.form.emailAddress.trim().length > 10)
    },
    hidePassword: true
  }))

  const _changeIcon = () => {
    state.hidePassword = !state.hidePassword
  }
  const showRegister = () => {
    props.navigation.push('Register')
  }


  const validateEmailOrPhone = (input: string) => {

    if (emailRegex.test(input)) {

      runInAction(() => {
        state.authMethod = 'email'
        state.form.emailAddress = input
        state.form.emailOrPhone = input
      })

    } else if (phoneRegex.test(input)) {

      const formatPhoneNumber = formatPhone(input)

      runInAction(() => {
        state.authMethod = 'phone'
        state.form.phoneNumber = formatPhoneNumber
        state.form.emailOrPhone = getNumber(input)
      })

    } else {
      //remove spaces and parens
      const getinput = getNumber(input)

      runInAction(() => {
        state.authMethod = ''
        state.form.emailOrPhone = getinput
      })
    }

  }

  const submit = async () => {
    runInAction(() => {
      state.submitting = true
      state.formErrors.clearErrors()
    })

    try {
      let request: ForgottenPasswordRequest

      if (!emailRegex.test(state.form.emailOrPhone) && !phoneRegex.test(state.form.emailOrPhone)) {
        runInAction(() => {
          state.formErrors.addError('emailAddress', t('Please enter a valid email address or phone number', 'Please enter a valid email address or phone number'))
          state.submitting = false
        })
        return
      }

      if (state.authMethod === 'phone') {
        runInAction(() => {
          state.form.emailOrPhone = '+1' + state.form.emailOrPhone
        })
      }

      request = { email: state.form.emailOrPhone }

      const response = await API.forgotPassword(request)
      props.navigation.push('VerifyForgotten', { email: state.form.emailOrPhone, countdown: response.countdown })
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      if (err.name === 'ValidationError') {
        getModalManager()
          .showModal({
            title: t('Error', 'Error'),
            message: err.message,
          })
      } else {
        runInAction(() => {
          handleErrorResponse(err.response, state.formErrors, {
            unhandledErrors: {
              handledErrorFields: Object.keys(state.form),
              unhandledErrorsKey: '_unhandled',
            }
          })
        })
      }
    }

    runInAction(() => {
      state.submitting = false
    })
  }

  return <SmartKeyboardAvoidingView style={styles.container}>
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1
      }}
      keyboardShouldPersistTaps="handled"
    >
      <View style={styles.main}>
        <MiniLogoSvg></MiniLogoSvg>
        <BoldText style={styles.headerText}>{t('Forgot password?', 'Forgot password?')}</BoldText>
        <Text style={styles.subTitleText}>{t('Please enter the email or phone number associated with your account', 'Please enter the email or phone number associated with your account')}</Text>
        <View style={styles.formContainer}>
          <FormError field={'_unhandled'} errors={state.formErrors} displayAsList={true} containerStyle={{ marginBottom: 16 }} />

          <StyledTextInput
            placeholder={t('Email or phone number', 'Email or phone number')}
            keyboardType={'email-address'}
            autoCapitalize={'none'}
            //value={state.form.emailOrPhone}
            //onChangeText={t => runInAction(() => state.form.emailOrPhone = t)}
            value={state.authMethod === 'phone' ? state.form.phoneNumber : state.form.emailOrPhone}
            maxLength={state.authMethod === 'phone' ? 14 : 100}
            onChangeText={t => { validateEmailOrPhone(t) }}
            editable={!state.submitting}
            style={{ minHeight: 56, borderColor: AppColors.blue.primary, borderWidth: 2, backgroundColor: AppColors.gray.neutral6, marginTop: 28 }}
          />
          <FormError field={'emailOrPhone'} errors={state.formErrors} />
          <BlockButton
            title={t('Submit', 'Submit')}
            variant={'secondary'}
            flex={1}
            disabled={!state.isValid || state.submitting}
            onPress={submit}
            textStyle={{ fontSize: 16 }}
            style={{ marginRight: 1, width: '100%', minHeight: 60, maxHeight: 60, borderRadius: 8, marginTop: 20, backgroundColor: (!state.isValid || state.submitting) ? '#9D9FA0' : '#FF7A33' }}
          />

        </View>
      </View>
    </ScrollView>
  </SmartKeyboardAvoidingView>
})

//formatting phone number (xxx) xxx xxxx
const formatPhone = (input: string) => {
  const getinput = input.replace(/\s/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '')
  const getNumber = getinput.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 $3')
  return getNumber
}

//get phone number without formatting
const getNumber = (number: string) => {
  return number.replace(/\s/g, '').replace(/\(/g, '').replace(/\)/g, '')
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  main: {
    flex: 1,
    alignItems: 'center',
  },
  headerText: {
    marginTop: 24,
    paddingHorizontal: 24,
    fontSize: 24,
    fontWeight: '500',
    color: '#0082CD',
    textAlign: 'center',
  },
  subTitleText: {
    marginTop: 24,
    paddingHorizontal: 24,
    fontSize: 16,
    color: '#626262',
    textAlign: 'center',
  },
  label: {
    fontSize: Sizes.DefaultTextSize,
    textAlign: 'center',
    marginBottom: 8,
  },
  formContainer: {
    paddingHorizontal: 16,
    width: '100%',
  },
  formNote: {
    textAlign: 'center',
    color: '#626262',
    marginTop: 4,
    marginBottom: 10
  },
  loginText: {
    color: '#000',
    fontWeight: '400',
    fontSize: 16,
    marginBottom: 30
  },
  forgotText: {
    color: '#00f',
    fontWeight: '500',
    fontSize: 16,
    marginTop: 25
  },
  footer: {
    alignItems: 'center',
  },
})
