import { StyleSheet, View } from 'react-native'
import React from 'react'
import { AppColors as COLORS } from '../common/AppColors'

export const ListItemSeparator = () => <View style={styles.itemSeparator} />

const styles = StyleSheet.create({
  itemSeparator: {
    height: 1,
    backgroundColor: COLORS.gray.neutral4,
  },
})
