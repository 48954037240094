import { NativeSyntheticEvent, StyleSheet, TextInput, TextInputContentSizeChangeEventData, TextInputProps, View, Platform } from 'react-native'
import React, { useEffect } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { ListItemSeparator } from '../ListItemSeparator'
import ErrorBag from '../../common/ErrorBag'
import { FormError } from '../FormError'
import { AppColors as COLORS } from '../../common/AppColors'

type Props = TextInputProps & {
  autoGrow?: boolean
  hideSeparator?: boolean
  errors?: ErrorBag
  fieldName?: string
  rightAccessory?: React.ReactElement
}

export const FormTextInput = observer((props: Props) => {
  const { style, ...rest } = props

  const state = useLocalObservable(() => ({
    height: undefined as number | undefined,
  }))

  useEffect(() => {
    if (!props.autoGrow) {
      runInAction(() => state.height = undefined)
    }
  }, [props.autoGrow])

  const onContentSizeChange = (e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => {
    if (props.autoGrow) {
      runInAction(() => state.height = Math.max(e.nativeEvent.contentSize.height, 50))
    }
  }

  return <View>
    <View style={styles.wrapper}>
      <TextInput
        {...rest}
        placeholderTextColor={COLORS.gray.neutral3}
        style={[
          styles.input, props.multiline ? { paddingTop: 12, paddingBottom: 12, textAlignVertical: 'center' } : undefined,
          state.height ? { height: state.height } : undefined,
          style,
          Platform.OS === 'web' && { outlineStyle: 'none' }
        ]}
        onContentSizeChange={onContentSizeChange}
      />
      {props.rightAccessory}
    </View>
    {
      (props.errors && props.fieldName)
        ? <FormError field={props.fieldName} errors={props.errors} containerStyle={{ marginHorizontal: 12 }} />
        : null
    }
  </View>
})

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
  },
  input: {
    minHeight: 56,
    alignItems: 'center',
    fontSize: 18,
    color: COLORS.gray.neutral1,
    paddingHorizontal: 10,
    flex: 1,
    backgroundColor: COLORS.gray.neutral6,
    justifyContent: 'center',
    borderRadius: 6,
    paddingVertical: 12,
  },
})
