import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useCallback, useEffect } from 'react'
import { LayoutChangeEvent, ScrollView, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle, Platform } from 'react-native'
import { DefaultText } from '../DefaultText'
import moment, { Moment } from 'moment-timezone'
import { runInAction } from 'mobx'
import { Event } from '../../api/schema/models/Event'
import { API } from '../../api/API'
import { ContentActivityIndicator } from '../ContentActivityIndicator'
import { useTranslation } from 'react-i18next'
import { extractErrorMessage, MYSQL_DATE_TIME_FORMAT } from '../../common/Util'
import { ErrorMessage } from '../ErrorMessage'
import _ from 'lodash'
import { Colors } from '../../common/Colors'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { FloatingActionButton } from '../../components/fab/FloatingActionButton'
import { StackScreenProps } from '@react-navigation/stack'
import { AppColors } from '../../../src/common/AppColors'
import { allowStateChanges } from 'mobx/dist/internal'
//import * as Sentry from 'sentry-expo'

type Props = {
  date: Moment
}

const HOURS_MARGIN = 60
const HOUR_HEIGHT = 88
const HOURS_VERTICAL_OFFSET = 10
const EVENT_HORIZONTAL_OFFSET = 1
const EVENT_HORIZONTAL_MARGIN = 20
const EVENT_STACK_OFFSET = 50
let numEvents = 0

export const AgendaView = observer((props: Props) => {
  const { t } = useTranslation()
  const navigation = useNavigation<StackNavigationProp<RootNavigationParamList>>()

  useEffect(() => {
    const listeners = [
      AppStateStore.eventBus.addRemovableListener(AppEvents.EventCreated, () => {
        loadEvents(props.date).then()
      }),
      AppStateStore.eventBus.addRemovableListener(AppEvents.EventDeleted, () => {
        loadEvents(props.date).then()
      }),
    ]

    return () => {
      listeners.forEach(l => l.remove())
    }
  }, [props.date])

  const state = useLocalObservable(() => ({
    loading: false,
    date: props.date.clone(),
    events: [] as Event[],
    get orderedEvents() {
      return _.orderBy(this.events, e => moment(e.startsAt).unix())
    },
    error: undefined as string | undefined,
    containerWidth: 0,
    get hourViews() {
      const views: React.ReactElement[] = []

      for (let hour = 0; hour <= 24; ++hour) {
        let text = `${hour} AM`

        if (hour === 0) {
          text = '12 AM'
        } else if (hour === 12) {
          text = `12 PM`
        } else if (hour > 12) {
          text = `${hour - 12} PM`
        }

        views.push(<View key={`hour-line-${hour}`} style={[styles.hourLine, { top: HOURS_VERTICAL_OFFSET + hour * HOUR_HEIGHT }]} />)
        if (hour < 24) {
          views.push(<DefaultText key={`hour-text-${hour}`} style={[styles.hourText, { top: HOURS_VERTICAL_OFFSET + hour * HOUR_HEIGHT - 9 }]}>{text}</DefaultText>)
        }
      }

      views.push(<View key={`vertical-line`} style={styles.verticalLine} />)

      return <View style={styles.hoursWrapper}>
      </View>
    },
    get eventViews() {
      const views: React.ReactElement[] = []

      type EventView = {
        startChunk: number
        endChunk: number
        offset: number
        event: Event,
        startsAt: any,
        endsAt: any
      }
      numEvents = this.orderedEvents.length
      const eventChunkViews: EventView[] = []

      for (const event of this.orderedEvents) {
        const startsAt = moment(event.startsAt, MYSQL_DATE_TIME_FORMAT)
        const endsAt = moment(event.endsAt, MYSQL_DATE_TIME_FORMAT)

        // find start and end chunk for this event
        let startHour = startsAt.hour()
        let startMinute = startsAt.minute()
        let endHour = endsAt.hour()
        let endMinute = endsAt.minute()
        let startTime = `${startsAt.format('h:mm A')}`
        let endTime = `${endsAt.format('h:mm A')}`

        if (startsAt.isBefore(this.date.clone().startOf('day'))) {
          startHour = 0
          startMinute = 0
        }

        if (endsAt.isAfter(this.date.clone().endOf('day'))) {
          endHour = 23
          endMinute = 59
        }

        const startChunk = Math.floor((startHour * 60 + startMinute) / 30)
        let endChunk = Math.ceil((endHour * 60 + endMinute) / 30)

        if (endChunk - startChunk < 2) {
          endChunk = startChunk + 2
        }

        const count = eventChunkViews.filter(ev => ev.startChunk < endChunk && ev.endChunk > startChunk).length

        eventChunkViews.push({
          startChunk,
          endChunk,
          offset: count,
          event,
          startsAt: startTime,
          endsAt: endTime
        })
      }
      let index = 0
      for (const eventView of eventChunkViews) {
        const startsAt = moment(eventView.event.startsAt, MYSQL_DATE_TIME_FORMAT)

        const offset = eventView.offset * EVENT_STACK_OFFSET
        const width = this.containerWidth - HOURS_MARGIN
        const style: StyleProp<ViewStyle> = {
          //top: index * HOUR_HEIGHT + (8 * index),
          //left: HOURS_MARGIN + EVENT_HORIZONTAL_OFFSET + offset,
          //left: (HOURS_MARGIN + 10) / 2,
          width,
          minHeight: HOUR_HEIGHT,
          backgroundColor: Colors.CalendarBackgroundColors[eventView.event.visibility] ?? AppColors.orange,
          borderRadius: 12,
          paddingLeft: 10,
          paddingTop: 12,
          marginLeft: HOURS_MARGIN / 2,
          marginBottom: 10,
          flex: 1
        }

        const textStyle: StyleProp<TextStyle> = {
          color: Colors.CalendarTextColors[eventView.event.visibility] ?? Colors.DefaultTextColor,
        }

        let description = ''

        if (eventView.event.visibility === 'district') {
          description = eventView.event.districtName ?? ''
        } else if (eventView.event.visibility === 'school') {
          description = eventView.event.schoolName ?? ''
        } else if (eventView.event.visibility === 'section') {
          description = eventView.event.sectionName ?? ''
        }
        description = `${eventView.startsAt} - ${eventView.endsAt}`

        views.push(<TouchableOpacity
          key={`event-${eventView.event.id}-${startsAt.unix()}`}
          style={style}
          onPress={() => navigation.push('EventDetail', { eventId: eventView.event.id, startsAt: eventView.event.startsAt })}
        >
          <DefaultText style={[styles.eventTitleText, textStyle]}>{eventView.event.title}</DefaultText>
          <DefaultText style={[styles.eventDescriptionText, textStyle]}>{description}</DefaultText>
          <DefaultText style={[styles.eventDescriptionText, textStyle]}>{eventView.event.location}</DefaultText>
        </TouchableOpacity>)
        index++
      }

      return <View>
        {views}
      </View>
    },
  }))

  const loadEvents = useCallback(async (date: Moment) => {
    runInAction(() => {
      state.error = undefined
      state.loading = true
      state.events = []
    })

    try {
      const response = await API.getEvents(date.clone().startOf('day'), date.clone().endOf('day'))

      runInAction(() => {
        state.events = response.events
      })
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => {
      state.loading = false
    })
  }, [])

  useEffect(() => {
    loadEvents(props.date).then()
  }, [loadEvents, props.date])

  useEffect(() => {
    runInAction(() => {
      state.date = props.date
    })
  }, [props.date])

  const onContainerLayout = (event: LayoutChangeEvent) => {
    runInAction(() => state.containerWidth = event.nativeEvent.layout.width)
  }

  return <View style={styles.wrapper}>
    {
      (AppStateStore.userContext?.userType === 'teacher') ?
        <FloatingActionButton onPress={() => navigation.push('EditEvent')} bgColor={AppColors.blue.primary} />
        : null
    }
    <View style={styles.content}>
      {
        state.loading
          ? <ContentActivityIndicator />
          : state.error
            ? <ErrorMessage message={state.error} />
            : state.events.length
              ? <ScrollView
                onLayout={onContainerLayout}
              >
                <View style={{ flex: 1 }}>
                  {state.eventViews}
                </View>
              </ScrollView>
              : <DefaultText style={styles.noEvents}>{t('There are no events on the selected day', 'There are no events on the selected day')}</DefaultText>
      }
    </View>
  </View>
})

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: AppColors.white
  },
  content: {
    flex: 1,
  },
  noEvents: {
    textAlign: 'center',
    padding: 16,
    color: '#999',
    fontSize: 18,
  },
  hoursWrapper: {
    height: HOUR_HEIGHT * numEvents,
  },
  verticalLine: {
    position: 'absolute',
    width: 1,
    height: '100%',
    left: HOURS_MARGIN,
    top: 0,
    backgroundColor: '#ccc',
  },
  hourLine: {
    position: 'absolute',
    height: 1,
    backgroundColor: '#ccc',
    width: '100%',
    left: HOURS_MARGIN - 4,
  },
  hourText: {
    position: 'absolute',
    left: 0,
    width: HOURS_MARGIN - 8,
    textAlign: 'right',
    color: '#666',
    fontSize: 14,
  },
  eventsWrapper: {
    //position: 'absolute',
    left: 0,
    top: HOURS_VERTICAL_OFFSET,
    height: HOUR_HEIGHT,
    width: '100%',
  },
  eventWrapper: {
    position: 'absolute',
    backgroundColor: '#f00',
    borderRadius: 4,
    borderWidth: .5,
    borderColor: '#fff',
    padding: 4,
  },
  eventTitleText: {
    fontSize: 16,
    fontFamily: 'Rubik_700Bold',
    marginBottom: 8
  },
  eventDescriptionText: {
    fontSize: 12,
    fontFamily: 'Rubik_400Regular',
    marginBottom: 6
  },
})
