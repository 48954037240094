import { useTranslation } from 'react-i18next'
import React, { useEffect, useCallback, useState } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { StackScreenProps } from '@react-navigation/stack'
//import PdfReader from 'rn-pdf-reader-js'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { View, TouchableOpacity, StyleSheet, Share, Pressable, Text, ActivityIndicator } from 'react-native'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { Document } from '../../api/schema/models/Document'
import Icons from '../../components/icons'
import { AppColors, AppColors as COLORS } from '../../common/AppColors'
import { openExternalUrl } from '../../common/Util'
import { SuspenseScreen } from '../../../src/screens/SuspenseScreen'
import { API } from '../../api/API'
import { runInAction } from 'mobx'
import { GetDocumentResponse } from '../../api/schema/GetDocumentResponse'
import { ReceivedForm } from '../../api/schema/models/ReceivedForm'


export type FormLoadingScreenParams = {
  formId: number
}

export const FormLoadingScreen = (props: StackScreenProps<RootNavigationParamList, 'FormLoader'>) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    form: undefined as ReceivedForm | undefined,
    isLoading: true,
    formRetrieved: undefined as ReceivedForm | undefined,
  }))
  const [loading, setLoading] = useState(true);
  const [retrievedForm, setForm] = useState(undefined as ReceivedForm | undefined);

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Form', 'Form'),
    })
  }, [props.navigation])

  const retrieveDocument = useCallback(async () => {
    if (props.route.params.formId) {
      let response = await API.getReceivedForm(props.route.params.formId, 0)
      setLoading(false)
      if (response.receivedForm) {
        setForm(response.receivedForm)
        props.navigation.replace('ReceivedFormDetail', { receivedForm: response.receivedForm })
      }

    }
  }, [props.route.params.formId, state])

  useEffect(() => {
    retrieveDocument().then().catch((err) => console.log('error', err))
  }, [retrieveDocument])



  /*   const onShare = async () => {
      const url = props.route.params.url
      await Share.share({
        message: url,
        title: 'Share Link'
      }, {
        dialogTitle: 'Share Link'
      })
    } */

  return <View style={{ width: '100%', height: '100%' }}>
    <View style={{ width: '100%', height: '100%', backgroundColor: AppColors.orange.primary, paddingTop: '100%' }}>
      {/* <SuspenseScreen/> */}
      <ActivityIndicator
        size={'large'}
        color={AppColors.white}
        animating={true}
      />
    </View>
  </View>


}

const styles = StyleSheet.create({
  headerButton: {
    padding: 10,
  },
})