import React from 'react'
import { Svg, Path } from "react-native-svg"

type Props = {
  width: number
  height: number
  color: string,
  variant: 'fill' | 'outline'
}
export default function Forms(props: Props) {
  return (
    <Svg
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 20 20" >
      {props.variant === 'fill' ? (
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d="M19.39 9.4C18.61 8.61 17.23 8.61 16.44 9.4L16.07 9.77V1.35C16.07 0.6 15.46 0 14.72 0H1.35C0.61 0 0 0.61 0 1.35V17.15C0 17.9 0.61 18.5 1.35 18.5H9.1L8.83 19.24C8.76 19.44 8.81 19.66 8.95 19.82C9.06 19.94 9.2 20 9.36 20C9.42 20 9.47 19.99 9.52 19.98L12.63 19.03C12.71 19 12.79 18.96 12.86 18.89L19.39 12.36C19.78 11.96 20 11.44 20 10.88C20 10.32 19.78 9.8 19.39 9.4ZM4.4 6.91H11.86C12.19 6.91 12.46 7.18 12.46 7.51C12.46 7.84 12.19 8.11 11.86 8.11H4.4C4.07 8.11 3.8 7.84 3.8 7.51C3.8 7.18 4.08 6.91 4.4 6.91ZM8.25 14.11H4.4C4.07 14.11 3.8 13.84 3.8 13.51C3.8 13.18 4.07 12.91 4.4 12.91H8.24C8.57 12.91 8.84 13.18 8.84 13.51C8.84 13.84 8.58 14.11 8.25 14.11ZM4.4 11.11C4.07 11.11 3.8 10.84 3.8 10.51C3.8 10.18 4.07 9.91 4.4 9.91H10.86C11.19 9.91 11.46 10.18 11.46 10.51C11.46 10.84 11.19 11.11 10.86 11.11H4.4ZM11.98 18.04L11.17 18.29C11.1 18.14 11 18.01 10.89 17.9C10.8 17.82 10.71 17.74 10.6 17.67L11 16.57H11.29V16.94C11.29 17.25 11.54 17.5 11.85 17.5H12.09L11.98 18.04ZM16.62 13.53L13.34 16.81C13.32 16.73 13.28 16.65 13.23 16.58C13.12 16.45 12.96 16.37 12.79 16.37H12.41V16C12.41 15.73 12.21 15.5 11.95 15.45L15.21 12.21C15.22 12.2 15.24 12.19 15.25 12.18C15.61 11.82 16.25 11.81 16.61 12.18C16.79 12.36 16.89 12.6 16.89 12.86C16.9 13.11 16.8 13.35 16.62 13.53ZM18.59 11.56L17.92 12.23C17.82 11.91 17.64 11.62 17.41 11.38C17.17 11.14 16.88 10.97 16.56 10.87L17.22 10.21C17.23 10.2 17.23 10.2 17.24 10.19C17.6 9.84 18.23 9.85 18.58 10.21C18.76 10.39 18.86 10.63 18.86 10.89C18.88 11.14 18.78 11.38 18.59 11.56Z"
          fill={props.color} />
      ) : (
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d="M12.46 7.51C12.46 7.84 12.19 8.11 11.86 8.11H4.4C4.07 8.11 3.8 7.84 3.8 7.51C3.8 7.18 4.07 6.91 4.4 6.91H11.86C12.19 6.91 12.46 7.18 12.46 7.51ZM10.86 9.91H4.4C4.07 9.91 3.8 10.18 3.8 10.51C3.8 10.84 4.07 11.11 4.4 11.11H10.86C11.19 11.11 11.46 10.84 11.46 10.51C11.46 10.18 11.19 9.91 10.86 9.91ZM8.25 12.91H4.4C4.07 12.91 3.8 13.18 3.8 13.51C3.8 13.84 4.07 14.11 4.4 14.11H8.24C8.57 14.11 8.84 13.84 8.84 13.51C8.84 13.18 8.58 12.91 8.25 12.91ZM20 10.88C20 11.44 19.78 11.96 19.39 12.36L12.86 18.89C12.79 18.96 12.71 19 12.63 19.03L9.52 19.98C9.47 19.99 9.42 20 9.36 20C9.21 20 9.06 19.94 8.95 19.83C8.8 19.67 8.75 19.45 8.83 19.25L9.1 18.51H1.35C0.61 18.51 0 17.9 0 17.15V1.35C0 0.61 0.61 0 1.35 0H14.72C15.46 0 16.07 0.61 16.07 1.35V9.77L16.44 9.4C17.23 8.61 18.61 8.61 19.39 9.4C19.78 9.8 20 10.32 20 10.88ZM10.22 15.59L14.52 11.31V1.55H1.55V16.95H9.66L10.08 15.81C10.11 15.72 10.16 15.65 10.22 15.59ZM12.1 17.5H11.86C11.55 17.5 11.3 17.25 11.3 16.94V16.57H11L10.6 17.67C10.71 17.74 10.81 17.82 10.89 17.9C11.01 18.02 11.1 18.15 11.17 18.29L11.98 18.04L12.1 17.5ZM16.9 12.85C16.9 12.59 16.8 12.35 16.62 12.17C16.26 11.8 15.62 11.81 15.26 12.17C15.25 12.18 15.23 12.19 15.22 12.2L11.96 15.44C12.22 15.49 12.42 15.72 12.42 15.99V16.37H12.8C12.97 16.37 13.13 16.45 13.24 16.58C13.29 16.65 13.33 16.72 13.35 16.81L16.63 13.53C16.8 13.35 16.9 13.11 16.9 12.85ZM18.88 10.88C18.88 10.62 18.78 10.38 18.6 10.2C18.24 9.84 17.62 9.84 17.26 10.18C17.26 10.19 17.25 10.19 17.24 10.2L16.58 10.86C16.9 10.96 17.19 11.13 17.43 11.37C17.67 11.61 17.84 11.9 17.94 12.22L18.6 11.55C18.78 11.38 18.88 11.14 18.88 10.88Z"
          fill={props.color} />
      )
      }

    </Svg>
  )
}

