import React from 'react';
import { NativeSyntheticEvent, StyleSheet, TextInput, TextInputContentSizeChangeEventData, View, Platform } from 'react-native';
import { AppColors } from '../common/AppColors';

interface Props {
  leftElement?: React.ReactElement;
  multiline?: boolean;
  placeholder?: string;
  rightElement?: React.ReactElement;
  value?: string;
  onChange?: (text: string) => void;
  onContentSizeChange?: (e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => void;
  onSubmit?: () => void;
}

export const TextInputField = ({
  leftElement,
  multiline,
  placeholder,
  rightElement,
  value,
  onChange,
  onContentSizeChange,
  onSubmit,
}: Props) => {
  return (
    <View style={styles.container}>
      <View style={styles.leftWrapper}>
        {leftElement}
        <TextInput
          multiline={multiline}
          placeholder={placeholder}
          placeholderTextColor={AppColors.gray.neutral3}
          style={[styles.textInput, Platform.OS === 'web' && { outlineStyle: 'none' }]}
          onContentSizeChange={onContentSizeChange}
          value={value}
          onChangeText={onChange}
          onSubmitEditing={onSubmit}
        />
      </View>
      {rightElement}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: AppColors.gray.neutral6,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingVertical: 8,
    paddingStart: 16,
    paddingEnd: 6,
    borderRadius: 6,
    position: 'relative',
  },
  leftWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1
  },
  textInput: {
    fontFamily: 'Rubik_400Regular',
    color: AppColors.gray.neutral1,
    fontSize: 16,
    flex: 1,
    marginRight: 5
  },
});