import React from 'react'
import { Svg, Path } from "react-native-svg"

type Props = {
  size: number
  color: string
}

const Download = (props: Props) => {
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 24 24" >
      <Path d="M7.07 14.35C6.72 14 6.72 13.43 7.07 13.08C7.42 12.73 7.99 12.73 8.34 13.08L11.26 16V2.9C11.26 2.4 11.66 2 12.16 2C12.66 2 13.06 2.4 13.06 2.9V15.86L15.84 13.08C16.19 12.73 16.76 12.73 17.11 13.08C17.46 13.43 17.46 14 17.11 14.35L12.93 18.53C12.78 18.81 12.5 19 12.16 19C12.15 19 12.14 18.99 12.13 18.99C12.12 18.99 12.11 19 12.09 19C11.86 19 11.63 18.91 11.45 18.74L7.07 14.35ZM20.91 17.5C20.31 17.5 19.82 17.98 19.82 18.58V19.84H4.18V18.58C4.18 17.98 3.69 17.5 3.09 17.5C2.49 17.5 2 17.98 2 18.58V20.92C2 21.52 2.49 22 3.09 22H20.91C21.51 22 22 21.52 22 20.92V18.58C22 17.98 21.51 17.5 20.91 17.5Z"
        fill={props.color} />
    </Svg>
  )
}

export default Download
