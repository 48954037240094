import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { AppColors } from '../../common/AppColors';

interface Props {
  backgroundColor?: string;
  onPress?: () => void;
  children?: React.ReactElement | React.ReactElement[];
}

export const ActionButton = ({
  backgroundColor = AppColors.orange.primary,
  onPress,
  children
}: Props) => {
  return (
    <View style={styles.buttonContainer} >
      <Pressable style={{
        ...styles.pressable,
        backgroundColor: backgroundColor,
      }} onPress={onPress}>

        {children}
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    zIndex: 2,
    borderRadius: 8,
  },
  pressable: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
    width: 44,
    height: 44,
    borderRadius: 8,
    shadowColor: AppColors.black,
    shadowOffset: {
      height: 0,
      width: 2,
    },
    shadowOpacity: 0.25,
    elevation: 4,
  },
  searchButton: {
    color: '#fff',
  },
});
