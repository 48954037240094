import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState } from 'react'
import { KeyboardAvoidingView, Platform, Pressable, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native'
import { runInAction } from 'mobx'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import ErrorBag from '../../common/ErrorBag'
import { extractErrorMessage, getMimeType, handleErrorResponse, MYSQL_DATE_FORMAT, MYSQL_DATE_TIME_FORMAT } from '../../common/Util'
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTranslation } from 'react-i18next'
import { DefaultText } from '../../components/DefaultText'
import { Attachment, AttachmentBar } from '../../components/AttachmentBar'
import { VisibilityOption } from '../../api/schema/models/VisibilityOption'
import { FormError } from '../../components/FormError'
import { API } from '../../api/API'
import { PostAttachment } from '../../api/schema/models/PostAttachment'
import moment, { Moment } from 'moment-timezone'
import { FormTextInput } from '../../components/forms/FormTextInput'
import { FormSelectInput, FormSelectInputValueStyle } from '../../components/forms/FormSelectInput'
import { FormSwitchInput } from '../../components/forms/FormSwitchInput'
import { FormDateInput } from '../../components/forms/FormDateInput'
import { Event } from '../../api/schema/models/Event'
import { ContentActivityIndicator } from '../../components/ContentActivityIndicator'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { FileData } from '../../api/methods/upload-file'
import { StackActions, useNavigation } from '@react-navigation/native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { SearchIcon } from '../feed/newPost/icons/Icons'
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import { EventType } from '../../api/schema/models/EventType'
import { Contact } from '../../api/schema/models/Contact'
import { AppColors } from '../../common/AppColors'
import Icons from '../../components/icons'
import { TouchableOpacity } from 'react-native-gesture-handler'
//import * as Sentry from 'sentry-expo'

export type EditEventScreenParams = {
  eventId: number
} | undefined

export const EditEventScreen = observer((props: StackScreenProps<RootNavigationParamList, 'EditEvent'>) => {
  const regex = /\b\w+\b/g;
  const { t } = useTranslation();
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();

  const isValidString = (string: string) => {
    return string.match(regex) != undefined;
  }

  const buttonColor = '#FF7A33'

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: props.route.params?.eventId ? t('Edit Event', 'Edit Event') : t('New Event', 'New Event'),
    })
  }, [props.navigation, props.route.params?.eventId])

  const state = useLocalObservable(() => ({
    loading: false,
    error: undefined as string | undefined,
    event: undefined as Event | undefined,
    locationMinHeight: 50,
    form: {
      title: '',
      location: '',
      description: '',
      isAllDay: false,
      startsAt: moment(),
      endsAt: moment().add(30, 'minutes'),
      recurrenceOption: undefined as 'daily' | 'weekly' | 'monthly_day_of_month' | 'yearly' | undefined,
      recursUntil: undefined as Moment | undefined,
      eventType: 0,
      contacts: [] as Contact[]
    },
    visibility: undefined as VisibilityOption | undefined,
    //eventType: undefined as EventType | undefined,
    selectedEventType: '',
    eventTypesOptions: undefined as EventType[] | undefined,
    showContacts: false,
    contactOptions: undefined as Contact[] | undefined,
    errors: new ErrorBag(),
    submitting: false,
    get isValid(): boolean {
      return isValidString(this.form.title)
        && (!!props.route.params?.eventId || !!this.visibility)
        && isValidString(this.form.location)
    },
    uploading: false,
    removeExistingAttachment: false,
    attachment: undefined as Attachment | undefined,
    get visibilityLabel() {
      if (this.visibility?.type === 'district') {
        return <DefaultText style={FormSelectInputValueStyle}>{this.visibility.name}</DefaultText>
      } else if (this.visibility?.type === 'school') {
        return <DefaultText style={FormSelectInputValueStyle}>{this.visibility.name}</DefaultText>
      } else if (this.visibility?.type === 'section') {
        return <DefaultText style={FormSelectInputValueStyle}>{this.visibility.name}</DefaultText>
      } else if (this.visibility?.type === 'grade') {
        return <DefaultText style={FormSelectInputValueStyle}>{this.visibility.name}</DefaultText>
      } else if (this.visibility?.type === 'community') {
        return <DefaultText style={FormSelectInputValueStyle}>{this.visibility.name}</DefaultText>
      } else {
        return undefined
      }
    },
    get recurrenceDescription() {
      if (this.form.recurrenceOption === 'daily') {
        return t('Repeats daily', 'Repeats daily')
      } else if (this.form.recurrenceOption === 'weekly') {
        return t('Repeats weekly', 'Repeats weekly')
      } else if (!this.form.recurrenceOption) {
        return undefined
      } else {
        return this.form.recurrenceOption
      }
    },
  }))

  const submit = async () => {
    if (!state.isValid) {
      return
    }

    AppStateStore.showActivityIndicator()
    runInAction(() => {
      state.errors.clearErrors()
      state.submitting = true
    })

    try {
      let attachment: PostAttachment | undefined = undefined

      if (state.attachment) {
        const uploadData = await API.requestUpload()

        let mimeType = 'application/octet-stream'
        let filename = 'upload'
        if (state.attachment.type === 'file') {
          mimeType = state.attachment.mimeType
          filename = state.attachment.name
        } else if (!state.attachment.url.startsWith('data:')) {
          mimeType = getMimeType(state.attachment.url) || 'application/octet-stream'
        }

        if (Platform.OS === 'web') {
          if (state.attachment.type === ('photo' || 'video')) {
            mimeType = state.attachment.mimeType
          }
        }

        let file: FileData = {
          uri: state.attachment.url,
          name: filename,
          type: mimeType,
        }

        if (state.attachment.url.startsWith('data:')) {
          const response = await fetch(state.attachment.url)
          const binary = await response.blob()
          file = new Blob([binary], {
            type: 'application/pdf',
          })
        }

        await API.uploadFile(
          uploadData.url,
          uploadData.fields,
          file,
        )

        attachment = {
          type: state.attachment.type,
          key: uploadData.key,
          filename: filename,
          mimeType: mimeType,
        }
      }

      if (!props.route.params) {
        await API.createEvent({
          title: state.form.title,
          location: state.form.location,
          description: state.form.description,
          recurrenceOption: state.form.recurrenceOption,
          recursUntil: state.form.recursUntil?.format(MYSQL_DATE_FORMAT) ?? null,
          isAllDay: state.form.isAllDay,
          startsAt: state.form.startsAt.format(MYSQL_DATE_TIME_FORMAT),
          endsAt: state.form.endsAt.format(MYSQL_DATE_TIME_FORMAT),
          visibility: state.visibility!,
          contacts: state.form.contacts,
          eventType: state.form.eventType,
          attachment,
        })
      } else {
        await API.updateEvent(props.route.params.eventId,
          {
            title: state.form.title,
            location: state.form.location,
            description: state.form.description,
            recurrenceOption: state.form.recurrenceOption,
            recursUntil: state.form.recursUntil?.format(MYSQL_DATE_FORMAT) ?? null,
            isAllDay: state.form.isAllDay,
            startsAt: state.form.startsAt.format(MYSQL_DATE_TIME_FORMAT),
            endsAt: state.form.endsAt.format(MYSQL_DATE_TIME_FORMAT),
            removeExistingAttachment: state.removeExistingAttachment,
            attachment,
            eventType: state.form.eventType,
          })
      }

      AppStateStore.eventBus.emit(AppEvents.EventCreated)

      props.navigation.pop()
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      handleErrorResponse(err?.response, state.errors, {
        unhandledErrors: {
          handledErrorFields: [
            'title',
            'location',
            'description',
            'recurrenceOption',
            'recursUntil',
            'isAllDay',
            'startsAt',
            'endsAt',
            'visibility',
          ],
          unhandledErrorsKey: '_unhandled',
        }
      })
    }

    AppStateStore.hideActivityIndicator()
    runInAction(() => state.submitting = false)
  }

  const setVisibilityName = (option: VisibilityOption, pop: () => void) => {
    //setVisibility(option);
    navigation.goBack();
  }

  const navigateToVisibilityPicker = () => {
    navigation.navigate('VisibilityPicker' as never, { title: t('Choose Visibility', 'Choose Visibility'), onChoose: setVisibilityName } as never);
  }

  const setVisibility = (option: VisibilityOption, pop: () => void) => {
    console.log('picked', option.type)
    runInAction(() => {
      state.visibility = option
      state.showContacts = option.type === 'private'
    })
    pop()
  }

  const chooseVisibility = () => {
    props.navigation.push('VisibilityPicker', { title: t('Choose Visibility', 'Choose Visibility'), onChoose: setVisibility, event: true })
  }

  const chooseRecurrenceOption = () => {
    props.navigation.push('Picker', {
      title: t('Choose', 'Choose'),
      onChoose: (option, pop) => {
        if (option.value === '') {
          runInAction(() => state.form.recurrenceOption = undefined)
        } else {
          runInAction(() => state.form.recurrenceOption = option.value as any)
        }

        pop()
      },
      options: [
        { value: '', text: t('Does not repeat', 'Does not repeat') },
        { value: 'daily', text: t('Repeats daily', 'Repeats daily') },
        { value: 'weekly', text: t('Repeats weekly', 'Repeats weekly') }
      ]
    })
  }

  const chooseEventTypeOption = () => {
    if (state.eventTypesOptions) {
      let options = state.eventTypesOptions.map((element) => { return { value: element.id, text: element.name } })
      props.navigation.push('Picker', {
        title: t('Choose', 'Choose'),
        onChoose: (option, pop) => {
          if (option.value === '') {
            runInAction(() => {
              state.form.eventType = 0
              state.selectedEventType = ''
            })
          } else {
            runInAction(() => {
              state.form.eventType = option.value as any
              state.selectedEventType = option.text
            })
          }

          pop()
        },
        options: options
      })
    }
  }

  const addRecipientContacts = (option: Contact, pop: () => void) => {
    runInAction(() => {
      if (!state.form.contacts.find(s => s.userId === option.userId)) {
        state.form.contacts.push(option)
      }
    })
    pop()
  }

  const showContactPicker = () => {
    props.navigation.push('ContactPicker', { title: t('Send To', 'Send To'), onChoose: addRecipientContacts })
  }

  const loadEvent = useCallback(async () => {
    if (props.route.params) {
      runInAction(() => {
        state.loading = true
        state.error = undefined
      })

      try {
        const response = await API.getEvent(props.route.params.eventId)

        runInAction(() => {
          state.event = response.event

          state.form.startsAt = moment(response.event.startsAt, MYSQL_DATE_TIME_FORMAT)
          state.form.endsAt = moment(response.event.endsAt, MYSQL_DATE_TIME_FORMAT)
          state.form.isAllDay = response.event.isAllDay
          state.form.recursUntil = response.event.recursUntil ? moment(response.event.recursUntil, MYSQL_DATE_FORMAT) : undefined
          state.form.recurrenceOption = response.event.recurrenceOption ?? undefined
          state.form.title = response.event.title
          state.form.location = response.event.location ?? ''
          state.form.description = response.event.description ?? ''
          state.form.eventType = response.event.eventType ?? 0
          state.selectedEventType = response.event.eventTypeName ?? ''
        })
      } catch (err: any) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(err)
        }
        else {
          //Sentry.Native.captureException(err)
        }
        runInAction(() => state.error = extractErrorMessage(err.response))
      }

      runInAction(() => state.loading = false)
    }
  }, [props.route.params?.eventId, state])

  useEffect(() => {
    loadEvent().then()
  }, [loadEvent])

  const loadOptions = useCallback(async () => {
    runInAction(() => {
      state.loading = true
      state.error = undefined
    })

    try {
      const response = await API.getEventTypes()
      const contactResponse = await API.getEventContacts()

      runInAction(() => {
        state.eventTypesOptions = response.eventTypes
        state.contactOptions = contactResponse.contacts
      })
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = t('There was an error getting the visibility options', 'There was an error getting the visibility options'))
    }

    runInAction(() => state.loading = false)
  }, [])

  useEffect(() => {
    loadOptions().then()
  }, [loadOptions])

  return <View style={{ flex: 1 }}>
    {
      state.loading
        ? <ContentActivityIndicator />
        : <KeyboardAvoidingView
          {...(Platform.OS === "ios" ? { behavior: "padding" } : {})}
          style={{ flex: 1 }}
          enabled
          contentContainerStyle={{ flex: 1 }}
        >
          <ScrollView nestedScrollEnabled={true} style={[styles.container, { paddingTop: insets.top + 10, paddingBottom: 50 }]}
          >
            <View style={styles.header}>
              <MaterialCommunityIcons name="arrow-left" size={25} onPress={() => {
                navigation.dispatch(StackActions.popToTop)
              }} />

              <View style={{
                marginLeft: 10
              }}>
                <Text style={styles.newPost}>New Post</Text>

              </View>
            </View>
            <Text style={{ color: buttonColor, fontWeight: 'bold', fontSize: 18 }}>Event</Text>
            <FormError field={'_unhandled'} errors={state.errors} />
            {
              !props.route.params?.eventId
                ?
                <Pressable style={styles.postTo} onPressIn={chooseVisibility}>
                  <TextInput
                    style={{ fontSize: 15 }}
                    placeholder="Post to (required)"
                    editable={false}
                    focusable={true}
                    value={state.visibility?.name}
                    onPressIn={chooseVisibility}
                    placeholderTextColor={AppColors.gray.neutral3}
                  >
                  </TextInput>
                  <View style={styles.inputIcon}>
                    <SearchIcon />
                  </View>
                </Pressable>
                : null
            }
            {
              state.showContacts ?
                <FormSelectInput
                  label={t('Guests', 'Guests')}
                  value={undefined}
                  onPress={showContactPicker}
                  //onPress={() => runInAction(() => { state.showDrawer = true })}
                  /* onPress={chooseRecipient} */
                  placeholder={t('Choose', 'Choose')}
                  style={{ marginBottom: 20 }}
                />
                : null
            }
            {
              state.form.contacts.map(contact => <View key={`${contact.userId}`} style={styles.section}>
                <View style={styles.sectionFormText}>
                  <View style={{ flex: 1 }}>
                    <DefaultText>{`${contact.fullName} - ${contact.role.charAt(0).toUpperCase()}${contact.role.slice(1).toLowerCase()}`}</DefaultText>
                    {
                      contact.externalId
                        ? <DefaultText style={{ color: AppColors.gray.neutral2 }}>{contact.externalId}</DefaultText>
                        : null
                    }
                  </View>
                  <TouchableOpacity onPress={() => runInAction(() => state.form.contacts = state.form.contacts.filter(s => s.userId !== contact.userId))}>
                    <Icons.Delete width={16} height={20} variant='outline' color={AppColors.orange.primary} />
                  </TouchableOpacity>
                </View>
              </View>)
            }
            <View style={{}}>
              <FormTextInput
                placeholder={t('Title', 'Title (required)')}
                value={state.form.title}
                onChangeText={value => runInAction(() => state.form.title = value)}
                autoGrow={true}
                errors={state.errors}
                fieldName={'description'}
                style={styles.input}
                hideSeparator={true}
              />
              <View style={styles.datePicker}>
                <FormSwitchInput
                  label={t('All-day', 'All-day')}
                  value={state.form.isAllDay}
                  onChange={value => runInAction(() => state.form.isAllDay = value)}
                  hideSeparator={true}
                  errors={state.errors}
                  fieldName={'isAllDay'}
                  style={{
                    paddingHorizontal: 10,

                  }}
                />
                <FormDateInput
                  placeholder={t('Start Date', 'Start Date')}
                  value={state.form.startsAt}
                  onChange={date => runInAction(() => state.form.startsAt = date)}
                  dateOnly={state.form.isAllDay}
                  hideSeparator={true}
                  errors={state.errors}
                  fieldName={'startsAt'}
                />
                <FormDateInput
                  placeholder={t('End Date', 'End Date')}
                  value={state.form.endsAt}
                  onChange={date => runInAction(() => state.form.endsAt = date)}
                  dateOnly={state.form.isAllDay}
                  hideSeparator={true}
                  errors={state.errors}
                  fieldName={'endsAt'}
                />
                <FormSelectInput
                  label={''}
                  value={state.recurrenceDescription}
                  placeholder={t('Does not repeat', 'Does not repeat')}
                  onPress={chooseRecurrenceOption}
                  hideSeparator={true}
                  errors={state.errors}
                  fieldName={'recurrenceOption'}
                />
                {
                  state.form.recurrenceOption
                    ? <FormDateInput
                      placeholder={t('Repeats until end of cycle', 'Repeats until end of cycle')}
                      value={state.form.recursUntil}
                      prefix={t('Repeats until', 'Repeats until')}
                      onChange={date => runInAction(() => state.form.recursUntil = date)}
                      onClear={() => runInAction(() => state.form.recursUntil = undefined)}
                      dateOnly={true}
                      errors={state.errors}
                      fieldName={'recursUntil'}
                    />
                    : null
                }
              </View>
              <FormTextInput
                placeholder={t('Location', 'Location (required)')}
                value={state.form.location}
                onChangeText={value => runInAction(() => state.form.location = value)}
                errors={state.errors}
                fieldName={'location'}
                style={styles.input}
                hideSeparator={true}
              />
              <FormTextInput
                placeholder={t('Description', 'Description')}
                value={state.form.description}
                onChangeText={value => runInAction(() => state.form.description = value)}
                onContentSizeChange={(event) => {
                  runInAction(() => state.locationMinHeight = event.nativeEvent.contentSize.height)
                }}
                multiline={true}
                errors={state.errors}
                fieldName={'description'}
                style={[styles.input, { minHeight: state.locationMinHeight }]}
                hideSeparator={true}
                scrollEnabled={false}
              />
              <FormSelectInput
                label={state.selectedEventType ? state.selectedEventType : t('Event type', 'Event type')}
                value={''}
                placeholder={''}
                onPress={chooseEventTypeOption}
                hideSeparator={true}
                errors={state.errors}
                fieldName={'recurrenceOption'}
              />
              <View style={{ paddingBottom: 50 }}>
                <AttachmentBar
                  existingAttachment={state.removeExistingAttachment ? undefined : state.event?.attachments}
                  onRemoveExistingAttachment={() => runInAction(() => state.removeExistingAttachment = true)}
                  onAttached={attachment => runInAction(() => state.attachment = attachment)}
                  attachment={state.attachment}
                />
              </View>

            </View>
          </ScrollView>
          <View>
            <SafeAreaView edges={['bottom']} >
              <SubmitButton
                color={(state.submitting || !state.isValid) ? '#EAB599' : buttonColor}
                disabled={state.submitting || !state.isValid}
                onSubmit={submit}
                text={props.route.params?.eventId ? 'Update Event' : 'Create Event'}
              />
            </SafeAreaView>
          </View>
        </KeyboardAvoidingView>

    }
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20
  },

  formContainer: {
    flex: 1,
  },
  input: {
    marginTop: 12,
    fontSize: 15,
    borderRadius: 6,
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 20,
    borderRadius: 6,
    paddingVertical: 10,
    elevation: 10,
    shadowColor: 'grey',
    shadowOffset: {
      width: 5,
      height: 5
    },
    shadowOpacity: .4,
    shadowRadius: 10
  },
  postTo: {
    backgroundColor: '#F6F7FA',
    width: '100%',
    height: 50,
    borderRadius: 6,
    justifyContent: 'center',
    padding: 10,
    marginTop: 20,
  },
  inputIcon: {
    position: 'absolute',
    right: 0,
    marginRight: 10
  },
  datePicker: {
    backgroundColor: '#F6F7FA',
    borderRadius: 6,
    marginTop: 10
  },
  newPost: {
    fontWeight: 'bold',
    fontSize: 18,
    justifyContent: 'space-between'
  },
  header: {
    flexDirection: 'row',
    minHeight: 30,
    marginBottom: 10,
    alignItems: "flex-start"
  },
  sectionFormText: {
    minHeight: 56,
    paddingHorizontal: 16,
    flex: 1,
    backgroundColor: AppColors.gray.neutral6,
    justifyContent: 'center',
    borderRadius: 6,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5
  },
  section: {
    marginBottom: 20
  },
})
