import React from 'react'
import { View } from 'react-native'
import { Svg, Path, Circle } from "react-native-svg"

type Props = {
  width: number
  height: number
  color: string,
  variant: 'fill' | 'outline'
}
export default function Profile(props: Props) {
  return (
    <Svg
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 24 24" >
      {props.variant === 'fill' ? (
        <>
          <Path
            d="M7.9968 13.1746C3.6838 13.1746 -0.000198364 13.8546 -0.000198364 16.5746C-0.000198364 19.2956 3.6608 19.9996 7.9968 19.9996C12.3098 19.9996 15.9938 19.3206 15.9938 16.5996C15.9938 13.8786 12.3338 13.1746 7.9968 13.1746"
            fill={props.color} />
          <Path
            d="M7.9968 10.5837C10.9348 10.5837 13.2888 8.22869 13.2888 5.29169C13.2888 2.35469 10.9348 -0.000305176 7.9968 -0.000305176C5.0598 -0.000305176 2.7048 2.35469 2.7048 5.29169C2.7048 8.22869 5.0598 10.5837 7.9968 10.5837"
            fill={props.color} />
        </>
      ) : (
        <>
          <Circle
            cx="8.57897" cy="5.77803" r="4.77803"
            stroke={props.color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />

          <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d="M1.00002 17.2014C0.998732 16.8655 1.07385 16.5337 1.2197 16.2311C1.67736 15.3158 2.96798 14.8307 4.03892 14.611C4.81128 14.4462 5.59431 14.336 6.38217 14.2815C7.84084 14.1533 9.30793 14.1533 10.7666 14.2815C11.5544 14.3367 12.3374 14.4468 13.1099 14.611C14.1808 14.8307 15.4714 15.27 15.9291 16.2311C16.2224 16.8479 16.2224 17.564 15.9291 18.1808C15.4714 19.1419 14.1808 19.5812 13.1099 19.7918C12.3384 19.9634 11.5551 20.0766 10.7666 20.1304C9.57937 20.2311 8.38659 20.2494 7.19681 20.1854C6.92221 20.1854 6.65677 20.1854 6.38217 20.1304C5.59663 20.0773 4.81632 19.9641 4.04807 19.7918C2.96798 19.5812 1.68652 19.1419 1.2197 18.1808C1.0746 17.8747 0.999552 17.5401 1.00002 17.2014Z"
            stroke={props.color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
      }

    </Svg>

  )
}
