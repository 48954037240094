import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconProps } from '.';
import { AppColors } from '../../common/AppColors';

const MessageFileIcon = ({
  color = AppColors.gray.neutral3,
  height = 20,
  width = 20,
}: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path fillRule="evenodd" clipRule="evenodd" d="M18.5408 1.32418L18.4384 1.23418C16.0528 -0.665819 12.5204 -0.335819 10.5341 1.97418L1.33963 12.6742C0.643391 13.4842 -0.0528474 14.9042 0.0597794 16.1042C0.162167 17.2342 0.704824 18.2642 1.60584 18.9942C2.49661 19.7242 3.63312 20.0742 4.79011 19.9742C5.95733 19.8742 7.00169 19.3342 7.75936 18.4642L15.6944 9.23418C16.2371 8.59418 16.4931 7.76418 16.3907 6.93418C16.2985 6.17418 15.9094 5.49418 15.3156 5.00418C14.691 4.49418 13.8924 4.24418 13.0733 4.31418C12.2542 4.38418 11.517 4.75418 10.9846 5.37418L4.90273 12.4442C4.74915 12.6242 4.66724 12.8542 4.68772 13.1042C4.72867 13.5942 5.16894 13.9642 5.68088 13.9142C5.93685 13.8942 6.15187 13.7742 6.30545 13.5942L12.3873 6.51418C12.6023 6.26418 12.9197 6.11418 13.2474 6.09418C13.5648 6.07418 13.8822 6.15418 14.1381 6.36418C14.3839 6.56418 14.5374 6.83418 14.5682 7.14418C14.6091 7.48418 14.5067 7.81418 14.2917 8.07418L6.35664 17.3042C5.91638 17.8142 5.31229 18.1242 4.63653 18.1842C3.96076 18.2442 3.30548 18.0342 2.7833 17.6142C2.26112 17.1942 1.94372 16.5942 1.89253 15.9342C1.84133 15.3842 2.24064 14.4042 2.75259 13.8042L11.947 3.10418C13.2986 1.53418 15.6842 1.32418 17.3019 2.61418L17.4043 2.70418C18.9606 4.04418 19.1551 6.36418 17.8139 7.92418L10.2064 16.7742C10.0529 16.9542 9.97094 17.1842 9.99142 17.4342C10.0324 17.9242 10.4829 18.2942 10.9846 18.2442C11.2406 18.2242 11.4556 18.1042 11.6092 17.9242L19.2166 9.06418C21.2029 6.76418 20.906 3.29418 18.5408 1.32418Z" fill={color} />
    </Svg>
  );
}

export default MessageFileIcon;