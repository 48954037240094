import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View, Text } from "react-native";
import { SurveyQuestion } from "../api/schema/models/SurveyQuestion";
import { AppColors } from "../common/AppColors";
import { Colors } from "../common/Colors";
import ErrorBag from "../common/ErrorBag";
import { RootNavigationParamList } from "../navigation/RootNav";
import { DefaultText } from "./DefaultText";
import { FormError } from "./FormError";
import { FormSwitchInput } from "./forms/FormSwitchInput";
import { FormTextInput } from "./forms/FormTextInput";
import Icons from "./icons";
import { ListItemSeparator } from "./ListItemSeparator";

type SurveyQuestionsFormProps = {
    questions: SurveyQuestion[]
    onUpdated: (questions: SurveyQuestion[]) => void
    formErrors: ErrorBag
  }


export default observer((props: SurveyQuestionsFormProps) => {
    const { t } = useTranslation()
    const navigation = useNavigation<StackNavigationProp<RootNavigationParamList>>()
  
    const addQuestion = () => {
      props.onUpdated([
        ...props.questions,
        {
          type: undefined,
          question: '',
          choices: [],
          isRequired: false,
        }
      ])
    };
  
    const getQuestionTypeLabel = (type: string | undefined) => {
      if (type === 'multipleChoiceOne') {
        return t('Multiple choice one', 'Multiple Choice (one answer)')
      } else if (type === 'multipleChoiceMany') {
        return t('Multiple choice many', 'Multiple Choice (many answers)')
      } else if (type === 'shortText') {
        return t('Short text', 'Short text')
      } else if (type === 'longText') {
        return t('Long text', 'Long text')
      } else {
        return type
      }
    }
  
    const setRequired = (idx: number, value: boolean) => {
      props.onUpdated(props.questions.map((q, i) => {
        return {
          ...q,
          isRequired: i === idx ? value : q.isRequired,
        }
      }))
    }
  
    const setQuestionText = (idx: number, value: string) => {
      props.onUpdated(props.questions.map((q, i) => {
        return {
          ...q,
          question: i === idx ? value : q.question,
        }
      }))
    }
  
    const setChoiceText = (questionIdx: number, choiceIdx: number, value: string) => {
      props.onUpdated(props.questions.map((q, i) => {
        return {
          ...q,
          choices: q.choices.map((c, j) => {
            return i === questionIdx && j === choiceIdx
              ? value
              : c
          }),
        }
      }))
    }
  
    const removeQuestion = (idx: number) => {
      props.onUpdated(props.questions.filter((q, i) => {
        return idx !== i
      }))
    }
  
    const removeChoice = (questionIdx: number, choiceIdx: number) => {
      props.onUpdated(props.questions.map((q, i) => {
        return {
          ...q,
          choices: q.choices.filter((c, j) => i !== questionIdx || j !== choiceIdx),
        }
      }))
    }
  
    const addChoice = (questionIdx: number) => {
      props.onUpdated(props.questions.map((q, i) => {
        return {
          ...q,
          choices: i === questionIdx
            ? [
              ...q.choices,
              '',
            ]
            : q.choices,
        }
      }))
    }
  
    const showQuestionTypePicker = (idx: number) => {
      navigation.push('Picker', {
        title: t('Question Type', 'Question Type'),
        options: [
          {
            value: 'multipleChoiceOne',
            text: t('Multiple choice one', 'Multiple Choice (one answer)'),
          },
          {
            value: 'multipleChoiceMany',
            text: t('Multiple choice many', 'Multiple Choice (many answers)'),
          },
          {
            value: 'shortText',
            text: t('Short text', 'Short text'),
          },
          {
            value: 'longText',
            text: t('Long text', 'Long text'),
          },
        ],
        onChoose: (option, pop) => {
          props.onUpdated(props.questions.map((q, i) => {
            return {
              ...q,
              type: i === idx ? option.value as any : q.type,
            }
          }))
  
          pop()
        }
      })
    }
  
    return <View>
      {
        props.questions.map((question, idx) => <View key={idx} style={[styles.container]}>
           <View style={styles.dragItem}>
            <Icons.CandyBox
              width={29}
              height={11}
              color={AppColors.gray.neutral3}
            />
           </View>
          <TouchableOpacity onPress={() => showQuestionTypePicker(idx)} style={{ flex: 1, alignItems: 'center' }}>
              <View style={{ flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                <DefaultText style={{ flex: 1, color: question.type ? Colors.DefaultTextColor : '#999' }}>{getQuestionTypeLabel(question.type) ?? t('Choose Type', 'Choose Type')}</DefaultText>
               
                <MaterialIcons
                  name={'chevron-right'}
                  size={30}
                  color={AppColors.orange.primary}
                />
              </View>
            </TouchableOpacity>
            <FormTextInput
              placeholder={t('Question', 'Question')}
              value={question.question}
              onChangeText={value => setQuestionText(idx, value)}
              style={styles.input}
              hideSeparator={false}
            />
            <ListItemSeparator/>
            {
            ['multipleChoiceOne', 'multipleChoiceMany'].indexOf(question.type ?? '') > -1
              ? <>
                  
                {question.choices.map((choice, choiceIdx) => <View key={choiceIdx} style={{ flexDirection: 'row', flex: 1, alignItems: 'center'}}>
                  <Icons.CheckBoxIcon
                    color={AppColors.orange.primary}
                    height={20}
                    width={20}
                  />
                  <View style={{ flex: 1, marginLeft: 10 }}>
                  
                    <FormTextInput
                      placeholder={t('Enter choice text', 'Enter choice text')}
                      value={choice}
                      onChangeText={value => setChoiceText(idx, choiceIdx, value)}
                      hideSeparator={true}
                      style={[styles.input, {height: 40}]}
                    />
                  </View>
                  <TouchableOpacity onPress={() => removeChoice(idx, choiceIdx)}>
                    <MaterialIcons
                      name="close"
                      size={24}
                      color={AppColors.gray.neutral1}
                    />
                  </TouchableOpacity>
                </View>)
                }
                <TouchableOpacity onPress={() => addChoice(idx)}>
                <View style={{ flexDirection: 'row', flex: 1, alignItems: 'center'}}>
                  <Icons.CheckBoxIcon
                    color={AppColors.orange.primary}
                    height={20}
                    width={20}
                  />
                  <View style={{ flex: 1, marginLeft: 10, height: 40, justifyContent: 'center' }}>
                    <Text style={styles.addChoiceButtonText}>Add option</Text>
                  </View>
          
                </View>
                </TouchableOpacity>
              </>
              : null
          }
            <ListItemSeparator/>

            <View style={styles.actions}>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text style={{color: AppColors.gray.neutral3}}>{t('Required Q', 'Required?')}</Text>
                <FormSwitchInput
                  label={''}
                  value={question.isRequired}
                  onChange={value => setRequired(idx, value)}
                  hideSeparator={true}
                />
              </View>
              <TouchableOpacity onPress={() => removeQuestion(idx)}>
                  <Icons.TrashIcon
                    color={AppColors.gray.neutral1}
                    height={20}
                    width={20}
                  />
                </TouchableOpacity>
            </View>
          
        </View>)
      }
      <TouchableOpacity onPress={() => addQuestion()}>
        <View style={styles.addQuestionButton}>
          <DefaultText style={styles.addQuestionButtonText}>{t('Add More', 'Add More')}</DefaultText>
        </View>
      </TouchableOpacity>
      <FormError
        field={'questions'}
        errors={props.formErrors}
      />
    </View>
});


const styles = StyleSheet.create({
    container: {
      backgroundColor: AppColors.gray.neutral6,
      borderRadius: 6,
      marginTop: 10, 
      padding: 10,
      justifyContent: 'center',
    },
    addQuestionButton: {
        padding: 20,
    },
    addQuestionButtonText: {
        color: Colors.SecondaryBlue,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    addChoiceButton: {
        padding: 10,
    },
    addChoiceButtonText: {
      color: AppColors.gray.neutral3,
      textAlignVertical: 'center'
    },
    actions: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    input: {
      paddingHorizontal: 0,
      fontSize: 15,
      marginVertical:0,
    },
    dragItem: {justifyContent: 'center', flexDirection: 'row'}
})
  