import React from 'react'
import { StyleSheet, View } from 'react-native'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';

export const NotificationPost = () => {
  return <Svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <Path d="M0.5 20C0.5 31.0457 9.45431 40 20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.95431 31.5457 0 20.5 0C9.45431 0 0.5 8.95431 0.5 20Z" fill="#FF7A33"/>
  <Path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M20.15 16.09C20.15 16.22 20.04 16.32 19.9 16.32C19.76 16.32 19.64 16.22 19.64 16.09V14.74C19.64 14.49 19.43 14.28 19.16 14.28C18.9 14.28 18.68 14.48 18.68 14.74V16.09C18.68 16.72 19.22 17.24 19.89 17.24C20.56 17.24 21.1 16.73 21.1 16.09V14.24L28.77 16.34L25 28.54L12.02 24.99L15.79 12.79L20.15 13.98V16.09Z" fill="white"/>
  <Path fill-rule="evenodd" clip-rule="evenodd" d="M29.5701 12.97H21.5601L21.5501 12.96V11.58C21.5501 10.71 20.8001 10 19.8801 10H19.7401C18.8201 10 18.0701 10.71 18.0701 11.58V12.01L15.7001 11.36C15.4701 11.3 15.2301 11.33 15.0201 11.44C14.8001 11.55 14.6401 11.74 14.5701 11.97L10.5401 25.05C10.4701 25.28 10.5001 25.52 10.6101 25.73C10.7301 25.94 10.9301 26.1 11.1701 26.17L25.0701 29.96C25.1501 29.98 25.2401 29.99 25.3201 29.99C25.7201 29.99 26.0801 29.73 26.2001 29.35L26.5001 28.36H29.5601C30.0701 28.36 30.4801 27.96 30.4901 27.47V13.86C30.5001 13.36 30.0901 12.97 29.5701 12.97ZM19.4701 11.58C19.4701 11.46 19.6001 11.36 19.7401 11.36H19.8901C20.0301 11.36 20.1601 11.46 20.1601 11.58V12.59L19.4701 12.41V11.58ZM25.0101 28.54L12.0201 24.99L15.7901 12.79L20.1501 13.98V16.09C20.1501 16.22 20.0401 16.32 19.9001 16.32C19.7601 16.32 19.6501 16.22 19.6501 16.09V14.74C19.6501 14.49 19.4401 14.28 19.1701 14.28C18.9101 14.28 18.6901 14.49 18.6901 14.74V16.09C18.6901 16.72 19.2301 17.24 19.9001 17.24C20.5701 17.24 21.1101 16.73 21.1101 16.09V14.24L28.7801 16.34L25.0101 28.54ZM29.1001 27.02H26.9101L29.1001 19.45V27.02ZM29.1001 15.04L25.9301 14.33H29.1001V15.04Z" fill="white"/>
  </Svg>
}
