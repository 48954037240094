import React from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { observer } from 'mobx-react-lite'
import { LogOutHeaderButton } from '../../components/LogOutHeaderButton'
import { District } from '../../api/schema/GetSchoolDistrictsResponse'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';
import { MiniLogoSvg } from '../../../assets/images/mini-logo'

export type ChooseRoleScreenParams = {
  district: District
}

export const ChooseRoleScreen = observer((props: StackScreenProps<RootNavigationParamList, 'ChooseRole'>) => {
  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: props.route.params.district.name,
      headerStyle: {backgroundColor: '#fff', borderBottomWidth: 0},
      headerTitleStyle: {color: '#FF7A33'},
      headerRight: () => <LogOutHeaderButton/>,
      headerBackImage: () => <Svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <Path d="M12.59 2.23001C13.13 1.72001 13.13 0.890007 12.59 0.390007C12.05 -0.119993 11.17 -0.119993 10.63 0.390007L1.4 9.09001C0.859995 9.60001 0.859995 10.43 1.4 10.93L10.63 19.63C11.17 20.14 12.05 20.14 12.59 19.63C13.13 19.12 13.13 18.29 12.59 17.79L4.34 10L12.59 2.23001Z" fill="#FF7A33"/>
                            </Svg>
    })
  }, [props.navigation])

  const renderRoleButton = (role: 'parent' | 'student') => {
    const icons = {
      parent: require('../../../assets/images/parent-icon.png'),
      student: require('../../../assets/images/student-icon.png'),
    }

    return <TouchableOpacity
      onPress={() => props.navigation.push('ScanStudentQrCode', {
        district: props.route.params.district,
        role,
      })}
      style={{width:'90%'}}
    >
      <View style={styles.roleButtonContainer}>
        <Image style={styles.roleIcon} source={icons[role]}/>
        <Text style={styles.roleText}>I'm a {role}</Text>
        <View style={{flex:1, alignItems:'center'}}>
          <Svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M8 7C7.99219 6.72656 7.89062 6.49219 7.67969 6.28125L1.60156 0.335938C1.42188 0.164062 1.21094 0.0703125 0.953125 0.0703125C0.429688 0.0703125 0.0234375 0.476562 0.0234375 1C0.0234375 1.25 0.125 1.48438 0.304688 1.66406L5.77344 7L0.304688 12.3359C0.125 12.5156 0.0234375 12.7422 0.0234375 13C0.0234375 13.5234 0.429688 13.9297 0.953125 13.9297C1.20312 13.9297 1.42188 13.8359 1.60156 13.6641L7.67969 7.71094C7.89844 7.50781 8 7.27344 8 7Z" fill="#FF7A33"/>
          </Svg>
        </View>
      </View>
    </TouchableOpacity>
  }

  return <View style={styles.container}>
    <View style={{flex:1, alignItems: 'center'}}>
      <MiniLogoSvg></MiniLogoSvg>
      <Text style={styles.headerText}>Choose your role</Text>
      {renderRoleButton('parent')}
      {renderRoleButton('student')}
    </View>
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },

  headerText: {
    paddingTop: 30,
    fontSize: 26,
    color: '#0082CD',
    textAlign: 'center',
    fontWeight: 'bold',
    width: '100%'
  },

  roleButtonContainer: {
    marginTop: 20,
    marginRight: 20,
    borderWidth: 2,
    borderColor: '#0082CD',
    backgroundColor: '#F6F7FA',
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 12,
    width: '100%'
  },

  roleIcon: {
    width: 80,
    height: 80,
  },

  roleText: {
    flex: 2,
    fontWeight: 'bold',
    fontSize: 18,
    paddingLeft: 40,
    paddingRight: 10,
  },
})
