import React from 'react'
import { ActivityIndicator, StyleSheet } from 'react-native'

export const ContentActivityIndicator = () => {
  return <ActivityIndicator size={'large'} style={styles.spinner}/>
}

const styles = StyleSheet.create({
  spinner: {
    marginTop: 24,
  },
})
