import { StyleSheet, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { DefaultText } from './DefaultText'
import { MaterialIcons } from '@expo/vector-icons'
import { Colors } from '../common/Colors'
import _ from 'lodash'

type Props = {
  label: string
  value: React.ReactElement | string
  onPress?: () => void
  disabled?: boolean
}

export const SelectInput = (props: Props) => {
  return <TouchableOpacity onPress={props.onPress} disabled={props.disabled}>
    <View style={styles.container}>
      <DefaultText style={styles.label}>{props.label}</DefaultText>
      {
        _.isString(props.value)
          ? <DefaultText style={styles.value}>{props.value}</DefaultText>
          : <View style={styles.valueWrapper}>
            {props.value}
          </View>
      }
      <MaterialIcons
        name={'chevron-right'}
        size={30}
        color={'#FF7A33'}
      />
    </View>
  </TouchableOpacity>
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 50,
  },
  label: {
    flex: 1,
    fontSize: 16,
    fontFamily: 'Rubik_400Regular',
  },
  value: {
    fontSize: 12,
    color: '#0082CD',
    fontFamily: 'Rubik_700Bold',
  },
  valueWrapper: {
    alignItems: 'flex-end',
  },
  accessory: {},
})
