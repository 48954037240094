import React from 'react'
import { ActivityIndicator, FlatList, ListRenderItemInfo, StyleSheet, Text, TouchableOpacity, View, Platform } from 'react-native'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { API } from '../../api/API'
import { LogOutHeaderButton } from '../../components/LogOutHeaderButton'
import { SearchTextInput } from '../../components/SearchTextInput'
import { useTranslation } from 'react-i18next'
import { District } from '../../api/schema/GetSchoolDistrictsResponse'
import { ErrorMessage } from '../../components/ErrorMessage'
import { BlockButton } from '../../components/BlockButton'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';
import { MiniLogoSvg } from '../../../assets/images/mini-logo'
//import * as Sentry from 'sentry-expo'

const MIN_SEARCH_LENGTH = 3

export const SearchSchoolDistrictScreen = observer((props: StackScreenProps<RootNavigationParamList, 'SearchSchoolDistrict'>) => {
  const { t } = useTranslation()

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: 'Search District',
      headerStyle: { backgroundColor: '#fff', borderBottomWidth: 0 },
      headerTitleStyle: { color: '#FF7A33' }
    })
  }, [props.navigation])

  type State = {
    loading: boolean
    searchText: string
    error?: string
    districts: District[]
    visibleDistricts: District[]
  }

  const state = useLocalObservable<State>(() => ({
    loading: false,
    searchText: '',
    error: undefined,
    districts: [] as District[],
    get visibleDistricts () {
      return this.searchText.length < MIN_SEARCH_LENGTH ? [] : this.districts.filter(d => d.name.toLowerCase().indexOf(this.searchText.toLowerCase().trim()) > -1)
    },
  }))

  React.useEffect(() => {
    loadDistricts().then()
  }, [])

  const loadDistricts = async () => {
    runInAction(() => {
      state.loading = true
      state.error = undefined
    })

    try {
      const response = await API.getSchoolDistricts()
      runInAction(() => state.districts = response.districts)
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = t('There was an error loading districts', 'There was an error loading districts'))
    }

    runInAction(() => state.loading = false)
  }

  const renderListItem = (item: ListRenderItemInfo<District>) => {
    return <TouchableOpacity
      style={{flexDirection:'row', alignContent:'center', alignItems:'center'}}
      onPress={() => props.navigation.push('AddStudentQrCode', { district: item.item })}
    >
      <View style={[styles.listItem, item.index % 2 == 0 ? styles.listItemAlt : undefined]}>
        <Text style={styles.listItemSubtitle}>{item.item.location}</Text>
        <Text style={styles.listItemTitle}>{item.item.name}</Text>
      </View>
      <View style={{flex:1, alignItems:'center'}}>
      <Svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path d="M8 7C7.99219 6.72656 7.89062 6.49219 7.67969 6.28125L1.60156 0.335938C1.42188 0.164062 1.21094 0.0703125 0.953125 0.0703125C0.429688 0.0703125 0.0234375 0.476562 0.0234375 1C0.0234375 1.25 0.125 1.48438 0.304688 1.66406L5.77344 7L0.304688 12.3359C0.125 12.5156 0.0234375 12.7422 0.0234375 13C0.0234375 13.5234 0.429688 13.9297 0.953125 13.9297C1.20312 13.9297 1.42188 13.8359 1.60156 13.6641L7.67969 7.71094C7.89844 7.50781 8 7.27344 8 7Z" fill="#626262"/>
      </Svg>
      </View>
    </TouchableOpacity>
  }

  return <View style={styles.container}>
    <View style={styles.main}>
    <MiniLogoSvg></MiniLogoSvg>
    <Text style={styles.headerText}>{t('Look for Your School District', 'Look for Your School District')}</Text>
    {
      state.error
        ? <>
          <ErrorMessage message={state.error}/>
          <BlockButton
            variant={'secondary'}
            title={t('Retry', 'Retry')}
            onPress={() => loadDistricts()}
          />
        </>
        : <>
          <View style={styles.inputContainer}>
            <SearchTextInput
              placeholder={t('Enter school district name', 'Enter school district name')}
              value={state.searchText}
              onChange={ev => runInAction(() => state.searchText = ev.nativeEvent.text)}
              containerStyle={{marginTop: 16, minHeight:56, borderColor: '#0082CD', borderWidth: 2, width: '100%', paddingRight: 24}}
            />
          </View>
          {
            state.searchText.length < MIN_SEARCH_LENGTH
              ? <Text style={styles.minSearchWarning}>{t('Enter at least X characters', 'Enter at least {{minCharacters}} characters...', { minCharacters: MIN_SEARCH_LENGTH })}</Text>
              : state.loading
                ? <View style={styles.activityIndicatorContainer}><ActivityIndicator/></View>
                : state.visibleDistricts.length === 0
                  ? <Text style={styles.noResults}>{t('No districts match your search', 'No districts match your search')}</Text>
                  : <FlatList
                    keyboardShouldPersistTaps="handled"
                    data={state.visibleDistricts}
                    keyExtractor={(item: District) => String(item.id)}
                    style={{width:'100%'}}
                    renderItem={renderListItem}
                  />
          }
        </>
    }
    </View>
    
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  main: {
    flex: 1,
    alignItems: 'center'
  },
  inputContainer: {
    margin: 20,
  },

  headerText: {
    paddingTop: 30,
    fontSize: 22,
    color: '#555',
    textAlign: 'center',
    fontWeight: 'bold',
  },

  minSearchWarning: {
    color: '#999',
    paddingHorizontal: 20,
    textAlign: 'center',
  },

  activityIndicatorContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
  },

  noResults: {
    color: '#999',
    paddingHorizontal: 20,
    textAlign: 'center',
  },

  listItem: {
    flex: 3,
    padding: 20,
    width: '100%'
  },

  listItemTitle: {
    fontWeight: 'bold',
    fontSize: 16,
  },

  listItemSubtitle: {
    fontSize: 12,
  },

  listItemAlt: {
    flex: 3,
    backgroundColor: '#fff',
    width: '100%'
  },
})
