import { UrgenAlert } from '../../api/schema/models/UrgentAlert'
import { StyleSheet, View, Text, Platform } from 'react-native'
import { ProfileImage } from '../ProfileImage'
import { BoldText } from '../BoldText'
import { extractErrorMessage, fullName, utc } from '../../common/Util'
import { DefaultText } from '../DefaultText'
import { RelativeTime } from '../RelativeTime'
import React from 'react'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { UserContext } from '../../models/UserContext'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { observer } from 'mobx-react-lite'
import { Menu, MenuOption, MenuOptions, MenuTrigger } from 'react-native-popup-menu'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Colors } from '../../common/Colors'
import { getModalManager } from '../../contexts/ModalContext'
import { useTranslation } from 'react-i18next'
import { API } from '../../api/API'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { RedFlag } from './RedFlag'
//import * as Sentry from 'sentry-expo'

type Props = {
  post: UrgenAlert
  hideEditButton?: boolean
}

export const UrgentAlertHeader = observer((props: Props) => {
  const navigation = useNavigation<StackNavigationProp<RootNavigationParamList>>()
  const { t } = useTranslation()

  const post = props.post

  const visibilityDescription = (post: UrgenAlert) => {
    if (post.visibility === 'district') {
      return post.districtName
    } else if (post.visibility === 'school') {
      return post.schoolName
    } else if (post.visibility === 'section') {
      return post.sectionName
    } else if (post.visibility === 'grade') {
      return post.gradeLevelName
    } else if (post.visibility === 'community') {
      return post.communityName
    } else {
      return '---'
    }
  }

  const canEditPost = (post: UrgenAlert, userContext: UserContext) => {

    if (!userContext) {
      return false
    }

    if (userContext.userType === 'teacher' && userContext.teacher) {
      if (userContext.teacher.role === 'district_director') {
        if (post.districtId === userContext.teacher.districtId) {
          return true
        }
      } else if (userContext.teacher.role === 'school_director') {
        if (post.schoolId === userContext.teacher.schoolId) {
          return true
        }
      } else if (post.userId === userContext.id) {
        return true
      }
    }

    return false
  }

  const submitDeletePost = async () => {
    AppStateStore.showActivityIndicator()

    try {
      await API.deletePost(post.id)
      AppStateStore.eventBus.emit(AppEvents.PostDeleted)
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      getModalManager()
        .showModal({
          title: t('Error', 'Error'),
          message: extractErrorMessage(err.response),
        })
    }

    AppStateStore.hideActivityIndicator()
  }

  const deletePost = () => {
    getModalManager()
      .showModal({
        title: t(`Delete Urgent Alert`, `Delete Urgent Alert`),
        message: t(`Are you sure you want to delete this Urgent Alert`, `Are you sure you want to delete this Urgent Alert?`),
        buttons: [
          {
            text: t('Yes Delete', 'Yes, Delete'),
            variant: 'danger',
            onPress: dismiss => {
              dismiss()
              submitDeletePost().then()
            },
          },
          {
            text: t('No', 'No'),
            variant: 'secondary',
          },
        ]
      })
  }

  return <View style={styles.header}>
    <ProfileImage size={40} url={post.profilePhotoUrl} />
    <View style={styles.metaContainer}>
      <Text style={styles.name}>{fullName(post)}</Text>
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.meta}>{visibilityDescription(post)} - <RelativeTime date={utc(post.createdAt)} /></Text>
      </View>
    </View>
    <RedFlag />
    {
      (!props.hideEditButton && canEditPost(post, AppStateStore.userContext!))
        ?
        <View style={styles.editButtonContainer}>
          <Menu>
            <MenuTrigger>
              <MaterialCommunityIcons name={'dots-vertical'} style={styles.editButtonIcon} />
            </MenuTrigger>
            <MenuOptions customStyles={{
              optionsContainer: { width: 100 },
            }}>
              <MenuOption onSelect={() => deletePost()} style={{ padding: 12 }}>
                <DefaultText style={{ fontSize: 16, color: Colors.DangerTextColor }}>Delete</DefaultText>
              </MenuOption>
            </MenuOptions>
          </Menu>
        </View>
        : null
    }
  </View>
})

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',

  },
  metaContainer: {
    marginLeft: 8,
    flex: 1,
  },
  name: {
    fontSize: 16,
    color: '#303030',
    fontWeight: '600',
    fontFamily: 'Rubik_700Bold',
  },
  meta: {
    color: '#626262',
    fontWeight: '500',
    fontSize: 13,
  },
  editButtonContainer: {
  },
  editButtonIcon: {
    minWidth: 30,
    minHeight: 30,
    fontSize: 28,
    color: '#666',
  },
})