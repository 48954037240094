import { observer } from 'mobx-react-lite'
import { AppStateStore } from '../contexts/AppStateStore'
import { ActivityIndicator, Modal, StyleSheet, View } from 'react-native'
import React from 'react'

export const ModalActivityIndicator = observer(() => {
  return AppStateStore.shouldShowModalActivityIndicator
    ? <Modal
      transparent={true}
      animationType={'none'}
      visible={true}>
      <View style={styles.modalContainer}>
        <View style={styles.modalActivityIndicatorBackground}>
          <ActivityIndicator size="large" color="#fff"/>
        </View>
      </View>
    </Modal>
    : null
})

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalActivityIndicatorBackground: {
    padding: 30,
    borderRadius: 8,
    backgroundColor: 'rgba(0,0,0,.2)',
  },
})
