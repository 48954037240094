import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconProps } from '.';
import { AppColors } from '../../common/AppColors';

const MessageImageIcon = ({
  color = AppColors.gray.neutral3,
  height = 20,
  width = 20,
}: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path fillRule="evenodd" clipRule="evenodd" d="M15.6916 6.27C16.3571 6.27 16.8896 6.8 16.8896 7.44C16.8896 8.08 16.3469 8.61 15.6916 8.61C15.0363 8.61 14.4937 8.08 14.4937 7.44C14.4937 6.8 15.0261 6.27 15.6916 6.27ZM15.6916 5.1C14.3708 5.1 13.2957 6.15 13.2957 7.44C13.2957 8.73 14.3708 9.78 15.6916 9.78C17.0124 9.78 18.0875 8.73 18.0875 7.44C18.0875 6.15 17.0124 5.1 15.6916 5.1ZM19.3776 2C21.1387 2 22.5721 3.4 22.5721 5.12V18.92C22.5721 20.62 21.1591 22 19.4185 22H5.24803C3.50744 22 2.09448 20.62 2.09448 18.92V5.12C2.09448 3.4 3.52792 2 5.28899 2H19.3776ZM20.9748 18.88C20.9748 19.74 20.2581 20.44 19.3776 20.44H5.28899C4.40845 20.44 3.69174 19.74 3.69174 18.88V18.82L8.85209 11.34L13.091 17.48C13.2343 17.7 13.4903 17.83 13.7565 17.83C14.0227 17.83 14.2684 17.7 14.422 17.48L17.2377 13.4L20.9748 18.81V18.88ZM20.9748 16.02L17.9032 11.57C17.7599 11.35 17.5039 11.22 17.2377 11.22C16.9715 11.22 16.7257 11.35 16.5722 11.57L13.7565 15.65L9.51762 9.5C9.37427 9.28 9.1183 9.15 8.86233 9.15C8.59612 9.15 8.35039 9.28 8.19681 9.5L3.69174 16.02V5.12C3.69174 4.26 4.40845 3.56 5.28899 3.56H19.3673C20.2479 3.56 20.9646 4.26 20.9646 5.12V16.02H20.9748Z" fill={color} />
    </Svg>
  );
}

export default MessageImageIcon;
