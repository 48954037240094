import React from 'react'
import { StyleSheet, View } from 'react-native'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';

export const NotificationVideo = () => {
  return <Svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <Path d="M0.5 20C0.5 31.0457 9.45431 40 20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.95431 31.5457 0 20.5 0C9.45431 0 0.5 8.95431 0.5 20Z" fill="#4DC9D1"/>
  <Path fill-rule="evenodd" clip-rule="evenodd" d="M14.613 12.5H20.4051C22.8271 12.5 24.5181 14.169 24.5181 16.5609V23.4391C24.5181 25.831 22.8271 27.5 20.4051 27.5H14.613C12.191 27.5 10.5 25.831 10.5 23.4391V16.5609C10.5 14.169 12.191 12.5 14.613 12.5ZM28.458 14.879C28.897 14.6556 29.412 14.679 29.8311 14.9429C30.2501 15.2059 30.5001 15.6627 30.5001 16.1622V23.8384C30.5001 24.3389 30.2501 24.7947 29.8311 25.0576C29.602 25.2008 29.346 25.2739 29.088 25.2739C28.873 25.2739 28.658 25.2231 28.457 25.1206L26.976 24.3734C26.428 24.0952 26.088 23.5368 26.088 22.9165V17.083C26.088 16.4617 26.428 15.9033 26.976 15.6272L28.458 14.879Z" fill="white"/>
  </Svg>
}
