import ApiClient from '../ApiClient'
import { RegisterPushNotificationTokenRequest } from '../schema/RegisterPushNotificationTokenRequest'

export const readPushNotification = async (token: any) => {
  const request = {
    notification: token,
  }

  await ApiClient.getInstance().post('/user/read-notification', request)
}
