import React from "react";
import { Pressable, Text } from "react-native";
import { buttonStyles } from "./buttonStyles";

interface SubmitButtonProps {
    color: string,
    onSubmit: (args: any) => void,
    disabled: boolean,
    text: string
}

export default (props: SubmitButtonProps) => {
    return (
        <Pressable 
              style={[buttonStyles.button, {backgroundColor: props.color}]}
              onPress={props.onSubmit}
              disabled={props.disabled}
          >
              <Text style={{fontSize: 15, fontWeight: 'bold', color: 'white'}}>{props.text}</Text>
          </Pressable>
    );
} 