import React, { useEffect, useState } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { BottomTabBarProps, BottomTabNavigationOptions, createBottomTabNavigator, useBottomTabBarHeight } from '@react-navigation/bottom-tabs'
import { observer } from 'mobx-react-lite'
import { PickerScreen, PickerScreenParams } from '../screens/PickerScreen'
import { DateTimePickerScreen, DateTimePickerScreenParams } from '../screens/DateTimePickerScreen'
import { AppStateStore } from '../contexts/AppStateStore'
import { UserContext } from '../models/UserContext'
import { SplashScreen } from '../screens/SplashScreen'
import { WelcomeScreen } from '../screens/onboarding/WelcomeScreen'
import { RegisterScreen } from '../screens/onboarding/RegisterScreen'
import { VerificationScreen, VerificationScreenParams } from '../screens/onboarding/VerificationScreen'
import { LoginScreen } from '../screens/onboarding/LoginScreen'
import { ForgotIdentification } from '../screens/onboarding/ForgotIdentification'
import { VerifyForgottenScreen, VerifyForgottenScreenParams } from '../screens/onboarding/VerifyForgottenScreen'
import { ChangePasswordScreen, ChangePasswordParams } from '../screens/onboarding/ChangePassword'
import { TermsOfServicesScreen } from '../screens/onboarding/TermsOfServicesScreen'
import { VisibilityPickerScreen, VisibilityPickerScreenParams } from '../screens/VisibilityPickerScreen'
import { SupportScreen } from '../screens/SupportScreen'
import { AudioPlayerScreen, AudioPlayerScreenParams } from '../screens/audio/AudioPlayerScreen'
import { VideoPlayerScreen, VideoPlayerScreenParams } from '../screens/video/VideoPlayerScreen'
import { DocumentViewerScreen, DocumentViewerScreenParams } from '../screens/documents/DocumentViewerScreen'
import { FormViewerScreen, FormViewerScreenParams } from '../screens/forms/FormViewerScreen'
import { DocumentLoadingScreen, DocumentLoadingScreenParams } from '../screens/documents/DocumentLoadingScreen'
import { RecordAudioScreen, RecordAudioScreenParams } from '../screens/audio/RecordAudioScreen'
import { CompleteProfileScreen } from '../screens/onboarding/CompleteProfileScreen'
import { InactiveTeacherScreen } from '../screens/onboarding/InactiveTeacherScreen'
import { ChooseSchoolDistrictScreen } from '../screens/onboarding/ChooseSchoolDistrictScreen'
import { ChooseRoleScreen, ChooseRoleScreenParams } from '../screens/onboarding/ChooseRoleScreen'
import { ScanStudentQrCodeScreen, ScanStudentQrCodeScreenParams } from '../screens/onboarding/ScanStudentQrCodeScreen'
import { FeedScreen } from '../screens/feed/FeedScreen'
import { PostDetailScreen, PostDetailScreenParams } from '../screens/feed/PostDetailScreen'
import { EditPostScreen, EditPostScreenParams } from '../screens/feed/EditPostScreen'
import { ConversationsScreen } from '../screens/messages/ConversationsScreen'
import { MessagesScreen, MessagesScreenParams } from '../screens/messages/MessagesScreen'
import { CreateMessageScreen } from '../screens/messages/CreateMessageScreen'
import { CalendarScreen } from '../screens/calendar/CalendarScreen'
import { EditEventScreen, EditEventScreenParams } from '../screens/calendar/EditEventScreen'
import { EventDetailScreen, EventDetailScreenParams } from '../screens/calendar/EventDetailScreen'
import { DocumentsScreen } from '../screens/documents/DocumentsScreen'
import { CreateDocumentScreen } from '../screens/documents/CreateDocumentScreen'
import { ProfileScreen } from '../screens/profile/ProfileScreen'
import { EditProfileScreen } from '../screens/profile/EditProfileScreen'
import { SectionMembersScreen, SectionMembersScreenParams } from '../screens/profile/SectionMembers'
import { AddStudentQrCodeScreen, AddStudentQrCodeScreenParams } from '../screens/profile/AddStudentQrCodeScreen'
import { SearchSchoolDistrictScreen } from '../screens/profile/SearchSchoolDistrictScreen'
import { FileViewerScreen, FileViewerScreenParams } from '../screens/files/FileViewerScreen'
import { CreateFormScreen } from '../screens/forms/CreateFormScreen'
import { SendFormScreen, SendFormScreenParams } from '../screens/forms/SendFormScreen'
import { StudentPickerScreen, StudentPickerScreenParams } from '../screens/StudentPickerScreen'
import { ContactPickerScreen, ContactPickerScreenParams } from '../screens/ContactPickerScreen'
import { SentFormGroupDetailScreen, SentFormGroupDetailScreenParams } from '../screens/forms/SentFormGroupDetailScreen'
import { ReceivedFormsScreen } from '../screens/forms/ReceivedFormsScreen'
import { TeacherFormsScreen } from '../screens/forms/TeacherFormsScreen'
import { ReceivedFormDetailScreen, ReceivedFormDetailScreenParams } from '../screens/forms/ReceivedFormDetailScreen'
import { SentFormDetailScreen, SentFormDetailScreenParams } from '../screens/forms/SentFormDetailScreen'
import { FormLoadingScreen, FormLoadingScreenParams } from '../screens/forms/FormLoadingScreen'
import { AttendanceScreen } from '../screens/attendance/AttendanceScreen'
import { TakeAttendanceScreen, TakeAttendanceScreenParams } from '../screens/attendance/TakeAttendanceScreen'
import { ExpirationPickerScreen, ExpirationPickerScreenParams } from '../components/urgenAlerts/ExpirationPickerScreen'
import { Pressable, View, Text, TouchableOpacity } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { TabIcon } from '../components/tab_bar/TabIcon'
import { CustomTabBar } from '../components/tab_bar/TabBar'
import { AppSideMenu } from '../components/AppSideMenu'
import { Header } from '../components/header/Header'
import { NotificationCenterScreen } from '../screens/messages/NotificationCenter'
import PostTypeModal from '../screens/feed/newPost/PostTypeModal'
import NewPost from '../screens/feed/newPost/NewPost'
import { useNavigation } from '@react-navigation/native'
import { AppColors } from '../common/AppColors'
import Icons from '../components/icons'
import * as Notifications from 'expo-notifications'
import { API } from '../api/API'
import { WebViewScreen, WebViewScreenParams } from '../screens/WebViewScreen'

export type RootNavigationParamList = {
  Splash: undefined

  InactiveTeacher: undefined

  ChooseSchoolDistrict: undefined
  ChooseRole: ChooseRoleScreenParams
  ScanStudentQrCode: ScanStudentQrCodeScreenParams

  Home: undefined
  Feed: undefined
  PostDetail: PostDetailScreenParams
  CreatePost: undefined
  EditPost: EditPostScreenParams
  NewPostScreen: any

  Conversations: undefined
  Messages: MessagesScreenParams
  CreateMessage: undefined
  NotificationCenter: undefined

  Calendar: undefined
  EditEvent: EditEventScreenParams
  EventDetail: EventDetailScreenParams

  Documents: undefined
  CreateDocument: undefined

  TeacherForms: undefined
  CreateForm: undefined
  SendForm: SendFormScreenParams
  SentFormGroupDetail: SentFormGroupDetailScreenParams
  SentFormDetail: SentFormDetailScreenParams
  ReceivedForms: undefined
  Forms: undefined
  ReceivedFormDetail: ReceivedFormDetailScreenParams
  ReceivedFormsScreen: undefined
  FormLoader: FormLoadingScreenParams

  Attendance: undefined
  TakeAttendance: TakeAttendanceScreenParams
  Assistance: undefined

  Profile: undefined
  ProfileScreen: undefined
  EditProfile: undefined
  SectionMembers: SectionMembersScreenParams
  AddStudentQrCode: AddStudentQrCodeScreenParams
  SearchSchoolDistrict: undefined
  Support: undefined
  SupportScreen: undefined

  Welcome: undefined
  Register: undefined
  Verification: VerificationScreenParams
  Login: undefined
  ForgotIdentification: undefined
  VerifyForgotten: VerifyForgottenScreenParams
  ChangePassword: ChangePasswordParams
  TermsOfService: undefined

  VisibilityPicker: VisibilityPickerScreenParams
  StudentPicker: StudentPickerScreenParams
  ContactPicker: ContactPickerScreenParams
  VideoPlayer: VideoPlayerScreenParams
  AudioPlayer: AudioPlayerScreenParams
  DocumentViewer: DocumentViewerScreenParams
  FormViewer: FormViewerScreenParams
  DocumentLoader: DocumentLoadingScreenParams
  FileViewer: FileViewerScreenParams
  RecordAudio: RecordAudioScreenParams
  CompleteProfile: undefined

  Picker: PickerScreenParams
  DateTimePicker: DateTimePickerScreenParams

  ExpirationPicker: ExpirationPickerScreenParams

  WebView: WebViewScreenParams

  "E-Sign": undefined

}
let badgeCount = 0
let firstTime = true
let intervals: any[] = []

const TabNav = createBottomTabNavigator<RootNavigationParamList>()

export const TabStack = observer(() => {
  const hasAuthToken = () => {
    return !!AppStateStore.authToken
  }

  const hasUserContext = (userContext: UserContext | undefined) => {
    return !!userContext
  }

  const hasValidUserContext = (userContext: UserContext | undefined) => {
    if (!userContext) {
      return false
    } else if (userContext.userType === 'teacher' && userContext.teacher) {
      return true
    } else if (userContext.userType === 'parent' && userContext.parent?.students?.length) {
      return true
    } else if (userContext.userType === 'student' && userContext.student) {
      return true
    } else {
      return false
    }
  }

  const hasCompletedProfile = (userContext: UserContext | undefined) => {
    if (!userContext) {
      return false
    }

    if (!userContext.language) {
      return false
    }

    if (userContext.userType === 'parent') {
      if (!userContext.parent?.firstName || !userContext.parent?.lastName) {
        return false
      }
    }

    return true
  }

  const isTeacher = (userContext: UserContext | undefined) => {
    return !!(userContext && userContext.userType === 'teacher')
  }
  const [updateBadge, setUpdate] = useState(0);

  /*useEffect(() => {
    if(firstTime) {
      API.getUnreadCount().then(response => {
        badgeCount = response.unread
      })
      firstTime = false
    }    
  })*/

  useEffect(() => {
    intervals.map((i) => {
      clearInterval(i)
      intervals = []
    })
    intervals.push(setInterval(() => {
      API.getUnreadCount().then(response => {
        badgeCount = response.unread
      })
      setUpdate(Math.random())
    }, 15000))
  })

  return <TabNav.Navigator
    screenOptions={{
      header: (props) => <Header routeName={props.route.name} badgeCount={badgeCount} />,
    }}
    initialRouteName='Home'
    tabBar={(props: BottomTabBarProps) => <CustomTabBar {...props} />}
  >
    <TabNav.Screen
      name={'Conversations'}
      component={ConversationsScreen}
    />

    <TabNav.Screen
      name={'Documents'}
      component={DocumentsScreen}
    />
    <TabNav.Screen
      name={'Home'}
      component={FeedScreen}
      options={{ tabBarLabel: 'Home' }}
    />
    <TabNav.Screen
      name={'Calendar'}
      component={CalendarScreen}
    />

    <TabNav.Screen
      name={'E-Sign'}
      component={isTeacher(AppStateStore.userContext) ? TeacherFormsScreen : ReceivedFormsScreen}
    />
    <TabNav.Screen
      name={'Attendance'}
      component={AttendanceScreen}
    />
    <TabNav.Screen
      name={'Profile'}
      component={ProfileScreen}
    />

    <TabNav.Screen
      name={'ReceivedFormsScreen'}
      component={ReceivedFormsScreen}
    />

    <TabNav.Screen
      name={'SupportScreen'}
      component={SupportScreen}
    />

  </TabNav.Navigator>
})

const Stack = createStackNavigator<RootNavigationParamList>()

export const RootStack = observer(() => {
  const hasAuthToken = () => {
    return !!AppStateStore.authToken
  }

  const hasUserContext = (userContext: UserContext | undefined) => {
    return !!userContext
  }

  const hasValidUserContext = (userContext: UserContext | undefined) => {
    if (!userContext) {
      return false
    } else if (userContext.userType === 'teacher' && userContext.teacher) {
      return true
    } else if (userContext.userType === 'parent' && userContext.parent?.students?.length) {
      return true
    } else if (userContext.userType === 'student' && userContext.student) {
      return true
    } else {
      return false
    }
  }

  const hasCompletedProfile = (userContext: UserContext | undefined) => {
    if (!userContext) {
      return false
    }

    if (!userContext.language) {
      return false
    }

    if (userContext.userType === 'parent') {
      if (!userContext.parent?.firstName || !userContext.parent?.lastName) {
        return false
      }
    }

    return true
  }

  const isActive = (userContext: UserContext | undefined) => {
    //return !!(userContext)
    return !!(userContext && userContext.userType === 'teacher')
  }

  const navigation = useNavigation();

  useEffect(() => {
    Notifications.addNotificationReceivedListener((response: any) => {
      if (response.request.content.data.notificationType !== 'new-message')
        badgeCount++
    });

    /*setTimeout(() => {
      console.log('viajamos')
      //navigation.navigate('Calendar' as never)
      //navigation.navigate('EventDetail' as never, {eventId: 16, startsAt: '2022-09-05 16:30:17'} as never)
      navigation.navigate('Conversations' as never)
      navigation.navigate('Messages' as never, {conversationId: 15} as never)
      //navigation.navigate('Home' as never)
      //navigation.navigate('PostDetail' as never, {postId: 165} as never)
      //navigation.navigate('Documents' as never)
      //navigation.navigate('DocumentLoader' as never, {documentId: 9, title: 'titula'} as never)
      
      //navigation.navigate(TabNav as never, {screen: 'ReceivedForms'} as never)
      
      //navigation.navigate('Forms' as never)
      //navigation.navigate('FormLoader' as never, {formId: 108} as never)
    }, 5000)*/

    Notifications.addNotificationResponseReceivedListener((response: any) => {
      API.markSearchNotification(response.notification.request.content.data?.notificationType, response.notification.request.content.data?.id).then()
      if (response.notification.request.content.data?.notificationType === 'event') {
        navigation.navigate('Calendar' as never)
        navigation.navigate('EventDetail' as never, { eventId: response.notification.request.content.data?.id, startsAt: response.notification.request.content.data?.other } as never)
      }
      else if (response.notification.request.content.data?.notificationType === 'new-message') {
        navigation.navigate('Conversations' as never)
        API.markConversation(response.notification.request.content.data?.id)
        navigation.navigate('Messages' as never, { conversationId: response.notification.request.content.data?.id } as never)
      }
      else if (response.notification.request.content.data?.notificationType === 'post') {
        navigation.navigate('Home' as never)
        navigation.navigate('PostDetail' as never, { postId: response.notification.request.content.data?.id } as never)
      }
      else if (response.notification.request.content.data?.notificationType === 'document') {
        navigation.navigate('Documents' as never)
        navigation.navigate('DocumentLoader' as never, { documentId: response.notification.request.content.data?.id, title: response.notification.request.content.data?.other } as never)
      }
      else if (response.notification.request.content.data?.notificationType === 'form') {
        navigation.navigate('E-Sign' as never)
        navigation.navigate('FormViewer' as never, { signatureId: response.notification.request.content.data?.id, studentId: response.notification.request.content.data?.other } as never)
      }
      else if (response.notification.request.content.data?.notificationType === 'home') {
        navigation.navigate('Conversations' as never)
        navigation.navigate('Messages' as never, { conversationId: response.notification.request.content.data?.id } as never)
      }
    });
  }, [])

  return <Stack.Navigator

    screenOptions={{
      cardStyle: {
        backgroundColor: 'white',
      },
      headerShadowVisible: false,
      headerBackTitleVisible: false,
      headerLeft: () => {
        return <View style={{ flexDirection: 'row', marginLeft: 15 }}>
          <TouchableOpacity onPress={() => navigation.goBack()} hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}>
            <Icons.Back width={20} height={20} color={AppColors.orange.primary} />
          </TouchableOpacity>
        </View>
      },
    }}>
    {
      hasAuthToken()
        ?
        hasUserContext(AppStateStore.userContext)
          ? hasValidUserContext(AppStateStore.userContext)
            ? hasCompletedProfile(AppStateStore.userContext)
              ? <>
                <Stack.Screen
                  name={'Feed'}
                  component={TabStack}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name={'PostDetail'}
                  component={PostDetailScreen}
                />
                <Stack.Screen
                  name={'CreatePost'}
                  component={NewPost}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name={'EditPost'}
                  component={EditPostScreen}
                />

                <Stack.Screen
                  name={'Conversations'}
                  component={ConversationsScreen}
                />

                <Stack.Screen
                  name='NewPostScreen'
                  options={{
                    animationEnabled: true,
                    headerShown: false,
                    presentation: 'modal',
                    detachPreviousScreen: false,
                    cardStyle: {
                      backgroundColor: 'transparent'
                    },
                  }}
                  component={PostTypeModal} />

                <Stack.Screen
                  name={'Messages'}
                  component={MessagesScreen}
                />
                <Stack.Screen
                  name={'CreateMessage'}
                  component={CreateMessageScreen}
                />
                <Stack.Screen
                  name={'NotificationCenter'}
                  component={NotificationCenterScreen}
                />

                <Stack.Screen
                  name={'Calendar'}
                  component={CalendarScreen}
                />
                <Stack.Screen
                  name={'EditEvent'}
                  options={{
                    headerShown: false
                  }}
                  component={EditEventScreen}
                />
                <Stack.Screen
                  name={'EventDetail'}
                  component={EventDetailScreen}
                />

                {/* <Stack.Screen
                  name={'Documents'}
                  component={DocumentsScreen}
                /> */}
                <Stack.Screen
                  name={'CreateDocument'}
                  component={CreateDocumentScreen}
                />

                <Stack.Screen
                  name={'TeacherForms'}
                  component={TeacherFormsScreen}
                />
                <Stack.Screen
                  name={'CreateForm'}
                  component={CreateFormScreen}
                />
                <Stack.Screen
                  name={'SendForm'}
                  component={SendFormScreen}
                />
                <Stack.Screen
                  name={'SentFormGroupDetail'}
                  component={SentFormGroupDetailScreen}
                />
                <Stack.Screen
                  name={'SentFormDetail'}
                  component={SentFormDetailScreen}
                />
                <Stack.Screen
                  name={'ReceivedForms'}
                  component={ReceivedFormsScreen}
                />
                <Stack.Screen
                  name={'ReceivedFormDetail'}
                  component={ReceivedFormDetailScreen}
                />
                <Stack.Screen
                  name={'FormLoader'}
                  component={FormLoadingScreen}
                />

                {/*  <Stack.Screen
                  name={'Attendance'}
                  component={AttendanceScreen}
                /> */}
                <Stack.Screen
                  name={'TakeAttendance'}
                  component={TakeAttendanceScreen}
                />

                {/* <Stack.Screen
                  name={'Profile'}
                  component={ProfileScreen}
                /> */}
                <Stack.Screen
                  name={'EditProfile'}
                  component={EditProfileScreen}
                />

                <Stack.Screen
                  name={'SectionMembers'}
                  component={SectionMembersScreen}
                />

                <Stack.Screen
                  name={'AddStudentQrCode'}
                  component={AddStudentQrCodeScreen}
                />

                <Stack.Screen
                  name={'SearchSchoolDistrict'}
                  component={SearchSchoolDistrictScreen}
                />

                <Stack.Screen
                  name={'Support'}
                  component={SupportScreen}
                />

                <Stack.Screen
                  name={'VisibilityPicker'}
                  component={VisibilityPickerScreen}
                />
                <Stack.Screen
                  name={'StudentPicker'}
                  component={StudentPickerScreen}
                />
                <Stack.Screen
                  name={'ContactPicker'}
                  component={ContactPickerScreen}
                />
                <Stack.Screen
                  name={'AudioPlayer'}
                  component={AudioPlayerScreen}
                />
                <Stack.Screen
                  name={'VideoPlayer'}
                  component={VideoPlayerScreen}
                />
                <Stack.Screen
                  name={'FormViewer'}
                  component={FormViewerScreen}
                  options={{
                    animationEnabled: true,
                  }}
                />
                <Stack.Screen
                  name={'DocumentViewer'}
                  component={DocumentViewerScreen}
                  options={{
                    animationEnabled: false,
                  }}
                />
                <Stack.Screen
                  name={'DocumentLoader'}
                  component={DocumentLoadingScreen}
                />
                <Stack.Screen
                  name={'FileViewer'}
                  component={FileViewerScreen}
                />
                <Stack.Screen
                  name={'RecordAudio'}
                  component={RecordAudioScreen}
                />
                <Stack.Screen
                  name={'ExpirationPicker'}
                  component={ExpirationPickerScreen}
                />
                <Stack.Screen
                  name="WebView"
                  component={WebViewScreen}
                />
              </>
              : <>
                <Stack.Screen
                  name={'Welcome'}
                  component={WelcomeScreen}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name={'CompleteProfile'}
                  component={CompleteProfileScreen}
                />
              </>
            : isActive(AppStateStore.userContext)
              ? <Stack.Screen
                name={'InactiveTeacher'}
                component={InactiveTeacherScreen}
                options={{
                  headerShown: false,
                }}
              />
              : <>
                <Stack.Screen
                  name={'ChooseSchoolDistrict'}
                  component={ChooseSchoolDistrictScreen}
                />
                <Stack.Screen
                  name={'ChooseRole'}
                  component={ChooseRoleScreen}
                />
                <Stack.Screen
                  name={'ScanStudentQrCode'}
                  component={ScanStudentQrCodeScreen}
                />
              </>
          : <Stack.Screen
            name={'Splash'}
            component={SplashScreen}
            options={{
              headerShown: false,
            }}
          />
        : <>
          {/*    <Stack.Screen
            name={'Welcome'}
            component={WelcomeScreen}
            options={{
              headerShown: false,
            }}

          /> */}
          <Stack.Screen
            name={'Login'}
            component={LoginScreen}

          />
          <Stack.Screen
            name={'Register'}
            component={RegisterScreen}
          />
          <Stack.Screen
            name={'Verification'}
            component={VerificationScreen}
          />
          {/*  <Stack.Screen
            name={'Login'}
            component={LoginScreen}
          /> */}
          <Stack.Screen
            name={'ForgotIdentification'}
            component={ForgotIdentification}
          />
          <Stack.Screen
            name={'VerifyForgotten'}
            component={VerifyForgottenScreen}
          />
          <Stack.Screen
            name={'ChangePassword'}
            component={ChangePasswordScreen}
          />
          <Stack.Screen
            name={'TermsOfService'}
            component={TermsOfServicesScreen}
          />
        </>
    }
    <Stack.Screen
      name={'Picker'}
      component={PickerScreen}
    />
    <Stack.Screen
      name={'DateTimePicker'}
      component={DateTimePickerScreen}
    />
  </Stack.Navigator>
})


