import React, { useCallback, useEffect } from 'react'
import { ActivityIndicator, Pressable, ScrollView, StyleSheet, Platform, TouchableOpacity, View, KeyboardAvoidingView } from 'react-native'
import Checkbox from 'expo-checkbox'
import { useTranslation } from 'react-i18next'
import { PostHeader } from '../../components/posts/PostHeader'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { Post, PostAttachments } from '../../api/schema/models/Post'
import { ContentActivityIndicator } from '../../components/ContentActivityIndicator'
import { ErrorMessage } from '../../components/ErrorMessage'
import { API } from '../../api/API'
import { runInAction } from 'mobx'
import { extractErrorMessage, formatNumber, getExtension, openExternalUrl } from '../../common/Util'
import { DefaultText } from '../../components/DefaultText'
import { DefaultTextAutoLink } from '../../components/DefaultTextAutoLink'
import { WebImage } from '../../components/WebImage'
import { VideoPreview } from '../../components/VideoPreview'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { getModalManager } from '../../contexts/ModalContext'
import { AudioPreview } from '../../components/FeedPost/AudioPreview'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { FilePreview } from '../../components/FilePreview'
import { KonektiIcon } from '../../components/KonektiIcon'
import { SurveyAnswer } from '../../api/schema/models/SurveyAnswer'
import { ListItemSeparator } from '../../components/ListItemSeparator'
import { FormTextInput } from '../../components/forms/FormTextInput'
import { Colors } from '../../common/Colors'
import _ from 'lodash'
import { BlockButton } from '../../components/BlockButton'
import { UserContext } from '../../models/UserContext'
import { SurveyResults } from '../../api/schema/models/SurveyResults'
import { UrgentAlertHeader } from '../../components/urgenAlerts/UrgentAlertsHeader'
import Icons from '../../components/icons'
import { AppColors } from '../../common/AppColors'
import { SafeAreaView } from 'react-native-safe-area-context'
import { AVPlaybackStatus, Video as VideoPlayer, ResizeMode } from 'expo-av'
import { AudioPlayer } from '../../components/AudioPlayer'
import { Video } from '../../api/schema/models/Video'
import { Audio } from '../../api/schema/models/Audio'
//import * as Sentry from 'sentry-expo'

export type PostDetailScreenParams = {
  postId: number
}

export const PostDetailScreen = observer((props: StackScreenProps<RootNavigationParamList, 'PostDetail'>) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    post: undefined as Post | undefined,
    translatePost: undefined as Post | undefined,
    surveyAnswers: null as SurveyAnswer[] | null,
    translateSurveyAnswers: null as SurveyAnswer[] | null,
    loading: false,
    error: undefined as string | undefined,
    isLoadingTranslation: false,
    showTranslation: false,
    translatedMessage: undefined as string | undefined,
    translatedSurveyTitle: undefined as string | undefined,
    attachmentData: null as string | null,
    translatedAttachment: null as string | null
  }))

  React.useLayoutEffect(() => {
    let screenTitle = t('Post', 'Post')

    if (state.post?.postType === 'urgentAlert') {
      screenTitle = t('Urgent Alert', 'Urgent Alert')
    } else if (state.post?.postType === 'survey') {
      screenTitle = t('Survey', 'Survey')
    } else if (state.post?.attachments.imageUrl) {
      screenTitle = t('Photo', 'Photo')
    } else if (state.post?.attachments.fileUrl) {
      screenTitle = t('File', 'File');
    } else if (state.post?.attachments.videoUrl) {
      screenTitle = t('Video', 'Video')
    } else if (state.post?.attachments.audioUrl) {
      screenTitle = t('Audio', 'Audio')
    }

    props.navigation.setOptions({
      title: screenTitle,
      headerTitleAlign: 'center',
      headerRight: () => {
        return (
          <View style={styles.translateButtonContainer}>
            <TouchableOpacity
              onPress={translateAll}
            >
              <KonektiIcon name={'translate-icon'} size={20} color={'#ff8d2d'} />
            </TouchableOpacity>
          </View>
        );
      }
    })
  }, [props.navigation, state.post])

  const loadPost = useCallback(async () => {
    runInAction(() => {
      state.loading = true
      state.error = undefined
      state.showTranslation = false
    })

    try {
      const response = await API.getPost(props.route.params.postId)
      if (response.post.attachments.videoUuid) {
        const video = await API.getVideo(response.post.attachments.videoUuid)
        runInAction(() => {
          state.post = response.post
          state.attachmentData = video.transcript
          state.surveyAnswers = response.surveyAnswers
        })
      }
      else if (response.post.attachments.audioUuid) {
        const audio = await API.getAudio(response.post.attachments.audioUuid)
        runInAction(() => {
          state.post = response.post
          state.attachmentData = audio.transcript
          state.surveyAnswers = response.surveyAnswers
        })
      }
      else {
        runInAction(() => {
          state.post = response.post
          state.surveyAnswers = response.surveyAnswers
        })
      }

    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [props.route.params.postId, state])

  useEffect(() => {
    loadPost().then()
  }, [loadPost])

  const videoPlayerRef = React.createRef<VideoPlayer>()

  const onPlaybackStatusUpdate = (status: AVPlaybackStatus) => {
    if (status.isLoaded) {
      if (status.didJustFinish) {
        videoPlayerRef.current?.setPositionAsync(0)
      }
    }
  }

  useEffect(() => {
    const listeners = [
      AppStateStore.eventBus.addRemovableListener(AppEvents.PostCreated, () => {
        loadPost().then()
      }),
      AppStateStore.eventBus.addRemovableListener(AppEvents.PostDeleted, () => {
        props.navigation.pop()
      }),
      AppStateStore.eventBus.addRemovableListener(AppEvents.SurveyAnswersSubmitted, () => {
        loadPost().then()
      })
    ]

    return () => {
      listeners.forEach(l => l.remove())
    }
  }, [])

  const renderAttachments = (attachments: PostAttachments) => {
    if (attachments.imageUrl) {
      return <View style={{ paddingHorizontal: 15 }}>
        <View style={styles.imageAttachmentContainer}>
          <WebImage
            style={{ width: '100%', borderRadius: 10, overflow: 'hidden' }}
            uri={attachments.imageUrl}
            maxHeight={600}
          />
        </View>
      </View>
    } else if (attachments.videoUuid) {
      console.log('attachment data', state.attachmentData)
      return <View>
        <View style={styles.videoAttachmentContainer}>
          <VideoPlayer
            posterSource={state.post.attachments.videoThumbnailUrl ? { uri: state.post.attachments.videoThumbnailUrl } : undefined}
            ref={videoPlayerRef}
            style={StyleSheet.absoluteFillObject}
            onPlaybackStatusUpdate={onPlaybackStatusUpdate}
            source={{ uri: state.post.attachments.videoUrl }}
            rate={1.0}
            volume={1.0}
            isMuted={false}
            resizeMode={ResizeMode.CONTAIN}
            useNativeControls={true}
            shouldPlay={true}
          />
        </View>
        {
          state.attachmentData ?
            <View style={{ marginHorizontal: 15, marginBottom: 25 }}>
              <DefaultText style={styles.messageText}>{state.showTranslation ? state.translatedAttachment : state.attachmentData}</DefaultText>
            </View>
            : null
        }
      </View>
      /*return null<View style={styles.videoAttachmentContainer}>
        <VideoPreview
          style={[StyleSheet.absoluteFillObject, styles.videoPreview]}
          posterImageUrl={attachments.videoThumbnailUrl!}
          onPress={() => props.navigation.push('VideoPlayer', { uuid: attachments.videoUuid! })}
          resizeMode="cover"
        />
      </View>*/
    } else if (attachments.audioUrl) {
      return <View style={styles.audioAttachmentContainer}>
        <AudioPlayer url={attachments.audioUrl}></AudioPlayer>
        {/*<AudioPreview
          onPress={() => props.navigation.push('AudioPlayer', { uuid: attachments.audioUuid! })}
        />*/}
        {
          state.attachmentData ?
            <View style={{ marginHorizontal: 15, marginBottom: 25, marginTop: 8 }}>
              <DefaultText style={styles.messageText}>{state.showTranslation ? state.translatedAttachment : state.attachmentData}</DefaultText>
            </View>
            : null
        }
      </View>
    } else if (attachments.fileUuid) {
      return <View style={styles.attachedFileContainer}>
        <FilePreview
          fileType={attachments.fileType!}
          fileName={attachments.fileName!}
          onPress={() => getFileAttachment(attachments.fileType!, attachments.fileUrl!, attachments.fileName!)}
          downloable={true}
          fileUrl={attachments.fileUrl!}
        />
      </View>
    } else {
      return null
    }
  }

  const getFileAttachment = (mimeType: string, fileUrl: string, fileName: string) => {
    const ext = getExtension(fileName)
    if (Platform.OS === 'web' || Platform.OS === 'android') {
      if (mimeType === 'application/pdf' || ext === 'pdf') {
        props.navigation.push('DocumentViewer', { url: fileUrl!, title: fileName! })
      } else {
        openExternalUrl(fileUrl!)
      }
    } else {
      if (mimeType === 'application/pdf' || ext === 'docx' || ext === 'doc' || ext === 'PNG' || ext === 'png' || ext === 'jpg') {
        props.navigation.push('DocumentViewer', { url: fileUrl!, title: fileName! })
      } else {
        openExternalUrl(fileUrl!)
      }

    }


  }

  const translateMessage = async () => {
    let translate = !state.showTranslation

    runInAction(() => state.isLoadingTranslation = true)

    if (translate && !state.translatedMessage) {
      try {
        const translation = await API.getTranslatedPost(props.route.params.postId, AppStateStore.userLanguage)
        runInAction(() => {
          state.translatedMessage = translation.message
          state.translatedSurveyTitle = translation.surveyTitle ?? ''
        })
      } catch (err) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(err)
        }
        else {
          //Sentry.Native.captureException(err)
        }
        getModalManager()
          .showModal({
            title: t('Error', 'Error'),
            message: t('There was an error loading the translation', 'There was an error loading the translation'),
          })
        translate = false
      }
    }

    runInAction(() => {
      state.isLoadingTranslation = false
      state.showTranslation = translate
    })
  }

  const translateAll = async () => {
    let translate = !state.showTranslation

    runInAction(() => state.isLoadingTranslation = true)

    if (translate && !state.translatedMessage) {
      try {
        const translation = await API.getTranslation(state.post, AppStateStore.userLanguage)
        const translateAnswers = await API.getTranslation(state.surveyAnswers, AppStateStore.userLanguage)
        const translateAttachment = await API.getTranslation({ transcript: state.attachmentData }, AppStateStore.userLanguage)
        console.log('finish translating', translation)
        console.log('finish translating answers', translateAnswers)
        runInAction(() => {
          state.translatePost = translation.translation
          state.translateSurveyAnswers = translateAnswers.translation
          state.translatedAttachment = translateAttachment.translation.transcript
        })
        console.log(state)
      } catch (err) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(err)
        }
        else {
          //Sentry.Native.captureException(err)
        }
        getModalManager()
          .showModal({
            title: t('Error', 'Error'),
            message: t('There was an error loading the translation', 'There was an error loading the translation'),
          })
        translate = false
      }
    }

    runInAction(() => {
      state.isLoadingTranslation = false
      state.showTranslation = translate
    })
  }

  const canViewSurveyResults = (post: Post, userContext: UserContext) => {
    if (userContext.userType === 'teacher' && userContext.teacher) {
      if (userContext.teacher.role === 'district_director') {
        if (post.districtId === userContext.teacher.districtId) {
          return true
        }
      } else if (userContext.teacher.role === 'school_director') {
        if (post.schoolId === userContext.teacher.schoolId) {
          return true
        }
      } else if (post.userId === userContext.id) {
        return true
      }
    }

    return false
  }

  return <View style={styles.container}>
    <KeyboardAvoidingView
      {...(Platform.OS === "ios" ? { behavior: "padding" } : {})}
      style={{ flex: 1 }}
      enabled
      contentContainerStyle={{ flex: 1 }}
    >
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          flexGrow: 1
        }}
      >
        {
          state.loading
            ? <ContentActivityIndicator />
            : state.error
              ? <ErrorMessage message={state.error} />
              : state.post
                ? <>
                  {
                    state.post.attachments.videoUrl ? state.post.processedAt
                      ? renderAttachments(state.post.attachments)
                      : <DefaultText style={styles.pendingAttachmentsText}>{t('This post has media attachments that are still processing', 'This post has media attachments that are still processing and should be available soon')}</DefaultText>
                      : null
                  }
                  <View style={{ marginHorizontal: 15 }}>
                    <PostHeader post={state.post} />
                  </View>

                  {
                    state.post.postType === 'survey'
                      ? <View style={styles.surveyTitle}>
                        {
                          state.isLoadingTranslation
                            ? <ActivityIndicator />
                            : <DefaultText style={styles.surveyTitleText}>{state.showTranslation ? state.translatePost?.surveyTitle : state.post.surveyTitle}</DefaultText>
                        }
                      </View>
                      : null
                  }
                  <View style={styles.message}>
                    {
                      state.isLoadingTranslation
                        ? <ActivityIndicator />
                        : state.post.postType === 'urgentAlert'
                          ? <DefaultTextAutoLink
                            style={styles.messageText}>
                            {state.showTranslation ? state.translatePost?.message : state.post.message}
                          </DefaultTextAutoLink>
                          :
                          <DefaultTextAutoLink
                            style={[styles.messageText, state.post.postType === 'survey' && { color: AppColors.gray.neutral2 }]}>
                            {state.showTranslation ? state.translatePost?.message : state.post.message}
                          </DefaultTextAutoLink>
                      /*   <DefaultText
                          style={[styles.messageText, state.post.postType === 'survey' && { color: AppColors.gray.neutral2 }]}>
                          {state.showTranslation ? state.translatePost?.message : state.post.message}
                        </DefaultText> */

                    }
                  </View>
                  {
                    !state.post.attachments.videoUrl ? state.post.processedAt
                      ? renderAttachments(state.post.attachments)
                      : <DefaultText style={styles.pendingAttachmentsText}>{t('This post has media attachments that are still processing', 'This post has media attachments that are still processing and should be available soon')}</DefaultText>
                      : null
                  }
                  {
                    state.post.postType === 'survey'
                      ? <>
                        {
                          canViewSurveyResults(state.post, AppStateStore.userContext!)
                            ? <SurveyResultsView postId={props.route.params.postId} showTranslation={state.showTranslation} />
                            : null
                        }
                        {
                          !canViewSurveyResults(state.post, AppStateStore.userContext!)
                            ? <SurveyForm
                              post={state.post}
                              answers={state.surveyAnswers}
                              showTranslation={state.showTranslation}
                              translatedPost={state.translatePost}
                              translatedAnswers={state.translateSurveyAnswers}
                            />
                            : null
                        }

                      </>
                      : null
                  }
                  {/*
                    !state.post.attachments.videoUrl ? state.post.processedAt
                      ? <VideoPlayer
                      posterSource={state.post.attachments.videoThumbnailUrl ? { uri: state.post.attachments.videoThumbnailUrl } : undefined}
                      ref={videoPlayerRef}
                      style={StyleSheet.absoluteFillObject}
                      source={{ uri: state.post.attachments.videoUrl }}
                      rate={1.0}
                      volume={1.0}
                      isMuted={false}
                      resizeMode={VideoPlayer.RESIZE_MODE_CONTAIN}
                      useNativeControls={true}
                      shouldPlay={true}
                    />
                      : null
                      : null*/
                  }
                </>
                : null
        }
      </ScrollView>
    </KeyboardAvoidingView>
  </View>
})

type SurveyResultsProps = {
  postId: number
  showTranslation: boolean
}

const SurveyResultsView = observer((props: SurveyResultsProps) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    results: undefined as SurveyResults | undefined,
    translatedResults: undefined as SurveyResults | undefined,
    error: undefined as string | undefined,
  }))

  const loadSurveyResults = useCallback(async () => {
    runInAction(() => {
      state.error = undefined
      state.results = undefined
    })

    try {
      const response = await API.getSurveyResults(props.postId)
      runInAction(() => {
        state.results = response.surveyResults
      })
      const translateResponse = await API.getTranslation(response.surveyResults, AppStateStore.userLanguage)
      runInAction(() => {
        state.translatedResults = translateResponse.translation
      })
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = extractErrorMessage(err.response))
    }
  }, [props.postId, state])

  useEffect(() => {
    loadSurveyResults().then()
  }, [loadSurveyResults])

  useEffect(() => {
    const listeners = [
      AppStateStore.eventBus.addRemovableListener(AppEvents.SurveyAnswersSubmitted, () => {
        loadSurveyResults().then()
      })
    ]

    return () => {
      listeners.forEach(l => l.remove())
    }
  }, [])

  return state.error
    ? <>
      <ErrorMessage message={state.error} />
      <BlockButton
        variant={'secondary'}
        title={t('Retry', 'Retry')}
        onPress={() => loadSurveyResults()}
      />
    </>
    : state.results
      ?
      <View style={styles.surveyContainer}>

        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>

          <DefaultText bold={true} style={{ textAlign: 'center', color: AppColors.blue.primary }}>
            {t('Survey Results', 'Survey Results')}
          </DefaultText>
          <DefaultText>{` ${state.results.totalSubmissions} responses`}</DefaultText>
        </View>
        <View style={{ marginVertical: 16 }}>
          <ListItemSeparator />
        </View>
        {
          state.results.questions.map((question, questionIdx) => {
            return <View key={questionIdx} style={{ marginBottom: 10 }}>
              <DefaultText bold={true}>{props.showTranslation ? state.translatedResults?.questions[questionIdx].question : question.question}</DefaultText>
              {
                question.answerCounts.map((answerCount, idx) => <DefaultText key={idx}>
                  ({formatNumber(answerCount.count)}) - {Math.round(answerCount.count / (state.results?.totalSubmissions ?? 1) * 100)}% {props.showTranslation ? state.translatedResults?.questions[questionIdx].answerCounts[idx].answer : answerCount.answer}
                </DefaultText>)
              }
              <View style={{ marginVertical: 16, }}>
                <ListItemSeparator />
              </View>
            </View>
          })
        }
      </View>
      : <ContentActivityIndicator />
})

type SurveyFormProps = {
  post: Post
  answers: SurveyAnswer[] | null
  showTranslation: boolean
  translatedPost?: Post | undefined
  translatedAnswers?: SurveyAnswer[] | null
}

const SurveyForm = observer((props: SurveyFormProps) => {
  const { t } = useTranslation()

  type Answer = string[]

  const state = useLocalObservable(() => ({
    answers: [] as Answer[],
    submitting: false,
    formMinHeight: 100
  }))

  useEffect(() => {
    runInAction(() => {
      state.answers = props.post.surveyQuestions ? props.post.surveyQuestions.map(() => []) : []
    })
  }, [props.post])

  const submit = async () => {
    AppStateStore.showActivityIndicator()
    runInAction(() => {
      state.submitting = true
    })

    try {
      await API.submitSurveyAnswers({
        postId: props.post.id,
        answers: state.answers.map(answers => ({
          answers,
        })),
      })

      AppStateStore.eventBus.emit(AppEvents.SurveyAnswersSubmitted)
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      getModalManager()
        .showModal({
          title: t('Error', 'Error'),
          message: extractErrorMessage(err.response),
        })
    }

    AppStateStore.hideActivityIndicator()
    runInAction(() => state.submitting = false)
  }

  const areThereRequiredAnswers = () => {
    let required = false;
    for (const question of props.post.surveyQuestions!) {
      if (question.isRequired) {
        required = true;
      }
    }
    return required;
  }



  return props.answers
    ? <View style={styles.surveyContainer}>
      {
        props.answers.map((answer, idx) => <View key={idx} style={{ borderBottomColor: '#999', borderBottomWidth: .5, marginVertical: 10, paddingBottom: 10 }}>
          <DefaultText bold={true}>{props.showTranslation ? props?.translatedAnswers[idx].question : answer.question}</DefaultText>
          <DefaultText style={{ marginTop: 5 }}>{props.showTranslation ? props?.translatedAnswers[idx].answers.join(', ') : answer.answers.join(', ')}</DefaultText>
        </View>)
      }
    </View>
    : <View style={styles.surveyContainer}>
      {
        (areThereRequiredAnswers()) ?
          <DefaultText style={{ color: AppColors.red.secondary, marginBottom: 10 }}>{t('Required indicator', 'Required *')}</DefaultText>
          : null
      }
      {

        props.post.surveyQuestions!.map((question, questionIdx) => <View key={questionIdx} style={styles.SurveyQmain}>
          <View style={styles.surveyQcontainer}>
            <View style={{ flexDirection: 'row', marginHorizontal: 16 }}>
              {
                question.isRequired
                  ? <DefaultText style={{ color: Colors.DangerTextColor }}>*</DefaultText>
                  : null
              }
              <DefaultText>
                {props.showTranslation ? props.translatedPost?.surveyQuestions[questionIdx].question : question.question}
              </DefaultText>
            </View>

            {
              (question.type === 'shortText')
                ?
                <FormTextInput
                  onContentSizeChange={(event) => runInAction(() => state.formMinHeight = event.nativeEvent.contentSize.height)}
                  editable={!state.submitting}
                  placeholder={t('Enter answer text', 'Enter answer text')}
                  onChangeText={value => runInAction(() => state.answers[questionIdx] = [value])}
                  value={(state.answers.length > questionIdx && state.answers[questionIdx].length) ? state.answers[questionIdx][0] : ''}
                  style={[{ paddingHorizontal: 16 }]}
                />
                : null
            }
            {
              (question.type === 'longText')
                ?
                <FormTextInput
                  onContentSizeChange={(event) => runInAction(() => state.formMinHeight = event.nativeEvent.contentSize.height)}
                  editable={!state.submitting}
                  placeholder={t('Enter answer text', 'Enter answer text')}
                  onChangeText={value => runInAction(() => state.answers[questionIdx] = [value])}
                  value={(state.answers.length > questionIdx && state.answers[questionIdx].length) ? state.answers[questionIdx][0] : ''}
                  multiline={true}
                  style={[{ minHeight: state.formMinHeight }, { paddingHorizontal: 16 }, { fontSize: 15, height: '100%', textAlignVertical: 'top' }]}
                />
                : null
            }
            {
              (question.type === 'multipleChoiceOne' || question.type === 'multipleChoiceMany')
                ? <View style={{
                  marginHorizontal: 16,
                  paddingBottom: 8,
                  marginBottom: 8,
                }}>
                  <View style={{ marginTop: 16, marginBottom: 8, marginHorizontal: -16 }}>
                    <ListItemSeparator />
                  </View>
                  {

                    question.choices.map((choice, choiceIdx) => <View key={choice} style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 16 }}>
                      <Checkbox
                        style={{ borderRadius: 6, height: 20, width: 20 }}
                        value={state.answers.length > questionIdx && state.answers[questionIdx].includes(choice)}
                        color={AppColors.orange.primary}
                        onValueChange={value => {
                          if (question.type === 'multipleChoiceOne') {
                            runInAction(() => {
                              if (value) {
                                state.answers[questionIdx] = [choice]
                              } else {
                                state.answers[questionIdx] = state.answers[questionIdx].filter(s => s !== choice)
                              }
                            })
                          } else {
                            runInAction(() => {
                              if (value) {
                                state.answers[questionIdx] = _.uniq([
                                  ...state.answers[questionIdx],
                                  choice,
                                ])
                              } else {
                                state.answers[questionIdx] = state.answers[questionIdx].filter(s => s !== choice)
                              }
                            })
                          }
                        }}
                      />
                      <DefaultText style={{ marginLeft: 10 }}>{props.showTranslation ? props.translatedPost?.surveyQuestions[questionIdx].choices[choiceIdx] : choice}</DefaultText>
                    </View>)
                  }
                </View>
                : null
            }
            <View style={{ marginBottom: 16 }}>
              <ListItemSeparator />
            </View>
          </View>
        </View>)
      }
      {
        props.post.surveyFinalMessage ?
          <View style={styles.surveyTitle}>
            <DefaultText style={styles.surveyTitleText}>{props.showTranslation ? props.translatedPost?.surveyFinalMessage : props.post.surveyFinalMessage}</DefaultText>
          </View>
          : null
      }

      <BlockButton
        style={{ marginTop: 18 }}
        disabled={state.submitting}
        title={t('Submit Survey', 'Submit Survey')}
        onPress={() => submit()}
      />
    </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    paddingVertical: 24,
  },
  message: {
    marginVertical: 20,
    marginHorizontal: 15
  },
  messageText: {
    color: AppColors.black.text,
    fontSize: 16
  },
  surveyTitle: {
    marginTop: 16,
    marginHorizontal: 15
  },
  surveyTitleText: {
    color: AppColors.gray.neutral1,
    fontSize: 16,
    fontFamily: 'Rubik_600SemiBold'
  },
  surveyContainer: {
    paddingHorizontal: 16,
  },
  SurveyQmain: {
    marginTop: 8,
    marginBottom: 8,
  },
  surveyQcontainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: AppColors.gray.neutral6,
    borderRadius: 6,
    paddingTop: 9,
    paddingBottom: 0
  },
  imageAttachmentContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
  },
  videoAttachmentContainer: {
    height: 400,
    marginBottom: 25,
    elevation: 20,
    marginHorizontal: 15,
    shadowColor: 'grey',
    shadowOffset: {
      width: 5,
      height: 5,
    },
    shadowOpacity: 0.4,
    shadowRadius: 10,
    borderRadius: 24,

  },
  audioAttachmentContainer: {
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 15,
  },
  attachedFileContainer: {
    marginTop: 16,
    alignItems: 'flex-start',
    //justifyContent: 'flex-start',
    marginHorizontal: 15
  },
  pendingAttachmentsText: {
    color: '#f00',
    padding: 10,
    textAlign: 'center',
    marginHorizontal: 15
  },
  translateButtonContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 15
  },
  videoPreview: {
    borderRadius: 24,
    overflow: 'hidden',
    elevation: 10
  }
})
