import React, { useCallback } from 'react'
import { useWindowDimensions, View, StyleSheet, Text, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { DrawerHeaderButton } from '../../components/DrawerHeaderButton'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { runInAction } from 'mobx'
import { SceneMap, TabBar, TabView } from 'react-native-tab-view'
import { FormList } from '../../components/FormList'
import { SentFormList } from '../../components/SentFormList'
import { AppColors as COLORS } from '../../common/AppColors'
import { FloatingActionButton } from '../../components/fab/FloatingActionButton'
import { TextInputField } from '../../components/TextInputField'
import { ActionButton } from '../../components/buttons/ActionButton'
import Icons from '../../components/icons'


const renderScene = SceneMap({
  sentFormList: SentFormList,
  formList: FormList,
})

export const TeacherFormsScreen = observer((props: StackScreenProps<RootNavigationParamList, 'TeacherForms' | 'E-Sign'>) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    index: 0,
    routes: [
      { key: 'sentFormList', title: t('Sent', 'Sent') },
      { key: 'formList', title: t('Library', 'Library') },
    ],
  }))

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Forms', 'Forms'),
      headerLeft: () => <DrawerHeaderButton />
    })
  }, [props.navigation, state.index])

  const layout = useWindowDimensions()

  return <View style={styles.container}>
    <FloatingActionButton onPress={() => props.navigation.push('CreateForm')} bgColor={COLORS.blue.primary} />
    <TextInputField
      placeholder="Search here"
      rightElement={
        <ActionButton >
          <Icons.Search
            color={COLORS.white}
            size={44}
          />
        </ActionButton>
      }
    />
    <TabView
      navigationState={{ index: state.index, routes: state.routes }}
      renderScene={renderScene}
      lazy={true}
      onIndexChange={index => runInAction(() => state.index = index)}
      initialLayout={{ width: layout.width }}
      swipeEnabled={false}
      renderTabBar={props => <TabBar
        {...props}
        style={styles.tabBar}
        indicatorStyle={[styles.indicator, Platform.OS === 'web' && { width: '49.6%' }]}
        renderLabel={({ route, focused }) => (
          <View style={{}}>
            <Text style={[focused ? styles.labelFocused : styles.label, { marginBottom: 6 }]}>
              {route.title}
            </Text>
          </View>
        )}
        indicatorContainerStyle={{
          marginHorizontal: 1.5,
          paddingHorizontal: 3,
          marginVertical: 2,
        }}
      />
      }
    />
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.white,
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  formContainer: {
    marginTop: 24,
    paddingHorizontal: 24,
    width: '100%',
  },
  tabBar: {
    backgroundColor: COLORS.gray.neutral6,
    borderRadius: 9,
    height: 40,
    marginTop: 16,
    elevation: 0,
    shadowOpacity: 0,
  },
  label: {
    fontSize: 14,
    color: COLORS.gray.neutral3,
    fontFamily: 'Rubik_400Regular'
  },
  labelFocused: {
    fontSize: 14,
    color: COLORS.gray.neutral2,
    fontFamily: 'Rubik_700Bold',
    width: 100,
  },
  indicator: {
    backgroundColor: COLORS.white,
    borderRadius: 9,
    borderColor: COLORS.gray.neutral5,
    borderWidth: 1.5,
    height: 36,
    shadowColor: COLORS.gray.neutral4,
    shadowOpacity: .3,
    shadowRadius: 9,
    elevation: 10,
    shadowOffset: {
      height: 0,
      width: 0,
    },
  },
})