import React from "react";
import Svg, { Circle, Path } from "react-native-svg";
import { Avatar, Badge, Icon, withBadge } from 'react-native-elements'
type Props = {
  badgeNumber: number
}
export const NotificationIcon = (props: any) => {
    const notiCount = 98
    return (
        <Svg
          width={20}
          height={22}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          {/* <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 10.5c0-3.07-1.14-5.64-4-6.32V2H6v2.18c-2.87.68-4 3.24-4 6.32V16l-2 1v2h16v-2l-2-1v-5.5ZM8.4 21.96A2.014 2.014 0 0 1 5.99 20h4c0 .28-.05.54-.15.78-.26.6-.79 1.04-1.44 1.18Z"
            fill="#303030"
          /> */}
          <Path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M14 8.5C14 5.43 12.86 2.86 10 2.18V0H6V2.18C3.13 2.86 2 5.42 2 8.5L2 14L0 15V17H16V15L14 14L14 8.5ZM8.40001 19.96C8.27001 19.99 8.14001 20 8.00001 20C6.89001 20 6.00001 19.1 5.99001 18H9.99001C9.99001 18.28 9.94001 18.54 9.84001 18.78C9.58001 19.38 9.05001 19.82 8.40001 19.96Z" 
            fill="#303030"/>
          {
            props.badgeNumber > 0 ? 
            <Badge
            value={props.badgeNumber > 99 ? 99 : props.badgeNumber}
            status="error"
            containerStyle={{ position: 'absolute', top: props.badgeNumber <= 9 ? -8: -7, right: props.badgeNumber <= 9 ? -4: -24, width: props.badgeNumber <= 9 ? 'auto': 50 }}
            />
            :
            null
          }
          
        </Svg>
        
      )

}