import React from 'react'
import { Svg, Path } from "react-native-svg"
import { Avatar, Badge, Icon, withBadge } from 'react-native-elements'

type Props = {
  width: number
  height: number
  color: string,
  variant: 'fill' | 'outline'
  badgeNumber?: number
}
export default function Message(props: Props) {
  return (
    <Svg
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 20 20" >
      {props.variant === 'fill' ? (
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d="M9.06 7.93C9.06 8.36 8.71 8.71 8.28 8.71C7.85 8.71 7.5 8.36 7.5 7.93C7.5 7.5 7.85 7.15 8.28 7.15C8.71 7.15 9.06 7.5 9.06 7.93ZM19.94 16.04C20.06 16.33 19.99 16.67 19.76 16.89C19.61 17.04 19.42 17.11 19.22 17.11C19.11 17.11 19.01 17.09 18.91 17.04L14.72 15.19C14.19 15.39 13.65 15.54 13.1 15.64C12.53 16.57 11.72 17.37 10.74 17.94C9.64 18.58 8.37 18.93 7.06 18.94C7.04 18.94 7.02 18.94 7 18.94C6.1 18.94 5.22 18.78 4.39 18.48L1.1 19.93C1 19.98 0.89 20 0.78 20C0.58 20 0.38 19.92 0.24 19.78C0.00999999 19.56 -0.06 19.22 0.06 18.93L1.2 16.1C0.41 15.03 0 13.79 0 12.48C0 10.38 1.09 8.44 2.92 7.22C3.09 5.35 3.96 3.62 5.42 2.29C7.03 0.81 9.17 0 11.45 0C13.72 0 15.86 0.81 17.47 2.29C19.1 3.78 20 5.77 20 7.9C20 8.25 19.97 8.65 19.91 9.02C19.9 9.06 19.88 9.1 19.87 9.14L19.88 9.16C19.67 10.29 19.16 11.37 18.45 12.32L19.94 16.04ZM11 15.78C8.84 15.68 6.83 14.83 5.32 13.38C4.1 12.21 3.3 10.72 3.01 9.16C2.09 10.06 1.56 11.24 1.56 12.49C1.56 13.58 1.95 14.61 2.68 15.48C2.87 15.7 2.92 16.01 2.81 16.28L2.22 17.74L4.05 16.93C4.25 16.84 4.47 16.84 4.67 16.93C5.39 17.23 6.16 17.39 6.95 17.4C6.96 17.4 6.97 17.4 6.98 17.4C8.53 17.38 9.98 16.78 11 15.78ZM14.53 7.15C14.1 7.15 13.75 7.5 13.75 7.93C13.75 8.36 14.1 8.71 14.53 8.71C14.96 8.71 15.31 8.36 15.31 7.93C15.31 7.5 14.96 7.15 14.53 7.15ZM11.41 7.15C10.98 7.15 10.63 7.5 10.63 7.93C10.63 8.36 10.98 8.71 11.41 8.71C11.84 8.71 12.19 8.36 12.19 7.93C12.19 7.5 11.84 7.15 11.41 7.15Z"
          fill={props.color} />
      ) : (
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d="M9.06 7.93C9.06 8.36 8.71 8.71 8.28 8.71C7.85 8.71 7.5 8.36 7.5 7.93C7.5 7.5 7.85 7.15 8.28 7.15C8.71 7.15 9.06 7.5 9.06 7.93ZM19.94 16.04C20.06 16.33 19.99 16.67 19.76 16.89C19.61 17.04 19.42 17.11 19.22 17.11C19.11 17.11 19.01 17.09 18.91 17.04L14.72 15.19C14.19 15.39 13.65 15.54 13.1 15.64C12.53 16.57 11.72 17.37 10.74 17.94C9.64 18.58 8.37 18.93 7.06 18.94C7.04 18.94 7.02 18.94 7 18.94C6.1 18.94 5.22 18.78 4.39 18.48L1.1 19.93C1 19.98 0.89 20 0.78 20C0.58 20 0.38 19.92 0.24 19.78C0.00999999 19.56 -0.06 19.22 0.06 18.93L1.2 16.1C0.41 15.03 0 13.79 0 12.48C0 10.38 1.09 8.44 2.92 7.22C3.09 5.35 3.96 3.62 5.42 2.29C7.03 0.81 9.17 0 11.45 0C13.72 0 15.86 0.81 17.47 2.29C19.1 3.78 20 5.77 20 7.9C20 8.25 19.97 8.65 19.91 9.02C19.9 9.06 19.88 9.1 19.87 9.14L19.88 9.16C19.67 10.29 19.16 11.37 18.45 12.32L19.94 16.04ZM11 15.78C8.84 15.68 6.83 14.83 5.32 13.38C4.1 12.21 3.3 10.72 3.01 9.16C2.09 10.06 1.56 11.24 1.56 12.49C1.56 13.58 1.95 14.61 2.68 15.48C2.87 15.7 2.92 16.01 2.81 16.28L2.22 17.74L4.05 16.93C4.25 16.84 4.47 16.84 4.67 16.93C5.39 17.23 6.16 17.39 6.95 17.4C6.96 17.4 6.97 17.4 6.98 17.4C8.53 17.38 9.98 16.78 11 15.78ZM17 11.68C17.92 10.57 18.44 9.21 18.44 7.9C18.44 7.84 18.43 7.79 18.43 7.73L18.44 7.72C18.33 4.31 15.25 1.56 11.46 1.56C7.61 1.56 4.47 4.4 4.47 7.89C4.47 10.95 7.28 14.22 11.46 14.22C12.5 14.22 13.5 14.02 14.43 13.62C14.63 13.54 14.85 13.54 15.05 13.62L17.78 14.82L16.86 12.55C16.74 12.27 16.8 11.91 17 11.68ZM14.53 7.15C14.1 7.15 13.75 7.5 13.75 7.93C13.75 8.36 14.1 8.71 14.53 8.71C14.96 8.71 15.31 8.36 15.31 7.93C15.31 7.5 14.96 7.15 14.53 7.15ZM11.41 7.15C10.98 7.15 10.63 7.5 10.63 7.93C10.63 8.36 10.98 8.71 11.41 8.71C11.84 8.71 12.19 8.36 12.19 7.93C12.19 7.5 11.84 7.15 11.41 7.15Z"
          fill={props.color} />
      )
      }
      {
        props.badgeNumber ? 
          props.badgeNumber > 0 ? 
          <Badge
          value={props.badgeNumber > 99 ? 99 : props.badgeNumber}
          status="error"
          containerStyle={{ position: 'absolute', top: props.badgeNumber <= 9 ? -8: -7, right: props.badgeNumber <= 9 ? -9: -29, width: props.badgeNumber <= 9 ? 'auto': 50 }}
          />
          :
          null
        :
        null
      }

    </Svg>
  )
}




