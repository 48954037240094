import React from "react";
import Modal from "react-native-modal";
import { View, StyleSheet, Dimensions, Text, ScrollView } from "react-native";
import { AppColors } from '../../common/AppColors'
import { useTranslation } from 'react-i18next'

type Props = {
  isVisible: boolean;
  dismiss: () => void;
  title?: React.ReactElement
  children?: React.ReactElement | React.ReactElement[]
};

const window = Dimensions.get('window');

const BottomContentModal = ({ isVisible, title, dismiss, children }: Props) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <Modal
        isVisible={isVisible}
        coverScreen={true}
        onBackdropPress={dismiss}
        style={{
          justifyContent: 'flex-end',
          margin: 0,
          height: window.height * 0.5,
        }}
        //animationIn="slideInUp"
        //animationOut="slideOutDown"
        propagateSwipe={true}
        statusBarTranslucent={true}
        useNativeDriver={true}

      >
        <View style={styles.wrapper}>
          {
            title && <Text style={styles.drawerHeaderText}>{title}</Text>
          }
          <ScrollView>
            <View style={{ padding: 0 }}>
              {children}
            </View>
          </ScrollView>
        </View>
      </Modal>
    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    position: "absolute",
  },
  drawerHeaderText: {
    paddingTop: 30,
    fontSize: 24,
    color: AppColors.blue.primary,
    textAlign: 'center',
    fontWeight: '500',
    fontFamily: 'Rubik_500Medium',
  },
  wrapper: {
    backgroundColor: 'white',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    flexDirection: 'column',
    maxHeight: window.height * 0.5,
  },
})

export default BottomContentModal;