import * as React from 'react'
import { createIconSet } from '@expo/vector-icons'
import { konektiGlyphMap, KonektiIconName } from '../../konekti-icons'
import { StyleProp, TextStyle } from 'react-native'

const CustomIcon = createIconSet(konektiGlyphMap, 'konekti-glyphs', 'konekti-glyphs.ttf')

export type Props = {
  name: KonektiIconName
  size: number
  color: string
  style?: StyleProp<TextStyle>
}

export const KonektiIcon = (props: Props) => <CustomIcon
  style={props.style}
  name={props.name}
  size={props.size}
  color={props.color === "#333" ? props.color : "#FF7A33"}
/>
