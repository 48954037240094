import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useCallback, useLayoutEffect } from 'react'
import { FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View, Text, Platform } from 'react-native'
import { API } from '../../api/API'
import { runInAction } from 'mobx'
import { useTranslation } from 'react-i18next'
import { extractErrorMessage, fullName, MYSQL_DATE_FORMAT } from '../../common/Util'
import { ContentActivityIndicator } from '../../components/ContentActivityIndicator'
import { ErrorMessage } from '../../components/ErrorMessage'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { AttendanceSection } from '../../api/schema/models/AttendanceSection'
import moment from 'moment-timezone'
import { DefaultText } from '../../components/DefaultText'
import { Colors } from '../../common/Colors'
import { MaterialIcons } from '@expo/vector-icons'
import { SectionAttendee } from '../../api/schema/models/SectionAttendee'
import { SectionMember } from '../../api/schema/models/SectionMember'
import { SectionParent } from '../../api/schema/models/SectionParent'
import { FormSwitchInput } from '../../components/forms/FormSwitchInput'
import { ListItemSeparator } from '../../components/ListItemSeparator'
import { SafeAreaView } from 'react-native-safe-area-context'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { getModalManager } from '../../contexts/ModalContext'
import { NewBackArrowSvg} from '../../../assets/images/back-arrow-svg'
import { BoldText } from '../../components/BoldText'
import { ModalPicker } from '../../components/ModalPicker'
import { BottomDrawer } from '../../components/BottomDrawer'
import { BlockButton } from '../../components/BlockButton'
import { RotateInUpLeft } from 'react-native-reanimated'
import SegmentedControl from '@react-native-segmented-control/segmented-control'
import { StyledTextInput } from '../../components/StyledTextInput'
import { ActionButton } from '../../components/buttons/ActionButton'
import Icons from '../../components/icons'
import { AppColors } from '../../common/AppColors'
import { SearchSvg } from '../../../assets/images/search'
//import * as Sentry from 'sentry-expo'

export type SectionMembersScreenParams = {
  sectionId: number,
  sectionName: string
}

export const SectionMembersScreen = observer((props: StackScreenProps<RootNavigationParamList, 'SectionMembers'>) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    data: undefined as {
      students: SectionMember[] | undefined,
      parents: SectionParent[],
      studentList: SectionMember[],
      parentList: SectionParent[],
    } | undefined,
    loading: false,
    error: undefined as string | undefined,
    completed: false,
    showPicker: false,
    showDrawer: false,
    coordY: 0,
    coordX: 0,
    selectedStudent: 0,
    listMode: false,
    search: ''
  }))

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: props.route.params.sectionName,
      headerStyle: {backgroundColor: '#fff', borderBottomWidth: 0},
      headerTitleStyle: {color: '#000'},
      headerBackImage: () => <NewBackArrowSvg></NewBackArrowSvg>
    })
  }, [props.navigation])

  const loadData = useCallback(async () => {
    runInAction(() => {
      state.error = undefined
      state.loading = true
    })

    try {
      const response = await API.getSectionMembers(props.route.params.sectionId)
      runInAction(() => {
        state.data = {
          students: response.students,
          parents: response.parents,
          studentList: response.students,
          parentList: response.parents,
        }
      })
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => {
        state.error = extractErrorMessage(err)
      })
    }

    runInAction(() => {
      state.loading = false
    })
  }, [state, props.route.params.sectionId])

  useLayoutEffect(() => {
    loadData().then()
  }, [loadData])

  const searchMember = () => {
    if(state.data) {
      if(state.search) {
        if(state.listMode) {
  
          if(state.data.parentList) {
            runInAction(() => {
              let matches = state.data.parentList.filter(p => {
                let searchName = fullName(p).toUpperCase()
                if(searchName.includes(state.search.toUpperCase()) || p.son.toUpperCase().includes(state.search.toUpperCase())) {
                  return true
                }
                return false
              })
              state.data.parents = matches[0] ? matches : undefined
            })
            
          }
  
        }
        else {
  
          if(state.data.studentList) {
            runInAction(() => {
              let matches = state.data.studentList.filter(s => {
                let searchName = fullName(s).toUpperCase()
                if(searchName.includes(state.search.toUpperCase())) {
                  return true
                }
                return false
              })
              state.data.students = matches[0] ? matches : undefined
            })
          }
  
        }
      }
      else {
        runInAction(() => {
          state.data.students = state.data?.studentList
          state.data.parents = state.data?.parentList
        })
      }
    }
  }

  const renderStudent = (info: ListRenderItemInfo<SectionMember>) => {
    return info.item ? <TouchableOpacity onPress={(event) => console.log('click')} style={{backgroundColor:'#F6F7FA', marginVertical: 8, borderRadius:6, minHeight: 56, justifyContent: 'center'}}>
      <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 12, paddingHorizontal: 16 }}>
        <DefaultText style={{ flex: 1, fontSize: 16 }}>{fullName(info.item)}</DefaultText>
        <MaterialIcons
          name={'chevron-right'}
          size={30}
          color={'#FF7A33'}
        />
      </View>
    </TouchableOpacity>
  : <Text>No student found</Text>
  }
  

  const renderParent = (info: ListRenderItemInfo<SectionParent>) => {
    return info.item ? <TouchableOpacity onPress={(event) => console.log('click')} style={{backgroundColor:'#F6F7FA', marginVertical: 8, borderRadius:6, minHeight: 56, justifyContent: 'center'}}>
      <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 12, paddingHorizontal: 16 }}>
        <DefaultText style={{ flex: 1, fontSize: 16 }}>{fullName(info.item)} <Text style={{fontSize: 14, color: AppColors.gray.neutral2}}>/ {info.item.son}</Text></DefaultText>
        <MaterialIcons
          name={'chevron-right'}
          size={30}
          color={'#FF7A33'}
        />
      </View>
    </TouchableOpacity>
    :<Text>No parent found</Text>
  }


  return <View style={styles.container}>
    {
      state.error
        ? <ErrorMessage message={state.error}/>
        : !state.data
          ? <ContentActivityIndicator/>
          : <>
            <StyledTextInput
              style={StyleSheet.flatten([Platform.select({web: {outlineStyle: 'none', borderWidth: 0}}), { marginTop: 16, minHeight:56, borderColor: '#f00', borderWidth: 0, backgroundColor: AppColors.gray.neutral6}])}
              placeholder={t('Search here', 'Search here')}
              value={state.search}
              onChangeText={t => {runInAction(() => state.search = t); searchMember();}}
              editable={!state.loading}>
            </StyledTextInput>
            {
              Platform.OS == 'web' ?
              <TouchableOpacity onPress={searchMember} style={{alignItems: 'flex-end'}}><SearchSvg onPress={() => null} style={{position:'relative', top:-50, right:8, marginBottom: -24}}></SearchSvg></TouchableOpacity>
              :<View style={{alignItems: 'flex-end'}}><SearchSvg onPress={searchMember} style={{position:'relative', top:-50, right:8, marginBottom: -24, elevation: 3}}></SearchSvg></View>
            }
            
            
            <View style={{ marginBottom: 16, height: 56 }}>
              <SegmentedControl
                appearance={'light'}
                style={{height: 56}}
                fontStyle={{color:'#9D9FA0'}}
                values={['Students', 'Parents']}
                selectedIndex={state.listMode ? 1 : 0}
                onChange={(ev) => {runInAction(() => {state.listMode = ev.nativeEvent.selectedSegmentIndex === 0 ? false : true;}); }}
                activeFontStyle={{color:'#000'}}
              />
            </View>
            {
              state.listMode ?
              state.data.parents ?<FlatList
                data={state.data.parents }
                keyExtractor={s => String(s.id)}
                renderItem={renderParent}
                ListFooterComponent={<SafeAreaView edges={['bottom']}/>}
              />
              :<DefaultText style={{ flex: 1, fontSize: 16, marginTop: 16, textAlign: 'center' }}>No parent found</DefaultText>
              : state.data.students ? <FlatList
                data={state.data.students }
                keyExtractor={s => String(s.id)}
                renderItem={renderStudent}
                ListFooterComponent={<SafeAreaView edges={['bottom']}/>}
              />
              :<DefaultText style={{ flex: 1, fontSize: 16, marginTop: 16, textAlign: 'center' }}>No student found</DefaultText>
            }
            
          </>
    }
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16
  },
  section: {
    padding: 12,
  },
  sectionHeaderText: {
    color: Colors.PrimaryOrange,
    marginBottom: 4,
  },
  sectionText: {
    fontSize: 18,
    fontWeight: '500',
    color: '#0082CD'
  },
  content: {
    backgroundColor: '#fff',
  },
  drawerHeaderText: {
    paddingTop: 30,
    fontSize: 24,
    color: '#0082CD',
    textAlign: 'center',
    fontWeight: '500',
  },
  subText: {
    paddingTop: 15,
    paddingHorizontal: 50,
    fontSize: 16,
    color: '#303030',
    textAlign: 'center',
    fontWeight: '400',
  },
})
