import React, { useLayoutEffect } from 'react'
import { Platform, StyleSheet, View, ActivityIndicator } from 'react-native'
import { observer } from 'mobx-react-lite'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { WebView } from 'react-native-webview'
import { useTranslation } from 'react-i18next'


let jsInject = `
document.querySelector('.navbar').style.display = 'none';
document.querySelector('#main').style.setProperty('padding-top', '0px', 'important');
document.querySelector('.row').style.setProperty('padding-top', '0px', 'important');

`;

const URL_TERMS = 'http://konektiapps.com/privacy/'

export const TermsOfServicesScreen = observer((props: StackScreenProps<RootNavigationParamList, 'TermsOfService'>) => {
  const { t } = useTranslation()

  useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('The Terms of Service', 'The Terms of Service'),
    })
  }, [props.navigation])

  function LoadingIndicatorView() {
    return <ActivityIndicator style={styles.spinner} size="large" />
  }

  return (
    Platform.OS === 'web' ?
      <iframe
        src={URL_TERMS}
        height={'100%'}
        width={'100%'}
      />
      :
      <View style={styles.container}>
        <View style={{ width: '100%', height: '100%' }}>
          <WebView
            source={{ uri: URL_TERMS }}
            renderLoading={LoadingIndicatorView}
            startInLoadingState={true}
            injectedJavaScript={jsInject}
            javaScriptEnabled={true}
            onMessage={(event) => { }}
          />
        </View>
      </View>
  )
})

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  main: {
    flex: 1,
    alignItems: 'center'
  },
  spinner: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0
  },
})