import { StyleSheet, TouchableOpacity, View, ViewProps, Platform } from 'react-native'
import React from 'react'
import _ from 'lodash'
import { DefaultText } from '../DefaultText'
import { useTranslation } from 'react-i18next'
import ErrorBag from '../../common/ErrorBag'
import { FormError } from '../FormError'
import Icons from '../icons'
import { AppColors as COLORS } from '../../common/AppColors'

type Props = ViewProps & {
  label: string
  value?: React.ReactElement | string
  placeholder?: string
  onPress?: () => void
  disabled?: boolean
  hideSeparator?: boolean
  errors?: ErrorBag
  fieldName?: string
}

export const FormSelectInput = (props: Props) => {
  const { t } = useTranslation()
  const { style, ...rest } = props

  return <View
    {...rest}
    style={[style]}
  >
    <TouchableOpacity onPress={props.onPress} disabled={props.disabled}>
      <View style={styles.container}>
        <DefaultText style={styles.label}>{props.label}</DefaultText>
        {
          props.value
            ? _.isString(props.value)
              ? <DefaultText style={styles.value} numberOfLines={1}>
                {
                  Platform.OS === 'web'
                    ? props.value.slice(0, 69) + (props.value.length > 69 ? '...' : '')
                    : props.value.slice(0, 19) + (props.value.length > 19 ? '...' : '')
                }
              </DefaultText>
              : <View style={styles.valueWrapper}>
                {props.value}
              </View>
            : <DefaultText style={styles.placeholderValue}>{props.placeholder ?? t('Choose', 'Choose')}</DefaultText>
        }
        <Icons.Forward width={12} height={20} color={COLORS.orange.primary} />
      </View>
    </TouchableOpacity>
    {
      (props.errors && props.fieldName)
        ? <FormError field={props.fieldName} errors={props.errors} />
        : null
    }
  </View>
}

export const FormSelectInputValueStyle = {
  fontSize: 18,
  color: COLORS.gray.neutral1,
  marginRight: 12,
}

export const FormSelectInputPlaceholderStyle = {
  ...FormSelectInputValueStyle,
  color: COLORS.blue.primary,
  fontSize: 12,
  marginRight: 12,
  fontFamily: 'Rubik_700Bold',

}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 12,
    height: 56,
    backgroundColor: COLORS.gray.neutral6,
    justifyContent: 'center',
    borderRadius: 6,
    marginVertical: 10,
  },
  label: {
    flex: 1,
    fontSize: 16,
    color: COLORS.gray.neutral1,
    fontWeight: '400',
  },
  value: FormSelectInputValueStyle,
  placeholderValue: FormSelectInputPlaceholderStyle,
  valueWrapper: {
    alignItems: 'flex-end',
  },
  accessory: {},
})
