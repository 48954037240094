import React, { useCallback, useEffect } from 'react'
import { FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View, Platform } from 'react-native'
import { DefaultText } from '../components/DefaultText'
import { useTranslation } from 'react-i18next'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { ContentActivityIndicator } from '../components/ContentActivityIndicator'
import { runInAction } from 'mobx'
import { ErrorMessage } from '../components/ErrorMessage'
import { BlockButton } from '../components/BlockButton'
import { API } from '../api/API'
import { VisibilityOption } from '../api/schema/models/VisibilityOption'
import { SafeAreaView } from 'react-native-safe-area-context'
import { SearchTextInput } from '../components/SearchTextInput'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../navigation/RootNav'
import { AppColors as COLORS } from '../common/AppColors'
import Icons from '../components/icons'
import { GetVisibilityOptionsResponse } from '../api/schema/GetVisibilityOptionsResponse'
//import * as Sentry from 'sentry-expo'

export type VisibilityPickerScreenParams = {
  title: string
  onChoose: (option: VisibilityOption, pop: () => void) => void,
  event? : boolean
  form? : boolean
}

export const VisibilityPickerScreen = observer((props: StackScreenProps<RootNavigationParamList, 'VisibilityPicker'>) => {
  const { t } = useTranslation()

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: props.route.params.title,
    })
  }, [props.navigation])

  const state = useLocalObservable(() => ({
    loading: false,
    error: undefined as undefined | string,
    searchText: '',
    options: [] as VisibilityOption[],
    get filteredOptions() {
      return this.options.filter(o => !this.searchText.trim().length || o.name.toLowerCase().indexOf(this.searchText.trim().toLowerCase()) > -1)
    }
  }))

  const pop = () => props.navigation.pop()

  const renderItem = (item: ListRenderItemInfo<VisibilityOption>) => {
    return <TouchableOpacity onPress={() => props.route.params.onChoose(item.item, pop)}>
      <View style={styles.listItem}>
        <View style={{ flex: 1 }}>

          <DefaultText style={styles.subTitle}>
            {
              item.item.type === 'district'
                ? t('District', 'District')
                : item.item.type === 'school'
                  ? t('School', 'School')
                  : item.item.type === 'section'
                    ? t('Class', 'Class')
                    : item.item.type === 'grade'
                      ? t('Grade', 'Grade Level')
                      : item.item.type === 'community'
                        ? t('Community', 'Community')
                        : item.item.type === 'private'
                          ? t('Private', 'Private')
                          : '---'
            }
          </DefaultText>
          {
            item.item.type === 'district'
              ? <DefaultText style={styles.title}>{item.item.name}</DefaultText>
              : null
          }
          {
            item.item.type === 'school'
              ? <DefaultText style={styles.title}>{item.item.name}</DefaultText>
              : null
          }
          {
            item.item.type === 'section'
              ? <>
                <DefaultText style={styles.title}>{item.item.name}</DefaultText>
                <DefaultText style={styles.subTitle}>{item.item.schoolName}</DefaultText>
              </>
              : null
          }
          {
            item.item.type === 'grade'
              ? <>
                <DefaultText style={styles.title}>{item.item.name}</DefaultText>
                <DefaultText style={styles.subTitle}>{item.item.schoolName}</DefaultText>
              </>
              : null
          }
          {
            item.item.type === 'community'
              ? <>
                <DefaultText style={styles.title}>{item.item.name}</DefaultText>
                <DefaultText style={styles.subTitle}>{item.item.schoolName}</DefaultText>
              </>
              : null
          }
          {
            item.item.type === 'private'
              ? <>
                <DefaultText style={styles.title}>{item.item.name}</DefaultText>
              </>
              : null
          }
        </View>

        <Icons.Forward width={8} height={14} color={COLORS.gray.neutral2} />

      </View>
    </TouchableOpacity>
  }

  const loadOptions = useCallback(async () => {
    runInAction(() => {
      state.loading = true
      state.error = undefined
    })

    try {
      let response: GetVisibilityOptionsResponse | null = null
      if(props.route.params.form) {
        response = await API.getFormVisibilityOptions()
      }
      else {
        response = await API.getVisibilityOptions(props.route.params.event?'?postType=event': '')
      }
      

      runInAction(() => state.options = response.options)
    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = t('There was an error getting the visibility options', 'There was an error getting the visibility options'))
    }

    runInAction(() => state.loading = false)
  }, [])

  useEffect(() => {
    loadOptions().then()
  }, [loadOptions])

  return <View style={styles.container}>
    {
      state.loading
        ? <ContentActivityIndicator />
        : state.error
          ? <>
            <ErrorMessage message={state.error} />
            <BlockButton
              variant={'secondary'}
              title={t('Retry', 'Retry')}
              onPress={() => loadOptions()}
            />
          </>
          : <>
            <View style={styles.searchContainer}>
              <SearchTextInput
                placeholder={t('Search', 'Search')}
                value={state.searchText}
                onChange={ev => runInAction(() => state.searchText = ev.nativeEvent.text)}
              />
            </View>
            <FlatList
              style={styles.list}
              data={state.filteredOptions}
              renderItem={renderItem}
              keyExtractor={i => `${i.type}:${i.id}`}
            />
          </>
    }
    <SafeAreaView edges={['bottom']}>
      <View />
    </SafeAreaView>
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  list: {
    flex: 1,
    paddingTop: 5,
    paddingHorizontal: 16
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    minHeight: 64,
  },
  footer: {
    flexDirection: 'row',
  },
  searchContainer: {
    marginHorizontal: 16,
  },
  title: {
    color: COLORS.gray.neutral1,
    fontSize: 16
  },
  subTitle: {
    color: COLORS.gray.neutral2,
    fontSize: 12
  }
})
