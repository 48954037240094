import React from 'react'
import { StyleSheet, View } from 'react-native'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';
import {NotificationAudio} from './noti-audio'
import {NotificationDocument} from './noti-document'
import {NotificationEvent} from './noti-event'
import {NotificationForm} from './noti-form'
import {NotificationPoll} from './noti-poll'
import {NotificationPost} from './noti-post'
import {NotificationUrgent} from './noti-urgent'
import {NotificationVideo} from './noti-video'

type Props = {
  icon: string
}

export const NotificationIcon = (props: Props) => {
  switch(props.icon) {
    case 'audio':
      return <NotificationAudio/>
    case 'document':
      return <NotificationDocument/>
    case 'event':
      return <NotificationEvent/>
    case 'form':
      return <NotificationForm/>
    case 'poll':
      return <NotificationPoll/>
    case 'post':
      return <NotificationPost/>
    case 'urgent':
      return <NotificationUrgent/>
    case 'video':
      return <NotificationVideo/>
    default:
      return <NotificationPost/>
  }
}
