import React, { useCallback, useEffect } from 'react'
import { ActivityIndicator, FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { DefaultText } from '../../components/DefaultText'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import Modal from 'react-native-modal'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { Colors } from '../../common/Colors'
import { ListItemSeparator } from '../../components/ListItemSeparator'
import { BlockButton } from '../../components/BlockButton'
import { Post } from '../../api/schema/models/Post'
import { UrgenAlert } from '../../api/schema/models/UrgentAlert'
import { API } from '../../api/API'
import { extractErrorMessage } from '../../common/Util'
import { ContentActivityIndicator } from '../../components/ContentActivityIndicator'
import { ErrorMessage } from '../../components/ErrorMessage'
import { FeedPost } from '../../components/FeedPost/FeedPost'
import { DrawerHeaderButton } from '../../components/DrawerHeaderButton'
import { StackScreenProps } from '@react-navigation/stack'
import { ListRefreshControl } from '../../components/web-list-refresh-control/ListRefreshControl'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { KonektiIcon } from '../../components/KonektiIcon'
import { FeedUrgentAlerts } from '../../components/urgenAlerts/FeedUrgentAlerts'
import { FloatingActionButton } from '../../components/fab/FloatingActionButton'
//import * as Sentry from 'sentry-expo'

export const FeedScreen = observer((props: StackScreenProps<RootNavigationParamList, 'Feed' | 'Home'>) => {
  const { t } = useTranslation()
  const state = useLocalObservable(() => ({
    renderActionBar: false,
    showActionBar: false,
    loaded: false,
    loading: false,
    refreshing: false,
    loadingMore: false,
    hasMorePosts: false,
    error: undefined as string | undefined,
    posts: [] as Post[],
    urgentAlerts: [] as UrgenAlert[],
    get visiblePosts() {
      return state.posts.filter(p => !!p.processedAt || p.userId === AppStateStore.userContext?.id)
    },
    get visibleUAlertsPosts() {
      return state.urgentAlerts.filter(p => !!p.processedAt || p.userId === AppStateStore.userContext?.id)
    },
  }))

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Konekti Feed', 'Konekti Feed'),
      headerLeft: () => <DrawerHeaderButton />,
    })
  }, [props.navigation])

  const showPostActions = () => {
    runInAction(() => {
      state.showActionBar = true
      state.renderActionBar = true
    })
  }

  const showCreatePost = () => {
    runInAction(() => state.showActionBar = false)
    props.navigation.push('CreatePost', { postType: 'post' })
  }

  const showCreateEvent = () => {
    runInAction(() => state.showActionBar = false)
    props.navigation.push('EditEvent')
  }

  const showCreateUrgentAlert = () => {
    runInAction(() => state.showActionBar = false)
    props.navigation.push('CreatePost', { postType: 'urgentAlert' })
  }

  const showCreateSurvey = () => {
    runInAction(() => state.showActionBar = false)
    props.navigation.push('CreatePost', { postType: 'survey' })
  }

  const loadPosts = useCallback(async (refresh: boolean = false, append: boolean = false) => {
    runInAction(() => {
      state.loading = true
      state.error = undefined
      state.loadingMore = append
      state.refreshing = refresh
    })

    const LOAD_SIZE = 20

    try {
      const response = await API.getPosts(append ? state.posts.length : 0, LOAD_SIZE + 1)
      const respAlerts = await API.getUrgentAlerts(append ? state.posts.length : 0, LOAD_SIZE + 1)

      runInAction(() => {

        if (append) {
          state.posts = [
            ...state.posts,
            ...response.posts,
          ]
          state.urgentAlerts = [
            ...state.urgentAlerts,
            ...respAlerts.posts,
          ]
        } else {
          state.posts = response.posts.slice(0, LOAD_SIZE)
          state.urgentAlerts = respAlerts.posts.slice(0, LOAD_SIZE)

        }

        if (response.posts.length > LOAD_SIZE) {
          state.hasMorePosts = true
        } else {
          state.hasMorePosts = false
        }
      })
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => {
      state.loading = false
      state.refreshing = false
      state.loadingMore = false
      state.loaded = true
    })
  }, [state])

  const loadMorePosts = () => {
    if (!state.loading && state.hasMorePosts) {
      loadPosts(false, true).then()
    }
  }

  useEffect(() => {
    loadPosts().then()
    const reloadPosts = props.navigation.addListener('focus', () => {
      loadPosts().then()
    })
  }, [loadPosts])

  useEffect(() => {
    const listeners = [
      AppStateStore.eventBus.addRemovableListener(AppEvents.PostCreated, () => {
        loadPosts().then()
      }),
      AppStateStore.eventBus.addRemovableListener(AppEvents.PostDeleted, () => {
        loadPosts().then()
      }),
      AppStateStore.eventBus.addRemovableListener(AppEvents.EventCreated, () => {
        loadPosts().then()
      }),
      AppStateStore.eventBus.addRemovableListener(AppEvents.EventDeleted, () => {
        loadPosts().then()
      }),
    ]

    return () => {
      listeners.forEach(l => l.remove())
    }
  }, [])

  const renderPost = (item: ListRenderItemInfo<Post>) => {
    return <FeedPost
      post={item.item}
      onPress={post => {
        post.postType === 'event' ? props.navigation.push('EventDetail', { eventId: post.eventId, startsAt: post.eventStartAt }) :
          props.navigation.push('PostDetail', { postId: post.id })
      }}
    />
  }

  return <View style={styles.container}>
    {
      AppStateStore.userContext?.userType === 'teacher' ?
        <FloatingActionButton onPress={() => props.navigation.push('NewPostScreen')} bgColor={'#FF7A33'} />
        : null
    }
    <View style={styles.content}>

      {
        !state.loaded
          ? <ContentActivityIndicator />
          : state.error
            ? <>
              <ErrorMessage message={state.error} />
              <BlockButton
                variant={'secondary'}
                title={t('Retry', 'Retry')}
                onPress={() => loadPosts()}
              />
            </>
            : state.posts.length
              ? <View style={styles.listContainer}>
                <ListRefreshControl
                  onPress={() => loadPosts(true, false)}
                  refreshing={state.refreshing}
                />
                {
                  state.urgentAlerts.length ?
                    <View style={{ marginHorizontal: 10 }}>
                      <FeedUrgentAlerts
                        posts={state.visibleUAlertsPosts}
                        onPress={post => {
                          props.navigation.push('PostDetail', { postId: post.id })
                        }}
                      />
                    </View>
                    : null
                }
                <FlatList
                  style={styles.list}
                  data={state.visiblePosts}
                  renderItem={renderPost}
                  keyExtractor={i => String(i.id)}
                  refreshing={state.refreshing}
                  onRefresh={() => loadPosts(true, false)}
                  onEndReached={() => state.hasMorePosts ? loadMorePosts() : console.log('')}
                  removeClippedSubviews={true}
                  initialNumToRender={3}
                  maxToRenderPerBatch={2}
                  updateCellsBatchingPeriod={150} // Increase time between renders
                  windowSize={5}
                />
                {
                  state.loadingMore && state.hasMorePosts
                    ? <View style={styles.loadingMoreContainer}>
                      <ActivityIndicator />
                      <DefaultText style={styles.loadingMoreText}>{t('Loading more', 'Loading more')}</DefaultText>
                    </View>
                    : null
                }
              </View>
              : <View style={[styles.emptyList]}>
                <DefaultText style={styles.emptyListText}>{t('There is nothing in your feed', 'There is nothing in your feed')}</DefaultText>
                <BlockButton
                  variant="link"
                  title={t('Check Again', 'Check Again')}
                  onPress={() => loadPosts()}
                />
              </View>
      }
    </View>
    {
      state.renderActionBar
        ? <Modal
          coverScreen={false}
          isVisible={state.showActionBar}
          onModalHide={() => runInAction(() => state.renderActionBar = false)}
        >
          <View style={styles.actionBar}>
            <ActionBarItem
              icon={<KonektiIcon name={'post-icon'} size={30} color={Colors.PrimaryOrange} />}
              title={t('Post', 'Post')}
              description={t('Share something with your community', 'Share something with your community')}
              onPress={() => showCreatePost()}
            />
            <ListItemSeparator />
            <ActionBarItem
              icon={<KonektiIcon name={'calendar-icon'} size={30} color={Colors.PrimaryOrange} />}
              title={t('Event', 'Event')}
              description={t('Add an event to the calendar', 'Add an event to the calendar')}
              onPress={() => showCreateEvent()}
            />
            <ListItemSeparator />
            <ActionBarItem
              icon={<KonektiIcon name={'poll-icon'} size={30} color={Colors.PrimaryOrange} />}
              title={t('Poll', 'Poll')}
              description={t('Survey your community', 'Survey your community')}
              onPress={() => showCreateSurvey()}
            />
            <ListItemSeparator />
            <ActionBarItem
              icon={<KonektiIcon name={'urgent-alert-icon'} size={30} color={Colors.PrimaryOrange} />}
              title={t('Urgent Alert', 'Urgent Alert')}
              description={t('Immediately alert your community', 'Immediately alert your community')}
              onPress={() => showCreateUrgentAlert()}
            />
            <BlockButton
              variant={'secondary'}
              title={t('Cancel', 'Cancel')}
              onPress={() => runInAction(() => state.showActionBar = false)}
            />
          </View>
        </Modal>
        : null
    }
  </View>
})

type ActionBarItemProps = {
  icon: React.ReactElement
  title: string
  description: string
  onPress?: () => void
}

const ActionBarItem = (props: ActionBarItemProps) => {
  return <TouchableOpacity onPress={() => props.onPress && props.onPress()}>
    <View style={styles.actionBarItem}>
      <View style={styles.actionBarIcon}>
        {props.icon}
      </View>
      <View>
        <DefaultText style={styles.actionBarItemTitleText}>{props.title}</DefaultText>
        <DefaultText style={styles.actionBarItemDescriptionText}>{props.description}</DefaultText>
      </View>
    </View>
  </TouchableOpacity>
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    flex: 1,
    backgroundColor: '#fff',
  },
  content: {
    flex: 1,
  },
  postContainer: {
    flexDirection: 'row',
    borderBottomWidth: .5,
    borderColor: '#ccc',
  },
  postInputContainer: {
    flex: 1,
    borderRightWidth: .5,
    borderColor: '#ccc',
    justifyContent: 'center',
  },
  postInput: {
    fontSize: 16,
    padding: 16,
    color: '#aaa',
  },
  postIconContainer: {
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  postIcon: {
    color: '#aaa',
    fontSize: 24,
  },
  actionBar: {
    backgroundColor: '#fff',
    position: 'absolute',
  },
  actionBarItem: {
    flexDirection: 'row',
    padding: 16,
  },
  actionBarIcon: {
    marginRight: 16,
  },
  actionBarItemTitleText: {
    fontSize: 15,
    marginBottom: 4,
  },
  actionBarItemDescriptionText: {
    fontSize: 14,
    color: '#999',
  },
  listContainer: {
    flex: 1,
  },
  list: {
    flex: 1,
  },
  emptyList: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyListText: {
    textAlign: 'center',
    fontSize: 16,
    color: '#666',
  },
  loadingMoreContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  loadingMoreText: {
    color: '#999',
    marginLeft: 10,
  },
})
