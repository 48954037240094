import { Pressable, StyleProp, StyleSheet, Text, View, ViewStyle, TouchableOpacity } from 'react-native'
import React from 'react'
import { DocumentIcon } from './DocumentIcon'
import Icons from './icons'
import { AppColors } from '../common/AppColors'
import { openExternalUrl, getExtension } from '../common/Util'

interface Props {
  fileName: string
  fileType: string
  onPress?: () => void
  style?: StyleProp<ViewStyle>
  disabled?: boolean,
  downloable?: boolean,
  fileUrl?: string
}


export const FilePreview = (props: Props) => {
  const ext = getExtension(props.fileName)!
  return (
    <TouchableOpacity
      style={styles.container}
      disabled={props.disabled}
      onPress={props.onPress}>
      <View style={styles.wrapper}>
        <DocumentIcon type={ext} />
        <Text style={styles.text}>{props.fileName}</Text>
      </View>
      {
        props.downloable && props.fileUrl
          ?
          <View>

            <Icons.Download
              color={AppColors.orange.primary}
              size={25}
            />
          </View>
          : null
      }
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    marginRight: '1%',
    alignItems: 'center',
  },
  text: {
    color: '#303030',
    marginLeft: 10,
    fontSize: 16
  },
  wrapper: {
    flex: 1,
    flexDirection: 'row',
    marginRight: 45,
    marginVertical: 10,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});
