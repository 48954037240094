import ApiClient from '../ApiClient'
import { GetStudentOptionsResponse } from '../schema/GetStudentOptionsResponse'
import { CancelToken } from 'axios'

export type GetStudentOptionsRequest = {
  search?: string
  offset?: number
  limit?: number
  cancelToken?: CancelToken
}

export const getStudentOptions = async (request: GetStudentOptionsRequest): Promise<GetStudentOptionsResponse> => {
  const { cancelToken, ...params } = request

  return (await ApiClient.getInstance().get<GetStudentOptionsResponse>('/user/student-options', {
    params,
    cancelToken,
  })).data
}
