import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ChainedBackend from "i18next-chained-backend"
import HttpApi from 'i18next-http-backend'
import resourcesToBackend from "i18next-resources-to-backend"
import { AppConfig } from './AppConfig'

i18n
  .use(ChainedBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    backend: {
      backends: [
        HttpApi,
        resourcesToBackend({
          en: {
            translation: {
              ...require('../strings/en.json'),
            },
          },
          es: {
            translation: {
              ...require('../strings/es.json'),
            },
          },
        }),
      ],
      backendOptions: [
        {
          loadPath: `${AppConfig.I18N_BASE_URL}/strings/{{lng}}.json`,
          // addPath: `${AppConfig.I18N_BASE_URL}/add-strings/{{lng}}.json`,
        },
      ],
    },
    lng: 'en',
    // saveMissing: true,
    // updateMissing: true,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n
