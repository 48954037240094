import React from 'react'
import { StyleSheet, View } from 'react-native'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';

export const NotificationEvent = () => {
  return <Svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <Path d="M0.5 20C0.5 31.0457 9.45431 40 20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.95431 31.5457 0 20.5 0C9.45431 0 0.5 8.95431 0.5 20Z" fill="#FCD034"/>
  <Path d="M19.5 24H21.5V26H19.5V24ZM19.5 22H21.5V20H19.5V22ZM15.5 22H17.5V20H15.5V22ZM15.5 26H17.5V24H15.5V26ZM14.5 17.5H26.5V16H14.5V17.5ZM23.5 22H25.5V20H23.5V22ZM23.5 26H25.5V24H23.5V26ZM29.5 14V28C29.5 29.1 28.6 30 27.5 30H13.5C12.4 30 11.5 29.1 11.5 28V14C11.5 12.9 12.4 12 13.5 12H15.5C15.5 11.61 15.5 11.3 15.5 11V10H17V12H24V10H25.5V12H27.5C28.6 12 29.5 12.9 29.5 14ZM28 28.5V13.5H13V28.5H28Z" fill="white"/>
  </Svg>
  
  
}
