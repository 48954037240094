import React from 'react';
import { Svg, Path } from 'react-native-svg';
type Props = {
    width: number
    height: number
    color: string,
  }
export const LocationIcon = (props: Props) => {
    return (
        <Svg viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path d="M6.1028 0.693359C6.03869 0.693359 5.97459 0.693359 5.91049 0.693359C3.19254 0.789513 0.94895 2.89849 0.69254 5.59721C0.641258 6.12285 0.666899 6.64208 0.763053 7.12926C0.763053 7.12926 0.769463 7.19977 0.801514 7.32798C0.884848 7.69977 1.00664 8.05874 1.16049 8.39208C1.70536 9.68054 2.89126 11.6613 5.46818 13.7959C5.64767 13.9434 5.87203 14.0267 6.10921 14.0267C6.34639 14.0267 6.57075 13.9434 6.75023 13.7959C9.32075 11.6613 10.5066 9.68695 11.0451 8.41131C11.2054 8.06516 11.3272 7.69977 11.4105 7.3408C11.4297 7.27028 11.4425 7.20618 11.4489 7.16131C11.5131 6.82798 11.5515 6.47541 11.5515 6.12926C11.5387 3.12926 9.09639 0.693359 6.1028 0.693359ZM10.3848 6.12926C10.3848 6.39849 10.3592 6.66772 10.3079 6.94977C10.3079 6.95618 10.3079 6.95618 10.3079 6.95618C10.3079 6.95618 10.3079 6.95618 10.3079 6.96259C10.3079 6.97541 10.3015 7.01387 10.2887 7.07157C10.2182 7.36644 10.1284 7.64849 9.99382 7.93695C9.50664 9.0908 8.43613 10.8728 6.11562 12.8216C3.78869 10.8664 2.71818 9.08439 2.231 7.91772C2.1028 7.64849 2.00664 7.36003 1.94254 7.06515L1.92331 7.00105C1.92331 6.98823 1.9169 6.94977 1.9169 6.93695C1.82075 6.5331 1.80151 6.12285 1.83998 5.71259C2.0451 3.58439 3.81434 1.92413 5.94895 1.85362C6.00023 1.85362 6.05151 1.85362 6.1028 1.85362C7.21818 1.85362 8.26946 2.27669 9.07075 3.05874C9.9169 3.86644 10.3848 4.96259 10.3848 6.12926ZM6.1028 3.25105C4.481 3.25105 3.1669 4.57156 3.1669 6.18695C3.1669 7.80874 4.48741 9.12926 6.1028 9.12926C7.72459 9.12926 9.03869 7.80874 9.03869 6.19336C9.03869 4.56515 7.71818 3.25105 6.1028 3.25105ZM7.88485 6.18695C7.88485 7.16772 7.08357 7.969 6.1028 7.969C5.12203 7.969 4.32074 7.16772 4.32074 6.18695C4.32074 5.20618 5.12203 4.4049 6.1028 4.4049C7.08357 4.4049 7.88485 5.20618 7.88485 6.18695Z" fill={props.color}/>
        </Svg>

    )
}