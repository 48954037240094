import { StyleSheet, TouchableOpacity, View, Modal, Platform, Dimensions } from 'react-native'
import React from 'react'

type Props = {
  height?: number | string
  backgrounColor?: string
  overlayColor?: string
  radius?: number
  onPress?: () => void
  swipeLength?: number
  outsideTouch: (isOutside: boolean) => void
  children: React.ReactNode
}

export const BottomDrawer = (props: Props) => {
  const defaultProps = {
    height: props.height ? props.height : 100,
    backgrounColor: props.backgrounColor ? props.backgrounColor : '#fff',
    overlayColor: props.overlayColor ? props.overlayColor : 'rgba(32,32,32,0.2)',
    radius: props.radius ? props.radius : 10,
    onPress: props.onPress ? props.onPress : () => { },
    swipeLength: props.swipeLength ? props.swipeLength : 75
  }
  //console.log(defaultProps.radius)
  let initPoint = 0
  let finalPoint = 0
  const outsideTouch = () => {
    props.outsideTouch(true)
  }
  const isWeb = Platform.OS == 'web'
  const webCenterMargins = (Dimensions.get('screen').width - 900) / 2
  const initTouch = (event: any) => {
    initPoint = event.nativeEvent.pageY
  }

  const lastTouch = (event: any, outer: boolean) => {
    finalPoint = event.nativeEvent.pageY
    let pointDiff = finalPoint - initPoint
    if ((pointDiff > defaultProps.swipeLength) || (pointDiff < 10 && outer)) {
      outsideTouch()
    }
  }

  return (
    <Modal
      transparent={true}
      animationType='slide'
      onRequestClose={() => outsideTouch()}
    >
      <TouchableOpacity
        style={{
          position: 'absolute',
          height: '100%', width: isWeb ? 900 : '100%',
          backgroundColor: defaultProps.overlayColor,
          marginHorizontal: isWeb ? webCenterMargins : 0
        }}
        onPressIn={(event) => initTouch(event)}
        onPressOut={(event) => lastTouch(event, true)}
        activeOpacity={1}>
        <TouchableOpacity style={{
          position: 'absolute', bottom: 0, width: '100%', elevation: 10, zIndex: 10,
          backgroundColor: defaultProps.backgrounColor, height: defaultProps.height,
          borderTopLeftRadius: (isWeb && defaultProps.radius <= 0) ? 0 : defaultProps.radius,
          borderTopRightRadius: (isWeb && defaultProps.radius <= 0) ? 0 : defaultProps.radius
        }}
          activeOpacity={1}
          onPressIn={(event) => initTouch(event)}
          onPressOut={(event) => lastTouch(event, false)}
        >
          {props.children}
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  )
}
