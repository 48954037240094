import React, { useState } from "react"
import { StyleSheet, TouchableOpacity, View, Platform } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import DropDownPicker from 'react-native-dropdown-picker';
import { NormalPostAdition } from "./NormalPost"
import { FormError } from "../../../components/FormError";
import { ExpirationOption } from "../../../api/schema/models/ExpirationOption";
import { Attachment } from "../../../components/AttachmentBar";
import ErrorBag from "../../../common/ErrorBag";
import { AppColors } from "../../../common/AppColors";

interface UrgentAlertProps {
    attachment: Attachment | undefined,
    errors: ErrorBag,
    setMessage: (message: string) => void,
    setAttachment: (attachment: Attachment | undefined) => void
    setExpiration: (value: number) => void
}

export default (props: UrgentAlertProps) => {

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    const [items, setItems] = useState([
        {
            value: 0.5,
            label: "30 minutes"
        },
        {
            value: 1,
            label: "1 hour"
        },
        {
            value: 2,
            label: "2 hours"
        },
        {
            value: 8,
            label: "8 hours"
        },
        {
            value: 24,
            label: "1 day"
        },
        {
            value: 168,
            label: "1 week"
        }
    ]);

    return (
        <>
            <View style={styles.container}>
                <DropDownPicker
                    open={open}
                    value={value}
                    items={items}
                    setOpen={setOpen}
                    setValue={setValue}
                    setItems={setItems}
                    style={{
                        backgroundColor: '#F6F7FA',
                        borderColor: 'transparent',
                        flex: 1,
                        flexDirection: 'row',
                        height: 50,
                        alignItems: 'center',
                        paddingHorizontal: 10
                    }}
                    placeholder={'Expiration (required)'}
                    placeholderStyle={{
                        color: AppColors.gray.neutral2
                    }}
                    dropDownContainerStyle={{
                        elevation: 20,
                        borderColor: 'transparent',
                        backgroundColor: '#F6F7FA',
                        shadowColor: 'grey',
                        paddingHorizontal: 10
                    }}
                    listItemContainerStyle={{
                        height: 50
                    }}
                    containerStyle={{
                        height: 50
                    }}
                    onChangeValue={(value) => { props.setExpiration(value!) }}
                    listMode={'SCROLLVIEW'}
                />

                <View style={styles.message}>
                    <TextInput multiline={true} style={[styles.messageInput, Platform.OS === 'web' && { outlineStyle: 'none' }]} onChangeText={(text) => props.setMessage(text)} placeholder="Message (required)">

                    </TextInput>
                    <FormError field={'message'} errors={props.errors} />
                </View>
            </View>
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        marginTop: 15,
    },
    message: {
        backgroundColor: '#F6F7FA',
        width: '100%',
        height: 180,
        borderRadius: 6,
        padding: 10,
        marginTop: 15,
        textAlignVertical: 'top'
    },
    messageInput: {
        fontSize: 15,
        height: '100%',
        textAlignVertical: 'top'
    }
});