import React from 'react'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { BlockButton } from '../../components/BlockButton'
import { useTranslation } from 'react-i18next'
import { DefaultText } from '../../components/DefaultText'
import { PdfViewer } from '../../components/PdfViewer'
import { openExternalUrl } from '../../common/Util'

export type FileViewerScreenParams = {
  url: string
  filename: string
  mimeType: string
}

export const FileViewerScreen = (props: StackScreenProps<RootNavigationParamList, 'FileViewer'>) => {
  const { t } = useTranslation()

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: props.route.params.filename,
    })
  }, [props.navigation])

  return <>
    {
      props.route.params.mimeType === 'application/pdf'
        ? <PdfViewer uri={props.route.params.url}/>
        : <View>
          <DefaultText style={{ margin: 20, textAlign: 'center' }}>{t('No file viewer available', 'There is no viewer for this file type. Would you like to download it to your device?')}</DefaultText>
        </View>
    }
    <SafeAreaView edges={['bottom']}>
      <BlockButton
        title={t('Download File', 'Download File')}
        variant={'primary'}
        onPress={() => openExternalUrl(props.route.params.url)}
      />
    </SafeAreaView>
  </>
}
