import React, { useCallback, useEffect } from 'react'
import { FlatList, KeyboardAvoidingView, ListRenderItemInfo, PixelRatio, Platform, Pressable, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import _ from 'lodash'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { fullName, getMimeType, handleErrorResponse } from '../../common/Util'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useTranslation } from 'react-i18next'
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { Contact } from '../../api/schema/models/Contact'
import { API } from '../../api/API'
import { SendMessageInput } from '../../components/SendMessageInput'
import { MessageAttachment } from '../../api/schema/models/MessageAttachment'
import { StackScreenProps } from '@react-navigation/stack'
import { useHeaderHeight } from '@react-navigation/elements'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { FileData } from '../../api/methods/upload-file'
import { Attachment } from '../../components/AttachmentBar'
import { Recipient } from '../../components/messages/Recipient'
import { AppColors } from '../../common/AppColors'
import { TextInputField } from '../../components/TextInputField'
import Icons from '../../components/icons'
import { ScrollView } from 'react-native-gesture-handler'
//import * as Sentry from 'sentry-expo'

export const CreateMessageScreen = observer((props: StackScreenProps<RootNavigationParamList, 'CreateMessage'>) => {

  const { t } = useTranslation()

  const inputRef = React.createRef<TextInput>()

  const state = useLocalObservable(() => ({
    contacts: [] as Contact[],
    recipients: [] as Contact[],
    get recipientSearchResults() {
      return this.contacts.filter(c => {
        if (this.recipients.find(r => r.role === c.role && r.id === c.id)) {
          return false
        }

        return this.searchText.length === 0
          || (fullName(c) || '').toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
          || (c.displayName && c.displayName.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1)
          || (c.externalId && c.externalId.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1)
      })
    },
    searchText: '',
    inputHeight: undefined as number | undefined,
    message: '',
    attachment: undefined as Attachment | undefined,
    submitting: false,
    get isValid() {
      return this.recipients.length > 0 && (!!this.attachment || this.message.trim().length > 0)
    },
  }))

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('New Message', 'New Message'),
      headerTitleStyle: (PixelRatio.getFontScale() > 2) ? {fontSize: 10} : {}
    })
  }, [props.navigation])

  const loadContacts = useCallback(async () => {
    const contacts = await API.getContacts()
    runInAction(() => {
      state.contacts = contacts.contacts
    })
  }, [state])

  useEffect(() => {
    loadContacts().then()
  }, [loadContacts])

  const addRecipient = (recipient: Contact) => {
    if (AppStateStore.userContext?.userType === 'parent') {
      runInAction(() => {
        state.recipients = []
        state.recipients.push(recipient)
        state.searchText = ''
      })
    } else {
      runInAction(() => {
        state.recipients.push(recipient)
        state.searchText = ''
      })
    }

  }

  const removeRecipient = (userId: number) => {
    runInAction(() => state.recipients = state.recipients.filter(r => r.userId !== userId))
  }

  const renderRecipientListItem = (item: Contact) => {
    return (
      <TouchableOpacity
        disabled={!item.userId}
        onPress={() => {
          addRecipient(item)
        }}
      >
        <Recipient item={item} />
        {
          !item.userId
            ? <View style={styles.recipientSearchItemDisabled} />
            : null
        }
      </TouchableOpacity>
    );
  }

  const sendMessage = async () => {
    AppStateStore.showActivityIndicator()
    runInAction(() => state.submitting = true)

    try {
      let attachment: MessageAttachment | undefined = undefined

      if (state.attachment) {
        const uploadData = await API.requestUpload()

        let mimeType = 'application/octet-stream'
        let filename = 'upload'
        if (state.attachment.type === 'file') {
          mimeType = state.attachment.mimeType
          filename = state.attachment.name
        } else if (!state.attachment.url.startsWith('data:')) {
          mimeType = getMimeType(state.attachment.url) || 'application/octet-stream'
        } else if (state.attachment.type === 'video' || state.attachment.type === 'photo') {
          mimeType = state.attachment.mimeType
        }

        let file: FileData = {
          uri: state.attachment.url,
          name: filename,
          type: mimeType,
        }

        if (state.attachment.url.startsWith('data:')) {
          const response = await fetch(state.attachment.url)
          const binary = await response.blob()
          file = new Blob([binary], {
            type: 'application/pdf',
          })
        }

        await API.uploadFile(
          uploadData.url,
          uploadData.fields,
          file,
        )

        attachment = {
          type: state.attachment.type,
          key: uploadData.key,
          filename: filename,
          mimeType: mimeType,
        }
      }

      await API.createConversation({
        message: state.message,
        attachment,
        recipientUserIds: state.recipients.map(r => r.userId),
      })

      AppStateStore.eventBus.emit(AppEvents.MessageCreated)

      props.navigation.pop()
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      handleErrorResponse(err?.response)
    }

    runInAction(() => state.submitting = false)
    AppStateStore.hideActivityIndicator()
  }

  const headerHeight = useHeaderHeight()

  return (
    <KeyboardAvoidingView
        {...(Platform.OS === "ios" ? { behavior: "padding" } : {})}
        style={{ flex: 1 }}
        contentContainerStyle={{ flex: 1 }}
        keyboardVerticalOffset={(headerHeight ?? 0) + 10}
      >
    <ScrollView style={styles.container} nestedScrollEnabled>
      
        <View>
          <View style={styles.searchContainer}>
            <MaterialCommunityIcons
              onPress={() => inputRef.current!.focus()}
              name="magnify"
              style={styles.searchIcon}
            />
            <TextInput
              style={[styles.searchInput, Platform.OS === 'web' && { outlineStyle: 'none' }]}
              ref={inputRef}
              placeholder={t('Recipient', 'Recipient')}
              placeholderTextColor={AppColors.gray.neutral3}
              value={state.searchText}
              onChange={e => runInAction(() => state.searchText = e.nativeEvent.text)}
            />
          </View>
          <View style={styles.recipientList}>
            {
              state.recipients.map(r => <RecipientTag key={r.userId} name={fullName(r) || ''} onPress={() => removeRecipient(r.userId)} />)
            }
          </View>
        </View>
        <View style={styles.body}>
          <View style={styles.recipientSearchList}>
            {
              state.recipientSearchResults.map(recipient => renderRecipientListItem(recipient))
            }
            {/* <FlatList
              keyboardShouldPersistTaps="handled"
              style={{ flex: 1 }}
              data={state.recipientSearchResults}
              renderItem={renderRecipientListItem}

              keyExtractor={c => String(`${c.role}:${c.id}`)} */}
            {/* /> */}
          </View>
        </View>
        
    </ScrollView>
    <View style={styles.footerContainer}>
    <SafeAreaView edges={['bottom']}>
      <View style={{ backgroundColor: AppColors.white }}>
        <SendMessageInput
          attachment={state.attachment}
          onAttached={(attachment) => runInAction(() => state.attachment = attachment)}
          message={state.message}
          onMessageChanged={message => runInAction(() => state.message = message)}
          onSendPressed={sendMessage}
          isValid={state.isValid}
        />
      </View>
    </SafeAreaView>
  </View>
  </KeyboardAvoidingView>
  );
});

type RecipientTagProps = {
  name: string
  onPress?: () => void
}

const RecipientTag = (props: RecipientTagProps) => {
  return (
    <TouchableOpacity onPress={props.onPress}>
      <View style={styles.recipientTag}>
        <Text style={styles.recipientTagText}>{props.name}</Text>
        <MaterialIcons name="close" style={styles.removeRecipientIcon} />
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColors.white,
    paddingHorizontal: 16,
  },
  recipientSearchItemDisabled: {
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,.5)',
    width: '100%',
    height: '100%',
  },
  searchContainer: {
    marginBottom: 12,
    backgroundColor: '#F6F7FA',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 6,

  },
  searchIcon: {
    fontSize: 22,
    color: '#626262',
    paddingLeft: 10,
  },
  searchInput: {
    fontSize: 18,
    paddingHorizontal: 8,
    paddingVertical: 14,
    color: AppColors.gray.neutral1,
    flex: 1,
    fontFamily: 'Rubik_400Regular',
  },
  recipientList: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    flexWrap: 'wrap',
  },
  recipientTag: {
    paddingLeft: 10,
    paddingRight: 6,
    paddingVertical: 6,
    backgroundColor: AppColors.blue.primary,
    borderRadius: 20,
    marginVertical: 2,
    marginRight: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  recipientTagText: {
    color: AppColors.white,
    fontSize: 14,
    fontFamily: 'Rubik_400Regular',
  },
  removeRecipientIcon: {
    color: AppColors.white,
    marginLeft: 4,
    fontSize: 16,
  },
  body: {
    flex: 1,
  },
  recipientSearchList: {
    flex: 1,
    marginTop: 10,
  },
  itemSeparator: {
    height: 1,
    backgroundColor: '#ddd',
  },
  footerContainer: {
    marginVertical: 12,
  },
  messageInputContainer: {
    backgroundColor: AppColors.white,
    flexDirection: 'row',
    alignItems: 'center',
  },
  messageInput: {
    flex: 1,
    paddingHorizontal: 20,
    fontSize: 16,
  },
  attachButton: {
    height: '100%',
    width: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonSeparator: {
    height: '100%',
    width: 1,
    backgroundColor: '#ddd',
  },
  sendButton: {
    height: '100%',
    width: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
