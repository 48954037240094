import ApiClient from '../ApiClient'
import { RegisterPushNotificationTokenRequest } from '../schema/RegisterPushNotificationTokenRequest'

export const deletePushNotificationToken = async (token: string) => {
  const request: RegisterPushNotificationTokenRequest = {
    pushNotificationToken: token,
  }

  await ApiClient.getInstance().post('/user/delete-notification-token', request)
}
