import React, { useCallback, useEffect } from 'react'
import {
  FlatList,
  ListRenderItemInfo,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
  ScrollView,
  RefreshControl,
} from 'react-native'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { NewMessageButton } from '../../components/NewMessageButton'
import { API } from '../../api/API'
import { Conversation } from '../../api/schema/models/Conversation'
import { runInAction } from 'mobx'
import { Contact } from '../../api/schema/models/Contact'
import { ErrorMessage } from '../../components/ErrorMessage'
import { extractErrorMessage, fullName, utc } from '../../common/Util'
import { BlockButton } from '../../components/BlockButton'
import { DefaultText } from '../../components/DefaultText'
import { ProfileImage } from '../../components/ProfileImage'
import { RelativeTime } from '../../components/RelativeTime'
import { useFocusEffect } from '@react-navigation/native'
import { DrawerHeaderButton } from '../../components/DrawerHeaderButton'
import { StackScreenProps } from '@react-navigation/stack'
import { ListRefreshControl } from '../../components/web-list-refresh-control/ListRefreshControl'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { FloatingActionButton } from '../../components/fab/FloatingActionButton'
import { AppColors } from '../../common/AppColors'
import { TextInputField } from '../../components/TextInputField'
import { ActionButton } from '../../components/buttons/ActionButton'
import Icons from '../../components/icons'
import { AppColors as COLORS } from '../../common/AppColors'
//import * as Sentry from 'sentry-expo'


export const ConversationsScreen = observer((props: StackScreenProps<RootNavigationParamList, 'Conversations'>) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    loading: false,
    refreshing: false,
    conversations: [] as Conversation[],
    contacts: [] as Contact[],
    error: undefined as string | undefined,
  }))

  useFocusEffect(() => {
    const listener = AppStateStore.addPushNotificationListener(async notification => {
      if (notification.request.content.data.notificationType === 'new-message') {
        loadConversations().then()
      }

      return false
    })

    return () => listener.remove()
  })

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Messages', 'Messages'),
      headerLeft: () => <DrawerHeaderButton />,
      //headerRight: () => AppStateStore.userContext!.userType === 'teacher' ? <NewMessageButton onPress={() => props.navigation.push('CreateMessage')}/> : null,
    })
  }, [props.navigation])

  const loadConversations = useCallback(async (refresh: boolean = false) => {
    runInAction(() => {
      state.loading = true
      state.error = undefined
      state.refreshing = refresh
    })

    try {
      const response = await API.getConversations()

      runInAction(() => {
        state.conversations = response.conversations
        state.contacts = response.contacts
      })
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      state.error = extractErrorMessage(err.response)
    }

    runInAction(() => {
      state.loading = false
      state.refreshing = false
    })
  }, [state])

  useEffect(() => {
    loadConversations().then()

    const listeners = [
      AppStateStore.eventBus.addRemovableListener(AppEvents.MessageCreated, onMessageCreated),
    ]

    return () => {
      listeners.forEach(l => l.remove())
    }
  }, [loadConversations])

  useEffect(() => {
    const reloadConversations = props.navigation.addListener('focus', () => {
      loadConversations().then()
    })
    //loadProfile().then()
  }, [loadConversations])

  const onMessageCreated = () => {
    loadConversations().then()
  }

  const goToConversation = async (conversationId: number) => {
    await API.markConversation(conversationId)
    props.navigation.push('Messages', { conversationId: conversationId, })
  }

  const renderConversationListItem = (item: ListRenderItemInfo<Conversation>) => {
    const { index } = item;

    // find other participant profile images
    const otherParticipants = state.contacts.filter(c => item.item.participantUserIds.indexOf(c.userId) > -1 && c.userId !== AppStateStore.userContext?.id)
    const lastMessage = item.item.lastMessage
    let recentParticipant = (lastMessage ? otherParticipants.find(p => p.userId === lastMessage.userId) : undefined) ?? otherParticipants[0]

    let messagePreview = lastMessage?.message

    if (!lastMessage?.message) {
      if (lastMessage?.attachments.imageUrl) {
        messagePreview = t('Image Attachment', 'Image Attachment')
      } else if (lastMessage?.attachments.videoUrl) {
        messagePreview = t('Video Attachment', 'Video Attachment')
      } else if (lastMessage?.attachments.audioUrl) {
        messagePreview = t('Audio Attachment', 'Audio Attachment')
      }
    }

    return (
      <TouchableOpacity onPress={() => goToConversation(item.item.id)}>
        <View style={styles.listItemContainer}>
          <View style={styles.listItemPhotoContainer}>
            <ProfileImage url={recentParticipant?.profilePhotoUrl} size={40} />
          </View>
          <View style={[
            styles.listItemConversation,
            (index !== state.conversations.length - 1) ? styles.listItemConversationBorder : null
          ]}>
            <View style={styles.listItemContent}>
              <View>
                <DefaultText style={styles.listItemNames} numberOfLines={2}>{otherParticipants.map(c => fullName(c)).join(', ')}</DefaultText>
                <DefaultText style={styles.listItemMessage} numberOfLines={2}>{messagePreview ?? 'No Message'}</DefaultText>
              </View>
            </View>
            <View style={styles.listItemMeta}>
              <DefaultText style={styles.listItemTime}>
                <RelativeTime date={utc(lastMessage?.createdAt || item.item.createdAt)} />
              </DefaultText>

              {/*TODO: implement unread count*/}
              {
                item.item.unreadCount ?
                  item.item.unreadCount > 0 ?
                    <View style={styles.itemBadge}>
                      <Text style={styles.itemBadgeText}>{item.item.unreadCount}</Text>
                    </View>
                    : null
                  : null
              }

            </View>
          </View>

        </View>
      </TouchableOpacity>
    );
  }

  const onSearch = () => {
    console.log('Searching...');
  }

  const onCreateMessage = () => props.navigation.push('CreateMessage');


  return (
    <View style={styles.container}>
      {
        AppStateStore.userContext?.userType === 'teacher' || AppStateStore.userContext?.userType === 'parent' ? (
          <FloatingActionButton
            onPress={onCreateMessage}
            bgColor={'#0082CD'}
          />
        ) : null
      }

      <View style={styles.main}>
        {
          state.error
            ? <>
              <ErrorMessage message={state.error} />
              <BlockButton
                title={t('Retry', 'Retry')}
                variant={'secondary'}
                onPress={() => loadConversations()}
              />
            </>
            : <>
              <View style={{ marginHorizontal: 16 }}>
                <TextInputField
                  onSubmit={onSearch}
                  placeholder="Search here"
                  rightElement={
                    <ActionButton onPress={onSearch}>
                      <Icons.Search
                        color={AppColors.white}
                        size={44}
                      />
                    </ActionButton>
                  }
                />

              </View>

              {
                state.conversations.length
                  ? <>
                    <ListRefreshControl
                      onPress={() => loadConversations(true)}
                      refreshing={state.refreshing}
                    />
                    <FlatList
                      keyboardShouldPersistTaps="handled"
                      style={styles.list}
                      data={state.conversations}
                      keyExtractor={item => String(item.id)}
                      renderItem={renderConversationListItem}
                      onRefresh={() => loadConversations(true)}
                      refreshing={state.refreshing}
                    />
                  </>
                  : null
              }
              {
                (state.conversations.length === 0)
                  ? (
                    /*   <View style={[styles.emptyList]}>
                        <Icons.EmptyMessages color={COLORS.gray.neutral5} />
                      </View> */
                    <>
                      <ListRefreshControl
                        onPress={() => loadConversations(true)}
                        refreshing={state.refreshing}
                      />
                      <ScrollView
                        contentContainerStyle={styles.emptyList}
                        refreshControl={
                          <RefreshControl
                            refreshing={state.refreshing}
                            onRefresh={() => loadConversations(true)}
                          />
                        }
                      >
                        <Icons.EmptyMessages color={COLORS.gray.neutral5} />
                      </ScrollView>
                    </>
                  )
                  : null
              }
            </>
        }
      </View>
    </View>
  );

})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    paddingTop: 16
  },
  main: {
    flex: 1,
  },
  list: {
    flex: 1,
  },
  emptyList: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyListText: {
    textAlign: 'center',
    fontSize: 16,
    color: '#666',
  },
  listItemContainer: {
    marginTop: 16,
    paddingHorizontal: 16,
    paddingVertical: 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listItemPhotoContainer: {
    marginRight: 10,
    width: 50,
    alignSelf: 'flex-start',
  },
  listItemGroupPhotoContainer: {
    width: 50,
    height: 30,
  },
  listItemContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
  },
  listItemConversation: {
    justifyContent: 'space-between',
    flex: 1,
    flexDirection: 'row',
    paddingBottom: 16,
  },
  listItemConversationBorder: {
    borderBottomColor: '#EEEEEE',
    borderBottomWidth: 1,
  },
  listItemNames: {
    fontFamily: 'Rubik_600SemiBold',
    fontSize: 16,
    marginBottom: 4,
    color: AppColors.gray.neutral1,
  },
  listItemMessage: {
    fontFamily: 'Rubik_400Regular',
    color: AppColors.gray.neutral2,
    fontSize: 16,
  },
  listItemTime: {
    color: '#666',
    fontSize: 13,
  },
  listItemMeta: {
    marginLeft: 10,
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  itemBadge: {
    height: 24,
    width: 24,
    backgroundColor: AppColors.blue.primary,
    borderRadius: 48,
    alignItems: 'center',
    justifyContent: 'center',
    //display: 'none',
  },
  itemBadgeText: {
    fontWeight: 'bold',
    color: AppColors.white,
    fontSize: 14,
    lineHeight: 17
  },
})

