import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { ActivityIndicator, ScrollView, StyleSheet, Text, TouchableOpacity, View, Platform } from 'react-native'
import { API } from '../../api/API'
import { runInAction } from 'mobx'
import { AppStateStore } from '../../contexts/AppStateStore'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Audio } from '../../api/schema/models/Audio'
import { useTranslation } from 'react-i18next'
import { AudioPlayer } from '../../components/AudioPlayer'
import { getModalManager } from '../../contexts/ModalContext'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { KonektiIcon } from '../../components/KonektiIcon'
//import * as Sentry from 'sentry-expo'

export type AudioPlayerScreenParams = {
  uuid: string
}

export const AudioPlayerScreen = observer((props: StackScreenProps<RootNavigationParamList, 'AudioPlayer'>) => {
  const { t } = useTranslation()

  const state = useLocalObservable(() => ({
    audio: undefined as Audio | undefined,
    showTranslation: false,
    translatedTranscript: undefined as string | undefined,
    isLoadingTranscript: false,
  }))

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Audio', 'Audio'),
    })
  }, [props.navigation])

  useEffect(() => {
    loadAudio().then()
  }, [])

  const loadAudio = async () => {
    const audio = await API.getAudio(props.route.params.uuid)

    runInAction(() => state.audio = audio)
  }

  const translateTranscript = async () => {
    let translate = !state.showTranslation

    runInAction(() => state.isLoadingTranscript = true)

    if (translate && !state.translatedTranscript) {
      if (!state.translatedTranscript) {
        try {
          const translation = await API.getAudioTranscript(props.route.params.uuid, AppStateStore.userLanguage)
          runInAction(() => state.translatedTranscript = translation)
        } catch (err) {
          if (Platform.OS === 'web') {
            //Sentry.Browser.captureException(err)
          }
          else {
            //Sentry.Native.captureException(err)
          }
          getModalManager()
            .showModal({
              title: t('Error', 'Error'),
              message: t('There was an error loading the translation', 'There was an error loading the translation'),
            })

          translate = false
        }
      }
    }

    runInAction(() => {
      state.isLoadingTranscript = false
      state.showTranslation = translate
    })
  }

  return <View style={styles.container}>
    {
      state.audio
        ? <>
          <View style={styles.audioContainer}>
            <AudioPlayer url={state.audio.audioUrl}/>
          </View>
          <View style={styles.transcriptContainer}>
            <SafeAreaView style={{ flex: 1 }} edges={['bottom']}>
              {
                state.audio.transcript
                  ? <TouchableOpacity
                    style={styles.translateIconContainer}
                    onPress={translateTranscript}
                  >
                    <KonektiIcon name={'translate-icon'} size={20} color={'#ff8d2d'}/>
                  </TouchableOpacity>
                  : null
              }
              {
                state.isLoadingTranscript
                  ? <ActivityIndicator/>
                  :
                  <ScrollView style={{ flex: 1 }} keyboardShouldPersistTaps="handled">
                    <Text style={styles.transcriptText}>{state.showTranslation ? state.translatedTranscript : state.audio.transcript}</Text>
                  </ScrollView>
              }
            </SafeAreaView>
          </View>
        </>
        : <ActivityIndicator/>
    }
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  audioContainer: {
    padding: 10,
  },
  transcriptContainer: {
    flex: 1,
  },
  transcriptText: {
    paddingHorizontal: 20,
    fontSize: 16,
  },
  translateIconContainer: {
    alignSelf: 'flex-end',
    marginBottom: 10,
    padding: 8,
  },
})
