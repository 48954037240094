import React from 'react'
import { Svg, Path } from "react-native-svg"
import { AppColors } from '../../common/AppColors';

type Props = {
  height: number
  width: number
  color: string
}

const EditIcon = ({
  height = 20,
  width = 20,
  color = AppColors.orange.primary
}: Props) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" fill="none" >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9262 5.18L7.67231 11.43C7.43216 11.67 7.43216 12.07 7.67231 12.31C7.79239 12.43 7.95249 12.49 8.11259 12.49C8.27269 12.49 8.43279 12.43 8.55286 12.31L14.8068 6.06C15.0469 5.82 15.0469 5.42 14.8068 5.17C14.5666 4.94 14.1764 4.94 13.9262 5.18ZM16.3277 0C17.3083 0 18.2289 0.38 18.9293 1.08C19.6198 1.77 20 2.69 20 3.67C20 4.65 19.6198 5.57 18.9193 6.27L7.27206 17.91C7.20202 17.98 7.11196 18.03 7.0119 18.07L0.788019 19.98C0.737988 19.99 0.67795 20 0.617913 20C0.447807 20 0.287707 19.93 0.167632 19.81C0.00753211 19.64 -0.0424991 19.39 0.0375508 19.17L2.53911 12.3C2.56913 12.21 2.61916 12.13 2.6892 12.07L13.7261 1.07C14.4165 0.38 15.3371 0 16.3277 0ZM6.30146 16.98L3.97 17.7C3.83992 17.3 3.61978 16.95 3.33961 16.66C3.08945 16.41 2.79927 16.22 2.48908 16.06L3.54974 13.13H4.99064V14.38C4.99064 14.72 5.27081 15 5.61103 15H6.71171L6.30146 16.98ZM15.5072 7.92L7.89245 15.53L8.11259 14.5C8.15261 14.31 8.10258 14.13 7.9825 13.98C7.86243 13.83 7.68232 13.75 7.50221 13.75H6.24142V12.5C6.24142 12.16 5.96124 11.87 5.61103 11.87H4.63041L12.0651 4.48L12.0751 4.49C12.5353 4.03 13.1457 3.78 13.7961 3.78C14.4465 3.78 15.0569 4.03 15.5072 4.49C15.9675 4.95 16.2176 5.55 16.2176 6.2C16.2176 6.85 15.9675 7.46 15.5072 7.92ZM18.0388 5.39L17.4584 5.97C17.3984 5.08 17.0281 4.24 16.3877 3.6C15.7473 2.96 14.9168 2.59 14.0163 2.54L14.6066 1.96C15.0669 1.5 15.6773 1.25 16.3277 1.25C16.9781 1.25 17.5785 1.5 18.0388 1.96C18.4991 2.42 18.7492 3.03 18.7492 3.68C18.7492 4.32 18.4991 4.93 18.0388 5.39Z"
        fill={color}
      />
    </Svg>
  )
}

export default EditIcon
