import React, { useEffect, useState } from "react";
import { GestureResponderEvent, Platform, Pressable, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { runInAction } from 'mobx'
import { Colors } from "../../../common/Colors";
import { StackNavigationState, StackRouterOptions, useNavigation } from "@react-navigation/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { PostOptionList } from "./SelectOption";
import NewPost from "./NewPost";
import { useTranslation } from "react-i18next";
import { VisibilityOption } from "../../../api/schema/models/VisibilityOption";
import { StackNavigationProp } from "@react-navigation/stack";

export default (props: any) => {

    const navigation = useNavigation();
    const { t } = useTranslation();


    const [locationY, setLocationY] = useState< undefined| number >(undefined)

    const backToFeed = () => {
        navigation.goBack();
    }

    const onResponserMove = (event: GestureResponderEvent) => {
        if(!locationY) {
            setLocationY(event.nativeEvent.locationY);
        }
    }

    const onResponderRelease = (event: GestureResponderEvent) => {
        const endLocationY = event.nativeEvent.locationY;
        if(endLocationY - locationY! > 10 ) {
            navigation.goBack();
        } 
    }

    return (
       <>
        <TouchableOpacity
            style={styles.closeModal}
            onPress= {() => navigation.goBack()}
        />

        <View 
            style={styles.container} 
            onResponderMove={onResponserMove} 
            onStartShouldSetResponder={() => true}
            onResponderRelease={onResponderRelease}
        >
            
            <Pressable style={styles.dragElement} onPress={() => navigation.goBack()}
            />

            <View style={styles.header}>
                <View>
                    <Text style={styles.headerTitle}> New Post </Text>
                    <Text style={{color: 'black'}}> Select Post Type </Text>
                </View>
            </View>
            
            <View style={{flex: 5}}>
                    <PostOptionList/>
            </View>

        </View>
       </>
    );
}




const styles = StyleSheet.create({
    container: {
      height: Platform.OS === 'ios' ? "90%" : "82%",
      width: '100%',
      position: 'absolute',
      bottom: 0,
      flex: 1,
      backgroundColor: 'white',
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
      zIndex: 1,
      paddingHorizontal: 20,
      justifyContent: 'space-between'
    },
    dragElement: { 
        height:8,
        backgroundColor: 'grey',
        borderRadius: 20, 
        position: 'relative', 
        marginHorizontal: 130, 
        opacity: .5,
        marginTop: 15
    },
    header: {
        marginTop: 25,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    headerTitle: {
        fontWeight: 'bold',
        fontSize: 18,
        marginBottom: 5,
        
    },
    headerSubtitle: {

    },
    optionList: {
        flex: 10,
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    closeModal: {    
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'absolute'
    },
    optionItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: "#F6F7FA",
        paddingVertical: 20,
        paddingHorizontal: 10,
        alignItems: 'center',
        borderRadius: 13

    },
    optionItemInfo: {
    },
    optionTitle: {
        fontSize: 18,
        fontWeight: '700'
    },
    optionSubtitle: {
        fontWeight: '500',
        fontSize: 15
    },
    button: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: 45,
        marginBottom: 30,
        borderRadius: 8,
        elevation: 8,
        shadowColor: 'grey',
        shadowOffset: {
            height: 5,
            width: 5
        },
        shadowOpacity: 0.7,
        shadowRadius: 10
    }

  })
  