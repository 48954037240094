import React from 'react'
import { Colors } from '../../common/Colors'
import { PixelRatio, Text, View } from 'react-native'
import Svg, { G, Path, Rect, Defs, ClipPath, Ellipse } from 'react-native-svg'
import Icons from '../icons'
import { AppColors as COLORS } from '../../common/AppColors'

export enum MainTabNames {
    Home = 'Home',
    Messages = 'Messages',
    Calendar = 'Calendar',
    ESignature = 'E-Sign',
    Documents = 'Documents',
    Profile = 'Profile',
}

export const TabIcon = (props: { selected: boolean, tabName: MainTabNames, currentRoute: string, messageBadge?: number }): JSX.Element => {
    const selected = props.tabName === 'E-Sign' && props.currentRoute === 'ReceivedFormsScreen' ? true : props.selected
    return (
        <View style={{ flex: 1, justifyContent: 'space-evenly', alignItems: 'center', width: 100 }}>
            {
                buildTabIcon(selected, props.messageBadge)[props.tabName]
            }
            <Text
                allowFontScaling={PixelRatio.getFontScale() > 2 ? false : true}
                style={selected ? { fontWeight: 'bold', fontSize: 11, marginTop: 5 } : { fontSize: (PixelRatio.getFontScale()) > 2 ? 15 : 11, marginTop: 5 }}
            >
                {props.tabName}
            </Text>
        </View>
    );
}

const buildTabIcon = (selected: boolean, messageBadge?: number) => {
    //const iconFill = selected ? Colors.PrimaryOrange : Colors.TertiaryGrey

    return {
        [MainTabNames.Home]: <Icons.Home width={26} height={25} color={selected ? COLORS.orange.primary : COLORS.gray.neutral2} variant={selected ? 'fill' : 'outline'} />,
        [MainTabNames.Messages]: <Icons.Message width={20} height={20} color={selected ? COLORS.orange.primary : COLORS.gray.neutral2} variant={selected ? 'fill' : 'outline'} badgeNumber={messageBadge} />,
        [MainTabNames.Calendar]: <Icons.Calendar width={18} height={20} color={selected ? COLORS.orange.primary : COLORS.gray.neutral2} variant={selected ? 'fill' : 'outline'} />,
        [MainTabNames.ESignature]: <Icons.Forms width={20} height={20} color={selected ? COLORS.orange.primary : COLORS.gray.neutral2} variant={selected ? 'fill' : 'outline'} />,
        [MainTabNames.Documents]: <Icons.Files width={20} height={20} color={selected ? COLORS.orange.primary : COLORS.gray.neutral2} variant={selected ? 'fill' : 'outline'} />,
        [MainTabNames.Profile]: <Icons.Profile width={20} height={20} color={selected ? COLORS.orange.primary : COLORS.gray.neutral2} variant={selected ? 'fill' : 'outline'} />,
    };
}

export const CalendarIcon = (props: any) => {
    return (
        <Svg width="23" height="23" viewBox="0 0 26 26" fill={'#FFFFFF'} xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path d="M8.22614 14H10.2739V16H8.22614V14ZM8.22614 12H10.2739V10H8.22614V12ZM4.13062 12H6.17838V10H4.13062V12ZM4.13062 16H6.17838V14H4.13062V16ZM3.10674 7.5H15.3933V6H3.10674V7.5ZM12.3217 12H14.3694V10H12.3217V12ZM12.3217 16H14.3694V14H12.3217V16ZM18.4649 4V18C18.4649 19.1 17.5435 20 16.4172 20H2.08286C0.956588 20 0.0350952 19.1 0.0350952 18V4C0.0350952 2.9 0.956588 2 2.08286 2H4.13062C4.13062 1.61 4.13062 1.3 4.13062 1V0H5.66644V2H12.8336V0H14.3694V2H16.4172C17.5435 2 18.4649 2.9 18.4649 4ZM16.9291 18.5V3.5H1.57092V18.5H16.9291Z" />
        </Svg>
    );
}