import React, { useEffect } from 'react'
import { CommonActions, NavigationContainer, NavigationContainerRef } from '@react-navigation/native'
import { Platform } from 'react-native'
import * as Notifications from 'expo-notifications'
import { RootStack, TabStack } from './navigation/RootNav'
import { AppSideMenu } from './components/AppSideMenu'

type Props = {
  onStateChange?: () => void
}

export const AppNavigation = (props: Props) => {
  const state = {
    navigationRef: React.createRef<NavigationContainerRef<any>>()
  }

  useEffect(() => {
    /*const responseListener = Notifications.addNotificationResponseReceivedListener(response => {
      if (response.notification.request.content.data.notificationType === 'new-message') {
        if (state.navigationRef.current) {
          state.navigationRef.current.dispatch(CommonActions.reset({
            index: 0,
            routes: [
              { name: 'Conversations' },
              { name: 'Messages', params: { conversationId: response.notification.request.content.data.conversationId } },
            ],
          }))
        }
      }
    })

    return () => {
      Notifications.removeNotificationSubscription(responseListener)
    }*/
  }, [state])

  return <NavigationContainer
    ref={state.navigationRef}
    documentTitle={{
      formatter: (options, route) =>
        Platform.OS === 'web' ? 'Konekti' : (options?.title ?? route?.name ?? 'Konekti'),
    }}
    onStateChange={props.onStateChange}
  >
    <RootStack />
    <AppSideMenu navigationRef={state.navigationRef} />
  </NavigationContainer>
}
