import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconProps } from '.';
import { AppColors } from '../../common/AppColors';

const TranslateIcon = ({
  color = AppColors.gray.neutral3,
  height = 20,
  width = 20,
}: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path d="M13.98 15.79C14.1 16.07 14.1 16.4 13.98 16.69C13.92 16.83 13.83 16.97 13.72 17.07L11.15 19.65C10.93 19.87 10.63 19.99 10.32 19.99C10 19.99 9.71 19.87 9.49 19.64C9.27 19.42 9.15 19.12 9.15 18.81C9.15 18.5 9.27 18.2 9.5 17.98L10.06 17.42H6.67C2.99 17.42 0 14.42 0 10.74C0 9.01 0.66 7.37 1.87 6.12C2.32 5.65 3.07 5.64 3.54 6.09C4.01 6.54 4.02 7.29 3.57 7.76C2.79 8.57 2.36 9.63 2.36 10.75C2.36 13.13 4.3 15.07 6.68 15.07H10.06L9.49 14.49C9.27 14.27 9.14 13.97 9.14 13.66C9.14 13.34 9.26 13.05 9.49 12.83C9.95 12.37 10.7 12.37 11.16 12.83L13.73 15.41C13.84 15.51 13.92 15.64 13.98 15.79ZM13.33 2.55H9.95L10.51 1.99C10.73 1.77 10.86 1.47 10.86 1.16C10.86 0.85 10.74 0.55 10.51 0.33C10.07 -0.11 9.28 -0.11 8.84 0.33L6.28 2.9C6.17 3.01 6.08 3.14 6.02 3.28C5.9 3.57 5.9 3.9 6.02 4.18C6.08 4.33 6.17 4.45 6.27 4.56L8.84 7.14C9.3 7.6 10.05 7.6 10.51 7.14C10.97 6.68 10.97 5.93 10.51 5.47L9.95 4.91H13.33C15.71 4.91 17.65 6.85 17.65 9.23C17.65 10.34 17.22 11.41 16.44 12.22C16.22 12.45 16.1 12.74 16.11 13.06C16.12 13.38 16.24 13.67 16.47 13.89C16.7 14.11 16.99 14.22 17.29 14.22C17.6 14.22 17.91 14.1 18.14 13.86C19.35 12.6 20.01 10.96 20.01 9.23C20 5.55 17.01 2.55 13.33 2.55Z" fill={color}/>
    </Svg>
  );
}

export default TranslateIcon;