import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'
import { handleLogOutButton } from '../common/Util'

export const LogOutHeaderButton = () => {
  return <TouchableOpacity
    onPress={() => handleLogOutButton()}
    style={styles.button}
  >
    <MaterialIcons
      style={styles.icon}
      name="logout"
    />
  </TouchableOpacity>
}

const styles = StyleSheet.create({
  button: {
    padding: 10,
  },
  icon: {
    fontSize: 20,
    color: '#FF7A33',
  },
})
