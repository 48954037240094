import React from 'react'
import { ActivityIndicator, TouchableOpacity, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

type Props = {
  onPress?: () => void
  refreshing?: boolean
}

export const ListRefreshControl = (props: Props) => {
  return <View style={{ flexDirection: 'row', justifyContent: 'flex-end', padding: 10 }}>
    <TouchableOpacity onPress={props.onPress} disabled={props.refreshing} style={{ width: 30, height: 30 }}>
      {
        props.refreshing
          ? <ActivityIndicator/>
          : <MaterialCommunityIcons name={'refresh'} size={30}/>
      }
    </TouchableOpacity>
  </View>
}
