import React from "react";
import { Svg, Path } from 'react-native-svg';

export const Audio = (props: any) => {
    return (
        <Svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path fill-rule="evenodd" clip-rule="evenodd" d="M8.62117 0C11.0887 0 13.0955 1.96 13.0955 4.37V9.53C13.0955 9.55 13.0853 9.57 13.0853 9.59C13.0239 11.95 11.0478 13.85 8.62117 13.85C6.15362 13.85 4.14681 11.89 4.14681 9.48V4.38C4.14681 1.96 6.15362 0 8.62117 0ZM15.5016 8.57C16.0136 8.57 16.4334 8.98 16.4334 9.48C16.4334 13.36 13.4846 16.6 9.5529 17.06V18.18H11.9897C12.5017 18.18 12.9215 18.59 12.9215 19.09C12.9215 19.59 12.5119 20 12 20H5.32427C4.81233 20 4.39254 19.59 4.39254 19.09C4.39254 18.59 4.81233 18.18 5.32427 18.18H7.6792V17.06C3.75774 16.6 0.80896 13.36 0.80896 9.48C0.80896 8.98 1.22875 8.57 1.74069 8.57C2.25263 8.57 2.67242 8.98 2.67242 9.48C2.67242 12.68 5.33451 15.29 8.61093 15.29C11.8873 15.29 14.5597 12.69 14.5597 9.48C14.5699 8.97 14.9795 8.57 15.5016 8.57ZM6.01027 4.37C6.01027 2.97 7.1775 1.82 8.62117 1.82C10.0648 1.82 11.2321 2.96 11.2321 4.37V9.47C11.2321 10.88 10.0648 12.02 8.62117 12.02C7.1775 12.02 6.01027 10.88 6.01027 9.47V4.37Z" fill="#626262"/>
        </Svg>
    );
}

export const File = (props: any) => {
    return (
        <Svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path fill-rule="evenodd" clip-rule="evenodd" d="M18.921 1.32418L18.8186 1.23418C16.433 -0.665819 12.9006 -0.335819 10.9143 1.97418L1.71982 12.6742C1.02358 13.4842 0.327341 14.9042 0.439967 16.1042C0.542355 17.2342 1.08501 18.2642 1.98603 18.9942C2.8768 19.7242 4.01331 20.0742 5.1703 19.9742C6.33752 19.8742 7.38188 19.3342 8.13955 18.4642L16.0746 9.23418C16.6173 8.59418 16.8732 7.76418 16.7709 6.93418C16.6787 6.17418 16.2896 5.49418 15.6958 5.00418C15.0712 4.49418 14.2726 4.24418 13.4535 4.31418C12.6344 4.38418 11.8972 4.75418 11.3648 5.37418L5.28292 12.4442C5.12934 12.6242 5.04743 12.8542 5.06791 13.1042C5.10886 13.5942 5.54913 13.9642 6.06107 13.9142C6.31704 13.8942 6.53206 13.7742 6.68564 13.5942L12.7675 6.51418C12.9825 6.26418 13.2999 6.11418 13.6275 6.09418C13.945 6.07418 14.2624 6.15418 14.5183 6.36418C14.7641 6.56418 14.9176 6.83418 14.9484 7.14418C14.9893 7.48418 14.8869 7.81418 14.6719 8.07418L6.73683 17.3042C6.29656 17.8142 5.69247 18.1242 5.01671 18.1842C4.34095 18.2442 3.68567 18.0342 3.16349 17.6142C2.64131 17.1942 2.32391 16.5942 2.27271 15.9342C2.22152 15.3842 2.62083 14.4042 3.13277 13.8042L12.3272 3.10418C13.6787 1.53418 16.0644 1.32418 17.6821 2.61418L17.7845 2.70418C19.3408 4.04418 19.5353 6.36418 18.1941 7.92418L10.5866 16.7742C10.433 16.9542 10.3511 17.1842 10.3716 17.4342C10.4126 17.9242 10.8631 18.2942 11.3648 18.2442C11.6207 18.2242 11.8358 18.1042 11.9893 17.9242L19.5968 9.06418C21.5831 6.76418 21.2862 3.29418 18.921 1.32418Z" fill="#626262"/>
        </Svg>

    );
}

export const Camera = (props: any) => {
    return (
        <Svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path d="M19.1604 2.47H16.785L16.1706 0.95C15.9351 0.37 15.3618 0 14.7167 0H7.28335C6.64854 0 6.06493 0.38 5.83968 0.95L5.22535 2.47H2.84995C1.69296 2.47 0.76123 3.35 0.76123 4.44V15.02C0.76123 16.11 1.69296 17 2.84995 17H19.1604C20.3071 17 21.2388 16.11 21.2388 15.02V4.44C21.2388 3.35 20.3071 2.47 19.1604 2.47ZM19.7235 15.02C19.7235 15.29 19.4675 15.51 19.1604 15.51H2.84995C2.53254 15.51 2.27657 15.29 2.27657 15.02V4.44C2.27657 4.17 2.53254 3.95 2.84995 3.95H5.74753C6.05469 3.95 6.34138 3.76 6.45401 3.48L7.29359 1.48H14.7167L15.5563 3.48C15.6689 3.76 15.9556 3.95 16.2628 3.95H19.1604C19.4778 3.95 19.7235 4.17 19.7235 4.44V15.02ZM11 5C8.31747 5 6.1366 7.13 6.1366 9.75C6.1366 12.37 8.31747 14.5 11 14.5C13.6826 14.5 15.8635 12.37 15.8635 9.75C15.8635 7.13 13.6826 5 11 5ZM11 13.03C9.15705 13.03 7.65195 11.56 7.65195 9.76C7.65195 7.96 9.15705 6.49 11 6.49C12.8533 6.49 14.3481 7.96 14.3481 9.76C14.3481 11.56 12.8533 13.03 11 13.03Z" fill="#626262"/>
        </Svg>
    );
}

export const Image = (props: any) => {
    return (
        <Svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path fill-rule="evenodd" clip-rule="evenodd" d="M13.6917 4.27C14.3573 4.27 14.8897 4.8 14.8897 5.44C14.8897 6.08 14.347 6.61 13.6917 6.61C13.0365 6.61 12.4938 6.08 12.4938 5.44C12.4938 4.8 13.0262 4.27 13.6917 4.27ZM13.6917 3.1C12.3709 3.1 11.2959 4.15 11.2959 5.44C11.2959 6.73 12.3709 7.78 13.6917 7.78C15.0125 7.78 16.0876 6.73 16.0876 5.44C16.0876 4.15 15.0125 3.1 13.6917 3.1ZM17.3777 0C19.1388 0 20.5722 1.4 20.5722 3.12V16.92C20.5722 18.62 19.1593 20 17.4187 20H3.24816C1.50756 20 0.0946045 18.62 0.0946045 16.92V3.12C0.0946045 1.4 1.52804 0 3.28911 0H17.3777ZM18.975 16.88C18.975 17.74 18.2582 18.44 17.3777 18.44H3.28911C2.40857 18.44 1.69186 17.74 1.69186 16.88V16.82L6.85222 9.34L11.0911 15.48C11.2344 15.7 11.4904 15.83 11.7566 15.83C12.0228 15.83 12.2685 15.7 12.4221 15.48L15.2378 11.4L18.975 16.81V16.88V16.88ZM18.975 14.02L15.9033 9.57C15.76 9.35 15.504 9.22 15.2378 9.22C14.9716 9.22 14.7259 9.35 14.5723 9.57L11.7566 13.65L7.51774 7.5C7.37439 7.28 7.11842 7.15 6.86245 7.15C6.59625 7.15 6.35051 7.28 6.19693 7.5L1.69186 14.02V3.12C1.69186 2.26 2.40857 1.56 3.28911 1.56H17.3675C18.248 1.56 18.9647 2.26 18.9647 3.12V14.02H18.975Z" fill="#626262"/>
        </Svg>

    );
} 

export const ArrowForward = (props: any) => {
    return (
        <Svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path d="M1.4 17.79C0.859995 18.3 0.859995 19.13 1.39999 19.63C1.93999 20.14 2.81999 20.14 3.35999 19.63L12.59 10.93C13.13 10.42 13.13 9.58995 12.59 9.08995L3.36 0.389951C2.82 -0.120049 1.94 -0.120049 1.4 0.389951C0.859997 0.899951 0.859996 1.72995 1.4 2.22995L9.65 10.02L1.4 17.79Z" fill="#FF7A33"/>
        </Svg>

    );
}

export const SearchIcon = (props: any) => {
    return (
        <Svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path fill-rule="evenodd" clip-rule="evenodd" d="M11.3764 9.47687C13.0463 7.13367 12.8302 3.85958 10.7279 1.75736C8.38479 -0.585787 4.5858 -0.585786 2.24265 1.75736C-0.100496 4.1005 -0.100496 7.89949 2.24265 10.2426C4.34488 12.3449 7.61896 12.561 9.96216 10.8911L15.6777 16.6066L17.0919 15.1924L11.3764 9.47687ZM9.31372 3.17157C10.8758 4.73367 10.8758 7.26633 9.31372 8.82843C7.75162 10.3905 5.21896 10.3905 3.65686 8.82843C2.09477 7.26633 2.09477 4.73367 3.65686 3.17157C5.21896 1.60948 7.75162 1.60948 9.31372 3.17157Z" fill="#626262"/>
        </Svg>

    );
}