import { observer, useLocalObservable } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect } from 'react'
import { runInAction } from 'mobx'
import { API } from '../../api/API'
import { extractErrorMessage, MYSQL_DATE_TIME_FORMAT, utc, getExtension, openExternalUrl } from '../../common/Util'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { ScrollView, StyleSheet, TouchableOpacity, View, Platform, Pressable, Image, Text } from 'react-native'
import { WebImage } from '../../components/WebImage'
import { VideoPreview } from '../../components/VideoPreview'
import { AudioPreview } from '../../components/FeedPost/AudioPreview'
import { ContentActivityIndicator } from '../../components/ContentActivityIndicator'
import { ErrorMessage } from '../../components/ErrorMessage'
import { Event, EventAttachments } from '../../api/schema/models/Event'
import moment from 'moment-timezone'
import { DefaultText } from '../../components/DefaultText'
import { Colors } from '../../common/Colors'
import { BoldText } from '../../components/BoldText'
import { getModalManager } from '../../contexts/ModalContext'
import { MaterialIcons } from '@expo/vector-icons'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { FilePreview } from '../../components/FilePreview'
import { NewBackArrowSvg } from '../../../assets/images/back-arrow-svg'
import { AppColors } from '../../common/AppColors'
import { ProfileImage } from '../../components/ProfileImage'
import { RelativeTime } from '../../components/RelativeTime'
import { CalendarIcon } from '../../components/tab_bar/TabIcon'
import { Menu, MenuOption, MenuOptions, MenuTrigger } from 'react-native-popup-menu'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { KonektiIcon } from '../../components/KonektiIcon'
import Icons from '../../components/icons'
import { DefaultTextAutoLink } from '../../components/DefaultTextAutoLink'
//import * as Sentry from 'sentry-expo'

export type EventDetailScreenParams = {
  eventId: number | null
  startsAt: string | null
}

export const EventDetailScreen = observer((props: StackScreenProps<RootNavigationParamList, 'EventDetail'>) => {
  const { t } = useTranslation()

  const propsState = useLocalObservable(() => ({
    startsAt: props.route.params.startsAt,
  }))

  useEffect(() => {
    runInAction(() => propsState.startsAt = props.route.params.startsAt)
  }, [props.route.params.startsAt])

  const state = useLocalObservable(() => ({
    event: undefined as Event | undefined,
    translateEvent: undefined as Event | undefined,
    loading: false,
    isLoadingTranslation: false,
    showTranslation: false,
    error: undefined as string | undefined,
    titleMultiline: false,
    get startsAt() {
      return moment(propsState.startsAt, MYSQL_DATE_TIME_FORMAT)
    },
    get endsAt() {
      if (!this.event) {
        return moment(propsState.startsAt)
      }

      if (propsState.startsAt === this.event.startsAt) {
        return moment(this.event.endsAt, MYSQL_DATE_TIME_FORMAT)
      } else {
        return this.startsAt.clone().add(moment(this.event.endsAt).diff(moment(this.event.startsAt, MYSQL_DATE_TIME_FORMAT), 'minutes'), 'minutes')
      }
    },
    get dateSpanString() {
      if (this.startsAt.isSame(this.endsAt, 'date')) {
        return `${this.startsAt.format('MM/DD/YYYY')}  ${this.startsAt.format('h:mm A')} - ${this.endsAt.format('h:mm A')}`
      } else {
        return `${this.startsAt.format('MM/DD/YYYY')} ${this.startsAt.format('h:mm A')} - ${this.endsAt.format('MM/DD/YYYY')} ${this.endsAt.format('h:mm A')}`
      }
    },
    get visibilityDescription() {
      if (this.event?.visibility === 'district') {
        return this.event.districtName ?? ''
      } else if (this.event?.visibility === 'school') {
        return this.event.schoolName ?? ''
      } else if (this.event?.visibility === 'section') {
        return this.event.sectionName ?? ''
      } else if (this.event?.visibility === 'grade') {
        return this.event.gradeLevelName ?? ''
      } else if (this.event?.visibility === 'community') {
        return this.event.communityName ?? ''
      } else if (this.event?.visibility === 'private') {
        return 'Closed Event'
      }
      else {
        return 'Calendar'
      }
    },
  }))

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: t('Event', 'Event'),
      headerStyle: { backgroundColor: AppColors.white, borderBottomWidth: 0 },
      headerTitleStyle: { color: AppColors.black.primary },
      headerBackImage: () => <NewBackArrowSvg></NewBackArrowSvg>,
      headerRight: () => {
        return (
          <View style={styles.translateButtonContainer}>
            <TouchableOpacity
              onPress={translateAll}
            >
              <KonektiIcon name={'translate-icon'} size={20} color={'#ff8d2d'} />
            </TouchableOpacity>
          </View>
        );
      }
    })
  }, [props.navigation, state.event])

  const loadEvent = useCallback(async () => {
    runInAction(() => {
      state.loading = true
      state.error = undefined
    })

    try {
      const response = await API.getEvent(props.route.params.eventId)
      runInAction(() => state.event = response.event)
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [props.route.params.eventId, state])

  useEffect(() => {
    loadEvent().then()
  }, [loadEvent])

  useEffect(() => {
    const listeners = [
      AppStateStore.eventBus.addRemovableListener(AppEvents.EventCreated, () => {
        loadEvent().then()
      }),
      AppStateStore.eventBus.addRemovableListener(AppEvents.EventDeleted, () => {
        props.navigation.pop()
      }),
    ]

    return () => {
      listeners.forEach(l => l.remove())
    }
  }, []);

  const onTextLayout = (e: any) => {
    if (e.nativeEvent.lines.length > 1)
      runInAction(() => state.titleMultiline = true)
  }

  const renderModalImage = (uri: string | null): React.ReactElement => {
    if (!uri) return <></>;

    return (
      <>
        <Image
          style={{
            width: '100%',
            height: '100%',
            resizeMode: 'cover',
          }}
          source={{ uri }}
        />
      </>
    );
  }

  const translateAll = async () => {
    let translate = !state.showTranslation

    runInAction(() => state.isLoadingTranslation = true)

    if (translate && !state.translateEvent) {
      try {
        const translation = await API.getTranslation(state.event, AppStateStore.userLanguage)
        runInAction(() => {
          state.translateEvent = translation.translation
        })
        console.log(state)
      } catch (err) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(err)
        }
        else {
          //Sentry.Native.captureException(err)
        }
        getModalManager()
          .showModal({
            title: t('Error', 'Error'),
            message: t('There was an error loading the translation', 'There was an error loading the translation'),
          })
        translate = false
      }
    }

    runInAction(() => {
      state.isLoadingTranslation = false
      state.showTranslation = translate
    })
  }

  const renderAttachments = (attachments: EventAttachments) => {
    if (attachments.imageUrl) {
      return <View style={styles.attachmentSection}>
        <Pressable onPress={() => {
          getModalManager()
            .showModal({
              type: 'content',
              showClosse: true,
              children: renderModalImage(attachments.imageUrl)
            })
        }} style={styles.imageAttachmentContainer}>
          <WebImage
            style={{ width: '100%' }}
            imageStyle={{ borderRadius: 24, overflow: 'hidden' }}
            shadowStyle={{
              shadowRadius: 24,
              shadowColor: '#000',
              shadowOffset: { width: 4, height: 8 },
              shadowOpacity: Platform.OS === 'android' ? 1 : 0.75, elevation: 20
            }}
            uri={attachments.imageUrl}
            maxHeight={240}
          />
        </Pressable>
      </View>
    } else if (attachments.videoUuid) {
      return <View style={styles.attachmentVideoSection}>
        <View style={styles.videoAttachmentContainer}>
          {/* <VideoPreview
            style={{width: '100%'}}
            imageStyle={{borderRadius: 24, overflow: 'hidden'}}
            maxWidth={850}
            fillAll={false}
            shadowStyle={{ shadowRadius: 24, 
              shadowColor: '#000', 
              shadowOffset: {width: 4, height: 8}, 
              shadowOpacity: Platform.OS === 'android' ? 1 : 0.75, elevation: 20}}
            posterImageUrl={attachments.videoThumbnailUrl!}
            onPress={() => props.navigation.push('VideoPlayer', { uuid: attachments.videoUuid! })}
            resizeMode="cover"
          /> */}
          <VideoPreview
            style={StyleSheet.absoluteFillObject}
            shadowStyle={{
              borderRadius: 24, shadowRadius: 24,
              shadowColor: '#000',
              shadowOffset: { width: 4, height: 8 },
              shadowOpacity: Platform.OS === 'android' ? 1 : 0.75, elevation: 20
            }}
            borderStyle={{ borderRadius: 24 }}
            posterImageUrl={attachments.videoThumbnailUrl!}
            onPress={() => props.navigation.push('VideoPlayer', { uuid: attachments.videoUuid! })}
            resizeMode="cover"
          />
        </View>
      </View>
    } else if (attachments.audioUrl) {
      return <View style={styles.section}>
        <DefaultText style={styles.sectionHeaderText}>Attachment</DefaultText>
        <View style={styles.audioAttachmentContainer}>
          <AudioPreview
            onPress={() => props.navigation.push('AudioPlayer', { uuid: attachments.audioUuid! })}
          />
        </View>
      </View>
    } else if (attachments.fileUuid) {
      return <View style={styles.attachedFileContainer}>
        <FilePreview
          downloable={true}
          fileUrl={attachments.fileUrl!}
          fileType={attachments.fileType!}
          fileName={attachments.fileName!}
          onPress={() => getFileAttachment(attachments.fileType!, attachments.fileUrl!, attachments.fileName!)}
        />
      </View>
    } else {
      return null
    }
  }

  const getFileAttachment = (mimeType: string, fileUrl: string, fileName: string) => {
    const ext = getExtension(fileName)
    if (Platform.OS === 'web' || Platform.OS === 'android') {
      if (mimeType === 'application/pdf' || ext === 'pdf') {
        props.navigation.push('DocumentViewer', { url: fileUrl!, title: fileName! })
      } else {
        openExternalUrl(fileUrl!)
      }
    } else {
      if (mimeType === 'application/pdf' || ext === 'docx' || ext === 'doc' || ext === 'PNG' || ext === 'png' || ext === 'jpg') {
        props.navigation.push('DocumentViewer', { url: fileUrl!, title: fileName! })
      } else {
        openExternalUrl(fileUrl!)
      }

    }


  }

  const visibilityDescription = (event: Event) => {
    if (event.visibility === 'district') {
      return event.districtName
    } else if (event.visibility === 'school') {
      return event.schoolName
    } else if (event.visibility === 'section') {
      return event.sectionName
    } else if (event.visibility === 'grade') {
      return event.gradeLevelName
    } else if (event.visibility === 'community') {
      return event.communityName
    } else if (event.visibility === 'private') {
      return 'Closed Event'
    }
    else {
      return '---'
    }
  }

  const canEditEvent = () => {
    const userContext = AppStateStore.userContext
    const event = state.event

    if (!userContext || !event) {
      return false
    }

    if (userContext.userType === 'teacher' && userContext.teacher) {
      if (userContext.teacher.role === 'district_director') {
        if (state.event!.districtId === userContext.teacher.districtId) {
          return true
        }
      } else if (userContext.teacher.role === 'school_director') {
        if (state.event!.schoolId === userContext.teacher.schoolId) {
          return true
        }
      } else if (state.event!.userId === userContext.id) {
        return true
      }
    }

    return false
  }

  const editEvent = () => {
    props.navigation.push('EditEvent', { eventId: state.event!.id })
  }

  const submitDeleteEvent = async () => {
    AppStateStore.showActivityIndicator()

    try {
      await API.deleteEvent(state.event!.id)
      AppStateStore.eventBus.emit(AppEvents.EventDeleted)
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      getModalManager()
        .showModal({
          title: t('Error', 'Error'),
          message: extractErrorMessage(err.response),
        })
    }

    AppStateStore.hideActivityIndicator()
  }

  const deleteEvent = () => {
    getModalManager()
      .showModal({
        title: t('Delete Event', 'Delete Event'),
        message: t('Are you sure you want to delete this event', 'Are you sure you want to delete this event?'),
        buttons: [
          {
            text: t('Yes Delete', 'Yes, Delete'),
            variant: 'danger',
            onPress: dismiss => {
              dismiss()
              submitDeleteEvent().then()
            },
          },
          {
            text: t('No', 'No'),
            variant: 'secondary',
          },
        ]
      })
  }

  return <View style={styles.container}>
    <View style={styles.content}>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          flexGrow: 1
        }}
      >
        {
          state.loading
            ? <ContentActivityIndicator />
            : state.error
              ? <ErrorMessage message={state.error} />
              : state.event
                ? <>
                  {
                    renderAttachments(state.event.attachments)
                  }
                  <View style={styles.header}>
                    <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: 50, alignSelf: 'center', margin: 12, marginBottom: state.titleMultiline ? 30 : 12 }}>
                      <Text style={[{ fontFamily: 'Rubik_700Bold', alignItems: 'center', justifyContent: 'center' }, styles.titleText]} onTextLayout={onTextLayout}>
                        {state.showTranslation ? state.translateEvent?.title : state.event.title}
                      </Text>
                      {/* <BoldText style={[styles.titleText, { alignItems: 'center', justifyContent: 'center' }]}>
                        {state.event.title}
                      </BoldText> */}
                      {
                        canEditEvent()
                          ? <View style={styles.editButtonContainer}>
                            <Menu>
                              <MenuTrigger>
                                <MaterialCommunityIcons name={'dots-vertical'} style={styles.editButtonIcon} />
                              </MenuTrigger>
                              <MenuOptions customStyles={{
                                optionsContainer: { width: 100 },
                              }}>
                                <MenuOption onSelect={() => editEvent()} style={{ padding: 12 }}>
                                  <DefaultText style={{ fontSize: 16 }}>Edit</DefaultText>
                                </MenuOption>
                                <MenuOption onSelect={() => deleteEvent()} style={{ padding: 12 }}>
                                  <DefaultText style={{ fontSize: 16, color: Colors.DangerTextColor }}>Delete</DefaultText>
                                </MenuOption>
                              </MenuOptions>
                            </Menu>
                          </View>
                          : null
                      }
                    </View>
                    <View style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                      <Icons.Calendar color={AppColors.orange.primary} height={23} width={23} variant={'outline'} />
                      <DefaultText style={styles.dateText}>
                        {state.dateSpanString}
                      </DefaultText>
                    </View>
                    {
                      state.event.location
                        ? <View style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 10 }}>
                          <Icons.LocationIcon color={AppColors.orange.primary} height={23} width={23} />

                          <DefaultText style={styles.locationText}>{state.showTranslation ? state.translateEvent?.location : state.event.location}</DefaultText>
                        </View>
                        : null
                    }
                  </View>
                  <View style={[styles.profileSection, { flexDirection: 'row' }]}>
                    <ProfileImage size={40} url={state.event.profilePhotoUrl} />
                    <View style={styles.metaContainer}>
                      <DefaultText style={styles.name}>{state.event.displayName}</DefaultText>
                      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                        <DefaultText style={styles.meta}>{visibilityDescription(state.event)} - </DefaultText>
                        <DefaultText style={styles.meta}><RelativeTime date={utc(state.event.createdAt)} /></DefaultText>
                      </View>
                    </View>
                  </View>
                  <View style={styles.main}>
                    {
                      state.event.description
                        ? <View style={styles.section}>
                          <DefaultTextAutoLink style={styles.sectionText}>
                            {state.showTranslation && state.translateEvent?.description ? state.translateEvent?.description : state.event.description}
                          </DefaultTextAutoLink>
                        </View>
                        : null
                    }
                  </View>
                </>
                : null
        }
      </ScrollView>
    </View>
  </View>
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  content: {
    flex: 1,
  },
  header: {
    paddingHorizontal: 24
  },
  profileSection: {
    marginTop: 16,
    paddingHorizontal: 24
  },
  titleText: {
    textAlign: 'left',
    color: AppColors.gray.neutral1,
    fontSize: 24,
    marginBottom: 4,
    display: 'flex',
  },
  visibility: {
    borderRadius: 15,
    paddingHorizontal: 24,
    paddingVertical: 4,
    marginBottom: 4,
  },
  visibilityText: {
    fontSize: 16,
  },
  dateText: {
    fontSize: 16,
    marginLeft: 5
  },
  main: {
    flex: 1,
    backgroundColor: '#fff',
  },
  imageAttachmentContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
  },
  videoAttachmentContainer: {
    //height: 400,
    //marginTop: 16,
    width: '95%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
    height: 300,
  },
  audioAttachmentContainer: {
    marginTop: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  attachedFileContainer: {
    marginTop: 16,
    alignItems: 'flex-start',
    //justifyContent: 'center',
    marginHorizontal: 15
  },
  pendingAttachmentsText: {
    color: '#f00',
    padding: 10,
    textAlign: 'center',
  },
  section: {
    borderBottomWidth: 0,
    borderBottomColor: '#ccc',
    padding: 12,
  },
  attachmentSection: {
    borderBottomWidth: 0,
    borderBottomColor: '#ccc',
    justifyContent: 'center',
    height: 300
  },
  attachmentVideoSection: {
    borderBottomWidth: 0,
    borderBottomColor: '#ccc',
    justifyContent: 'center',
    alignItems: 'center',
    height: 300,
    marginBottom: 24
  },
  sectionHeaderText: {
    color: Colors.PrimaryOrange,
    marginBottom: 4,
  },
  sectionText: {
    paddingHorizontal: 16,
    fontSize: 16,
  },
  locationText: {
    fontSize: 14,
  },
  headerButton: {
    padding: 10,
  },
  headerIcon: {
    fontSize: 20,
    color: '#FF7A33',
  },
  metaContainer: {
    marginLeft: 8,
    flex: 1,
  },
  name: {
    fontSize: 16,
    color: AppColors.gray.neutral1,
    fontWeight: '600',
    fontFamily: 'Rubik_700Bold',
    marginBottom: 3
  },
  meta: {
    color: AppColors.gray.neutral2,
    fontWeight: '500',
    fontSize: 13,
  },
  editButtonContainer: {
    height: '100%',
    marginLeft: 'auto',
    alignItems: 'flex-end',
    justifyContent: 'center',
    display: 'flex',
    flex: 1,
  },
  editButtonIcon: {
    minWidth: 30,
    minHeight: 30,
    fontSize: 28,
    color: '#666',
  },
  translateButtonContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 15
  },
})
