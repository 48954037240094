import { StyleSheet } from "react-native";

export const buttonStyles = StyleSheet.create({
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 20, 
        marginVertical: 15,
        borderRadius: 6,
        paddingVertical: 18, 
        marginBottom: 20,
        elevation: 10,
        shadowColor: 'grey',
        shadowOffset: {
          width: 5,
          height: 5
        },
        shadowOpacity: .4,
        shadowRadius:10
      }
});