import React from 'react'
import { Svg, Path } from "react-native-svg"

type Props = {
  size: number
  color: string
}

const InfoIcon = (props: Props) => {
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 24 24" >
      <Path
        d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM13.9 17.3C13.8 17.6 13.5 17.8 13.2 17.8H10.5C10 17.8 9.7 17.4 9.8 16.9L11 11.9H9.5L9.9 10.3C9.9 10 10.2 9.8 10.6 9.8H13.3C13.8 9.8 14.1 10.2 14 10.7L12.8 15.7H14.3L13.9 17.3ZM14.4 7.7C14.3 7.9 14.2 8 14.1 8.1C14 8.2 13.8 8.3 13.7 8.4C13.5 8.5 13.4 8.5 13.2 8.5C13 8.5 12.8 8.5 12.7 8.4C12.5 8.3 12.4 8.2 12.3 8.1C12.2 8 11.9 7.9 11.9 7.7C11.8 7.6 11.8 7.4 11.8 7.2C11.8 7 11.8 6.8 11.9 6.7C12 6.5 12.1 6.4 12.2 6.3C12.3 6.2 12.5 6.1 12.6 6C12.7 5.9 12.9 5.9 13.1 5.9C13.3 5.9 13.5 5.9 13.6 6C13.7 6.1 13.9 6.2 14 6.3C14.1 6.4 14.2 6.6 14.3 6.7C14.4 6.9 14.4 7 14.4 7.2C14.4 7.4 14.5 7.6 14.4 7.7Z"
        fill={props.color} />
    </Svg>
  )
}

export default InfoIcon
