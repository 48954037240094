import React from "react";
import { Svg, Path } from 'react-native-svg';

interface IconProps {
    color: string,
    height: number,
    width: number
}

export default (props: IconProps) => {
    return (
        <Svg {...props} viewBox="0 0 21 21" fill="none">
            <Path d="M19.4177 6.23132C19.4166 6.2509 19.4161 6.27051 19.4161 6.29013V13.9179C19.4161 13.9375 19.4166 13.9571 19.4177 13.9767C19.4888 15.3057 19.0615 16.5915 18.2638 17.5361C17.4709 18.475 16.3907 18.9885 15.2846 19.004H5.25255C2.95088 19.004 1.14773 16.9424 1.14773 13.9179V6.29013C1.14773 3.26565 2.95088 1.204 5.25255 1.204H15.2846C16.3907 1.21952 17.4708 1.73297 18.2638 2.67188C19.0615 3.61649 19.4888 4.90229 19.4177 6.23132Z" stroke="#FF7A33" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
        </Svg>

    );
}