import { StyleProp, Text, TextStyle } from 'react-native'
import React from 'react'

type Props = {
  style?: StyleProp<TextStyle>
  children?: React.ReactNode
}

const onTextLayout = (e: any) => {
  console.log(e.nativeEvent.lines.length)
}

export const BoldText = (props: Props) => {
  const style: StyleProp<TextStyle> = {
    fontFamily: 'Rubik_700Bold',
  }

  return <Text style={[style, props.style]} onTextLayout={onTextLayout}>{props.children}</Text>
}
