import React from 'react'
import { Svg, Path } from "react-native-svg"

type Props = {
  width: number
  height: number
  color: string,
  variant: 'fill' | 'outline'
}
export default function Settings(props: Props) {
  return (
    <Svg
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 24 24" >
      {props.variant === 'fill' ? (
        <>
          <Path
            d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
            fill={props.color} />
          <Path
            d="M20 11.82V8.18C18.21 7.54 17.73 7.51 17.48 6.91C17.23 6.3 17.55 5.94 18.36 4.22L15.78 1.64C14.09 2.44 13.71 2.78 13.09 2.52C12.49 2.27 12.46 1.78 11.82 0H8.18C7.54 1.79 7.51 2.27 6.9 2.52C6.27 2.78 5.9 2.44 4.21 1.64L1.64 4.22C2.45 5.93 2.78 6.29 2.52 6.9C2.27 7.51 1.79 7.54 0 8.18V11.83C1.78 12.46 2.27 12.5 2.52 13.1C2.77 13.71 2.45 14.07 1.64 15.79L4.22 18.37C5.89 17.58 6.28 17.23 6.91 17.49C7.52 17.74 7.55 18.22 8.18 20.01H11.83C12.46 18.23 12.5 17.74 13.11 17.48C13.73 17.22 14.1 17.56 15.79 18.37L18.37 15.79C17.56 14.08 17.23 13.71 17.49 13.1C17.73 12.49 18.21 12.46 20 11.82ZM10 14.17C7.7 14.17 5.83 12.31 5.83 10C5.83 7.7 7.7 5.83 10 5.83C12.3 5.83 14.17 7.7 14.17 10C14.17 12.3 12.3 14.17 10 14.17Z"
            fill={props.color} />
        </>
      ) : (
        <Path
          d="M18 10.6403V7.35975C16.389 6.78525 15.9555 6.75825 15.7297 6.213C15.5025 5.66625 15.792 5.33625 16.524 3.79575L14.2042 1.476C12.6847 2.19825 12.3383 2.499 11.7863 2.27025C11.241 2.04375 11.2102 1.6035 10.641 0H7.35975C6.78675 1.608 6.75975 2.04375 6.213 2.27025C5.649 2.505 5.31075 2.19525 3.79575 1.476L1.476 3.79575C2.20875 5.337 2.4975 5.6655 2.27025 6.21375C2.04375 6.759 1.6095 6.78675 0 7.35975V10.641C1.60425 11.211 2.04375 11.241 2.27025 11.787C2.49825 12.3375 2.2095 12.6622 1.476 14.2042L3.79575 16.524C5.295 15.8115 5.64825 15.4942 6.213 15.7297C6.759 15.9562 6.786 16.3897 7.35975 18H10.6403C11.2088 16.4017 11.2395 15.9577 11.793 15.7267C12.3517 15.4957 12.6825 15.801 14.2042 16.5233L16.524 14.2035C15.7927 12.666 15.5025 12.3345 15.7297 11.7863C15.9547 11.2418 16.3897 11.214 18 10.6403V10.6403ZM14.3438 11.2133C13.911 12.2588 14.2928 13.0567 14.7098 13.8967L13.8967 14.7098C13.077 14.3025 12.276 13.9027 11.217 14.3422C10.17 14.778 9.8745 15.612 9.576 16.5H8.4255C8.127 15.6113 7.83225 14.7773 6.78825 14.3438C5.724 13.9028 4.908 14.31 4.104 14.7098L3.29175 13.8967C3.7095 13.0582 4.09125 12.2618 3.657 11.2118C3.22275 10.1685 2.38875 9.87375 1.5 9.57525V8.4255C2.38875 8.127 3.22275 7.83225 3.65625 6.7875C4.08975 5.742 3.70725 4.94325 3.29025 4.10325L4.10325 3.29025C4.91475 3.69375 5.72475 4.098 6.78825 3.65625C7.83225 3.2235 8.127 2.38875 8.4255 1.5H9.576C9.8745 2.38875 10.17 3.22275 11.214 3.65625C12.2782 4.09725 13.0935 3.69 13.8983 3.29025L14.7112 4.10325C14.2942 4.944 13.9125 5.7435 14.3453 6.78825C14.778 7.8315 15.612 8.12625 16.5015 8.4255V9.576C15.6105 9.8745 14.775 10.1693 14.3438 11.2133V11.2133ZM9 6.75C10.2405 6.75 11.25 7.7595 11.25 9C11.25 10.2405 10.2405 11.25 9 11.25C7.7595 11.25 6.75 10.2405 6.75 9C6.75 7.7595 7.7595 6.75 9 6.75ZM9 5.25C6.9285 5.25 5.25 6.9285 5.25 9C5.25 11.0715 6.9285 12.75 9 12.75C11.0715 12.75 12.75 11.0715 12.75 9C12.75 6.9285 11.0715 5.25 9 5.25Z"
          fill={props.color} />
      )
      }

    </Svg>
  )
}
