import React from 'react'
import { Svg, Path } from "react-native-svg"

type Props = {
  width: number
  height: number
  color: string,
  variant: 'fill' | 'outline'
}
export default function Home(props: Props) {
  return (
    <Svg
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 21 20" >
      {props.variant === 'fill' ? (
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d="M16.3385 4.05V0.77C16.3385 0.68 16.3585 0.59 16.3885 0.5C16.4185 0.41 16.4685 0.34 16.5285 0.27C16.6485 0.13 16.8085 0.04 16.9985 0.01C17.0085 0.01 17.0285 0.01 17.0485 0C17.0685 0 17.0885 0 17.0985 0C17.1185 0 17.1385 0 17.1685 0C17.3385 0 17.5085 0.07 17.6385 0.19C17.8085 0.34 17.8985 0.56 17.8985 0.78V4.06C17.8985 4.25 17.8285 4.42 17.7085 4.56C17.5885 4.7 17.4285 4.79 17.2385 4.83H17.2085C17.1585 4.83 17.1285 4.84 17.1385 4.84C16.6885 4.81 16.3385 4.47 16.3385 4.05ZM19.6985 7.81C19.6985 7.81 19.6885 7.81 19.6985 7.81L10.4385 1.27C10.3085 1.18 10.1585 1.13 9.9985 1.13C9.8385 1.13 9.68851 1.18 9.55851 1.27L0.308505 7.81C0.138505 7.92 0.0385046 8.09 0.0085046 8.29C-0.0214954 8.48 0.0285046 8.67 0.148505 8.83C0.388505 9.15 0.858505 9.22 1.1885 8.99L2.1585 8.31V12.23V19.27C2.1585 19.67 2.4985 20 2.9085 20H7.9785C8.3685 20 8.6885 19.69 8.7285 19.28V14.39C8.7285 13.71 9.30851 13.16 10.0085 13.16C10.7185 13.16 11.2885 13.71 11.2885 14.39V19.34C11.3185 19.71 11.6485 20 12.0285 20H17.0985C17.5085 20 17.8485 19.67 17.8485 19.27V8.3L18.8185 8.98C19.1285 9.2 19.6185 9.13 19.8585 8.82C19.9785 8.66 20.0285 8.47 19.9985 8.28C19.9585 8.09 19.8585 7.93 19.6985 7.81Z"
          fill={props.color} />
      ) : (
        <Path
          d="M16.34 4.06001V0.78001C16.34 0.69001 16.36 0.60001 16.39 0.51001C16.42 0.42001 16.47 0.35001 16.53 0.28001C16.65 0.14001 16.81 0.0500098 17 0.0200098C17.01 0.0200098 17.03 0.0200098 17.05 0.0100098C17.07 0.0100098 17.09 0.0100098 17.1 0.0100098C17.12 0.0100098 17.14 0.0100098 17.17 0.0100098C17.34 0.0100098 17.51 0.0800098 17.64 0.20001C17.81 0.35001 17.9 0.57001 17.9 0.79001V4.07001C17.9 4.26001 17.83 4.43001 17.71 4.57001C17.59 4.71001 17.43 4.80001 17.24 4.84001H17.21C17.16 4.84001 17.13 4.85001 17.14 4.85001C16.69 4.82001 16.34 4.48001 16.34 4.06001ZM19.99 8.30001C20.02 8.49001 19.97 8.69001 19.85 8.84001C19.62 9.15001 19.13 9.22001 18.81 9.00001L17.84 8.32001V19.28C17.84 19.68 17.51 20.01 17.09 20.01H12.02C11.63 20.01 11.31 19.72 11.28 19.35V14.4C11.28 13.72 10.7 13.17 9.99998 13.17C9.28998 13.17 8.71998 13.72 8.71998 14.4V19.29C8.67998 19.69 8.35998 20 7.96998 20H2.89998C2.48998 20 2.14998 19.67 2.14998 19.27V12.23V8.31001L1.17998 8.99001C0.849978 9.23001 0.379978 9.16001 0.139978 8.83001C0.029978 8.68001 -0.020022 8.49001 0.00997801 8.30001C0.039978 8.10001 0.149978 7.93001 0.309978 7.82001L9.55998 1.29001C9.68998 1.19001 9.83998 1.14001 9.99998 1.14001C10.16 1.14001 10.31 1.19001 10.44 1.28001L19.69 7.81001C19.69 7.81001 19.7 7.81001 19.7 7.82001C19.86 7.94001 19.96 8.10001 19.99 8.30001ZM16.36 7.26001L10.09 2.83001C10.04 2.80001 9.96998 2.80001 9.91998 2.83001L3.63998 7.26001C3.63998 7.27001 3.64998 7.28001 3.64998 7.29001V12.23V18.39C3.64998 18.47 3.71998 18.54 3.79998 18.54H7.07998C7.15998 18.54 7.22998 18.47 7.22998 18.39V14.39C7.22998 12.91 8.47998 11.7 10.01 11.7C11.54 11.7 12.79 12.9 12.79 14.39V18.39C12.79 18.47 12.86 18.54 12.94 18.54H16.22C16.3 18.54 16.37 18.47 16.37 18.39V7.26001H16.36Z"
          fill={props.color} />
      )
      }

    </Svg>
  )
}

