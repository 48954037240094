import React, { useEffect } from 'react'
import { ActivityIndicator, Image, StyleSheet, View, Platform } from 'react-native'
import { AppStateStore } from '../contexts/AppStateStore'
import { API } from '../api/API'
import { useTranslation } from 'react-i18next'
import { getModalManager } from '../contexts/ModalContext'
import { StackScreenProps } from '@react-navigation/stack'
import { RootNavigationParamList } from '../navigation/RootNav'
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';
import { OwlieColor } from '../../assets/images/owlie-color'
//import * as Sentry from 'sentry-expo'

export const SplashScreen = (props: StackScreenProps<RootNavigationParamList, 'Splash'>) => {
  const { t } = useTranslation()

  useEffect(() => {
    loadUserContext().then()
  }, [])

  const loadUserContext = async () => {
    try {
      if (AppStateStore.authToken) {
        const userContext = await API.getUserContext()
        const allowedUrls = (await API.getAllowedLinks()).allowedLinks
        setTimeout(() => AppStateStore.setUserContext(userContext), 500)
        setTimeout(() => AppStateStore.setAllowedLinks(allowedUrls), 500)
      }
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      if (err.response && (err.response.status === 401 || err.response.status === 403)) {
        await AppStateStore.logout()
      } else {
        getModalManager()
          .showModal({
            title: t('Error', 'Error'),
            message: t('Error authenticating', 'There was an error authenticating your account'),
            buttons: [
              {
                text: t('Try Again', 'Try Again'),
                onPress: dismiss => {
                  dismiss()
                  loadUserContext()
                },
              },
              {
                text: t('Log Out', 'Log Out'),
                onPress: dismiss => {
                  dismiss()
                  AppStateStore.logout()
                },
                variant: 'secondary',
              },
            ],
          })
      }
    }
  }

  return <View style={styles.container}>
    <OwlieColor></OwlieColor>
    <ActivityIndicator
      size={'large'}
      color={'#fff'}
      animating={true}
    />
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FF7A33'
  },
  image: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  logo: {
    width: 200,
    height: 200,
    maxWidth: '50%',
    maxHeight: '50%',
    resizeMode: 'contain',
    marginBottom: 20,
  },
})
