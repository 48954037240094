import React from 'react'
import { Svg, Path } from "react-native-svg"

type Props = {
  width?: number
  height?: number
  color?: string
}

export default function EmptyMessages({
  width = 180,
  height = 130,
  color = '#000',
}: Props
) {
  return (
    <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}  >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M142.08 50.62C140.7 50.58 139.23 51.49 139.23 53.52C139.23 63.68 139.23 83.99 139.23 83.99H68.33V34.16C68.33 22.13 62.08 11.54 52.66 5.45C52.66 5.45 100.34 5.5 109.98 5.5C111.7 5.5 123.96 5.45 131.93 15.1C139.9 24.74 139.23 34.17 139.23 34.17C139.23 34.17 139.91 36.85 142.08 36.85C144.25 36.85 144.68 34.17 144.68 34.17C144.68 34.17 145.7 22.35 136.28 11.48C126.86 0.599999 111.96 0.05 110.33 0.05C94.38 0.05 34.17 0 34.17 0C15.33 0 0 15.33 0 34.16V86.71C0 88.22 1.22 89.44 2.73 89.44H62.88V127.27C62.88 128.78 64.1 130 65.61 130C67.12 130 68.34 128.78 68.34 127.27V89.44H76.36V127.27C76.36 128.78 77.58 130 79.09 130C80.6 130 81.82 128.78 81.82 127.27V89.44H141.97C143.48 89.44 144.7 88.22 144.7 86.71C144.7 86.71 144.7 64.59 144.7 53.52C144.68 51.37 143.43 50.66 142.08 50.62ZM62.88 83.98H5.45V34.16C5.45 18.33 18.33 5.45 34.16 5.45C49.99 5.45 62.87 18.33 62.87 34.16V83.98H62.88ZM177.27 41.19H79.57C78.06 41.19 76.84 42.41 76.84 43.92C76.84 45.43 78.06 46.65 79.57 46.65H153.46V71.26C153.46 74.57 156.15 77.26 159.46 77.26H174C177.31 77.26 180 74.57 180 71.26V43.92C180 42.42 178.78 41.19 177.27 41.19ZM174.55 71.26C174.55 71.56 174.31 71.8 174.01 71.8H159.47C159.17 71.8 158.93 71.56 158.93 71.26V46.65H174.56V71.26H174.55ZM36.89 20.91V29.27C36.89 30.78 35.67 32 34.16 32C32.65 32 31.43 30.78 31.43 29.27V20.91C31.43 19.4 32.65 18.18 34.16 18.18C35.67 18.18 36.89 19.4 36.89 20.91Z"
        fill={color} />
    </Svg>
  )
}