import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'
import React from 'react'
import { DocumentIcon } from '../DocumentIcon'
import { DefaultText } from '../DefaultText'
import { getExtension } from '../../common/Util'

interface Props {
  fileName: string
  fileType: string
  onPress?: () => void
  style?: StyleProp<ViewStyle>
  disabled?: boolean
  sentMessage?: boolean
}

export const FilePreview = (props: Props) => {
  const ext = getExtension(props.fileName)!
  return <TouchableOpacity onPress={props.onPress} style={[
    styles.container,
    props.sentMessage ? { backgroundColor: '#0071b3' } : { backgroundColor: '#d9d9d9' },
  ]}
    disabled={props.disabled}>
    <View style={[styles.wrapper]}>
      <DocumentIcon type={ext} />
      <DefaultText style={[{ marginLeft: 5 }, props.sentMessage && { color: 'white' }]}>{props.fileName}</DefaultText>
    </View>
  </TouchableOpacity>
}

const styles = StyleSheet.create({
  container: {
    //backgroundColor: '#0071b3',
    padding: 8,
    borderRadius: 8,
  },
  wrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'center'
  },
})
