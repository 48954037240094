import React from 'react'
import { Svg, Path } from "react-native-svg"

type Props = {
  width: number
  height: number
  color: string
}

export default function ForWard(props: Props) {
  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 13 21" >
      <Path
        d="M1.4 17.79C0.859995 18.3 0.859995 19.13 1.39999 19.63C1.93999 20.14 2.81999 20.14 3.35999 19.63L12.59 10.93C13.13 10.42 13.13 9.59001 12.59 9.09001L3.36 0.390012C2.82 -0.119988 1.94 -0.119988 1.4 0.390012C0.859997 0.900012 0.859996 1.73001 1.4 2.23001L9.65 10.02L1.4 17.79Z"
        fill={props.color} />
    </Svg>

  )
}


