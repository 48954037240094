import { Image, StyleSheet, View } from 'react-native'
import React from 'react'
import { AppColors as COLORS } from '../common/AppColors'

type Props = {
  url?: string | null
  size?: number
}

export const ProfileImage = (props: Props) => {
  const style: any = {}

  if (props.size) {
    style.width = props.size
    style.height = props.size
    style.borderRadius = props.size / 2
  }

  return <View style={[styles.container, style]}>
    {
      props.url
        ? <Image source={{ uri: props.url }} style={styles.image} />
        : <Image source={require('../../assets/images/profile_default.png')} style={styles.image} />
    }
  </View>
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    width: 40,
    height: 40,
    backgroundColor: COLORS.gray.neutral5,
    overflow: 'hidden',
  },

  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
})
