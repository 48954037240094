import { observer, useLocalObservable } from 'mobx-react-lite'
import { Text } from 'react-native'
import React, { useEffect } from 'react'
import { runInAction } from 'mobx'
import { AppEvents, AppStateStore } from '../contexts/AppStateStore'
import { Moment } from 'moment-timezone'
import { relativeTime, utc } from '../common/Util'

type Props = {
  date: Moment
}

export const RelativeTime = observer((props: Props) => {
  const state = useLocalObservable(() => ({
    ageMinutes: utc().diff(props.date, 'minutes'),
  }))

  useEffect(() => {
    const listener = AppStateStore.eventBus.addRemovableListener(AppEvents.Tick, () => runInAction(() => state.ageMinutes = utc().diff(props.date, 'minutes')))
    return () => listener.remove()
  }, [])

  return <Text>{state.ageMinutes ? relativeTime(props.date) : 'Just now'}</Text>
})
