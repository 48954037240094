import React from 'react'
import { Svg, Path } from "react-native-svg"

type Props = {
  width: number
  height: number
  color: string,
  variant: 'fill' | 'outline'
}
export default function Calendar(props: Props) {
  return (
    <Svg
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 18 20" >
      {props.variant === 'fill' ? (
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d="M16 2H14V0H12.5V2H5.5V0H4V1C4 1.3 4 1.61 4 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM6 16H4V14H6V16ZM6 12H4V10H6V12ZM10 16H8V14H10V16ZM10 12H8V10H10V12ZM14 16H12V14H14V16ZM14 12H12V10H14V12ZM15 7.5H3V6H15V7.5Z"
          fill={props.color} />
      ) : (
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d="M8 14H10V16H8V14ZM8 12H10V10H8V12ZM4 12H6V10H4V12ZM4 16H6V14H4V16ZM3 7.5H15V6H3V7.5ZM12 12H14V10H12V12ZM12 16H14V14H12V16ZM18 4V18C18 19.1 17.1 20 16 20H2C0.9 20 0 19.1 0 18V4C0 2.9 0.9 2 2 2H4C4 1.61 4 1.3 4 1V0H5.5V2H12.5V0H14V2H16C17.1 2 18 2.9 18 4ZM16.5 18.5V3.5H1.5V18.5H16.5Z"
          fill={props.color} />
      )
      }

    </Svg>
  )
}






