import React from 'react'
import { StyleProp, TextStyle, Linking } from 'react-native'
import { Colors } from '../common/Colors'
import { Autolink } from 'react-native-autolink'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { RootNavigationParamList } from '../navigation/RootNav'
import { AppColors as COLORS } from '../common/AppColors'
import { AppStateStore } from '../contexts/AppStateStore'

type Props = {
  style?: StyleProp<TextStyle>
  children?: string
  bold?: boolean
  numberOfLines?: number
  color?: string
}

export const DefaultTextAutoLink = (props: Props) => {
  const navigation = useNavigation<StackNavigationProp<RootNavigationParamList>>()
  const linkColor = props.color ? props.color : COLORS.orange.primary

  const style: StyleProp<TextStyle> = {
    fontFamily: props.bold ? 'Rubik_700Bold' : 'Rubik_400Regular',
    color: Colors.DefaultTextColor,
  }

  const handleLinkPress = async (url: string) => {
    const allowedUrls = AppStateStore.allowedLinks
    const shouldOpenInExternalApp = allowedUrls.some((allowedUrl) => url.includes(allowedUrl))

    if (shouldOpenInExternalApp) {
      Linking.openURL(url)
    } else {
      navigation.navigate('WebView', { url })
    }

  }

  return <Autolink
    style={[style, props.style]}
    numberOfLines={props.numberOfLines}
    text={
      props.children ?? ''
    }
    onPress={handleLinkPress}
    linkStyle={{ color: linkColor, textDecorationLine: 'underline' }}
  />
}
