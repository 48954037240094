import { Keyboard, Platform, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import { runInAction } from 'mobx'
import React, { useEffect } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Attachment, AttachmentBar } from './AttachmentBar'
import { TextInputField } from './TextInputField'
import Icons from './icons/'
import { ActionButton } from './buttons/ActionButton'
import { AppColors } from '../common/AppColors'
import { requestAudioPermission } from '../helpers/recordAudio'
import { Audio } from 'expo-av'
import { getModalManager } from '../contexts/ModalContext'
import { RecordingStatus } from 'expo-av/build/Audio'
import { AudioProgressBar } from './messages/AudioProgressBar'
import { MessageAudioPlayer } from './messages/MessageAudioPlayer'
//import * as Sentry from 'sentry-expo'

type Props = {
  attachment?: Attachment
  onAttached: (attachment?: Attachment) => void
  message: string
  onMessageChanged: (message: string) => void
  onSendPressed: (callback?: () => void) => void
  isValid: boolean,
}

type State = {
  uploading: boolean
  inputHeight?: number
  showAttachmentButtons: boolean,

  /* Audio */
  hasAudioPermission: boolean
  recording: boolean
  playing: boolean
  loading: boolean
  recordingInstance?: Audio.Recording
  recordingDurationMillis: number
  recordingUrl?: string
  maxRecordingLengthSeconds: number
  playProgressMillis: number
  soundInstance?: Audio.Sound
}

export const SendMessageInput = observer((props: Props) => {
  const { t } = useTranslation()

  const state = useLocalObservable<State>(() => ({
    uploading: false,
    inputHeight: undefined,
    showAttachmentButtons: false,
    /* Audio */
    hasAudioPermission: false,
    recording: false,
    playing: false,
    loading: false,
    recordingInstance: undefined,
    recordingDurationMillis: 0,
    recordingUrl: undefined,
    maxRecordingLengthSeconds: 120,
    playProgressMillis: 0,
    soundInstance: undefined,
  }))

  const updateInputHeight = (height: number) => {
    runInAction(() => state.inputHeight = height)
  }

  const calculateInputHeightStyle = () => {
    if (state.inputHeight) {
      return {
        height: Math.min(120, Math.max(60, state.inputHeight + 20)),
      }
    } else {
      return undefined
    }
  }

  const inputRef = React.createRef<TextInput>()

  useEffect(() => {
    if (!props.attachment) {
      runInAction(() => state.showAttachmentButtons = false)
    }
  }, [props.attachment])

  const messageLeftElement = (): React.ReactElement => {
    return (
      <TouchableOpacity
        onPress={() => {
          runInAction(() => state.showAttachmentButtons = !state.showAttachmentButtons)
        }}
        style={{ paddingRight: 16 }}
      >
        <Icons.FileIcon width={22} height={22} />
      </TouchableOpacity>
    );
  }

  const recordAudio = async () => {
    if (state.recording) {
      await stopRecording();

      return;
    }

    if (Platform.OS !== 'web') {
      const hasPermission = await requestAudioPermission();

      if (!hasPermission) {
        getModalManager()
          .showModal({
            title: t('Error', 'Error'),
            message: t('Audio recording permission blocked', 'Audio recording permission has been disabled. Please enabled it in your phone settings.'),
          });

        return;
      }
    }

    startRecording();
  }

  const onRecordingStatusUpdate = (status: RecordingStatus) => {
    if (state.recording) {
      runInAction(() => state.recordingDurationMillis = status.durationMillis)

      if (status.durationMillis / 1000 >= state.maxRecordingLengthSeconds) {
        stopRecording().then()
      }
    }
  }

  const stopRecording = async () => {
    if (!state.recording || state.loading) {
      return
    }

    runInAction(() => state.loading = true)

    const status = await state.recordingInstance!.stopAndUnloadAsync()

    runInAction(() => {
      state.recording = false
      state.recordingDurationMillis = status.durationMillis
      state.recordingUrl = state.recordingInstance!.getURI()!
      state.loading = false
      state.playProgressMillis = 0
    })

    runInAction(() => props.onAttached({ type: 'audio', url: state.recordingUrl! }));
  }

  const startRecording = async () => {
    const recording = new Audio.Recording()

    try {
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      });

      await recording.prepareToRecordAsync({
        android: {
          extension: ".mp3",
          outputFormat: Audio.RECORDING_OPTION_ANDROID_OUTPUT_FORMAT_DEFAULT,
          audioEncoder: Audio.RECORDING_OPTION_ANDROID_AUDIO_ENCODER_DEFAULT,
          sampleRate: 44100,
          numberOfChannels: 2,
          bitRate: 128000,
        },
        ios: {
          extension: ".wav",
          audioQuality: Audio.RECORDING_OPTION_IOS_AUDIO_QUALITY_HIGH,
          sampleRate: 44100,
          numberOfChannels: 1,
          bitRate: 128000,
          linearPCMBitDepth: 16,
          linearPCMIsBigEndian: false,
          linearPCMIsFloat: false
        },
      } as any);

      recording.setOnRecordingStatusUpdate(onRecordingStatusUpdate);

      await recording.startAsync();

      // Update state to recording

      runInAction(() => {
        state.recording = true
        state.recordingDurationMillis = 0
        state.recordingInstance = recording
      });

    } catch (error) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(error)
      }
      else {
        //Sentry.Native.captureException(error)
      }
      console.log(error);

      getModalManager()
        .showModal({
          title: t('Error', 'Error'),
          message: t('There was an error starting the recording', 'There was an error starting the recording'),
        });
    } finally {
      runInAction(() => state.loading = false);
    }

  }


  const messageRightElement = (): React.ReactElement => {

    if (Platform.OS === 'web' && (!props.message && !props.attachment)) {
      return (
        <View>
          <ActionButton backgroundColor={AppColors.gray.neutral4}>
            <Icons.SendIcon color={AppColors.white} />
          </ActionButton>
        </View>
      )
    }

    if ((!props.message && !props.attachment) && !state.recordingUrl) {
      return (
        <View>
          <ActionButton
            onPress={recordAudio}
            backgroundColor={state.recording ? AppColors.red.secondary : AppColors.orange.primary}
          >
            <Icons.AudioIcon type='nonzero' width={24} height={24} color={AppColors.white} />
          </ActionButton>
        </View>
      );
    }

    return (
      <View>
        <ActionButton
          onPress={() => {
            props.onSendPressed(deleteAudio)
          }}
          backgroundColor={AppColors.orange.primary}
        >
          <Icons.SendIcon color={AppColors.white} />
        </ActionButton>
      </View >
    );
  }

  const deleteAudio = async () => {
    runInAction(() => {
      props.onAttached(undefined);
      state.recordingUrl = undefined
      state.playProgressMillis = 0
    });
  }

  const onDeleteAudio = async () => {
    Keyboard.dismiss()
    getModalManager()
      .showModal({
        type: 'bottom-drawer',
        drawerHeight: 280,
        title: t('Delete Recording', 'Delete Recording'),
        message: t('Are you sure you want to delete this recording', 'Are you sure you want to delete this recording?'),
        buttons: [
          {
            text: t('Yes Delete', 'Yes, Delete'),
            variant: 'primary',
            onPress: dismiss => {
              dismiss();
              deleteAudio();
            },
          },
          {
            text: t('No', 'No'),
            variant: 'secondary',
          },
        ]
      });
  }

  return (
    <View>
      {
        state.recordingUrl ?
          <View style={styles.recordingContainer}>
            <Text style={styles.recordingText}>Play audio...</Text>
            <View style={styles.recordedContainer}>
              <View style={{ flex: 1 }}>
                <MessageAudioPlayer url={state.recordingUrl} />
              </View>
              <TouchableOpacity
                style={{ marginLeft: 16 }}
                onPress={onDeleteAudio}
              >
                <Icons.TrashIcon width={20} height={20} color={AppColors.gray.neutral2} />
              </TouchableOpacity>

            </View>
          </View>
          : state.recording ?
            (
              <View style={styles.recordingContainer}>
                <Text style={styles.recordingText}>Recording...</Text>
                <View style={{ height: 20 }}>
                  <AudioProgressBar
                    playProgressMillis={state.recordingDurationMillis}
                    durationMillis={(state.maxRecordingLengthSeconds * 1000)}
                  />
                </View>
              </View>
            ) : (
              (props.attachment || state.showAttachmentButtons)
                ? <AttachmentBar
                  onAttached={props.onAttached}
                  attachment={props.attachment}
                  attachmentList={['file', 'photo', 'video']}
                />
                : null
            )
      }
      <View style={[styles.messageInputContainer]}>
        <TextInputField
          leftElement={messageLeftElement()}
          placeholder={t('Type a message', 'Type a message')}
          multiline={true}
          onContentSizeChange={e => updateInputHeight(e.nativeEvent.contentSize.height)}
          value={props.message}
          onChange={props.onMessageChanged}
          rightElement={messageRightElement()}
        />
      </View>
    </View>
  );
})

const styles = StyleSheet.create({
  messageInputContainer: {
  },
  messageInputWrapper: {
  },
  messageInput: {
    flex: 1,
    paddingHorizontal: 20,
    fontSize: 16,
  },
  attachButton: {
    height: '100%',
    width: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonSeparator: {
    height: '100%',
    width: 1,
    backgroundColor: '#ddd',
  },
  sendButton: {
    height: '100%',
    width: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  attachmentPreviewContainer: {
    width: 80,
    height: 80,
    margin: 10,
  },
  attachmentImage: {
    width: 80,
    height: 80,
    resizeMode: 'cover',
    borderRadius: 8,
  },
  attachmentVideo: {
    width: 80,
    height: 80,
    borderRadius: 8,
  },
  attachmentVideoPlayIconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  playIcon: {
    fontSize: 30,
    color: '#fff',
    textShadowColor: '#000',
    textShadowRadius: 4,
    position: 'absolute',
    bottom: -10,
    left: -10,
  },
  attachmentRemoveButton: {
    position: 'absolute',
    top: -10,
    right: -10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f00',
    borderRadius: 15,
    width: 30,
    height: 30,
  },
  attachmentRemoveIcon: {
    fontSize: 18,
    color: '#fff',
  },
  audioAttachmentContainer: {
    margin: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  audioAttachmentRemoveButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f00',
    borderRadius: 15,
    width: 30,
    height: 30,
  },
  fileAttachmentContainer: {
    margin: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  fileAttachmentRemoveButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f00',
    borderRadius: 15,
    width: 30,
    height: 30,
  },
  recordedContainer: {
    flexDirection: 'row',
  },
  recordingContainer: {
    backgroundColor: AppColors.gray.neutral6,
    marginVertical: 12,
    padding: 12,
    borderRadius: 8,
  },
  recordingText: {
    marginBottom: 6,
    fontSize: 14,
    color: AppColors.gray.neutral1,
    fontFamily: 'Rubik_400Regular',
  }
})
