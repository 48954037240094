import { observer, useLocalObservable } from 'mobx-react-lite';
import { StyleSheet, TouchableOpacity, View, ActivityIndicator, ScrollView, Text, TouchableWithoutFeedback, Platform } from 'react-native';
import React, { useEffect, useState } from 'react';
import { Audio, AVPlaybackStatus } from 'expo-av';
import { runInAction } from 'mobx'
import { AppColors } from '../../common/AppColors';
import Icons from '../icons';
import { AudioProgressBar, AudioProgressBarVariant } from './AudioProgressBar';
import { KonektiIcon } from '../../components/KonektiIcon'
import { API } from '../../api/API'
import { Audio as AudioType } from '../../api/schema/models/Audio'
import { getModalManager } from '../../contexts/ModalContext'
import { useTranslation } from 'react-i18next'
import { AppStateStore } from '../../contexts/AppStateStore'
import { SafeAreaView } from 'react-native-safe-area-context'
//import * as Sentry from 'sentry-expo'

interface Props {
  url: string;
  progressBarVariant?: AudioProgressBarVariant;
  sentMessage?: boolean
  uuid: string
}

interface State {
  loading: boolean;
  playing: boolean;
  playProgressMillis: number;
  durationMillis: number;
  soundInstance?: Audio.Sound;
  progressBarWidth: string;
  audio?: AudioType
  isLoadingTranscript: boolean
  isLoadingTranslate: boolean
  showTranslation: boolean
  translatedTranscript?: string
}

export const MessageAudio = observer(({
  url,
  progressBarVariant,
  sentMessage = false,
  uuid,
}: Props) => {
  const { t } = useTranslation()

  const state = useLocalObservable<State>(() => ({
    loading: false,
    playing: false,
    playProgressMillis: 0,
    durationMillis: 0,
    soundInstance: undefined,
    get progressBarWidth() {
      if (this.durationMillis === 0) {
        return `0%`
      } else {
        return `${Math.ceil(this.playProgressMillis / this.durationMillis * 100)}%`
      }
    },
    showTranslation: false,
    translatedTranscript: undefined as string | undefined,
    isLoadingTranscript: false,
    isLoadingTranslate: false,
    audio: undefined as AudioType | undefined,
  }))

  const [openTranscript, setOpenTranscript] = useState(false)

  useEffect(() => {
    loadAudio().then()
    return () => {
      unloadAudio().then()
    }
  }, [
    url,
  ])

  const loadAudio = async () => {
    runInAction(() => state.loading = true)
    await unloadAudio()

    if (url) {
      try {
        const soundObject = new Audio.Sound()
        const status = await soundObject.loadAsync({ uri: url }, {
          shouldPlay: false,
        })

        soundObject.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate)

        runInAction(() => {
          state.playing = false
          state.durationMillis = status.isLoaded ? (status.durationMillis ?? 0) : 0
          state.playProgressMillis = status.isLoaded ? (status.positionMillis ?? 0) : 0
          state.soundInstance = soundObject
        })
      } catch (err) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(err)
        }
        else {
          //Sentry.Native.captureException(err)
        }
      }
    }



    runInAction(() => state.loading = false)
  }

  const onPlaybackStatusUpdate = (status: AVPlaybackStatus) => {
    if (status.isLoaded) {
      if (status.didJustFinish) {
        state.soundInstance!.stopAsync().then()
      }

      runInAction(() => {
        state.durationMillis = status.durationMillis ?? 0
        state.playProgressMillis = status.positionMillis
        state.playing = status.isPlaying
      })
    }
  }

  const unloadAudio = async () => {
    if (state.soundInstance) {
      await state.soundInstance.unloadAsync()
      runInAction(() => state.soundInstance = undefined)
    }
  }

  const playAudio = async () => {
    if (state.soundInstance) {
      if (state.playing) {
        await state.soundInstance.stopAsync()
      } else {
        await state.soundInstance.playAsync()
      }
    }
  }

  const onOpenTranscript = async () => {

    if (openTranscript) {
      setOpenTranscript(false)
      return
    }


    runInAction(() => state.isLoadingTranslate = true)
    setOpenTranscript(!openTranscript)
    try {
      const audio = await API.getAudio(uuid)
      runInAction(() => state.audio = audio)

    } catch (err) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      getModalManager()
        .showModal({
          title: t('Error', 'Error'),
          message: t('There was an error loading the translate', 'There was an error loading the translate'),
        })
    }
    runInAction(() => state.isLoadingTranslate = false)
  }


  const translateTranscript = async () => {

    if (state.audio?.transcript === null) {
      return
    }
    let translate = !state.showTranslation

    runInAction(() => state.isLoadingTranslate = true)

    if (translate && !state.translatedTranscript) {
      if (!state.translatedTranscript) {
        try {
          const translation = await API.getAudioTranscript(uuid, AppStateStore.userLanguage)
          runInAction(() => state.translatedTranscript = translation)
        } catch (err) {
          if (Platform.OS === 'web') {
            //Sentry.Browser.captureException(err)
          }
          else {
            //Sentry.Native.captureException(err)
          }
          getModalManager()
            .showModal({
              title: t('Error', 'Error'),
              message: t('There was an error loading the translation', 'There was an error loading the translation'),
            })

          translate = false
        }
      }
    }

    runInAction(() => {
      state.isLoadingTranslate = false
      state.showTranslation = translate
    })
  }



  return (
    <View style={styles.attachedAudioContainer}>
      <View style={styles.container}>

        <TouchableOpacity style={styles.button} onPress={playAudio}>
          {
            state.playing
              ? <Icons.AudioPauseIcon color={AppColors.orange.primary} />
              : <Icons.AudioPlayIcon color={AppColors.orange.primary} />
          }
        </TouchableOpacity>

        <AudioProgressBar
          playProgressMillis={state.playProgressMillis}
          durationMillis={state.durationMillis}
          variant={progressBarVariant}
        />
        {
          !sentMessage && (
            <TouchableOpacity
              style={styles.transcriptIconContainer}
              onPress={onOpenTranscript}
            >
              <Icons.Files
                color={openTranscript ? AppColors.orange.primary : AppColors.gray.neutral2}
                height={15}
                width={15}
                variant='outline'
              />
            </TouchableOpacity>
          )
        }

      </View>
      {
        !sentMessage && openTranscript && (

          <View style={styles.transcriptAudioContainer}>

            <View style={{ borderBottomWidth: 0.5, borderBottomColor: AppColors.gray.neutral3, marginVertical: 3 }} />

            {
              state.isLoadingTranscript ?
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', height: 50 }}>
                  <ActivityIndicator size='small' color={AppColors.orange.primary} />
                </View>
                :
                <View style={styles.transcriptScroll}>
                  <SafeAreaView style={{ flex: 1 }} edges={['bottom']}>
                    <TouchableOpacity
                      style={styles.translateIconContainer}
                      onPress={translateTranscript}
                    >
                      <KonektiIcon
                        color={AppColors.orange.primary}
                        name={'translate-icon'}
                        size={20}
                        style={{ color: state.showTranslation ? AppColors.orange.primary : AppColors.gray.neutral2 }}
                      />
                    </TouchableOpacity>
                    {/* <ScrollView nestedScrollEnabled={true} style={{ flex: 1 }}> */}
                    {
                      state.isLoadingTranslate && (
                        <View style={styles.loading}>
                          <ActivityIndicator size='large' color={AppColors.orange.primary} />
                        </View>
                      )
                    }
                    <Text style={[styles.transcriptText, state.isLoadingTranslate && { color: AppColors.gray.neutral4 }]}>
                      {state.showTranslation ? state.translatedTranscript : state.audio?.transcript || t('No transcript available', 'No transcript available')}
                    </Text>
                    {/*  </ScrollView> */}
                  </SafeAreaView>

                </View>

            }

          </View>
        )
      }
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  durationText: {
    color: AppColors.white,
    fontFamily: 'Rubik_500Medium',
    fontSize: 12,
  },
  playbackStatus: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: AppColors.gray.neutral3,
    borderRadius: 8,
    marginRight: 6,
  },
  progressBar: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 0,
    backgroundColor: AppColors.blue.primary,
    borderRadius: 8,
  },
  button: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: AppColors.white,
    borderRadius: 20,
  },
  attachedAudioContainer: {
    minWidth: 240,
  },
  transcriptAudioContainer: {
    marginTop: 6,
    minWidth: 250,
    justifyContent: 'space-between',
    backgroundColor: AppColors.gray.neutral5,
    paddingHorizontal: 10,

    //height: 20,
  },
  transcriptText: {
    color: AppColors.gray.neutral1,
    //paddingHorizontal: 20,
    fontSize: 16,
  },
  translateIconContainer: {
    width: 35,
    height: 35,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  transcriptIconContainer: {
    width: 20,
    height: 20,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  transcriptScroll: {
    flex: 1,
    //height: 200,
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center'
  }
})
