import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconProps } from '.';
import { AppColors } from '../../common/AppColors';

const VideoIcon = ({
  color = AppColors.gray.neutral3,
  height = 20,
  width = 20,
}: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path d="M12.9079 6C14.2594 6 15.0683 6.77 15.0683 8.06V14.94C15.0683 16.23 14.2594 17 12.9079 17H6.96938C5.61786 17 4.80899 16.23 4.80899 14.94V8.06C4.80899 6.77 5.61786 6 6.96938 6H12.9079ZM12.9079 4H6.96938C4.49159 4 2.76123 5.67 2.76123 8.06V14.94C2.76123 17.33 4.49159 19 6.96938 19H12.8976C15.3754 19 17.1058 17.33 17.1058 14.94V8.06C17.116 5.67 15.3857 4 12.9079 4Z" fill={color} />
      <Path d="M21.795 17.77C21.4059 17.77 21.0271 17.68 20.6789 17.51L19.1636 16.77C18.2523 16.32 17.6892 15.42 17.6892 14.42V8.57998C17.6892 7.57998 18.2523 6.67998 19.1636 6.22998L20.6789 5.47998C21.4571 5.09998 22.3581 5.13998 23.0953 5.58998C23.8325 6.03998 24.2625 6.81998 24.2625 7.65998V15.34C24.2625 16.19 23.8223 16.96 23.0953 17.41C22.696 17.65 22.2455 17.77 21.795 17.77ZM21.795 7.22998C21.7335 7.22998 21.6721 7.23998 21.6107 7.26998L20.0953 8.01998C19.8803 8.12998 19.7472 8.33998 19.7472 8.57998V14.41C19.7472 14.66 19.8803 14.87 20.0953 14.98L21.6107 15.73C21.754 15.8 21.8871 15.79 22.01 15.71C22.1329 15.63 22.2148 15.5 22.2148 15.34V7.65998C22.2148 7.49998 22.1431 7.36998 22.01 7.28998C21.9383 7.24998 21.8666 7.22998 21.795 7.22998Z" fill={color} />
    </Svg>
  );
}

export default VideoIcon;